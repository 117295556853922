import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    scrollY: 0,
    displayModal: false,
    displayModalTitle: "",
    displayCallBack: "",
    displayModalKeywords: {},
    displayModalContent: "",
    displayModalAllowCancel: false,
    socialType: null,
    accountInfoEditOrigin: undefined,
};

const accountEdit = createSlice({
    name: "accountEdit",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        successfulBindSocial: (state, { payload }, status) => {
            const socialType = payload.payload;
            const { bindSocialAccountResponse } = payload.response;
            if (bindSocialAccountResponse.RC) {
                if (bindSocialAccountResponse.RC === 200) {
                    state.displayModal = true;
                    state.displayModalTitle = "success";
                    state.displayModalContent = "social_bind_success";
                    state.displayModalKeywords = {
                        socialType:
                            socialType && _.isString(socialType)
                                ? socialType.replace(/^\w/, (c) => c.toUpperCase())
                                : " ",
                    };
                }
            }
        },
        duplicateEmail: (state, { payload }) => {
            state.gDisplayModal = true;
            state.gDisplayModalTitle = "warning";
            state.gDisplayModalContent = "alert_email_exists_update";
        },
        failedBindSocialAccount: (state, { payload }) => {
            const socialType = payload;

            state.displayModal = true;
            state.displayModalContent = "social_bind_failed";
            state.displayModalKeywords = {
                socialType: socialType ? socialType.replace(/^\w/, (c) => c.toUpperCase()) : " ",
            };
        },
        successfulUnbindSocial: (state, { payload }, status) => {
            const socialType = payload.payload;
            const { unbindSocialAccountResponse } = payload.response;
            if (unbindSocialAccountResponse.RC) {
                if (unbindSocialAccountResponse.RC === 200) {
                    state.displayModal = true;
                    state.displayModalTitle = "success";
                    state.displayModalContent = "unbind_social_success";
                    state.displayModalKeywords = {
                        socialType: socialType ? socialType.replace(/^\w/, (c) => c.toUpperCase()) : " ",
                    };
                }
            }
        },
        unbindSocialDialog: (state, { payload }) => {
            const { socialType, toggle } = payload;

            if (toggle) {
                state.displayModal = true;
                state.displayModalAllowCancel = true;
                state.displayCallBack = "unbind";
                state.displayModalContent = "confirm_unbind_social_account";
                state.displayModalKeywords = {
                    socialType: socialType ? socialType.replace(/^\w/, (c) => c.toUpperCase()) : " ",
                };
                state.socialType = socialType;
            } else {
                state.displayModal = false;
                state.socialType = null;
            }
        },
        failedUnbindSocialAccount: (state, { payload }) => {
            const { socialType } = payload;

            state.displayModal = true;
            state.displayModalContent = "unbind_social_error";
            state.displayModalKeywords = {
                socialType: socialType ? socialType.replace(/^\w/, (c) => c.toUpperCase()) : " ",
            };
        },
        updateCustomerInfoSuccess: (state, { payload }) => {
            const RC = Number(_.get(payload, "response.RC"));
            if (RC === 400) {
                state.displayModal = true;
                state.displayModalAllowCancel = false;
                state.displayModalContent = "alert_forbidden_action";
                state.displayCallBack = "";
            } else if (RC === 403) {
                state.displayModal = true;
                state.displayModalAllowCancel = false;
                state.displayModalContent = "alert_forbidden_email";
                state.displayCallBack = "";
            } else if (RC === 452) {
                state.displayModal = true;
                state.displayModalTitle = "warning";
                state.displayModalAllowCancel = false;
                state.displayModalContent = "alert_email_exists_update";
                state.displayCallBack = "";
            }
        },
    },
});

export const {
    //to components
    setState,
    //to service
    clearData,
    failedBindSocialAccount,
    successfulBindSocial,
    unbindSocialDialog,
    successfulUnbindSocial,
    failedUnbindSocialAccount,
    updateCustomerInfoSuccess,
    duplicateEmail,
} = accountEdit.actions;

export default accountEdit.reducer;
