const exports = {
    store: "api/v8/store",
    storeReviews: "/api/v7/s_review",
    storeProducts: "/api/v7/cplst2",
    storeCatalog: "/api/v8/catalog",
    product: "/api/v7/pdtl",
    productSearch: "/api/v7/psearch",
    productReviews: "/api/v7/p_review",
    getVerifyCode: "api/rest/v7/vcd",
    getVerifyCodeWithOauth: "/api/rest/v8/vcd",
    emailReset: "api/v7/consumerresetpwd",
    confirmEmail: "api/v8/consumerConfirmEmail",
    ping: "/api/rest/v7/ping",
    logIn: "api/rest/v8/login",
    slogin: "api/rest/v8/slogin",
    customerInfo: "/api/rest/v8/customerinfo",
    QRParser: "api/v7/qrparser",
    QRParserV8: "api/v8/qrparser",
    signUp: "api/rest/v9/reg",
    orderDetail: "/api/rest/v8/orddtl",
    storeDetail: "api/v8/sdtl2",
    cartTotal: "/api/rest/v9/ctotal",
    guestCartTotal: "api/rest/v9/ctotal",
    instantCheckout: "/api/rest/v9/instant_checkout",
    allInOneOrder: "/api/rest/v7/aio",
    orderList: "/api/rest/v8/ordlst",
    forgotPassword: "/api/v7/forgetpwd",
    updatePassword: "/api/rest/v7/updatepwd",
    resetPassword: "api/v7/consumerresetpwd",
    giftCard: "/api/rest/v7/giftcard/0",
    giftCardHistory: "/api/rest/v7/gifthistory",
    giftCardClean: "/api/rest/v7/giftcard",
    productAndStore: "/api/v7/pdtlall",
    productAndStoreGroup: "/api/v8/group_purchase",
    productGroupOrders: "/api/v8/gp_orders",
    customerAddress: "/api/rest/v8/customerAddr",
    config: "/api/v7/appconfig",
    instantPay: "api/v7/instantpay",
    points: "/api/rest/v7/points",
    pointsList: "/api/rest/v7/pointslst",
    pointsDetails: "/api/rest/v7/pointsdtl",
    getDomain: "api/v7/domain",
    updatePhone: "/api/rest/v7/newphone",
    customerLogout: "/api/rest/v7/logout",
    bookmark: "/api/rest/v7/bookmark",
    customerEmail: "/api/rest/v7/newemail",
    storeList: "/api/v7/slst",
    errorReport: "api/v8/h5errors",
    unbind: "/api/rest/v7/unbind",
    reviews: "/api/rest/v7/reviews",
    comments: "/api/rest/v7/comment",
    shortComments: "/api/v7/scmts",
    homepage: "/api/v7/homepage",
    homepageShortcuts: "/api/v7/hs",
    paypalExpress: "/api/rest/v7/express",
    storesList: "/api/v7/slst",
    storeSubCategory: "/api/v7/ssc",
    storeCategoryType: "/api/v7/sfc",
    storeSortType: "/api/v7/sfs",
    storeFilterType: "/api/v7/sff",
    itemChangeRequest: "/api/rest/v10/order_update_request",
    getToken: "/api/rest/v8/payment_token",
    refCode: "/api/rest/v9/my_referral_code",
    emailReceipt: "/api/rest/v9/email_receipt",
    myReferrals: "/api/rest/v9/my_referrals",
    customerReferrals: "/api/rest/v9/my_referral_txn",
    storeCategories: "/api/v7/catlist",
    allowedPeriods: "/api/v8/eta_slots",
    googleAutoComplete: "/api/v8/autocomplete_proxy",
    storage: "api/v8/web_reg",
    contentPages: "api/v8/content_pages_list",
    contentPage: "api/v8/content_page",
    myUpsells: "/api/rest/v10/my_upsells",
    phoneValidation: "/api/rest/v8/phone_validation",
    reviewParms: "api/v8/review_parms?",
    addUpdateReview: "api/v8/sms_review",
    orderIdVerify: "api/rest/v9/order_id",
    deactivateAccount: "/api/rest/v8/customerinfo/delete",
};

export default exports;
