import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fingerPrint: "",
};

const storage = createSlice({
    name: "storage",
    initialState,
    reducers: {
        //from components
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        fetchLocaleStorageSuccess: (state, payload) => {
        },
    },
});

export const {
    //to components
    setState,
    fetchLocaleStorageSuccess,
} = storage.actions;

export default storage.reducer;
