import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ads: [],
    deals: [],
    highlights: [],
    shortcuts: [],
    recentSearches: [],
};

const homepage = createSlice({
    name: "homepage",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getHomePageDataSuccess: (state, { payload }) => {
            state.ads = _.get(payload, "ads", []);
            state.deals = _.get(payload, "deals", []);
            state.highlights = _.get(payload, "highlights", []);
        },
        getHomePageShortcutsSuccess: (state, { payload }) => {
            state.shortcuts = payload;
        },
        addRecentSearch: (state, { payload }) => {
            if (!state.recentSearches.includes(payload)) {
                state.recentSearches.push(payload);
            }
            state.recentSearches = state.recentSearches.filter((item) => item);
        },
        removeRecentSearch: (state, { payload }) => {
            const index = state.recentSearches.indexOf(payload);

            if (index !== -1) {
                state.recentSearches.splice(index, 1);
            }
            state.recentSearches = state.recentSearches.filter((item) => item);
        },
        clearRecentSearch: (state, { payload }) => {
            state.recentSearches = [];
        },
    },
});

export const {
    setState,
    getHomePageDataSuccess,
    getHomePageShortcutsSuccess,
    addRecentSearch,
    removeRecentSearch,
    clearRecentSearch,
} = homepage.actions;

export default homepage.reducer;
