import OAuth from "oauth-1.0a";
import { store } from "../../containers/app/store";
import _ from "lodash";
// Initialize
export const oauth = OAuth({
    consumer: {
        key: _.get(store.getState(), "account.config.key", "8fb7ec71f8b4e1f2ec28d2f8c3f7785a"),
        secret: _.get(store.getState(), "account.config.secret", "af035f0f340e090d5b51870f9a168acd"),
    },
});
