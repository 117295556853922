import { createSlice } from "@reduxjs/toolkit";

const toast = createSlice({
    name: "toast",
    initialState: {
        showToast: false,
        toastMessage: "",
        toastKeywords: {},
        toastClassName: "",
    },
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        toasted(state, { payload }) {
            state.showToast = true;
            state.toastMessage = payload.message;
            state.toastKeywords = payload.keywords;
            state.toastClassName = payload.className;
        },
        clearToast(state) {
            state.showToast = false;
            state.toastMessage = "";
            state.toastKeywords = {};
            state.toastClassName = "";
        },
    },
});

export const { setState, toasted, clearToast } = toast.actions;

export default toast.reducer;
