import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentTab: 1,
    phoneNumber: "",
    vc: "",
    email: "",
    nickname: "",
    country_code: "CA",
    counter: -1,
    password: "",
    showDialog: false,
    dialogTitle: "",
    dialogContent: "login",
    dialogOnClose: "",
    requestTextDisabled: true,
    countryCodeList: {},
    signUpTextDisabled: true,
    signUpEmailDisabled: true,
    isCountingDown: false,
    phoneDisplayPw: "password",
    emailDisplayPw: "password",
    pageLoading: false,
};

const signUp = createSlice({
    name: "signUp",
    initialState,
    reducers: {
        //from components
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setCurrentTab: (state, { payload }) => {
            state.currentTab = payload;
        },
        setPhoneNumber: (state, { payload }) => {
            state.phoneNumber = payload;
        },
        setVC: (state, { payload }) => {
            state.vc = payload;
        },
        setEmail: (state, { payload }) => {
            state.email = payload;
        },
        setNickname: (state, { payload }) => {
            state.nickname = payload;
        },
        setCountryCode: (state, { payload }) => {
            state.country_code = payload;
        },
        setCounter: (state, { payload }) => {
            state.counter = payload;
        },
        setPassword: (state, { payload }) => {
            state.password = payload;
        },
        toggleShowDialog: (state, { payload }) => {
            state.showDialog = payload;
        },
        setDialogTitle: (state, { payload }) => {
            state.dialogTitle = payload;
        },
        setDialogContent: (state, { payload }) => {
            state.dialogContent = payload;
        },
        setDialogOnClose: (state, { payload }) => {
            state.dialogOnClose = payload;
        },
        toggleIsCountingDown: (state, { payload }) => {
            state.isCountingDown = payload;
        },
        clearData: (state, { payload }) => {
            state = initialState;
        },
        toggleEmailDisplayPw: (state, { payload }) => {
            state.emailDisplayPw = payload === "password" ? "text" : "password";
        },
        //from service
        signUpVCTextRequestSuccess: (state, { payload }) => {
            state.isCountingDown = true;
        },
        signUpTextSuccess: (state, { payload }) => {
            state.pageLoading = false;
        },
        signUpEmailSuccess: (state, { payload }) => {
            state.pageLoading = false;
        },
    },
});

export const {
    //to components
    setState,
    setCurrentTab,
    setPhoneNumber,
    setVC,
    setEmail,
    setNickname,
    setCountryCode,
    setCounter,
    setPassword,
    toggleShowDialog,
    setDialogTitle,
    setDialogContent,
    setDialogOnClose,
    toggleRequestTextDisabled,
    setCountryCodeList,
    toggleSignUpTextDisabled,
    toggleSignUpEmailDisabled,
    toggleIsCountingDown,
    clearData,
    togglePhoneDisplayPw,
    toggleEmailDisplayPw,
    //to service
    signUpVCTextRequestSuccess,
    signUpTextSuccess,
    signUpEmailSuccess,
} = signUp.actions;

export default signUp.reducer;
