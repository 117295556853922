import _ from "lodash";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const storesAdapter = createEntityAdapter({
    selectId: (e) => (e.gid + "-" + e.nm).replace(/\s/g, "-").toLowerCase(),
});

const storesList = createSlice({
    name: "storesList",
    initialState: storesAdapter.getInitialState({ total: 0 }),
    reducers: {
        getStoresListSuccess: (state, { payload }) => {
            storesAdapter.addMany(state, payload.records);
            state.total = _.get(payload, "paging.total", 0);
            state.page = payload.page;
        },
        emptyStoresList: storesAdapter.removeAll,
    },
});

export const { getStoresListSuccess, emptyStoresList } = storesList.actions;

export const storesListSelectors = storesAdapter.getSelectors();

export default storesList.reducer;
