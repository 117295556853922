import React, { useState, useEffect } from "react";
import _ from "lodash";
import { FaPlus } from "react-icons/fa";
import { animatedButtonsSelector, editAnimatedButton } from "./slice";
import { connect, useDispatch } from "react-redux";

//time for button to go to cart
const ANIMATION_TIME_S = 0.56;

class AnimatedCartButton extends React.Component {
    render() {
        return _.get(this.props, "buttons", []).map((button) => (
            <AnimatedButton button={button} key={button?.id} style={{ zIndex: "1000px" }} />
        ));
    }
}

const AnimatedButton = ({ button }) => {
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            dispatch(editAnimatedButton({ id: button?.id, changes: { translateY: window.innerHeight - button.y } }));
        };
        window.addEventListener("resize", handleResize);
        if (!isLoaded) {
            setIsLoaded(true);
        }
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [button, dispatch, isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            if (button.translateX && button.translateY) {
                setTranslateX(button.translateX);
                setTranslateY(button.translateY);
            }
        }
    }, [isLoaded, button]);

    return (
        <div
            style={{
                zIndex: 1525,
                position: "fixed",
                left: button.x + 5,
                top: button.y - 5,
                transform: `translate3d(${translateX}px,0,0)`,
                transition: `all ${ANIMATION_TIME_S}s linear`,
            }}
        >
            <div
                className="storeProductButton addProduct floatingAnimatedButton"
                style={{
                    transform: `translate3d(0,${translateY}px,0)`,
                    transition: `all ${ANIMATION_TIME_S}s cubic-bezier(0.5, -0.6, 1, 0.4)`,
                }}
            >
                <FaPlus />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    buttons: animatedButtonsSelector.selectAll(state),
});

const mapDispatchToProps = (state) => {};

AnimatedCartButton = connect(mapStateToProps, mapDispatchToProps)(AnimatedCartButton);

export default AnimatedCartButton;
