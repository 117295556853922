import en from "./en";
import zh from "./zh";
import kr from "./kr";
import jp from "./jp";
import zhHant from "./zh-Hant";
import es from "./es";
import frLang from "./fr-lang";
import de from "./de";
import { createIntl } from "react-intl";
import { store } from "../containers/app/store";

let messages = {
    en,
    zh,
    kr,
    "zh-Hant": zhHant,
    jp,
    es,
    "fr": frLang,
    de,
};

function getIntlMessages(language = "en") {
    let currentMessages = messages[language] || {};
    return currentMessages;
}

export function getIntl() {
    const state = store.getState();
    const { lan = "" } = state.settings;

    return createIntl({ locale: lan, messages: getIntlMessages(lan) });
}

const exports = {
    getIntlMessages,
};

export default exports;
