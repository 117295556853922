const exports = {
    " ": " ",
    go_back: "返回",
    notifications: "通知",
    settings: "設置",
    logout: "簽退",
    logged_out_error: "您已經簽退了，請登錄後再試。",
    logged_out_success: "您已成功簽退。",
    confirm_logout_text: "您確認要簽退嗎?",
    ok: "確定",
    error: "錯誤",
    no: "無",
    yes: "有",
    language: "語言",
    account: "帳戶",
    login: "登錄",
    cancel: "取消",
    store: "商家",
    products: "產品",
    restaurant: "餐飲",
    groupsale: "團購",
    travel: "旅遊",
    shopping: "購物",
    service: "服務",
    save: "保存",
    confirm: "確認",
    success: "成功",
    loading: "載入中",
    warning: "警告",
    general_error: "發生了未知錯誤，請稍後再試",
    orders: "我的訂單",
    click_to_input_address: "請點擊選擇地址",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    edit: "編輯",
    input: "自定義",
    years: "年",
    quarters: "季",
    months: "月",
    weeks: "周",
    days: "天",
    days_2: "日期",
    time: "時間",
    times: "時間",
    hours: "小時",
    minutes: "分鐘",
    seconds: "秒",
    milliseconds: "毫秒",
    today: "今天",
    tomorrow: "明天",
    yesterday: "昨天",
    this_week: "本星期",
    last_week: "上个星期",
    this_month: "這個月",
    last_month: "上個月",
    last: "上個",
    next: "下一個",
    now: "现在",
    mon: "星期一",
    tue: "星期二",
    wed: "星期三",
    thu: "星期四",
    fri: "星期五",
    sat: "星期六",
    sun: "星期天",
    mo: "星期一",
    tu: "星期二",
    we: "星期三",
    th: "星期四",
    fr: "星期五",
    sa: "星期六",
    su: "星期天",
    share: "分享",
    dont_show_this_again: "不再顯示此內容",
    update_order: "Update Order",
    schedule_for_later: "預訂",
    ready_in_asap: "準備時間{start}-{end}分鐘",
    when_would_you_like_to_order: "您想何時要",
    no_shipping_address_selected: "未提供配送地址。",
    change: "更改",
    start_order: "開始下單",
    order_for: "訂購",
    jan: "1月",
    feb: "2月",
    mar: "3月",
    apr: "4月",
    may: "5月",
    jun: "6月",
    jul: "7月",
    aug: "8月",
    sep: "9月",
    oct: "10月",
    nov: "11月",
    dec: "12月",
    view_more: "查看更多",
    viewing: "正在查看",
    show_filters: "顯示過濾器",
    reward_history_filter: "積分記錄過濾器",
    date_range: "日期範圍",
    my_referrals: "我的推薦",
    how_to_do_it: "如何領取獎勵積分及獎勵積分規則",
    how_to_do_it_referrals:
        "1. 向您的朋友或社交媒體分享帶您個人推薦碼的鏈接或二維碼，如果他們之前沒有被其他人推薦並且他們使用您提供的鏈接進行下單，他們將成為您的被推薦人。{br}{br}2. 來自被推薦人當前的訂單和未來的訂單，首單可獲得獎勵積分為: 稅前消費金額 * {v2pCombined}; 舉例：稅前消費$ {exampleBasePurchase},那麼獎勵積分為 {exampleBasePurchase} * {v2pCombined} = {exampleResultPoints}；首單之後的每一個訂單您都將獲得獎勵積分，即稅前消費金額 * {v2pCustomerRef}；舉例：稅前消費$ {exampleBasePurchase}，獎勵 {exampleBasePurchase} * {v2pCustomerRef} = {exampleResultPoints3} 積分。如果您分享的鏈接讓已被別人綁定的推薦人使用下單，那麼您將獲得一次性獎勵積分為: 稅前消費金額 * {v2pOrderRef}；舉例：稅前消費$ {exampleBasePurchase}，一次性獎勵 {exampleBasePurchase} * {v2pOrderRef} = {exampleResultPoints2} 積分。{br}{br}3. 比如：A推薦B，B尚未成為別人的被推薦人，B下單後A將獲得B首單的稅前消費金額 * {v2pCombined} 作為獎勵積分，之後B每次下單，A都可以獲得B稅前消費金額 * {v2pCustomerRef} 作為獎勵積分。如果B已經成為別人的被推薦人，B通過A分享的鏈接下單後，A將一次性獲得B的稅前消費金額 * {v2pOrderRef} 作為獎勵積分。{br}{br}註解：本店每 {p2v} 個獎勵積分可兌換 $1。",
    copy_ref_url: "複製推薦網址",
    total: "總計",
    generate_qr_code: "生成二維碼",
    ref_orders: "訂單",
    income: "收入",
    check_how_much_money_earned: "查看您賺了多少錢",
    check_how_much_rewards_earned: "您獲得了多少獎勵積分",
    redeemable: "可兌換",
    total_redeemed: "已兌換的總數",
    sales: "銷售",
    check_how_many_sales: "查看您的銷售量",
    rewards_history: "獎勵歷史",
    check_daily_history: "查看您的每日獎勵交易",
    orders_info: "查看您的朋友下了多少個訂單",
    referrals_info: "推薦信息",
    referrals_info_desc: "這是您推薦的客戶信息。點按每個按鈕即可獲取相應訂單和銷售信息",
    admin_reward_action: "管理員獎勵積分",
    points_redeemed: "用獎勵積分支付訂單",
    customer_invitation: "推薦客戶積分",
    order_invitation: "推薦訂單積分",
    admin_credit: "商家獎勵積分",
    sales_rule: "購買訂單積分",
    admin_revert_points: "提取積分",
    refunded_order: "訂單退款",
    ref_successfully_copied: "推薦碼成功複製",
    click_tips_with_helper_text: "小費(點擊金額可修改)",
    click_set_points: "點擊兌換積分",
    click_to_search_address: "点击搜索地址",
    click_please_input_shipping_addresss: "點擊搜索地址",
    click_please_input_pickup_addresss: "點擊選擇自取位置",
    click_please_input_payment_method: "點擊選擇支付方式",
    click_to_show_on_map: "點擊打開地圖",
    tap_to_search_address: "點按搜索地址",
    sorry_search: "暫無搜索結果",
    try_again_search: "換個關鍵詞試試",
    recent_searches: "最近搜索",
    recommendations: "推薦",
    deals: "優惠",
    view_all: "查看全部",
    products_not_available: "以下產品現在不能購買，將從購物車中移除:",
    shopping_cart_empty: "您的購物車是空的",
    not_available_dialog_title: "現在不可下單",
    not_available_dialog_captain: "此產品僅在下面的時間範圍可以下單",
    sorry_product_not_available: "抱歉，此產品僅在以下時間提供",
    feedback: "用戶反饋",
    download_qr: "下載二維碼",
    download_qr_mobile: "長按圖片保存至相冊",
    download_poster: "下載海報",
    download_poster_mobile: "長按圖片保存至相冊",
    rate_the_product: "產品評價",
    are_you_sure_cancel_review: "您確定要捨棄此評論嗎？",
    submit: "重置",
    review_order_placeholder: "您的評價將幫助別人做出更好的選擇",
    review_submitted_message: "感謝您的參與，評價將在驗證後發布",
    please_input_or_select: "請輸入或選擇滿意的理由",
    review_service: "評價商家服務",
    rate_hint: "點按星標進行評分",
    not_available: "不可用",
    current_not_available: "目前不可用",
    allow_reservation: "可預約",
    has_tv: "有電視",
    has_free_wifi: "無線上網",
    has_outdoor_seating: "戶外座位",
    kid_friendly: "適合兒童",
    group_friendly: "適合聚會",
    business_friendly: "商業氛圍",
    street_parking: "路邊停車",
    free_parking: "免費停車",
    paid_parking: "收費停車",
    noise_level: "噪音程度",
    average: "一般",
    loud: "有點吵",
    very_loud: "很吵",
    casual: "非正式的",
    business_casual: "商務休閒",
    smart_casual: "時尚休閒",
    business: "商務",
    semi_formal: "半正式",
    has_alcohol: "酒類服務",
    environment: "環境",
    attire: "穿著",
    upscale: "高端",
    classy: "優雅",
    hipster: "潮人",
    intimate: "私密性",
    no_alcohol: "不提供酒精飲料",
    beer_and_wine: "提供酒水",
    full_bar: "標準酒吧",
    write_your_review_here: "寫下你對本商家的評價",
    rate_the_store: "商家評價",
    more_information: "更多信息",
    merchant_information: "商家信息",
    additional_pages: "其它頁面",
    store_info: "商家詳情",
    store_overview: "商家概况",
    opens: "營業",
    closes: "打烊",
    current_store: "當前商家",
    order: "訂單",
    my_order: "我的訂單",
    deliver_to: "配送地址",
    delivery: "配送",
    pickup: "自取",
    takeout: "自取",
    options: "選規格",
    check_out: "結賬",
    pick_one: "必選1項",
    pick_amount: "必選{min}項",
    pick_range_up_to: "可選{max}項",
    pick_range_required: "必選{min}-{max}項 ",
    pick_optional: "可選",
    pick_one_with_name: "{nm}需要必選一項",
    pick_amount_with_name: "{nm}需要必選{min}項",
    pick_range_up_to_with_name: "{nm}最多選擇{max}項",
    pick_range_required_with_name: "{nm}需要選擇{min}-{max}項",
    up: "起",
    search_products: "搜索產品",
    current_menu: "當前菜單",
    all_menus: "全部菜單",
    search: "搜索",
    discount_by: "滿{y}減{x}",
    discount_by_without_price: "減{x}",
    store_closed: "打烊",
    are_you_sure_clear_cart: "您確定要清空購物車嗎?",
    are_you_sure_keeping_cart: "購物車中有已保存的物品。您想將物品保留在購物車中嗎？",
    clear_the_cart: "清空購物車",
    keep_the_cart: "保留",
    clear: "清空購物車",
    results: "搜索結果",
    search_results: "{x} 个搜索结果",
    pending_acceptance: "待接單",
    delivery_time: "配送時間",
    eatin: "堂食",
    order_pickup_time: "取貨時間: {time}",
    order_delivery_time: "配送時間: {time}",
    discount_amount: "已減{currentDiscount}",
    remaining: "再買{remaining}減{amount}",
    remaining_delivery: "還差{remaining}起送",
    remaining_pickup: "添加{remaining}待取",
    menu_available_at: "此菜單有效時間為：@{available}…",
    please_input_name: "請輸入名字",
    no_address_head: "沒有已保存的地址",
    no_address_message: "您目前沒有任何地址記錄。",
    select_branch: "請選擇分店",
    store_on_hold: "抱歉，本店目前暫不接受網上訂單",
    out_of_stock: "售罄",
    load_more: "加載更多",
    no_order_message: "沒有訂單記錄。當您有新訂單或者結束訂單後將會在這裡出現。",
    no_order: "沒有訂單記錄。",
    create_an_order: "下單",
    no_more_data: "沒有更多了",
    no_search_results: "沒有搜索結果",
    group_purchase: "惠民團",
    show_more: "顯示更多",
    show_less: "收起",
    purchase_history: "購買歷史",
    start_time: "開始時間",
    end_time: "結束時間",
    expired: "已經結束",
    offer_expires_in: "距離結束還有: {time}",
    remaining_stock: "庫存 {count}",
    people_ordered: "{amt} 人已團",
    offer_starts_in: "接龍開始還有: { time }",
    offer_starts_on: "開始時間: {time}",
    offer_ends_on: "結束時間: {time}",
    select_category: "選擇目錄",
    selection_error_min: "{option}最少要選{min}項",
    no_giftcard_message: "您擁有的禮品卡將出現在這裡。",
    upsell_products: "您可能還喜歡...",
    switch_to_dine_in: "確認堂食",
    switch_to_in_store: "店內下單確認",
    switch_to_dine_in_message: "請輸入您的餐桌號以進行堂食點餐，點擊取消將保持在{type}訂單類型",
    surcharge: "附加費",
    store_surcharge: "附加費",
    order_surcharge: "訂單附加費",
    item_total: "商品小計",
    surcharge_paid: "附加費(已支付)",
    surcharge_unpaid: "附加費(未支付)",
    related_id: "相關訂單 #",
    related: "相關",
    surcharege_paid_message: "此订单不包括附加费。请参考订单 #{id}",
    surcharge_paid_to_message: "本訂單是用來支付訂單 #{id}的附加費",
    switch_to_dine_in_confirm: "看來您目前不在商家，您確定是堂食就餐嗎？",
    switch_to_in_store_confirm: "看來您目前不在商家，您確定是店內下單嗎？",
    pull_to_refresh: "Pull to Refresh",
    no_products_record: "找不到產品.",
    delivery_locations: "配送區域/時間",
    pickup_locations: "自取位置",
    min_amount: "最少金額",
    min_order_amount: "最少訂單金額",
    free_delivery_amount: "免費配送金額",
    delivery_times: "配送時間",
    pickup_times: "自取時間",
    time_range: "時間段",
    store_location: "商家位置",
    pickup_locations_schedule: "自取位置/時間",
    roll_over_image_to_zoom_in: "鼠標懸停圖片放大",
    about: "關於",
    product_information: "產品簡介",
    product_details: "商品詳情",
    buy_now: "立即購買",
    see_more_product_details: "查看更多商品詳情",
    video: "視頻",
    page_of: "第{page}頁, 共{totalNumOfPages}頁",
    warning_max_purchase_limit_reached: "提醒: 已達到最大購買限制",
    max_purchase_limit_reached_msg: "您最多只能購買{max_sale_qty}個此產品。",
    warning_max_purchase_qty_exceeded_msg: "以下商品已超過最大購買數量。商品數量將被修改為最大可購買數量。{item_list}",
    max_purchase_qty: "最大購買數量: {qty}",
    warning_min_sale_qty_not_met_msg: "以下商品不滿足最低購買數量。",
    min_purchase_qty: "最少訂購數量: {qty}",
    remove_items: "刪除商品",
    increase_item_quantities: "增加商品數量",
    store_offline_message: "抱歉，我們目前不接受在線訂單。請稍後再試。",
    order_payment_status_error: "商家已經接單，請當面支付。",
    order_comment_status_error: "商家已經開始準備此訂單，如果有任何更改，請聯繫商家。電話：{phone}。",
    oos_items_cannot_be_added: "The item(s) {oos_items} are not available and could not be added to the cart.",
    order_cancelled: "訂單已取消",
    pending_store_confirmation: "待商家接單",
    pending_customer_confirmation: "待客戶確認",
    store_accepted: "商家已接單",
    order_preparing: "商家準備中",
    store_is_preparing: "商家準備中",
    ready_pickup: "已經",
    ready_courier_pickup: "訂單已經準備好配送ß",
    rejected_by_courier: " 配送員拒單",
    courier_assigned: " 已安排配送員",
    courier_accepted: "配送員接單",
    courier_heading_to_store: "配送員前往商家",
    courier_arrived_at_store: "配送員抵達商家",
    "courier_collected_pa​​ckage": "配送員已取單",
    courier_heading_to_customer: "正在配送",
    courier_arrived_at_customer: "配送員抵達客戶處",
    delivered_order: "配送完成",
    order_changes_description: "您的訂單已更改。 請參考建議的更改並儘快確認，以免延誤您的訂單。",
    confirm_item_change: "確認訂單更改",
    are_you_sure_reject: "拒絕建議的更改將導致該產品從訂單中移除，您確認要繼續嗎？",
    accepted: "Accepted",
    rejected: "Rejected",
    added: "Added",
    removed: "Removed",
    updated: "Updated",
    updated_an_item: "更改了產品",
    added_an_item: "增加了產品",
    removed_an_item: "移除了產品",
    replaced_an_item: "更換了產品",
    are_you_sure_change_order_confirm: "您確定要確認對此訂單所做的更改嗎？",
    order_change_return_price_delta: "更改後的訂單的總金額為{new_price}，將在完成訂時退還差額給您。",
    order_change_pay_price_delta: "更改後的訂單的總金額為{new_price}，您的信用卡的最終消費額將為該金額。",
    order_change_pay_new_price:
        "更改後的訂單總額是{new_price}，您需要支付差價{price_delta}。請及時支付附加費以免延誤您的訂單。",
    order_change_reject_all_refund: "您已拒絕所有建議的更改。確定要取消訂單嗎？取消後，付款將獲得退款{price_delta}。",
    order_change_refund: "更新後的訂單總金額為{new_price}，系統將退還{price_delta}的差價給您。",
    order_again_adds_items: "[再來一單]會自動將相同的商品添加到購物車",
    hide_menu: "隱藏產品",
    show_menu: "顯示產品",
    copy: "複製",
    copied_success: "已經拷貝訂單號到剪切板",
    shipping_time: "自取時間",
    pickup_time: "自取時間",
    request_due_time: "就緒時間",
    requested_pickup_time: "要求自取時間",
    requested_delivery_time: "要求配送時間",
    ready_pickup_time: "自取準備時間",
    estimated_delivery_time: "預計送達時間",
    order_time: "下單時間",
    shipping_info: "配送信息",
    order_info: "訂單信息",
    order_detail: "訂單詳情",
    alipay: "支付寶",
    union_pay: "銀聯借記卡",
    union_pay_express: "銀聯信用卡",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "信用卡",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "電子或郵件轉賬",
    emt_description: "下單後用電子或郵件轉賬付款",
    payment_received: "已付款",
    payment_pending: "待支付",
    payment_cancelled: "付款取消",
    payment_failed: "支付失敗",
    emt_payment_note_long: "您為此訂單選擇了E-Transfer/EMT，請儘早安排付款並通知店家，以免延誤您的訂單。",
    emt_payment_password: "請將 {amount} 發送至 {emt_account}，將支付密碼設置為 {emt_password}",
    emt_payment_account: "請將 {amount} 發送至 {emt_account}",
    free: "無需支付",
    dine_in: "堂食",
    in_store: "店內",
    flatrate: "平價配送",
    freeShipping: "數字資產",
    instantCheckout: "即刻結帳",
    pending: "待接單",
    processing: "處理中",
    payment_review: "付款審查中",
    completed: "已完成",
    canceled: "已取消",
    closed: "已關閉",
    pending_payment: "等待店家確認",
    holded: "保留",
    cancel_order: "取消訂單",
    order_number: "訂單 #",
    order_status: "訂單狀態",
    order_date: "下單時間",
    order_type: "訂單類型",
    shipping_method: "運輸方式",
    expected_time: "要求時間",
    customer: "用戶姓名",
    party_size: "人數",
    delivery_address: "配送地址",
    pickup_location: "自取地址",
    store_discount: "商家折扣",
    coupon: "優惠券",
    shipping_and_handling: "配送費",
    shipping_and_handling_with_distance: "配送費 ({distance})",
    order_total: "總額",
    giftcard_payment: "禮品卡付款",
    points_payment: "積分付款",
    points_payment_with_points: "積分付款 ({x})",
    comments: "訂單備註",
    reorder: "再來一單",
    general_order_error: " 發生錯誤。請回到訂單列表頁重試。",
    order_unexist: "沒有發現訂單記錄。",
    you: "您",
    cancel_order_warning: "您確定要取消訂單嗎?",
    cancel_order_success: "您在{store_name} 的訂單#{order_id}已經取消。",
    cancel_order_failed: "未能取消訂單，請稍後再試。",
    pay_now_unavailable: "網上付款不可用",
    no_online_payment: "抱歉，本商家目前不接受在線支付。請稍後重試或直接與商家聯絡。",
    pay_now: "現在付款",
    write_review: "寫評論",
    update_review: "修改評價",
    write_review_for: "為{name}寫評論。",
    overall: "總體",
    add_a_review: "添加評價",
    rate_our_service: "服務評價",
    rate_our_delivery: "配送評價",
    post: "發布",
    requested_time: "要求時間",
    scheduled_time: "預計時間",
    ord_dt: "下單時間",
    exp_dt: "期待時間",
    ord_total: "訂單總價",
    s_method: "訂單類型",
    p_method: "支付方式",
    start_processing_message: "您確定要標註訂單為正在處理嗎?",
    mark_as_complete_message: "您確定要把訂單標註為完成嗎？",
    cancel_order_message: "您確定要取消訂單嗎?",
    complete: "已完成",
    reject: "拒絕",
    total_refunded: "退款總額",
    refund_amount: "退款金額",
    refund_all: "全額退款",
    remark: "備註",
    "edit-shipping-fee-message": "更改運費將導致取消原訂單並用修改後的運費創建新訂單, 您確定要繼續嗎？",
    edit_shipping_info: "編輯配送地址",
    additional_info: "額外信息",
    estimatedDeliTime: "預計配送時間",
    exp_time: "要求配送時間",
    "create-new-order-message": "修改訂單將會取消此訂單，並且創建一個新的訂單，您確定要修改嗎？",
    order_and_account_information: "訂單與客戶信息",
    address_info: "地址信息",
    shipping_address: "配送地址",
    payment_and_shipping_method: "付款和送貨方式",
    payment_info: "支付信息",
    shipping_handling_info: "配送信息",
    item_order: "訂單物品",
    raw_total: "物品小結",
    notes_for_this_order: "訂單注釋",
    alert_on_notification: "發消息時警告",
    sub_comment: "提交評論",
    modify_discount: "修改折扣",
    add_discount: "添加折扣",
    modify_tips: "修改小費",
    add_tips: "添加小費",
    discount: "折扣",
    modify_shipping_fee: "修改配送費",
    edit_payment: "更改支付",
    edit_extra: "修改信息",
    edit_payment_method: "更改支付方式",
    "select_receipt(s)_to_print": "選擇要打印的收據",
    change_payment_to: "您確定要將付款方式更改為",
    handling: "處理",
    coupon_discount: "優惠券折扣",
    phone: "電話號碼",
    email: "電子郵件",
    email_receipt: "電郵收據",
    email_receipt_sent_to: "電郵發票已發送至",
    unpaid: "未付款",
    admin: "管理員",
    cust: "客戶",
    other_payment: "其他付款",
    access_denied: "抱歉，您無權查看此訂單",
    please_pay_now: "訂單尚未支付，您可以在線支付。",
    are_you_sure_cancel_order: "您確定要取消訂單嗎?",
    please_pay_by_x: "請在{x}內完成支付，超時後訂單將自動取消",
    reward: "獎勵",
    reward_points: "獎勵積分",
    shipment_tracking_info: "快遞追踪信息",
    carrier: "快遞公司",
    tracking_number: "運單號碼",
    register: "註冊",
    vc_login: "手機驗證登錄",
    resend: "重新發送",
    pwd_login: "賬號登錄",
    email_phone_placeholder: "手機號或電子郵件",
    password: "密碼",
    vc: "驗證碼",
    get_vc: "發送驗證碼",
    titleS: "夠淘",
    alert_login_succeed: "登錄成功",
    alert_phone_not_found: "此號碼尚未註冊",
    alert_too_many_request: "您已達到請求驗證碼次數的上限。請在 15 分鐘後重試。",
    alert_phone_length: "無效的的手機號!",
    alert_phone_vc_not_match: "手機驗證碼不匹配",
    alert_login_failed_general: "登錄失敗，請稍後再試。",
    alert_login_failed: "無效的用戶名/密碼.",
    forget_pwd: "忘記密碼",
    state_reg: "如果您尚未在我們的網站上註冊，通過短信登錄後系統將為您創建一個帳戶",
    cos_privacy_statement: "登錄即表示您同意Goopter的{cos}和{privacy}",
    cos_privacy_statement_register: "註冊後，即表示您同意Goopter的{cos}和{privacy}",
    cos: "使用條款",
    privacy: "隱私政策",
    or_login_with_social: "或用社交帳號登錄",
    social_redirect_text: "要使用您的社交帳戶登錄，您將被重定向到本商家的宿主網站，您要繼續嗎？",
    social_redirect_text_cart:
        "要使用您的社交帳戶登錄，您將被重定向到本商家的宿主網站，但是您的購物車中的產品不能被結轉，您要繼續嗎？",
    social_login_failed: "{socialType}賬號登錄出錯，請重試。",
    check_out_as_guest: "匿名下單",
    email_placeholder: "電子郵件",
    c_tn: "用戶電話",
    nickname: "暱稱",
    alert_invalid_request: "無效的請求",
    alert_phone_exists: "此號碼已經使用",
    alert_bind_phone_conflict_title: "號碼已經註冊",
    alert_bind_phone_phone_exists:
        "這個號碼已經在另外一個賬戶中註冊了，如果您需要用這個號碼下單的話請簽退後用此號碼登錄再下單；您也可以選擇用另外一個號碼來下單。",
    alert_bind_phone_phone_exists_guests:
        "這個號碼已經在另外一個賬戶中註冊了，如果您需要用這個號碼下單的話請用此號碼登錄再下單；您也可以選擇用另外一個號碼來下單。",
    alert_bind_phone_conflict:
        "您嘗試使用的號碼已在另一個帳戶中註冊，如果要使用該號碼下單，點擊繼續按鈕會將當前帳戶與此號碼綁定，如果您想使用另一個號碼下單，請單擊取消然後嘗試另一個號碼",
    alert_vc_input_time_expired:
        "如果您在1分鐘內未收到短信驗證碼，則表示您的號碼不正確或您的號碼無法正確接收短信。{br}{br}請確保您使用的是正確的號碼或使用其他號碼重試。",
    alert_reg_success_text: "您現在可以用您的電話號碼登錄",
    alert_reg_success_email: " 您現在可以用您的電子郵件地址登錄",
    alert_reg_failed: "註冊失敗.",
    alert_email_length: "無效郵件地址",
    store_popup_sold: "已售",
    distance: "距離",
    prep_time: "準備時間",
    min_delivery_amnt: "最少配送金額",
    max_delivery_dist: "最遠配送距離",
    public_notice: "公告",
    discounts: "折扣信息",
    discount_message: "滿{price}減{discount}",
    discount_message_without_price: "減{discount}",
    credit_card_added: "已加信用卡",
    map_search_placeholder: "請輸入街道地址",
    exceed_delivery_distance_select:
        "提供的送貨地址超過了商家的最大配送距離 {delivery_distance} {distance_unit}。 請選擇另一個送貨地址。",
    exceed_delivery_distance_input:
        "提供的送貨地址超過了商家的最大配送距離 {delivery_distance} {distance_unit}。 請選擇另一個送貨地址。",
    address_not_in_delivery_zone: "提供的送貨地址不在商家的送貨區域範圍內。請選擇另一個送貨地址。",
    unsuccessful_checkout: "結帳不成功。請再試一次。",
    invalid_shp_mtd: "結帳不成功。無效配送方式，請嘗試另一種配送方式。",
    pay_later_cash_description: "當面現金支付",
    pay_later_card_description: "當面刷卡支付",
    continue: "繼續",
    customer_info: "客戶信息",
    please_leave_your_comment: "添加備註",
    order_now: "盡快",
    order_success: "訂單已發出",
    view_order: "查看訂單",
    secure_connection: "信息將通過加密方式安全傳送",
    confirm_order: "確認訂單",
    table_number: "桌號",
    back_to_store: "返回商店",
    subtotal: "小計",
    delivery_fee: "配送費",
    total_before_tax: "稅前金額",
    tax: "稅",
    tips: "小費",
    tips_with_helper_text: "小費(點擊金額可修改)",
    quantity: "數量",
    total_paid: "已付款",
    total_due: "應付金額",
    refund: "退款",
    payment_method: "支付方式",
    estimate_shipping_fee: "預估配送費",
    shipping_option: "配送方式",
    pick_shipping_option: "選擇一個配送方式",
    shipping_option_title: "運費: ",
    pay_later: "稍後刷卡支付",
    cash: "稍後現金支付",
    credit_card: "信用卡",
    pay_by_credit_card: "信用卡支付",
    giftcard: "禮品卡",
    points: "積分",
    points_and_giftcard: "積分和禮品卡",
    wechat_pay: "微信支付",
    address_customer_info: "Address Customer Info",
    c2c_regular: "普通配送",
    c2c_express: "快速配送",
    S01: "無需配送",
    S02: "平價配送",
    S03: "表單費率",
    item_oos_all: "購物車中的所有產品都缺貨。請添加其它產品以繼續。",
    error_occured: "抱歉，發生錯誤，請簽退後重試。",
    transaction_declined: "交易被拒，請選擇另外的支付方式。",
    invalid_phone_number: "無效的電話號碼!",
    payment_method_missing: "請填寫支付信息!",
    billing_address_missing: "請提供賬單地址!",
    giftcard_error: "禮品卡錯誤，請刷新頁面重試。",
    customer_info_missing: "請提供用戶信息",
    table_number_missing: "請提供桌號!",
    pickup_time_missing: "請提供自取時間",
    delivery_time_missing: "請提供配送時間",
    delivery_address_missing: "請提供收貨地址",
    shipping_method_missing: "請提供配送方式",
    set: "確認",
    invalid_pay_mtd: "結賬不成功。付款方式無效，請嘗試另一種付款方式。",
    item_error: "發生了一個錯誤。你的購物車已被清空，請重新添加產品到購物車後再結賬。",
    no_items_error: "購物車裡沒有任何產品。請添加一些產品後再結算。",
    use_profile_info: "使用賬戶信息",
    pick_up: "自取",
    delivery_option: "配送方式",
    required: "必填項目",
    first_name_placeholder: "輸入您的名字",
    last_name_placeholder: "輸入您的姓",
    table_number_placeholder: "輸入桌號",
    unit_placeholder: "輸入房間號",
    buzz_placeholder: "輸入門鈴號",
    street_placeholder: "輸入街道號",
    city_placeholder: "輸入城市名",
    region_placeholder: "輸入州名或省名",
    cntry_placeholder: "輸入您的國家",
    zipcd_placeholder: "輸入您的郵編",
    table_number_required: "請提供桌號",
    no_shipping_options: "所選地址沒有配送方法。請選擇另一個配送地址。",
    delivery_time_changed: "配送時間已經更改，請選擇另一個合適的時間。",
    gift_card_payment: "禮品卡付款",
    expires: "過期",
    allowed_periods_unavailable: "請選擇一個合適的送貨地址，以獲得合理的送貨時間。",
    delivery_method_unavailable: "配送已不再適用，請返回商家頁面再次結帳。",
    takeout_method_unavailable: "自取已不再適用，請返回商家頁面再次結帳。",
    in_store_method_unavailable: "店內下單已不再適用，請返回商家頁面再次結帳。",
    agreement_notice: "通過確認購買，我同意Goopter的{conditions_of_use} & {privacy_policy}",
    conditions_of_use: "使用條款",
    privacy_policy: "隱私條款",
    "secure-connection": "信息將通過加密方式安全傳送",
    set_payment_method: "請選擇支付方式",
    set_billing_address: "設置賬單地址",
    set_delivery_address: "設置配送地址",
    set_giftcard: "選擇禮品卡",
    set_points: "選擇積分",
    redeem_points_dialog_title: "兌換積分",
    redeem_x: "兌換: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "輸入客戶信息",
    set_table_number: "輸入桌號",
    set_pickup_time: "選擇自取時間",
    set_delivery_time: "選擇配送時間",
    set_shipping_method: "設置配送方式",
    address_book: "地址本",
    "no-giftcard-text": "您沒有此商家的禮品卡。",
    delete_credit_card_failed: "抱歉，不能刪除此卡，請稍後重試。",
    checkout_success: "您的訂單已經創建：#{order_id}。當訂單狀態更新時系統將會發送通知信息給您。",
    new_order_created: "新訂單已創建",
    distance_exceeded: "超出距離",
    min_delivery_amt_required: "最低配送金額為{required} (稅前不包配送費)，如需配送請再加{gap}。",
    insufficient_fund: " 資金不足，請嘗試使用其他付款方式。",
    invalid_payment_info: "支付信息無效，請檢查您的輸入後重試。",
    merchant_not_ready: "商家尚未準備好接受您的支付方式。請稍後再試。",
    shipping_country_text: "可配送到使用以下國家的地址: {countries}",
    apply: "使用優惠券",
    enter_coupon_code: "輸入優惠劵代碼",
    remove_coupon_alert: "您確定要刪除此優惠券嗎?",
    replace_coupon_alert: "當前已用的優惠券將被替換，您是否希望繼續?",
    invalid_alipay_coupon: "這個優惠券只能選擇支付寶支付才有效",
    remove_current_alipay_coupon:
        "當前的購物車裡的折扣券只能在選擇支付寶支付才有效，如果用其它方式支付的話這個折扣將會失效，您還要更改支付方式嗎?",
    use_wechat_dialog_message:
        "要使用微信支付，請在微信裡打開商家網站下單。{br}{br}在微信上打開商家網站的選項：{br}1.如果您有已經商家二維碼，直接用微信掃碼；{br}2.單擊頁面頂部的商店名稱以返回商店頁面，然後單擊右上角的圖標以打開二維碼顯示窗口，保存二維碼，然後在微信中打開二維碼下單。",
    discount_total: "折扣總額",
    first_name_missing: "請輸入您的名",
    phone_number_missing: "請輸入您的電話號碼",
    payment: "支付式",
    select_payment: "選擇一個支付方式",
    select_giftcards: "請選擇禮品卡",
    special_request: "特殊要求",
    place_order_with_x: "確認下單 - {x}",
    min_type_required_message: "最低{type}金額是{value}，請選購更多產品",
    select_pickup_location: "請選擇自取位置",
    please_choose_pickup_location: "請選擇自取地點",
    invalid_delivery_zone_title: "超出配送範圍",
    invalid_delivery_zone_content: "提供的地址超出了配送範圍。請輸入其它地址。配送區域和時間如下：",
    please_input_address: "請輸入有效地址",
    please_input_pickup_time: "請選擇自取時間",
    please_input_delivery_time: "請選擇配送時間",
    recommend_a_good_restaurant: "為您挑選了一個好店",
    recommend_a_good_merchat: "為您挑選了一個好商家",
    recommend_a_good_product: "為您挑選了一個好物",
    guest_user: "匿名用戶",
    require_utensil: "需要餐具",
    already_have_account: "匿名下單",
    order_success_table: "感謝您的惠顧。訂單就緒後，我們會立即帶到您的桌子上。",
    order_success_parking_lot: "感謝您的惠顧。訂單就緒後，我們會立即帶到您的車上。",
    order_success_kiosk: "感謝您的惠顧。訂單就緒後，我們會立即通知您。",
    view_special_deals: "查看特別優惠",
    more_items_to_get_special_deals: "當前訂單金額不符合特別優惠條件。請添加更多產品。",
    special_deals_for_you: "為您提供的特別優惠",
    add_items: "添加更多",
    discounted_upsell_items_removed_msg: "由於當前訂單金額不再符優惠條件，折扣加售商品已被刪除。",
    discounted_upsell_items_removed_empty_cart_msg:
        "由於當前訂單金額不再符優惠條件，折扣加售商品已被刪除。您的購物車現在是空的，請添加產品後繼續。",
    menu: "菜單",
    category: "分類",
    categories: "分類",
    store_details: "商家詳情",
    store_description: "商家描述",
    reviews: "客戶評價",
    rank: "評價",
    customer_reviews: "評論",
    single_selection: "單選",
    optional: "可選",
    pick_varied_options: "選擇{count}項",
    sold: "已售{count}",
    only_x_left: "還剩{count}個",
    sold_out: "售罄",
    pick_option: "可選規格",
    contact_vendor: "致電商家",
    call_vendor: "聯繫商家",
    self_input: "自定義價格",
    coupons: "{couponCount}個優惠券",
    coupons_title: "優惠券",
    redeem: "領取",
    remove: "移除",
    redeemed: "已領取",
    valid_until: "有效期至 {endDate}",
    checkout: "結賬",
    shopping_cart: "購物車",
    empty_cart: "清空購物車",
    alert_empty_cart: "您確定要清空購物車嗎?",
    discount_title: "折扣信息",
    qr_code: "二維碼",
    poster: "海報",
    m: "公尺",
    km: "公里",
    min: "分鐘",
    hour: "小時",
    rating: "評分",
    rating_title: "評分",
    base_delivery_fee: "{flatDistance}{distanceUnit}內配送費用",
    min_delivery_amount: "最少配送金額",
    max_delivery_distance: "最遠配送距離",
    distance_title: "距離",
    takes_reservations: "預定",
    more_info: "更多信息",
    announcement: "公告",
    no_announcement: "暫無公告",
    buy_x_discount_flat: "滿{currency}{price}，減{currency}{discount}",
    buy_x_discount_percent: "滿{currency}{price}，減{discount}%",
    search_products_title: "搜索產品",
    search_products_input_placeholder: "請輸入關鍵字搜索...",
    search_products_no_match: "抱歉，沒有找到與 '{text}' 相關的產品。",
    alert_delete_product_with_options: "多規格商品只能去購物車刪除。",
    store_closed_allowed_preorder: "商家休息中，您可以現在預購。",
    store_closed_allowed_preorder_x_mins_before: "商家目前已打烊。您可以在開門前 {mins} 分鐘預訂。",
    store_closed_soon_not_allowed_order: "商家即将打烊，目前不接受新订单。",
    store_closed_soon_allow_preorder_next_day: "商舖即將打烊，僅接受下一個工作日的預訂。",
    store_closed_only_accepting_preorder_next_business_day: "商舖已打烊，您可預訂下一個工作日的訂單。",
    results_colon: "搜索結果: ",
    price: "價格",
    popularity: "人氣",
    service_title: "服務",
    product_title: "產品",
    all_title: "全部",
    satisfied_title: "滿意",
    unsatisfied_title: "不滿意",
    open: "營業中",
    closed1: "已打烊",
    discount_rate: "{rate}折",
    invalid_option_selection: "無效選項",
    no_product_options: "沒有產品選項",
    single_selection_error: "請選擇一個 '{productOption}' 選項",
    pick_ranged_options_error: "'{productOption}' 選項最少選擇{count}個",
    pick_fixed_options_error: "請選擇{count}個 '{productOption}' 選項",
    add_to_cart: "加入購物車",
    update_cart: "更新購物車",
    total_reviews: "個評價",
    store_total_sold_count: "銷量",
    hot_products: "熱門產品",
    related_products: "相關產品",
    store_hours: "營業時間",
    delivery_hours: "配送時間",
    add_for_delivery: "差 {currency}{amount} 起送",
    open_in_app: "用App開啟",
    qrcode_message: "掃描二維碼打開此商店",
    review_submitted_validate: "感謝您提交評價，該評價將在審核後發布。",
    review_submitted: "您的評價已提交。",
    add_update_review: "請添加或更新您的評價",
    post_review_failed: "評價未提交。請刷新您的頁面，然後重試。",
    not_accepting_orders: "本店暫不接受網上下單",
    bookmark_sign_in_alert_store: "登錄後才能將此商家添加為書籤",
    bookmark_sign_in_alert_product: "登錄後才能將此產品添加為書籤",
    no_store_bookmarks: "沒有商家書籤",
    no_store_bookmarks_message: "您沒有任何商家的書籤。點擊商家頁面右上角的心形圖標即可為商店添加書籤",
    no_store_bookmarks_button: "添加商家書籤",
    no_product_bookmarks: "沒有產品書籤",
    no_product_bookmarks_message: "您沒有產品的書籤。點擊產品頁上的心形圖標將其加上書籤",
    no_product_bookmarks_button: "添加產品書籤",
    no_group_bookmarks: "沒有優惠書籤",
    no_group_bookmarks_message: "您沒有特價品的書籤。點擊特價品頁面上的心形圖標將其加上書籤",
    no_group_bookmarks_button: "添加特價品書籤",
    add_item_another_menu_warning: "您的購物車已包含菜單: {menu} 中的菜品，是否要清除購物車並添加此菜品？",
    start_new_cart_question: "新購物車",
    change_menu: "切換菜單",
    direction: "路線",
    website: "網站",
    we_also_recommend: "商家推薦...",
    diff_delivery_price: "最低配送金額{x}",
    discount_info_format: "滿{x}減{y}",
    discount_add_x_get_y: "再加{x}可減{y}",
    discount_get_percentage_discount: " 減{x}",
    discount_get_value_discount: " 減{x}",
    discount_not_accepting_order: "本店暫不接受網上下單",
    discount_store_closed_preorder: "商家休息中，您可以預購",
    discount_x_off_add_y_get_z: "已減{x}，再加{y}減{z}",
    discount_x_off: "已減{x}",
    switch_menu_warning_message:
        "所選菜單支持不同的訂單類型({new_order_type})。如果繼續，購物車中的物品將被清除。您要切換到新菜單嗎？",
    logout_confirm_message: "您確定要簽退嗎?",
    cannot_merge_account: "您的帳戶已存在，請使用其他電話號碼。",
    duplicate_social_error: "該電話號碼已在另一個帳戶註冊，請使用其它號碼。要使用此號碼，請簽退後用該號碼登錄。",
    store_has_no_products: "這家商店目前沒有產品。",
    error_with_code: "[Error {code}]: {error}",
    unable_to_load_any_data: "無法加載數據",
    unstable_network: "網絡速度較慢或不穩定，請檢查您的網絡連接",
    logout_force_message: "抱歉，您的帳戶已從其他設備登錄或超時，請重新登錄。",
    incorrect_password: "密碼錯誤",
    country_code_error: "國家/地區代碼錯誤",
    incorrect_username_password: "用戶名/密碼不正確",
    verification_code_expired: "驗證碼已過期",
    verifcation_code_request_overlimit: "驗證碼請求超限",
    incorrect_verification_code: "驗證碼不正確",
    error_fetch_user_profile: "無法獲取用戶個人資料",
    login_failed: "登錄失敗",
    profile_update_success: "更新個人資料成功",
    email_update_success: "更新電子郵件成功",
    phone_update_success: "更新電話號碼成功",
    failed_fetch_gift_card_detail: "無法獲取禮品卡詳細信息",
    gift_card_transfer_success: "禮品卡轉讓成功",
    gift_card_transfer_fail: "無法轉讓禮品卡",
    record_not_found: "未找到記錄",
    failed_fetch_points_detail: "無法獲取積分詳細信息",
    profile_update_failed: "更新用戶個人資料失敗",
    email_update_failed: "更新電子郵件失敗",
    phone_update_failed: "更新電話號碼失敗",
    get_verification_code_success: "驗證碼已發送到您的手機",
    verification_too_many_requests: "驗證碼已被請求過多次，請稍後再試",
    invalid_table_number: "無效的桌號",
    invalid_party_size: "無效的人數",
    coupon_invalid: "無效的優惠券",
    coupon_valid: "有效優惠券",
    invalid_coupon: "優惠券代碼 {couponCode} 無效",
    coupon_applied: "優惠券已應用到購物車",
    invalid_address: "無效地址",
    remaining_stock_header: "庫存",
    item_oos: "抱歉，以下商品的库存数量不足：",
    invalid_shipping_method: "無效配送方式，請嘗試另一種配送方式。",
    please_input_phone_number: "請輸入有效的電話號碼",
    please_input_first_name: "請輸入名字",
    please_input_last_name: "請輸入姓氏",
    please_input_shipping_method: "請輸入送貨方式",
    please_input_sub_shipping_method: "請輸入送貨方式",
    please_input_shipping_addresss: "點按輸入配送地址",
    please_input_pickup_addresss: "點按選擇自取位置",
    please_input_table_number: "請輸入桌號",
    please_input_payment_method: "點按選擇支付方式",
    please_input_valid_credit_card: "請輸入有效的信用卡",
    please_input_billing_address: "請輸入帳單地址",
    tap_to_show_on_map: "點按打開地圖",
    please_add_some_items: "請添加一些產品",
    please_confirm_tips: "點按設置小費金額",
    please_set_minimum_tips: "請輸入最少小費金額",
    please_set_minimum_tips_detail: "為了確保為您的訂單找到司機，最低小費金額為${tips_amount}",
    bind_phone: "驗證電話號碼",
    please_bind_phone: "下單前需要先驗證您的電話號碼。你想現在驗證電話號碼嗎?",
    go_to_bind: "去驗證",
    bind_phone_success: "驗證成功",
    invalid_credit_card: "無效信用卡，清選擇另外一個支付方式。",
    invalid_request: "結帳不成功。無效請求。",
    price_over_limit: "結賬不成功。價格超過付款限額。",
    please_input_belows: "請輸入以下缺少的部分",
    unable_to_change_here: "無法在此更改",
    please_select_on_previous_page: "請在上一頁選擇",
    invalid_card_number: "無效的卡號",
    invalid_zipcd: "無效的郵政編碼",
    click_one_more_time_to_deselect: "再點擊一次取消選擇",
    paypal_payment_success_title: "支付成功",
    paypal_payment_success_text: "您的付款已收到。下面將返回到訂單頁面。",
    error_payment_failed: "支付失敗",
    internal_server_error: "抱歉，出現意外錯誤，請稍後再試或直接與商家聯繫。",
    unexpected_error: "發生意外錯誤，請稍後再試",
    are_you_sure_remove_gift_card: "你確定要刪除這張禮品卡？",
    giftcard_pays_order_total: "所選的禮品卡將支付此訂單，無需其它付款方式。",
    points_pays_order_total: "您的積分已經支付訂單總額，無需其它付款方式。",
    giftcard_points_pays_order_total: "您的禮品卡和積分已經支付訂單總額，無需其他付款方式。",
    digit_only: "請只輸入數字",
    please_selected_belows: "請完成所有選擇：",
    please_select_location_from_the_list: "請從目錄中選擇地址。",
    alert_password_reset_success: "您的登錄密碼已經更改成功，您可以啟用新密碼登錄。",
    alert_email_not_found: "此郵件地址尚未註冊",
    alert_password_reset_email_send: "重置密碼郵件已經發送",
    x_is_required: "是必填項",
    location_permission_is_blocked_service_may_not_perform_correctly: "位置權限受限，此服務可能會受到影響 ",
    x_missing: "請輸入 {x} ",
    x_invalid: "無效 {x}",
    x_select: "請選擇支 {x}",
    do_you_want_to_receive_notification: "您希望收到推送通知嗎?",
    gift_cards_points_alert: "兌換積分時不能再使用禮品卡; 要使用禮品卡, 請先移除兌換的積分。",
    points_gift_cards_alert_message: "使用禮品卡時就不能再兌換積分; 要兌換積分，請先移除禮品卡。",
    not_found_description: "抱歉！找不到頁面",
    not_found_detail_description: "很抱歉，您要查找的頁面不存在，已被刪除，名稱已更改或暫時不可用",
    back_to_home: "返回首頁",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    please_input_valid_postal_code: "請輸入有效的郵編。",
    please_input_valid_x: "請輸入有效的{x}",
    too_many_card_attempt: "提供的卡被拒絕次數過多；請嘗試使用另一張卡付款。",
    too_many_checkout_attempt: "超出最大重試限制。請稍後再試。",
    payment_declined: "支付失敗",
    credit_card_declined: "請檢查信用卡信息以確保輸入正確，然後重試。如果這樣不能解決問題，請致電您的發卡銀行解決。",
    no_giftcard: "您沒有有效的禮品卡",
    giftcard_insuffienct_fund: "您的禮品卡沒有足夠的餘額來支付",
    invalid_request_body: "無效的請求",
    pay_order: "訂單支付",
    expiry_date: "有效期",
    postal_code: "郵編",
    postal_code_zipcd: "郵編",
    country: "國家",
    last_four: "尾數為",
    are_you_sure_delete_card: "你確定要刪除這張信用卡嗎？",
    visa: "Visa",
    master_card: "Master Card",
    american_express: "American Express",
    discover: "Discover",
    jcb: "JCB",
    maestro: "Maestro",
    credit_card_description: "Visa, Master Card, American Express",
    confirm_pay: "確認支付",
    unsuccessful_payment: "付款失敗。",
    reset_password_title: "重設密碼",
    phone_placeholder: "手機號",
    new_password: "新密碼",
    password_reset_continue: "重置",
    reset_by_text: "通過短信重設",
    reset_by_email: "通過電子郵件",
    alert_vc_expired: "驗證碼已經失效",
    email_verification: "郵件驗證",
    verification_success: "您的賬戶已經驗證!",
    verify_account: "您的賬戶 {email}，已經驗證。",
    verification_fail: "邮件验证失败",
    verify_error: "發生錯誤，請通過重新發送驗證電子郵件重試。{email}",
    view_account: "查看賬戶",
    link_expired: "無法驗證電子郵件地址",
    link_expired_message: "驗證鏈接已過期或已被使用。請再試一次。{email}",
    email_already_verified: "您的帳戶已經驗證。",
    resend_verification_email: "重新發送驗證電子郵件",
    recently_viewed: "最近瀏覽",
    by_phone: "通過手機短信",
    by_email: "通過電子郵件",
    by_password: "提供原始密碼更改",
    op_incorrect: "提交的原始密碼不正確。",
    password_change_success: "密碼已成功更改.",
    password_request_sent: "已發送更改密碼請求",
    social_binding: "Social Binding",
    linked: "Linked",
    facebook: "Facebook",
    twitter: "Twitter",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    or: "或者",
    connect_with_facebook: "用Facebook登錄",
    connect_with_twitter: "用Twitter登錄",
    connect_with_google: "用Google登錄",
    connect_with_wechat: "用微信登錄",
    connect_with_apple: "用Apple ID登錄",
    dont_have_account: "沒有賬戶",
    vouchers: "優惠券",
    gift_card: "禮品卡",
    bookmarks: "收藏",
    bookmark_added: "已添加書籤",
    bookmark_deleted: "已刪除書籤",
    account_info: "帳戶信息",
    my_points: "我的積分",
    my_giftcards: "我的禮品卡",
    my_bookmarks: "收藏",
    my_messages: "我的消息",
    my_orders: "我的訂單",
    avatar: "頭像",
    name: "姓名",
    my_address: "地址簿",
    account_binding: "帳號綁定",
    connect: "綁定",
    safety_settings: "安全設置",
    login_password: "登陸密碼",
    modify: "修改",
    delete: "刪除",
    first_name: "名字",
    last_name: "姓氏",
    phone_number: "手機號",
    address: "地址",
    pick_place_on_map: "在地圖上選擇地址",
    done: "完成",
    enter_address_manually: "手動增加地址",
    use_name_account: "使用賬戶中的名字",
    new_address: "新增地址",
    buzz: "門鈴",
    unit: "單元",
    street: "街道",
    city: "城市",
    region: "省份",
    cntry: "國家",
    zipcd: "郵編",
    postalcd: "郵編",
    required_text: "*表示必填項",
    map_search: "地圖搜索",
    accept: "接受",
    invalid_first_name: "請提供名！",
    invalid_last_name: "請提供姓！",
    invalid_credit_card_number: "請提供卡號!",
    invalid_credit_card_cvv: "請提供安全碼",
    invalid_cntry: "無效的國家輸入",
    Invalid_zipcd: "無效的郵政編碼",
    street_missing: "請提供街道!",
    city_missing: "請提供城市!",
    region_missing: "請提供省份!",
    cntry_missing: "請提供國家!",
    zipcd_missing: "請提供郵編!",
    billing_address: "賬單地址",
    confirm_delete_address: "確認刪除地址",
    delete_address_message: "確認刪除此地址？",
    address_edit: "修改地址",
    default: "默認",
    set_as_default: "設為默認",
    nick_name: "暱稱",
    update_profile_success: "您的用戶資料已經成功更改",
    update_profile_failed: "抱歉，更改用戶資料失敗，請重試。",
    last_name_missing: "姓氏缺失",
    nick_name_missing: "暱稱缺失",
    update: "更新",
    user_info: "用戶信息",
    new_phone: "新號碼",
    new_email: "新郵件",
    alert_phone_updated: "您的電話號碼已成功更新！",
    alert_missing_fields: "抱歉，缺少輸入字段。",
    alert_forbidden_action: "抱歉，此操作被禁止。",
    alert_email_updated: "更新電子郵件地址成功",
    alert_forbidden_email: "抱歉，此操作被禁止。",
    alert_email_exists_sign_up: "您輸入的電子郵件已被另一個帳戶使用。請登錄該帳戶或使用其他電子郵件。",
    alert_email_exists_update: "您輸入的電子郵件已被另一個帳戶使用。請使用其他電子郵件。",
    wallet: "錢包",
    confirm_delete_credit_card: "確認刪除信用卡",
    confirm_delete_credit_card_message: "你確定要刪除這張信用卡嗎？",
    add_credit_card_title: "增加信用卡",
    add_credit_card: "增加信用卡",
    save_credit_card: "保存信用卡",
    card_number: "卡號",
    name_on_card: "持卡人",
    cvv: "安全碼",
    set_payment_password: "設置支付密碼",
    update_password: "更改密碼",
    old_password: "舊密碼",
    confirm_new_password: "確認新密碼",
    password_not_detected: "舊密碼不符，請修改後重試或重置密碼。",
    server_error: "抱歉，系統異常。請稍後再試。",
    unmatched_passwords: "新密碼和確認新密碼不一樣，請修改並重試。",
    social_bind_not_allowed: "您不可以在微信瀏覽器里綁定或登錄除了微信以外的社區賬戶，請使用別的瀏覽器打開此頁面",
    social_bind_success: "您的帳戶已成功與您的{socialType}帳戶相關聯。 您現在可以使用{socialType}帳戶登錄",
    social_bind_failed: "鏈接{socialType}帳戶時出錯。請重試。",
    connected: "已綁定",
    confirm_unbind_social_account: "您確定要取消綁定{socialType}帳戶嗎？",
    unbind_social_success: "您的{socialType}帳戶已被解除綁定。",
    unbind_social_error: "抱歉，我們目前無法取消綁定您的{socialType}帳戶。請稍後再試。",
    create_new_vault_password: "請設置一個支付密碼",
    confirm_vault_password: "請確認密碼",
    input_vault_password: "請輸入支付密碼",
    vault_password: "支付密碼",
    password_instructions: "密碼需要4-6位數字",
    password_incorrect: "密碼錯誤",
    add_address_invalid_address: "地址無效。請試着在地圖上挑選地址。",
    country_not_exists: "國家無效。請試着在地圖上挑選地址。",
    map_select_address: "請填寫搜索欄並選擇建議的地址。",
    error_current_location: "獲取當前位置時出錯，請在搜索欄中輸入您的地址。",
    map_address_accept_error: "谷歌地圖錯誤。請嘗試其它地址或手動輸入地址。",
    invalid_name: "請填寫信用卡上的名字。",
    invalid_cc_info: "請填寫持卡人的姓名。",
    invalid_expiry_date: "信用卡有效期無效，請修改後再試。",
    alert_password_min_length: "密碼必須至少為6個字符",
    alert_guest_account:
        "您當前是以匿名身份登錄的；填寫您的姓名，電子郵件/電話號碼，即可成為正常客戶，日後可享受個性化服務或商家優惠。您想繼續嗎？",
    alert_missing_account_info: "請填寫缺失的信息: {missingInfo}",
    address_amount_exceeded: "超過地址本的數量限制。您最多只能保存10個地址。",
    i_will_do_it_later: "以後再做",
    finish_later: "稍後完成",
    skip: "跳過",
    change_by_email: "通過電子郵件",
    change_by_text_message: "通過手機短信",
    change_by_existing_password: "通過電子郵件",
    set_password: "設置密碼",
    change_password: "更改密碼",
    code: "驗證碼",
    request_pass_reset: "請求重設密碼",
    confirm_password: "確認密碼",
    set_a_password: "設置密碼",
    set_a_password_caption: "請設置密碼，以便下次更快地登錄",
    set_a_new_password: "設置新密碼",
    set_a_new_password_caption: "忘記密碼了嗎？設置一個新的，以便下次更快登錄。",
    update_password_success: "更新密碼成功",
    update_password_fail: "更新密碼失敗，請稍後再試",
    birthday: "出生日期",
    gender: "性別",
    not_provided: "未提供",
    currency: "貨幣",
    male: "男",
    female: "女",
    none: "無",
    year: "Year",
    month: "Month",
    day: "Day",
    cannot_update_profile: "無法更新個人資料。",
    updated_profile: "更新的個人資料。",
    giftcards: "禮品卡",
    gift_card_details: "禮品卡詳情",
    all: "全部",
    active: "有效的",
    active_giftcard: "可用的",
    inactive_giftcard: "已用完",
    used: "已使用",
    balance: "餘額",
    book_value: "面值",
    purchase_date: "購買日期",
    status: "狀態",
    reassign: "轉送",
    reassign_giftcard: "轉送禮品卡",
    receivers_email_phone: "接受人的郵件或者電話",
    giftcard_reassign_success: "禮品卡已成功轉送給{receiver}",
    giftcard_reassign_missing_receiver: "請輸入接受人的信息",
    giftcard_reassign_missing_info: "您必須有一個有效的接受者才能發送禮品卡。",
    giftcard_reassign_failed: "此電話號碼不屬於任何註冊用戶，請嘗試其他電話號碼",
    giftcard_reassign_failed_phone: "此電話號碼不屬於任何註冊用戶，請嘗試其他電話號碼",
    giftcard_reassign_failed_email: "此電子郵件不屬於任何註冊用戶，請嘗試另一個電子郵件.",
    no_giftcards: "您沒有禮品卡",
    no_active_giftcards: "你沒有有效的禮品卡",
    no_active_giftcards_head: "沒有有效禮品卡",
    no_used_giftcards_head: "沒有已用禮品卡",
    no_used_giftcards: "您還沒有已用的禮品卡",
    invalid_phone: "電話號碼無效，請確保電話號碼長度正確",
    giftcard_reassign_tip: "禮品卡轉送允許您將購買的禮品卡送給其他註冊用戶，可以根據此用戶的郵件或者電話來發送",
    gift_card_purchased: "購買禮品卡",
    paid_with_gift_card: "用禮品卡支付",
    refunded: "退款",
    transferred_to: "來自: {contact} 的贈送",
    received_from: "轉送給: {contact}",
    store_name: "商店名稱",
    comment: "備註",
    transactions: "交易",
    details: "詳細",
    date: "日期",
    balance_change: "積分變化",
    points_balance: "積分總額",
    no_points: "您尚無獎勵積分，現在下單攢積分.",
    no_points_head: "您還沒有積分",
    view_stores: "瀏覽商家",
    last_updated: "最近更新: {time}",
    point_balance: "餘額: {balance}",
    point_store_name: "商家名稱: {name}",
    points_received: "獲得積分",
    store_closed_not_allow_order: "商铺已打烊，目前不接受新订单。",
    no_orders: "您目前還沒有訂單",
    no_past_orders: "沒有以往訂單",
    no_upcoming_orders: "沒有未完成的訂單",
    shop_now: "現在購物",
    pickup_method: "自取時間",
    delivery_method: "配送時間",
    eatin_method: "就緒時間",
    instantCheckout_method: "即刻結帳",
    items: "件商品",
    ongoing: "正在進行",
    history: "歷史訂單",
    upcoming: "進行中的",
    past_orders: "歷史訂單",
    buzz_code: "門鈴",
    make_default: "設為默認",
    add_address: "新增地址",
    add_a_address: "添加地址",
    are_you_sure_delete_address: "確認刪除此地址？",
    select_address: "選擇禮地址",
    search_address: "搜索地址",
    current_location: "當前地址",
    use_name_on_account: "使用賬戶姓名",
    confirm_address: "確認地址",
    location_user_denied: "定位服務已禁用，請啟用定位服務",
    geolocation_not_supported: "不支持位置服務，請在地址欄中搜索地址",
    location_general_error: "無法獲取您的當前位置，請稍後再試",
    self_checkout: "快速支付",
    amount: "金額",
    add_comment: "添加備註",
    contact_merchant: "聯繫商家",
    powered_by_goopter: "由Goopter提供技術支持",
    pay: "支付",
    "self-checkout-disabled": "此商家不能用即刻支付。",
    surcharge_for: "訂單#{oid}的附加費",
    phone_number_verification: "電話號碼驗證",
    please_verify_number: "请验证您的号码",
    verification_code_sent_message: "4位数验证码的短信刚刚发送到 {phoneNumber}",
    enter_code: "输入验证码",
    try_different_number: "尝试不同的号码",
    number_verified_message: "您的号码已通过验证！",
    continue_checkout_message: "请继续完成订单",
    get_vc_descprtion_text: "我們會向您的手機發送驗證碼",
    payment_success_title: "支付成功",
    payment_success_text: "您的付款已收到。下面將返回到訂單頁面。",
    go_to_order: "前往該訂單",
    qr_scanner: "掃碼下單",
    camera_instructor_title: "需要使用攝像頭",
    camera_instructor_description_dine_in: "接下來將打開您的相機。請把相機瞄準二維碼以繼續您的訂單。",
    camera_instructor_button_text_dine_in: "掃碼下單",
    camera_error_title_order: "相機訪問被拒絕",
    camera_error_description_order: "請允許攝像頭掃描二維碼以繼續下單",
    camera_error_button_text_order: "啟用攝像頭",
    error_invalid_code_dine_in: "此二維碼不是用來本商家的堂食點餐用途，請掃描正確的二維碼。",
    error_invalid_code_in_store: "此二維碼不是用來本商家的堂食點餐用途，請掃描正確的二維碼。",
    parking_lot: "停車位號",
    kiosk: "自助終端號",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "點按相機切換{type}",
    scan_again: "再試試",
    scanner_title: "將方框對準二維碼/條碼即可自動掃描",
    orders_today: "今日訂單",
    orders_total: "訂單總數",
    points_today: "今日推薦積分",
    points_total: "總推薦積分",
    check_how_many_reward_points_from_referrals: "查看您從推薦人那裡獲得了多少獎勵積分",
    no_referrals_message: "您還沒有任何推薦。開始推薦賺取獎勵積分以在您喜歡的商家消費！",
    order_id: "單號",
    no_referral_orders_found: "未找到推薦訂單",
    from: "從",
    pts: "積分",
    sort: "排序",
    filter: "篩選",
    your_order: "您的訂單",
    order_items: "已購商品",
    add_to_cart_success: "產品已成功加入購物車",
    courier_collected_package: "配送員已取件",
    add_back: "加回",
    to: "到",
    order_change_reject_all: "您已拒絕所有建議的更改。您確定要取消訂單嗎?",
    table_info: "座位信息",
    serve_time: "預計就緒時間",
    reward_points_extra_text: "訂單完成後，您的帳戶積分餘額將會更新。",
    gift_cards: "禮品卡",
    with_option: "包含選項",
    qrcode: "二維碼",
    email_required: "請提供郵件地址",
    password_required: "密碼是必需的",
    phone_required: "請提供電話號碼",
    vc_required: "需要驗證碼",
    please_input_at_least_two_characters: "請輸入至少兩個字符",
    phone_num: "電話號碼",
    change_address: "更改地址",
    inactive: "禁用",
    purchase_a_giftcard: "購買禮品卡",
    self_checkout_disabled: "此商家不提供快速支付",
    quick_pay_hint_1: "快速支付讓您隨時支付您的訂單或欠款！",
    quick_pay_hint_2: "只需輸入金額並在備註中提供有關此付款的相關信息。",
    got_it: "知道了。",
    camera_error_title: "無法訪問相機",
    camera_error_description_dine_in: "請允許相機訪問掃描二維碼進行訂購。",
    camera_error_button_text: "啟用相機",
    msg_country_update: "國家更新成功。",
    msg_currency_update: "貨幣更新成功。",
    msg_birthday_update: "生日更新成功。",
    msg_email_update: "電子郵件更新成功。",
    msg_gender_update: "性別更新成功。",
    msg_name_update: "姓名更新成功。",
    msg_nickname_update: "暱稱更新成功。",
    no_thanks: "不用, 謝謝",
    verify: "驗證",
    number_already_exist:
        "此号码已在另一个帐户中注册。如果您想使用此号码下订单，请签退后使用该号码登录。或者您可以使用另一个电话号码。",
    code_not_revieved: "沒有收到驗證碼？",
    click_to_resend: "點擊重新發送。",
    sign_in_with_number: "使用現有號碼登錄",
    try_another_number: "換個號碼試試",
    add_instructions: "添加說明",
    review_recommend_store: "您有多大可能向朋友或家人推薦 {storeName}？",
    example_review_score: "麻煩請告知您為什麼選擇評分: {score}的原因",
    change_score: "更改評分",
    not_likely: "不太願意",
    very_likely: "非常願意",
    review_thank_you_recieve_coupon: "謝謝，您將在大約1分鐘內通過短信收到一張優惠券。",
    review_share_your_heart: "麻煩了，可以把您對我們的熱愛分享下嗎？",
    review_copy_comment: "1. 複製你寫的評論。",
    and_share_your_review: "2. 分享你的評論。",
    the_comment_is_copied_to_clipboard: "評語已經復製到剪貼板",
    thank_you: "非常感謝 !",
    review_thank_you_note: "如果你沒有收到優惠券短信，請回复 \"my-coupon\" 查看你的優惠券，謝謝!",
    share_to_google: "分享到 Google",
    powered_by: "由 Goopter 提供技術支持",

    quick_pay_self_serve_order_hins: "我有從觸摸屏自助下單或掃碼下單來的未支付訂單。",
    quick_pay_staff_order_hins: "工作人員幫我下的訂單。",
    quick_pay_order_type_self_serve: "自助下單的訂單",
    quick_pay_order_type_staff: "員工輔助下單的訂單",
    quick_pay_option_title: "您是否要為已有訂單付款？",
    quick_pay_order_type_no_order: "我沒有訂單號",
    add_payment_note: "添加付款說明",
    select: "请选择",
    leave_quick_pay_confirm_msg: "你確定要離開快速支付嗎？",
    self_serve_order_number: "自助服務訂單號",
    staff_assisted_order_number: "員工輔助訂單號",
    self_serve_order_number_input_placeholder: "請輸入自助服務訂單號",
    please_confirm: "請確認",
    staff_assisted_order_number_input_placeholder: "請輸入員工輔助訂單號",
    invalid_order_id: "訂單號無效，請重試。",
    quick_pay_order_type_no_order_placeholder: "沒有訂單號與此付款相關聯",
    quick_pay_order_id: "為{orderType}付款 訂單號: {orderId}",
    quick_pay_confirm_msg: "您確定要為以下訂單付款嗎?",
    quick_pay_confirm_msg_2: " ",
    amount_due: "應付金額",
    quick_pay_confirm_hin: "如果您的訂單號相同但應付金額不同，請選擇",
    quick_pay_confirm_hin_2: "選項。",
    staff_assisted: "員工輔助下單",
    pls_input_number: "請輸入数字",
    back: "返回",
    okay: "确认",
    quick_pay_order_type_additional_payment: "自助服务訂單的部分或额外付款",
    refunded_details: "退款詳情",
    refund_reason: "退款理由",
    deactivate_account: "停用帳戶與刪除",
    msg_deactivation: "您的帳戶將會被永久刪除。在您刪除您的 Goopter 帳戶之後，您將無法使用當前帳戶登入或檢索任何以前的訂單或地址記錄",
    account_deactivated: "帳戶已停用並刪除"
};

export default exports;
