const exports = {
    " ": " ",
    go_back: "Volver atrás",
    notifications: "Notificaciones",
    settings: "Ajustes",
    logout: "Cierre sesión",
    logged_out_error: "Su sesión se ha cerrado, reinicie sesión e inténtelo de nuevo.",
    logged_out_success: "Su sesión se ha cerrado con éxito. ¡Hasta pronto!",
    confirm_logout_text: "¿Está seguro de que quiere salir?",
    ok: "Ok",
    error: "Error",
    no: "No",
    yes: "Sí",
    language: "Idioma",
    account: "Cuenta",
    login: "Iniciar sesión",
    cancel: "Cancelar",
    store: "Establecimiento",
    products: "Artículos",
    restaurant: "Restaurante",
    groupsale: "Grupo de venta",
    travel: "Viajar",
    shopping: "Comprar",
    service: "Servicio",
    save: "Guardar",
    confirm: "Confirmar",
    success: "Éxito",
    loading: "Cargando",
    warning: "Aviso",
    general_error: "Se ha producido un error inesperado, inténtelo de nuevo más tarde",
    orders: "Mis pedidos",
    click_to_input_address: "Haga clic para introducir su dirección",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    "fr-lang": "Français",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    de: "Deutsch",
    input: "Introducir",
    edit: "Editar",
    years: "Años",
    quarters: "Trimestres",
    months: "Meses",
    weeks: "Semanas",
    days: "Días",
    days_2: "Días",
    time: "Hora",
    times: "Veces",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
    milliseconds: "Milésima de segundo",
    sort: "Clasificar",
    filter: "Filtrar",
    today: "Hoy",
    tomorrow: "Mañana",
    yesterday: "Ayer",
    this_week: "Esta semana",
    last_week: "La semana pasada",
    this_month: "Este mes",
    last_month: "El mes pasado",
    last: "Último",
    next: "Siguiente",
    now: "Ahora",
    mon: "Lunes",
    tue: "Martes",
    wed: "Miércoles",
    thu: "Jueves",
    fri: "Viernes",
    sat: "Sábado",
    sun: "Domingo",
    mo: "Lunes",
    tu: "Martes",
    we: "Miércoles",
    th: "Jueves",
    fr: "Viernes",
    sa: "Sábado",
    su: "Domingo",
    share: "Compartir",
    dont_show_this_again: "No vuelvas a mostrar esto",
    jan: "Enero",
    feb: "Febrero",
    mar: "Marzo",
    apr: "Abril",
    may: "Mayo",
    jun: "Junio",
    jul: "Julio",
    aug: "Agosto",
    sep: "Septiembre",
    oct: "Octubre",
    nov: "Noviembre",
    dec: "Diciembre",
    view_more: "Ver más",
    viewing: "Viendo",
    show_filters: "Mostrar filtros",
    reward_history_filter: "Filtro del historial de recompensas",
    date_range: "Intervalo de fechas",
    my_referrals: "Mis referencias",
    how_to_do_it: "Cómo recibir puntos de recompensa y reglas de puntos de recompensa",
    how_to_do_it_referrals:
        "Comparta el enlace de referencia o el código QR con sus amigos y en las redes sociales. Se convertirán en su referencia si no fueron referidos previamente por otra persona y realizan una compra utilizando su enlace. Recibirá {v2pCustomerRef} puntos de recompensa por cada dólar de su compra y compras futuras. Si comparte el enlace con alguien que ya ha sido referido, recibirá una recompensa única de {v2pOrderRef} puntos por cada dólar que gaste en su compra actual.{br}{br}Cada {p2v} puntos de recompensa equivale a $ 1 para esta tienda.",
    copy_ref_url: "Copiar la URL de referencia",
    total: "Total",
    generate_qr_code: "Generar Código QR",
    ref_orders: "Pedidos",
    income: "Ingresos",
    check_how_much_money_earned: "Compruebe cuánto dinero ha ganado",
    check_how_much_rewards_earned: "Compruebe cuántos puntos de recompensa ha ganado",
    redeemable: "Reembolsable",
    total_redeemed: "Total reembolsado",
    sales: "Ventas",
    check_how_many_sales: "Compruebe cuántas ventas ha hecho",
    rewards_history: "Historial de recompensas",
    check_daily_history: "Compruebe sus transacciones de recompensa diarias",
    orders_info: "Compruebe cuántos pedidos han realizado sus amigos",
    referrals_info: "Información de referencias",
    referrals_info_desc:
        "Esta es la información de sus referencias. Haga clic en cada una de ellas para obtener información de sus pedidos y ventas",
    admin_reward_action: "Puntos de recompensa del administrador\t",
    points_redeemed: "Puntos canjeados",
    customer_invitation: "Puntos de referencia de clientes",
    order_invitation: "Puntos de referencia de pedidos",
    admin_credit: "Crédito del administrador",
    sales_rule: "Puntos de venta",
    admin_revert_points: "Puntos revertidos al administrador",
    refunded_order: "Puntos de pedidos devueltos",
    ref_successfully_copied: "Código de referencia copiado con éxito",
    click_tips_with_helper_text: "Tips(click amount to update)",
    click_set_points: "Click to redeem points",
    click_to_search_address: "Click to search address",
    click_please_input_shipping_addresss: "Click to input shipping address",
    click_please_input_pickup_addresss: "Click to choose pick-up location",
    click_please_input_payment_method: "Click to Select Payment Method",
    click_to_show_on_map: "Click here to show on map",
    tap_to_search_address: "Haga clic para buscar una dirección",
    sorry_search: "No se han encontrado resultados para esa palabra clave.",
    try_again_search: "Pruebe con otra palabra clave",
    recent_searches: "Búsquedas recientes",
    recommendations: "Recomendaciones",
    deals: "Ofertas",
    view_all: "Ver todo",
    products_not_available: "Su pedido no está disponible en estos momentos y será eliminado de su carro:",
    shopping_cart_empty: "Su carro de compra está vacío.",
    not_available_dialog_title: "No está disponible en estos momentos",
    not_available_dialog_captain: "Este artículo sólo está disponible en las horas siguientes.",
    sorry_product_not_available: "Lo sentimos, este artículo sólo está disponible en las horas siguientes.",
    feedback: "Opiniones",
    download_qr: "Descarga el código QR",
    download_qr_mobile: "Presione sobre la imagen de manera prolongada para descargarla",
    download_poster: "Descargar el cartel",
    download_poster_mobile: "Presione sobre la imagen de manera prolongada para descargarla",
    rate_the_product: "Valore el producto",
    are_you_sure_cancel_review: "¿Está seguro de que quiere descartar esta opinión?",
    submit: "Enviar",
    review_order_placeholder: "Su opinión ayudará a otras personas a elegir sus productos",
    review_submitted_message: "Gracias por su opinión, se publicará cuando haya sido validada",
    please_input_or_select: "Por favor introduzca o seleccione un motivo.",
    review_service: "Valoración del servicio",
    rate_hint: "Toque el icono de la estrella para valorar",
    not_available: "No disponible",
    current_not_available: "No está disponible en estos momentos",
    allow_reservation: "Admite reservas",
    has_tv: "Tiene televisor",
    has_free_wifi: "Tiene wifi gratis",
    has_outdoor_seating: "Tiene mesas al aire libre",
    kid_friendly: "Apto para niños",
    group_friendly: "Apto para grupos",
    business_friendly: "Apto para reuniones de trabajo",
    street_parking: "Aparcamiento en la calle",
    free_parking: "Aparcamiento gratis",
    paid_parking: "Aparcamiento privado",
    noise_level: "Nivel de ruido",
    average: "Medio",
    loud: "Alto",
    very_loud: "Muy alto",
    casual: "Casual",
    business_casual: "Casual Business",
    smart_casual: "Elegante informal",
    business: "Business",
    semi_formal: "Semi formal",
    has_alcohol: "Contiene Alcohol",
    environment: "Alrededores",
    attire: "Vestimenta",
    upscale: "Lujoso",
    classy: "Clásico",
    hipster: "Hípster",
    intimate: "Íntimo",
    no_alcohol: "Sin Alcohol",
    beer_and_wine: "Cerveza y vino",
    full_bar: "Bar completo",
    write_your_review_here: "Escriba su opinión aquí",
    rate_the_store: "Valore el establecimiento",
    more_information: "Más información",
    merchant_information: "Información comercial",
    additional_pages: "Paginas Adicionales",
    store_info: "Información del establecimiento",
    store_overview: "Visión general",
    opens: "Apertura",
    closes: "Cierre",
    current_store: "Establecimiento actual",
    order: "Pedido",
    my_order: "Mi pedido",
    deliver_to: "Deliver to",
    delivery: "Envío",
    check_out: "Pago",
    options: "Opciones",
    add_to_cart: "Añadir al carro",
    pick_one: "Necesario, elija 1",
    pick_amount: "Necesario, escoja {min}",
    pick_range: " Escoja {min}-{max}",
    pick_range_up_to: "Escoja hasta {max}",
    pick_range_required: "Necesario, escoja {min}-{max}",
    pick_optional: "Opcional",
    up: "hasta",
    search_products: "Buscar productos",
    search: "Búsqueda",
    discount_by: "{x} descuento en {y}",
    discount_by_without_price: "{x} descuento",
    store_closed: "Cerrado",
    store_closed_not_allow_order: "Establecimiento cerrado, por favor haga su pedido cuando el establecimiento abra.",
    order_items: "Pedir artículos",
    are_you_sure_clear_cart: "¿Está seguro de que quiere vaciar su carrito?",
    qr_code: "Código QR",
    poster: "Póster",
    clear: "Vaciar",
    results: "Resultados",
    search_results: "{x} buscar resultados",
    pending_acceptance: "Esperando confirmación",
    delivery_time: "Hora de envío",
    pickup: "Recogida ",
    takeout: "Salida ",
    eatin: "Comer dentro",
    order_pickup_time: "Hora de recogida: {time}",
    order_delivery_time: "Hora de entrega: {time}",
    transferred_to: "Transferido a: {contact} ",
    received_from: "Recibido de: {contact} ",
    discount_amount: "{currentDiscount} de descuento.",
    remaining: " Añadir {remaining} más para conseguir {amount} de descuento.",
    remaining_delivery: " Añadir {remaining} por envío.",
    remaining_pickup: " Añadir {remaining} por recogida.",
    menu_available_at: "Este menú sólo está disponible en {available}…",
    please_input_name: "Introduzca nombre",
    no_address_head: "No hay direcciones guardadas",
    no_address_message: "Actualmente no tiene ninguna dirección guardada.",
    select_branch: "Seleccione un establecimiento",
    store_on_hold: "El establecimiento está en espera y no admite pedidos por ahora.",
    out_of_stock: "Agotado",
    load_more: "Ver más",
    no_order_message: "Sus pedidos aparecerán aquí después de crear/completar un pedido.",
    no_order: "Sin pedidos ",
    create_an_order: "Crear un pedido",
    no_more_data: "No hay más datos",
    no_search_results: "No hay resultados de búsqueda",
    group_purchase: "Compra grupal",
    show_more: "Mostrar más",
    show_less: "Mostrar menos",
    purchase_history: "Historial de compra",
    start_time: "Hora de inicio",
    end_time: "Hora de finalización",
    expired: "expirado/a",
    offer_expires_in: "La oferta expira en: {time}",
    offer_starts_in: "La oferta comienza en: {time}",
    offer_starts_on: "Start Time: {time}",
    remaining_stock: "{count} restante",
    people_ordered: "{amt} personas que han realizado un pedido",
    select_category: "Seleccione categoría",
    selection_error_min: "Se requiere seleccionar al menos {min} para {option}.",
    no_giftcard_message: "Sus tarjetas regalo aparecerán aquí.",
    upsell_products: "También podría gustarle...",
    switch_to_dine_in: "Confirmar comer",
    switch_to_in_store: "Confirmar en establecimiento",
    switch_to_dine_in_message:
        "introduzca su número de mesa para proceder con su pedido de comer en el establecimiento, haga clic en cancelar para seguir en {type} tipo de pedido.",
    surcharge: "Recargo",
    store_surcharge: "Recargo online",
    order_surcharge: "Cuota de pedido",
    item_total: "Total artículo(s)",
    surcharge_paid: "Recargo (pagado)",
    surcharge_unpaid: "Recargo (pendiente de pago)",
    related_id: "Pedido correspondiente #",
    related: "Correspondiente",
    surcharege_paid_message:
        "La cantidad a recargar no está incluida en este pedido. Por favor consulte su pedido #{id}",
    surcharge_paid_to_message: "Este pedido es para pagar el recargo del pedido #{id}",
    switch_to_dine_in_confirm:
        "Parece que usted no se encuentra en el establecimiento, ¿Está seguro de que quiere reservar para comer aquí?",
    switch_to_in_store_confirm:
        "Parece que usted no se encuentra en el establecimiento, ¿Está seguro de que quiere realizar un pedido en tienda?",
    pull_to_refresh: "Volver a cargar",
    no_products_record: "Productos no encontrados",
    delivery_locations: "Puntos de entrega / Horario",
    pickup_locations: "Puntos de recogida",
    min_amount: "Pedido mín.",
    min_order_amount: "Cantidad de pedido mín.",
    free_delivery_amount: "Cantidad de envío gratis",
    delivery_times: "Horario de entrega",
    pickup_times: "Elija un horario",
    time_range: "Intervalo de tiempo",
    store_location: "Ubicación del establecimiento",
    pickup_locations_schedule: "Puntos de recogida / Horario",
    roll_over_image_to_zoom_in: "Pasa el cursor sobre la imagen para acercarla",
    about: "Sobre",
    product_information: "Descripción Breve del Producto",
    product_details: "Detalles de Producto",
    buy_now: "Compra ahora",
    see_more_product_details: "Ver más detalles del producto",
    video: "Video",
    page_of: "Página {page} de {totalNumOfPages}",
    warning_max_purchase_limit_reached: "Warning: Maximum purchase limit reached",
    max_purchase_limit_reached_msg: "You may only purchase {max_sale_qty} of this product.",
    warning_max_purchase_qty_exceeded_msg:
        "The following items have exceeded the maximum purchase quantity. Item quantities will be modified to fit within the limit.",
    max_purchase_qty: "Maximum Purchase Quantity: {qty}",
    warning_min_sale_qty_not_met_msg: "The following items do not fulfill the minimum purchase quantity.",
    min_purchase_qty: "Minimum Purchase Quantity: {qty}",
    remove_items: "Remove items",
    increase_item_quantities: "Increase item quantities",
    store_offline_message: "Sorry, we are currently not accepting online orders. Please check back later.",
    order_payment_status_error:
        "El establecimiento ha aceptado su pedido. Por favor realice el pago en el establecimiento.",
    order_comment_status_error:
        "El establecimiento está preparando su pedido. Por favor llámenos si desea realizar algún cambio. Tel: {phone}.",
    oos_items_cannot_be_added: "Artículo {oos_items} no disponible y no se ha añadido al carro.",
    order_cancelled: "Pedido cancelado",
    pending_store_confirmation: "Esperando confirmación del establecimiento",
    pending_customer_confirmation: "Esperando confirmación del cliente",
    store_accepted: "Su pedido ha sido aceptado",
    order_preparing: "Su pedido está siendo preparado",
    store_is_preparing: "Establecimiento preparando",
    ready_pickup: "Su pedido está listo para recoger",
    ready_courier_pickup: "Su pedido está listo para entregar",
    rejected_by_courier: "Rechazado por mensajero",
    courier_assigned: "Mensajero asignado",
    courier_accepted: "El mensajero ha aceptado el encargo",
    courier_heading_to_store: "El mensajero está de camino al establecimiento",
    courier_arrived_at_store: "El mensajero ha llegado al establecimiento",
    courier_collected_package: "El mensajero ha recogido el paquete",
    courier_heading_to_customer: "El pedido está siendo enviado",
    courier_arrived_at_customer: "El mensajero ha llegado a su destino",
    delivered_order: "El pedido ha sido entregado",
    order_changes_description:
        "Se han realizado cambios en su pedido. Por favor haga referencia a los cambios sugeridos y confirme cuanto antes para evitar posibles demoras en su pedido.",
    confirm_item_change: "Confirmar cambio de pedido",
    are_you_sure_reject:
        "Rechazar los cambios sugeridos hará que este artículo sea eliminado de su pedido, ¿está seguro de que quiere continuar?",
    accepted: "Aceptado",
    rejected: "Rechazado",
    added: "Añadido",
    removed: "Eliminado",
    updated: "Actualizado",
    add_back: "Añadir de nuevo",
    to: "a/hacia",
    updated_an_item: "Actualizado un artículo",
    added_an_item: "Añadido un artículo",
    removed_an_item: "Eliminado un artículo",
    replaced_an_item: "Reemplazado un artículo",
    are_you_sure_change_order_confirm: "¿Está seguro de que quiere confirmar los cambios de este pedido?",
    order_change_return_price_delta:
        "El importe total del pedido actualizado es {new_price}, se le devolverá la diferencia al finalizar el pedido.",
    order_change_pay_price_delta:
        "El importe total del pedido actualizado es {new_price}, este será el importe final a pagar al finalizar el pedido.",
    order_change_pay_new_price:
        " El importe total del pedido actualizado {new_price}, tendrá que abonar la diferencia de {price_delta}. Por favor abone la cantidad del recargo para evitar retrasos en su pedido.",
    order_change_reject_all: "Ha rechazado todos los cambios sugeridos. ¿Está seguro de que quiere cancelar el pedido?",
    order_change_reject_all_refund:
        " Ha rechazado todos los cambios sugeridos. ¿Está seguro de que quiere cancelar el pedido? Al cancelar se le devolverá {price_delta}.",
    order_change_refund:
        "El importe total del pedido actual es {new_price}, se le devolverá la diferencia de precio {price_delta}.",
    order_again_adds_items: "[Nuevo pedido] añada los mismos artículos a su carro automáticamente.",
    hide_menu: "Hide Item",
    show_menu: "Show Item",
    copy: "Copiar",
    copied_success: "Pedido copiado # en Archivo",
    shipping_time: "Tiempo de envío",
    order_time: "Tiempo de pedido",
    pickup_time: "Hora de recogida",
    request_due_time: "Plazo señalado",
    requested_pickup_time: "Solicitar hora de recogida",
    requested_delivery_time: "Solicitar. Hora de entrega",
    ready_pickup_time: "Listo para hora de recogida",
    estimated_delivery_time: "Hora de envío estimada",
    store_name: "Nombre del establecimiento",
    shipping_info: "Información de envío",
    order_info: "Información de pedido",
    table_info: "Tabla de información ",
    serve_time: "Tiempo de preparación estimado ",
    buzz: "Zumbido",
    order_detail: "Detalles del pedido",
    alipay: "Alipay",
    union_pay: "Union Pay",
    union_pay_express: "Union Pay Express",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "Tarjeta de crédito",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "E-Transfer/EMT",
    emt_description: "Pay with E-Transfer/EMT after placing order",
    payment_received: "Payment Received",
    payment_pending: "Payment Pending",
    payment_cancelled: "Payment Cancelled",
    payment_failed: "Payment Failed",
    emt_payment_note_long:
        "You selected E-Transfer/EMT payment for this order. Please arrange payment to the store at your earliest convenience and notify the store to avoid delays with your order.",
    emt_payment_password: "Please send {amount} to {emt_account} with the payment password {emt_password}",
    emt_payment_account: "Please send {amount} to {emt_account}",
    free: "No es necesario realizar ningún pago",
    dine_in: "Comer",
    in_store: "En el establecimiento",
    flatrate: "Tarifa plana",
    freeShipping: "Digital Asset",
    instantCheckout: "Instant Checkout",
    pending: "Esperando confirmación",
    processing: "Procesando",
    payment_review: "Revisando el pago",
    completed: "Completado",
    canceled: "Cancelado",
    closed: "Cerrado",
    pending_payment: "Pago pendiente",
    holded: "Retenido",
    cancel_order: "Cancelar pedido",
    order_number: "Pedido #",
    order_status: "Estado del pedido",
    order_date: "Hora del pedido",
    order_type: "Tipo de pedido",
    shipping_method: "Método de envío",
    expected_time: "Plazo señalado",
    customer: "Cliente",
    table_number: "Mesa #",
    party_size: "Tamaño de grupo",
    delivery_address: "Dirección de envío",
    pickup_location: "Punto de recogida",
    store_discount: "Descuento del establecimiento",
    coupon: "Cupón",
    shipping_and_handling: "Envío y gestión",
    shipping_and_handling_with_distance: "Coste de entrega ({distance})",
    order_total: "Total",
    giftcard_payment: "Pago con Tarjeta regalo",
    points_payment: "Pago con Puntos",
    points_payment_with_points: " Pago con Puntos ({x})",
    comments: "Comentarios",
    reorder: "VOLVER A PEDIR",
    general_order_error: "Se ha producido un error. Vuelva a su lista de pedidos e inténtelo de nuevo",
    order_unexist: "No se encontró ningún pedido.",
    you: "Usted",
    cancel_order_warning: "¿Está seguro de que quiere cancelar este pedido?",
    cancel_order_success: "Su pedido #{order_id} en {store_name} ha sido cancelado con éxito.",
    cancel_order_failed: "Error al cancelar su pedido, inténtelo de nuevo más tarde",
    pay_now_unavailable: "Pago online no disponible",
    no_online_payment:
        "Lo sentimos, el establecimiento no acepta pagos online en este momento. Por favor inténtelo de nuevo más tarde o contacte al establecimiento",
    pay_now: "Pagar ahora",
    reviews: "Opiniones",
    write_review: "Escriba una opinión",
    write_review_for: "Escriba una opinión sobre {name}.",
    update_review: "Actualizar opinión",
    overall: "En general",
    add_a_review: "Añada una opinión",
    rate_our_service: "Valore nuestro servicio",
    rate_our_delivery: "Valore nuestros precios",
    post: "Publicar",
    requested_time: "Hora solicitada",
    scheduled_time: "Hora prevista",
    exp_dt: "Plazo señalado",
    ord_total: "Cantidad",
    s_method: "Tipo de pedido",
    p_method: "Método de pago",
    start_processing_message: "¿Está seguro de que quiere procesar el pedido?",
    mark_as_complete_message: "¿Está seguro de que quiere completar el pedido?",
    cancel_order_message: "¿Está seguro de que quiere cancelar el pedido?",
    complete: "Completado",
    reject: "Rechazar",
    total_refunded: "Total devuelto",
    refund_amount: "Cantidad",
    refund_all: "Devolver todo",
    remark: "Remarcar",
    "edit-shipping-fee-message":
        "Al actualizar la tarifa de envío se cancelará el pedido anterior y creará uno nuevo con el coste de envío actualizado, ¿seguro que quiere continuar?",
    edit_shipping_info: "Cambiar la dirección de envío",
    additional_info: "Información adicional",
    exp_time: "Solicitar hora Deli",
    estimatedDeliTime: "Hora Deli estimada",
    "create-new-order-message": "¿Está seguro? Este pedido será cancelado y se creará uno nuevo en su lugar.",
    order_and_account_information: "Pedido e Información de cuenta",
    address_info: "Información de dirección",
    shipping_address: "Dirección de envío",
    payment_and_shipping_method: "Pago y Método de envío",
    payment_info: "Información de pago",
    shipping_handling_info: "Información de envío y gestión",
    item_order: "Artículos pedidos",
    raw_total: "Total bruto",
    notes_for_this_order: "Notas para este pedido ",
    alert_on_notification: "Alerta de notificación",
    sub_comment: "Envíar comentario",
    modify_discount: "Modificar descuento",
    add_discount: "Añadir descuento",
    modify_tips: "Modificar propinas",
    add_tips: "Añadir propinas",
    modify_shipping_fee: "Modificar coste de envío",
    discount: "Descuento",
    edit_payment: "Actualizar pago",
    edit_extra: "Actualizar información",
    edit_payment_method: " Actualizar pago ",
    "select_receipt(s)_to_print": "Seleccionar recibo(s) para imprimir ",
    change_payment_to: "¿Está seguro de que quiere cambiar el método de pago por ",
    handling: "Gestión",
    coupon_discount: "Cupón de descuento",
    phone: "Teléfono #",
    email: "Email",
    email_receipt: "Recibo por email",
    email_receipt_sent_to: "Recibo por email enviado a",
    unpaid: "No pagado",
    admin: "Admin",
    cust: "Cliente",
    other_payment: "Otros pagos",
    access_denied: "Lo sentimos, no tiene permiso para ver este pedido",
    please_pay_now: "El pedido no está pagado, puede pagar ahora.",
    are_you_sure_cancel_order: "¿Está seguro de que quiere cancelar este pedido?",
    please_pay_by_x: "Su pedido se guardará por {x}. Pague ahora para evitar su cancelación",
    reward: "Recompensa",
    reward_points: "Puntos de recompensa",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    shipment_tracking_info: "Información de Seguimiento del Envío",
    carrier: "Transportista",
    tracking_number: "El Número de Rastreo",
    register: "Registrarse",
    vc_login: "Inicie sesión a través de mensaje de texto",
    resend: "Reenviar",
    pwd_login: "Incie sesión en cuenta",
    email_phone_placeholder: "Email/teléfono",
    password: "Contraseña",
    vc: "Código de verificación",
    get_vc: "Solicite código",
    titleS: "Goopter",
    alert_login_succeed: "Inicio de sesión satisfactorio ",
    alert_phone_not_found: "Este número de teléfono todavía no ha sido registrado",
    alert_too_many_request:
        "Ha alcanzado el límite para solicitar el código de verificación. Vuelva a intentarlo en 15 minutos.",
    alert_phone_length: "Número de teléfono no válido",
    alert_phone_vc_not_match: "Código de verificación incorrecto, por favor inténtelo de nuevo.",
    alert_login_failed_general: "No se puede iniciar sesión, por favor inténtelo de nuevo más tarde.",
    alert_login_failed: "Nombre/contraseña no válidos.",
    forget_pwd: "Olvidar contraseña",
    state_reg:
        "Si todavía no tiene una cuenta con nosotros, se le creará una automáticamente cuando inicie sesión a través de un mensaje de texto",
    cos_privacy_statement: "Al iniciar sesión, confirma que está de acuerdo con las {cos} y {privacy} de Goopter",
    cos_privacy_statement_register: "Al registrarse, confirma que está de acuerdo con las {cos} y {privacy} de Goopter",
    cos: "Términos de uso",
    privacy: "Aviso de privacidad",
    or_login_with_social: "o inicie sesión con una red social",
    social_redirect_text:
        "Al iniciar sesión con una red social, será redirigido a la página del establecimiento. ¿Desea continuar?",
    social_redirect_text_cart:
        "Al iniciar sesión con una red social, será redirigido a la página del establecimiento y su carro se vaciará, ¿Desea continuar?",
    social_login_failed:
        "Se ha producido un error al iniciar sesión con su cuenta de {socialType}. Por favor inténtelo de nuevo.",
    check_out_as_guest: "Continuar como invitado",
    email_placeholder: "Email",
    c_tn: "Con teléfono #",
    nickname: "Nombre/Nombre de usuario",
    alert_invalid_request: "Solicitud incorrecta",
    alert_phone_exists: "Lo sentimos, este número de teléfono ya se encuentra en nuestro sistema.",
    alert_bind_phone_conflict_title: "El número de teléfono ya está registrado",
    alert_bind_phone_phone_exists:
        "Este número ha sido registrado con otra cuenta, por favor cierre la sesión y vuelva a iniciarla con el número si desea hacer un pedido con este número; o puede registrar un número de teléfono alternativo para su cuenta actual.",
    alert_bind_phone_phone_exists_guests:
        "This number has been registered with another account, please sign in with the number if you want to use this number to place an order; alternatively you may use another phone number for your current account",
    alert_bind_phone_conflict:
        "El número que intenta utilizar está registrado con otra cuenta, si desea utilizar este número para hacer su pedido, haga clic en Continuar para vincular su cuenta de red social a este número. Si desea usar otro número, haga clic en Cancelar e inténtelo con otro número.",
    alert_vc_input_time_expired:
        "Si no ha recibido un código de verificación por SMS dentro de 1 minuto, eso podría significar que su número es incorrecto o que su número no puede recibir mensajes SMS correctamente.{br}{br}Asegúrate de estar usando el número correcto o vuelve a intentarlo con otro número.",
    alert_reg_success_text: "Ya puede utilizar su número de teléfono para iniciar sesión.",
    alert_reg_success_email: "Ya puede utilizar su email para iniciar sesión.",
    alert_reg_failed: "No se pudo registrar.",
    alert_email_length: "Email no válido",
    alert_password_min_length: "La contraseña debe tener al menos 6 caracteres",
    open: "Abierto",
    store_popup_sold: "Vendido",
    distance: "Distancia",
    prep_time: "Tiempo de preparación",
    min_delivery_amnt: "Cantidad de entrega mínima",
    max_delivery_dist: "Cantidad de entrega máxima",
    public_notice: "Aviso público",
    discounts: "Descuento(s)",
    discount_message: "{discount} de descuento sobre {price}",
    discount_message_without_price: "{discount} de descuento",
    pay_later_cash_description: "Pagar en efectivo en persona",
    pay_later_card_description: "Pagar con tarjeta de crédito en persona",
    continue: "Continuar",
    please_leave_your_comment: "Por favor escriba su comentario",
    order_now: "Realizar pedido ahora",
    order_success: "Su pedido ha sido enviado",
    view_order: "Ver pedido",
    secure_connection: "La información se ha enviado a través de una conexión segura",
    confirm_order: "Confirmar pedido",
    back_to_store: "Volver al establecimiento",
    subtotal: "Subtotal",
    delivery_fee: "Tarifa de envío",
    total_before_tax: "Total antes de impuestos",
    tax: "Impuesto",
    tips: "Propinas",
    tips_with_helper_text: "Propinas (haga clic en la cantidad para actualizar)",
    quantity: "Cantidad",
    total_paid: "Total pagado",
    total_due: "Cantidad a deber",
    refund: "Devolución",
    payment_method: "Método de pago",
    estimate_shipping_fee: "Tarifa de envío estimada",
    shipping_option: "Forma de envío",
    pick_shipping_option: "Elija una forma de envío",
    shipping_option_title: "Envío: ",
    pay_later: "Pagar más tarde con tarjeta",
    cash: "Pagar más tarde en efectivo",
    credit_card: "Tarjeta de crédito",
    pay_by_credit_card: "Pagar con tarjeta de débito",
    giftcard: "Tarjeta regalo",
    points: "Puntos",
    points_and_giftcard: "Puntos y Tarjetas regalo",
    wechat_pay: "Pagar con WeChat",
    address_customer_info: "Información de la dirección del cliente",
    c2c_regular: "Envío estándar",
    c2c_express: "Envío exprés",
    S01: "Envío gratuito",
    S02: "Tarifa plana",
    S03: "Tabla de tarifas",
    credit_card_added: "Tarjeta de crédito añadida",
    map_search_placeholder: "Añada la calle",
    exceed_delivery_distance_select:
        "La dirección de entrega seleccionada excede la distancia máxima de entrega de la tienda de {delivery_distance} {distance_unit}. Elija otra dirección de entrega.",
    exceed_delivery_distance_input:
        "La dirección de entrega proporcionada excede la distancia máxima de entrega de la tienda de {delivery_distance} {distance_unit}. Ingrese otra dirección de entrega.",
    address_not_in_delivery_zone:
        "La dirección de entrega seleccionada no está dentro del rango de las zonas de entrega de la tienda. Elija otra dirección de entrega.",
    item_oos_all: "Todos los artículos en su carro están disponibles. Añada otros artículos para continuar",
    error_occured:
        "Lo sentimos, la autentificación de su cuenta ha fallado. Por favor re-inicie sesión y vuelva a intentarlo.",
    transaction_declined: "Pago rechazado. Tarjeta de crédito no válida. Por favor elija otro método de pago.",
    invalid_first_name: "Nombre no válido",
    invalid_last_name: "Apellido no válido",
    payment_method_missing: "Método de pago está vacío",
    billing_address_missing: "Dirección de facturación está vacío",
    giftcard_error: "Error en la tarjeta regalo. Por favor actualice la página e inténtelo de nuevo",
    customer_info_missing: "Información del cliente vacío",
    table_number_missing: "Por favor agregue su número de mesa",
    pickup_time_missing: "Tiempo de envío vacío",
    delivery_time_missing: "Tiempo de envío vacío ",
    delivery_address_missing: "Dirección de envío vacío ",
    shipping_method_missing: "Método de envío vacío ",
    set: "Configurar/Establecer",
    invalid_request: " No se ha podido realizar el pago. Solicitud no válida.",
    item_error: "Se ha producido un error. Su carro está vacío. Por favor agregue algún producto e inténtelo de nuevo.",
    no_items_error: "No hay ningún artículo en su carro. Por favor agregue algún producto e inténtelo de nuevo.",
    use_profile_info: "Usar la información de perfil",
    pick_up: "Elegir",
    delivery_option: "Opción de envío",
    required: "Obligatorio",
    first_name_placeholder: "Introduzca nombre",
    last_name_placeholder: "Introduzca apellido",
    table_number_placeholder: "Introduzca mesa #",
    unit_placeholder: "Introduzca unidad #",
    buzz_placeholder: "Introduzca zumbido #",
    street_placeholder: "Introduzca calle",
    city_placeholder: "Introduzca ciudad",
    region_placeholder: "Introduzca provincia o estado",
    cntry_placeholder: "Introduzca país",
    zipcd_placeholder: "Introduzca código Zip",
    table_number_required: "El número de mesa es obligatorio",
    are_you_sure_remove_gift_card: "¿Está seguro/a de que quiere eliminar esta tarjeta regalo de su pago?",
    no_shipping_options: "Método de envío no seleccionado. Por favor elija otra dirección de envío.",
    delivery_time_changed: "Se ha revisado el plazo de entrega. Por favor seleccione otra hora apropiada.",
    gift_card_payment: "Pago con tarjeta regalo",
    expires: "Expira",
    allowed_periods_unavailable: "Por favor elija una dirección de envío para poder seleccionar la hora de envío.",
    delivery_method_unavailable:
        "El envío ya no está disponible. Por favor vuelva al establecimiento y realice el pedido de nuevo.",
    takeout_method_unavailable:
        "La recogida ya no está disponible. Por favor vuelva al establecimiento y realice el pedido de nuevo.",
    in_store_method_unavailable:
        "El pedido en tienda ya no está disponible. Por favor vuelva al establecimiento y realice el pedido de nuevo.",
    agreement_notice:
        "Al realizar la compra, confirma estar de acuerdo con los {conditions_of_use} y {privacy_policy} de Goopter",
    terms_of_use: "Términos de uso",
    privacy_policy: "Política de privacidad",
    "secure-connection": "La información se ha enviado a través de una conexión segura",
    set_payment_method: "Establezca un método de pago",
    set_billing_address: " Establezca una dirección de facturación",
    set_delivery_address: " Establezca una dirección de envío",
    set_giftcard: "Elija una tarjeta regalo",
    set_points: "Haga clic para canjear puntos",
    redeem_points_dialog_title: "Canjear puntos",
    redeem_x: "Canjear: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "Agregue información de cliente",
    set_table_number: "Agregue número de mesa",
    set_pickup_time: "Elija hora de recogida",
    set_delivery_time: "Elija hora de envío",
    set_shipping_method: "Establezca forma de envío",
    address_book: "Directorio",
    "no-giftcard-text": "No tiene ninguna tarjeta regalo para este establecimiento",
    delete_credit_card_failed: "Lo sentimos, su tarjeta no se pudo eliminar, inténtelo de nuevo más tarde.",
    checkout_success:
        "Gracias por su compra, su pedido ha sido creado: #{order_id}. Recibirá una notificación cuando el estatus de su pedido se actualice.",
    new_order_created: "Nuevo pedido creado",
    distance_exceeded: "Distancia excedida",
    min_delivery_amt_required:
        "La cantidad mínima de envío es {required} antes de impuestos y costo de entrega, añada {gap} más para enviar.",
    insufficient_fund: "Fondos insuficientes, utilice otro método de pago.",
    invalid_payment_info: "Información de pago no válida, compruebe los datos e inténtelo de nuevo.",
    merchant_not_ready:
        "El establecimiento no está listo para aceptar el pago. Por favor inténtelo de nuevo más tarde.",
    shipping_country_text: "Envío disponible para las direcciones en los países siguientes: {countries}",
    apply: "Aplicar",
    enter_coupon_code: "Introduzca cupón",
    remove_coupon_alert: "Está Seguro de que desea eliminar este cupón?",
    replace_coupon_alert: "El cupón actual será reemplazado, ¿desea continuar?",
    invalid_alipay_coupon: "El código de este cupón sólo está disponible para pagos con AliPay",
    remove_current_alipay_coupon:
        "El carrito actual contiene un cupón que sólo está disponible para pagos con AliPay, si cambia el método de pago perderá el descuento, ¿desea cambiar el método de pago?",
    use_wechat_dialog_message:
        "Para usar WeChatPay, seleccione el establecimiento en Wechat para realizar su pedido. {br}{br}Opciones para seleccionar el establecimiento en Wechat:{br}1. Si tiene el código QR del establecimiento, utilice Wechat para escanearlo;{br}2. Haga clic en el nombre del establecimiento en la parte superior de la página, después haga clic en el icono de la esquina superior derecha para abrir la ventana del código QR, después abra el código QR en Wechat para realizar el pedido.",
    discount_total: "Descuento total",
    first_name_missing: "Por favor introduzca su nombre",
    phone_number_missing: "Por favor introduzca su número de teléfono",
    payment: "pago",
    select_payment: "Seleccione forma de pago",
    select_giftcards: "Seleccione tarjeta regalo",
    special_request: "Solicitud especial",
    place_order_with_x: "Realizar pedido - {x}",
    min_type_required_message:
        "La cantidad mínima para {tipo} es {valor}, por favor añada más artículos para habilitar {type}.",
    select_pickup_location: "Seleccione punto de recogida",
    please_choose_pickup_location: "Por favor elija punto de recogida",
    invalid_delivery_zone_title: "Fuera de los límites de envío",
    invalid_delivery_zone_content:
        "La dirección seleccionada está fuera de los límites de envío. Por favor introduzca otra dirección. Las áreas de envío y horarios son:",
    please_input_address: "Por favor introduzca una dirección válida",
    please_input_pickup_time: "Por favor introduzca hora de recogida",
    please_input_delivery_time: "Por favor introduzca hora de envío",
    recommend_a_good_restaurant: "Le recomendó un buen establecimiento",
    recommend_a_good_merchat: "Le recomendó un buen comerciante",
    recommend_a_good_product: "Le recomendó un buen producto",
    guest_user: "Usuario invitado",
    require_utensil: "Solicite cubiertos, etc.",
    already_have_account: "Pagar como invitado",
    order_success_table: "Gracias por su pedido. Le llevaremos su pedido a su mesa en cuanto esté listo.",
    order_success_parking_lot: " Gracias por su pedido. Le llevaremos su pedido a su auto en cuanto esté listo.",
    order_success_kiosk: "Gracias por su pedido. Le enviaremos una notificación en cuanto esté listo.",
    view_special_deals: "View special deals",
    more_items_to_get_special_deals: "Current order amount does not qualify for special deals. Please add more items.",
    special_deals_for_you: "Special deals for you",
    add_items: "Add items",
    discounted_upsell_items_removed_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers.",
    discounted_upsell_items_removed_empty_cart_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers. Your cart is now empty. Please add items to continue.",
    menu: "Menú",
    category: "Categoría",
    categories: "Categorías",
    store_details: "Información del establecimiento",
    store_description: "Descripción del establecimiento",
    rank: "Clasificación",
    customer_reviews: "Opiniones de clientes",
    single_selection: "Selección única",
    optional: "Opcional",
    pick_varied_options: "Elija {count} artículos",
    sold: "{count} vendido",
    only_x_left: "Sólo quedan {count}",
    sold_out: "Agotado",
    pick_option: "Elija una opción",
    contact_vendor: "Contactar al vendedor",
    call_vendor: "Llamar al vendedor",
    self_input: "Introducción manual",
    with_option: "Con opción",
    coupons: "{couponCount} Cupón",
    coupons_title: "Cupones",
    redeemed: "Canjeado",
    redeem: "Canjear",
    remove: "Eliminar",
    valid_until: "Válido hasta {endDate}",
    checkout: "Compra",
    shopping_cart: "Carro de compra",
    empty_cart: "Vaciar",
    alert_empty_cart: "¿Desea vaciar su carro?",
    discount_title: "Descuento",
    qrcode: "Código QR",
    m: "m",
    km: "km",
    min: "min",
    hour: "h",
    rating: "rating",
    rating_title: "Valoración",
    base_delivery_fee: "Tarifa de envío según {flatDistance} {distanceUnit}",
    min_delivery_amount: "Cantidad de envío mínima",
    max_delivery_distance: "Cantidad de envío máxima",
    distance_title: "Distancia",
    takes_reservations: "Acepta reservas",
    more_info: "Más información",
    announcement: "Aviso público",
    no_announcement: "Sin aviso público",
    buy_x_discount_flat: "Comprar {currency}{price}, {currency}{discount} de descuento",
    buy_x_discount_percent: "Comprar {currency}{price}, {discount}% de descuento",
    search_products_title: "Buscar productos",
    search_products_input_placeholder: "Introduzca palabra clave para buscar...",
    search_products_no_match: "Su búsqueda '{text}' no coincide con ningún producto.",
    alert_delete_product_with_options: "Producto con opciones solo puede eliminarse del carro de compra.",
    store_closed_allowed_preorder: "El establecimiento está cerrado, puede hacer un pedido previo.",
    store_closed_allowed_preorder_x_mins_before:
        "La tienda está cerrada actualmente. Puede reservar {mins} minutos antes de que abra la tienda.",
    store_closed_soon_not_allowed_order: "La tienda cerrará pronto y actualmente no acepta nuevos pedidos.",
    results_colon: "Resultados: ",
    price: "Precio",
    popularity: "Popularidad",
    service_title: "Servicio",
    product_title: "Producto",
    all_title: "Todo",
    satisfied_title: "Satisfecho",
    unsatisfied_title: "Insatisfecho",
    discount_rate: "{rate}% de descuento",
    invalid_option_selection: "Selección de opción no válida",
    no_product_options: "Sin opciones de productos",
    single_selection_error: "Por favor elija una opción de producto desde '{productOption}'",
    pick_ranged_options_error: "Opción de selección mínima por '{productOption}' es {count} productos",
    pick_fixed_options_error: "Por favor elija {count} opción de selección entre '{productOption}'",
    update_cart: "Actualizar carro",
    total_reviews: "Opiniones",
    store_total_sold_count: "Vendido",
    hot_products: "Productos más vendidos",
    related_products: "Productos relacionados",
    store_hours: "Horario del establecimiento",
    delivery_hours: "Horas de envío",
    add_for_delivery: "Añadir {currency}{amount} para enviar",
    open_in_app: "Abrir en la App",
    qrcode_message: "Escanea este código QR para abrir el establecimiento",
    review_submitted_validate: "Gracias por su opinión. Se publicará una vez haya sido validada.",
    review_submitted: "Su opinión ha sido enviada.",
    add_update_review: "Por favor añada o actualice su opinión",
    post_review_failed: "Su opinión no ha sido enviada. Por favor actualice la página e inténtelo de nuevo.",
    not_accepting_orders: "No se aceptan pedidos online en estos momentos",
    bookmark_sign_in_alert_store: "Inicie sesión para añadir un marcador para este establecimiento",
    bookmark_sign_in_alert_product: "Inicie sesión para añadir un marcador para este producto",
    no_store_bookmarks: "No hay marcadores para este establecimiento",
    no_store_bookmarks_message:
        "No tiene marcadores para este establecimiento. Haga clic en el icono de corazón en la esquina superior derecha para añadir un marcador para este establecimiento.",
    no_store_bookmarks_button: "Añadir un marcador de establecimiento",
    no_product_bookmarks: "No hay productos en los marcadores",
    no_product_bookmarks_message:
        "No tiene productos en los marcadores. Haga clic en los corazones de los productos para añadirlos a marcadores.",
    no_product_bookmarks_button: "Añadir un marcador de producto",
    no_group_bookmarks: "No hay ofertas en sus marcadores",
    no_group_bookmarks_message:
        "No tiene marcadores de ofertas. Haga clic en los corazones de las ofertas para añadirlas a marcadores.",
    no_group_bookmarks_button: "Añadir un marcador de ofertas",
    add_item_another_menu_warning:
        "Su carro ya tiene artículos de otro menú ({menu}), ¿Deasea vaciar su carro anterior y añadir este artículo?",
    start_new_cart_question: "¿Nuevo carro de compra?",
    change_menu: "Cambiar menú",
    direction: "Indicaciones",
    website: "Página web",
    we_also_recommend: "Le recomendamos...",
    diff_delivery_price: "Añadir {x} para enviar",
    discount_info_format: "Comprar {x}",
    discount_add_x_get_y: "Añadir {x} más para conseguir {y} de descuento",
    discount_get_percentage_discount: " conseguir {x} de descuento",
    discount_get_value_discount: " conseguir {x} de descuento",
    discount_not_accepting_order: "Lo sentimos, no procesamos pedidos online en este momento",
    discount_store_closed_preorder: "Establecimiento cerrado, reserve un pedido ahora",
    discount_x_off_add_y_get_z: "{x} de descuento, añada {y} más para conseguir {z} de descuento",
    discount_x_off: "{x} de descuento",
    switch_menu_warning_message:
        "El menú seleccionado admite un tipo de pedido diferente ({new_order_type}). Los productos de carro se eliminarán si continúa. ¿Desea cambiar al nuevo menú?",
    logout_confirm_message: "¿Está Seguro de que quiere cerrar sesión?",
    logout_force_message:
        "Lo sentimos, se ha iniciado sesión con su cuenta desde otro dispositivo o la sesión ha expirado. Inicie sesión de nuevo.",
    cannot_merge_account: "Su cuenta ya existe, utilice otro número de teléfono.",
    duplicate_social_error:
        "Este teléfono está registrado con otra cuenta, por favor vuelva a intentarlo con otro número. Para utilizar este número cierre la sesión y reinicie con este número.",
    store_has_no_products: "Actualmente este establecimiento no tiene productos.",
    error_with_code: "[Error {code}]: {error}",
    unable_to_load_any_data: "Carga de datos no válida",
    unstable_network: "Conexión de red lenta o inestable, por favor compruebe su conexión",
    incorrect_password: "Contraseña incorrecta",
    country_code_error: "Error en el código de país",
    incorrect_username_password: "Nombre de usuario o contraseña incorrectos",
    verification_code_expired: "Código de verificación expirado",
    verifcation_code_request_overlimit: "Tiempo de solicitud de código de verificación sobrepasado",
    incorrect_verification_code: "Código de verificación incorrecto",
    error_fetch_user_profile: "No se pudo obtener el perfil de usuario",
    login_failed: "No se pudo iniciar sesión",
    profile_update_success: "Actualización del perfil correcta",
    email_update_success: "Actualización del email correcta",
    phone_update_success: "Actualización del número de teléfono correcta",
    failed_fetch_gift_card_detail: "No se pudo obtener los datos de la tarjeta regalo",
    gift_card_transfer_success: "Transferencia de la tarjeta regalo correcta",
    gift_card_transfer_fail: "No se pudo transferir la tarjeta regalo",
    record_not_found: "Artículo no encontrado",
    failed_fetch_points_detail: "No se pudo obtener los datos de los puntos",
    profile_update_failed: "No se pudo actualizar el perfil de usuario",
    email_update_failed: "No se pudo actualizar el email",
    phone_update_failed: "No se pudo actualizar el número de teléfono",
    get_verification_code_success: "El Código de verificación ha sido enviado a su celular.",
    verification_too_many_requests:
        " El código de verificación no se ha solicitado demasiadas veces, inténtelo de nuevo más tarde",
    invalid_table_number: "Por favor introduzca un número",
    invalid_party_size: " Por favor introduzca un número",
    email_required: "El correo electrónico es obligatorio",
    password_required: "La contraseña es obligatoria",
    phone_required: "Un celular es obligatorio ",
    vc_required: "Un Código de verificación es obligatorio",
    coupon_invalid: "Cupón no válido",
    coupon_valid: " Cupón no válido",
    invalid_coupon: "Código de cupón {couponCode} no válido",
    coupon_applied: "El cupón ha sido aplicado al carrito.",
    invalid_address: "Dirección no válida",
    remaining_stock_header: "Remaining Stock",
    item_oos: "Lo sentimos, el/los artículo(s) {oos_items} están agotado(s).",
    invalid_shipping_method: "Método de envío no válido. Por favor intente otro método de envío",
    please_input_phone_number: "Por favor agregue un número de teléfono válido",
    please_input_first_name: "Por favor agregue un nombre",
    please_input_at_least_two_characters: "Por favor agregue al menos dos caracteres",
    please_input_last_name: "Por favor agregue un apellido",
    please_input_shipping_method: "Por favor agregue un método de envío",
    please_input_sub_shipping_method: "Por favor agregue un método de envío",
    please_input_shipping_addresss: "Haga clic para agregar una dirección de envío",
    please_input_pickup_addresss: "Haga clic para elegir un punto de recogida",
    please_input_table_number: "Por favor agregue un número de mesa",
    please_input_payment_method: "Haga clic para elegir un método de pago",
    please_input_valid_credit_card: "Por favor agregue una tarjeta de crédito válida",
    please_input_billing_address: "Por favor agregue una dirección de facturación",
    tap_to_show_on_map: "Haga clic aquí para ver en el mapa",
    please_add_some_items: "Por favor añada algún artículo",
    please_confirm_tips: "Tap to Set Tip Amount",
    please_set_minimum_tips: "Please Input Minimum Tip Amount",
    please_set_minimum_tips_detail: "The minimum tip amount for delivery is ${tips_amount} in order to secure a driver for your order.",
    bind_phone: "Verificar número de teléfono",
    please_bind_phone:
        "Se necesita un número de teléfono para hacer un pedido. ¿Desea verificar su número de teléfono ahora?",
    go_to_bind: "Ir a conectar",
    bind_phone_success: "Se ha conectado el teléfono con éxito",
    invalid_credit_card: "Tarjeta de crédito no válida. Por favor elija otro método de pago",
    unsuccessful_checkout: "No se pudo realizar la compra. Por favor inténtelo de nuevo.",
    price_over_limit: " No se pudo realizar la compra. El precio excede el límite de pago.",
    invalid_shp_mtd:
        " No se pudo realizar la compra. El método de envío no es válido. Por favor vuelva a intentarlo con otro método de envío",
    invalid_pay_mtd:
        " No se pudo realizar la compra. La forma de pago no es válida. Por favor vuelva a intentarlo con otra forma de pago.",
    please_input_belows: "Por favor inserte los campos que faltan",
    unable_to_change_here: "No se puede cambiar aquí",
    please_select_on_previous_page: "Por favor seleccione en la página anterior",
    invalid_card_number: "Número de tarjeta no válido",
    invalid_zipcd: "Código postal no válido",
    click_one_more_time_to_deselect: "Haga clic en más tiempo para anular",
    paypal_payment_success_title: "Pago realizado con éxito",
    paypal_payment_success_text: "El pago ha sido recibido. Será redirigido a la página de pedidos.",
    error_payment_failed: "Pago rechazado",
    internal_server_error:
        "Lo sentimos, se ha producido un error. Por favor inténtelo de nuevo más tarde o contacte al vendedor directamente.",
    unexpected_error: "Se ha producido un error inesperado, por favor inténtelo de nuevo más tarde",
    giftcard_pays_order_total:
        " El total del pedido se pagará con la tarjeta regalo. No necesita añadir otro método de pago.",
    points_pays_order_total: "El total del pedido se pagará con los puntos. No necesita añadir otro método de pago.",
    giftcard_points_pays_order_total:
        " El total del pedido se pagará con la tarjeta regalo o con los puntos. No necesita añadir otro método de pago.",
    digit_only: "Por favor inserte solo dígitos.",
    please_selected_belows: "Por favor complete todas las selecciones:",
    please_select_location_from_the_list: "Por favor seleccione una calle de la lista.",
    alert_password_reset_success:
        "Su contraseña se ha actualizado con éxito, ya puede iniciar sesión con la nueva contraseña. ",
    alert_email_not_found: "Este email no se ha registrado todavía",
    alert_password_reset_email_send: "Se ha enviado el email para restablecer la contraseña",
    x_is_required: " es un campo obligatorio",
    location_permission_is_blocked_service_may_not_perform_correctly:
        "Permiso de localización bloqueado, este servicio puede verse afectado ",
    x_missing: "Por favor inserte {x} ",
    x_invalid: "{x} Inválido",
    x_select: "Por favor seleccione {x}",
    do_you_want_to_receive_notification: "¿Desea recibir notificaciones?",
    gift_cards_points_alert:
        "No se permite utilizar una tarjeta regalo cuando canjea los puntos. Para utilizar una tarjeta regalo debe eliminar los puntos de recompensa primero.",
    points_gift_cards_alert_message:
        " No se permite utilizar puntos de recompensa cuando utiliza una tarjeta regalo. Para utilizar los puntos de recompensa debe eliminar la tarjeta regalo primero.",
    not_found_description: "Oops! No se pudo encontrar página",
    not_found_detail_description:
        "Lo sentimos, la página que busca no existe, ha sido eliminada, ha cambiado de nombre o está temporalmente no disponible",
    back_to_home: "Volver a la página principal",
    braintree_credit_card_invalid_message: "Algo ha fallado. Revise los datos de su tarjeta e inténtelo de nuevo.",
    please_input_valid_postal_code: "Por favor introduzca un código postal o Zip válido.",
    please_input_valid_x: "Por favor introduzca un {x} válido",
    too_many_card_attempt:
        "The provided card has been declined too many times; please try another card for the payment.",
    too_many_checkout_attempt: "Maximum retry limit exceeded. Please try again later.",
    payment_declined: "Payment Declined",
    credit_card_declined:
        "Please check the credit card information to make sure they were entered correctly and try again. If this does not resolve the problem, please call your card issuing bank to resolve.",
    no_giftcard: "You do not have available gift card",
    giftcard_insuffienct_fund: "Your Gift Card has an insufficient balance for the payment.",
    invalid_request_body: "Invalid request",
    pay_order: "Pagar pedido",
    expiry_date: "Fecha de vencimiento",
    postal_code: "Código postal",
    postal_code_zipcd: " Código postal / Código Zip",
    country: "País",
    last_four: "Los últimos cuatro",
    are_you_sure_delete_card: "¿Está Seguro de que quiere eliminar esta tarjeta?",
    visa: "Visa",
    master_card: "Master Card",
    american_express: "American Express",
    discover: "Discover",
    jcb: "JCB",
    maestro: "Maestro",
    credit_card_description: "Visa, Master Card, American Express",
    confirm_pay: "Confirmar pago",
    unsuccessful_payment: "Pago fallido. Por favor inténtelo de nuevo.",
    reset_password_title: "Restablecer contraseña",
    new_password: "Nueva contraseña",
    password_reset_continue: "Enviar",
    reset_by_text: "Reenviar por mensaje de texto",
    reset_by_email: "Restablecer por email",
    alert_vc_expired:
        "Lo sentimos, el código de verificación proporcionado ha expirado, solicite otro código e inténtelo de nuevo.",
    email_verification: "Verificación del email",
    verification_success: "Su cuenta ha sido verificada",
    verify_account: "Gracias por verificar su cuenta, {email}.",
    verification_fail: "Error de verificación del email",
    verify_error:
        "Se ha producido un error. Vuelva a intentarlo enviando un correo electrónico de verificación. {email}",
    view_account: "Ver cuenta",
    link_expired: "Couldn't verify email address",
    link_expired_message: "The verification link has expired or was already used. Please try again. {email}",
    email_already_verified: "Your account has already been verified.",
    resend_verification_email: "Resend Verification Email",
    recently_viewed: "Visto recientemente",
    by_phone: "Por teléfono",
    by_email: "Por email",
    by_password: "Por contraseña existente",
    op_incorrect: "La contraseña original enviada es incorrecta.",
    password_change_success: "Contraseña cambiada con éxito.",
    password_request_sent: "Solicitud de cambio de la contraseña enviada",
    social_binding: "Conexión/Vinculación social",
    linked: "Vinculado/a",
    facebook: "Facebook",
    twitter: "Twitter",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    or: "o",
    connect_with_facebook: "Iniciar sesión con Facebook",
    connect_with_twitter: "Iniciar sesión con Twitter",
    connect_with_google: "Iniciar sesión con Google",
    connect_with_wechat: "Iniciar sesión con WeChat",
    connect_with_apple: "Iniciar sesión con Apple",
    dont_have_account: "¿No tienen una cuenta? ",
    vouchers: "Vales",
    gift_card: "Tarjeta regalo",
    bookmarks: "Marcadores",
    bookmark_added: "Marcador agregado",
    bookmark_deleted: "Marcador eliminado",
    account_info: "Información de la cuenta",
    my_points: "Mis puntos",
    my_giftcards: "Mis tarjetas regalo",
    my_bookmarks: "Mis marcadores",
    my_messages: "Mis mensajes",
    my_orders: "Mis pedidos",
    avatar: "Avatar",
    name: "Nombre",
    my_address: "Mi dirección",
    account_binding: "Cuenta vinculada",
    connect: "Conectar",
    safety_settings: "Ajustes de seguridad",
    login_password: "Contraseña de inicio de sesión",
    modify: "Modificar",
    delete: "Eliminar",
    first_name: "Nombre",
    last_name: "Apellido",
    phone_number: "Teléfono #",
    address: "Dirección",
    pick_place_on_map: "Elija un lugar en el mapa",
    done: "Listo",
    enter_address_manually: "Introduzca la dirección manualmente",
    use_name_account: "Utilice el nombre en la cuenta",
    new_address: "Nueva dirección",
    unit: "Unidad",
    street: "Calle",
    city: "Ciudad",
    region: "Región",
    cntry: "País",
    zipcd: "Código Zip",
    postalcd: "Código postal",
    customer_info: "Información del cliente",
    required_text: "* indica que un campo es obligatorio",
    map_search: "Búsqueda en el mapa",
    accept: "Aceptar",
    invalid_credit_card_number: "Número de la tarjeta de crédito no válido",
    invalid_credit_card_cvv: "CVV no válido",
    invalid_cntry: "País no válido",
    Invalid_zipcd: "Código Zip no válido",
    street_missing: "Campo de Calle vacío",
    city_missing: "Campo de Ciudad vacío",
    region_missing: "Campo de Región vacío",
    cntry_missing: "Campo de País vacío",
    zipcd_missing: "Campo de Código Zip vacío",
    billing_address: "Dirección de facturación",
    confirm_delete_address: "Confirmar eliminar dirección",
    delete_address_message: "¿Está seguro de que desea eliminar esta dirección? ",
    address_edit: "Editar dirección",
    default: "Predeterminada",
    set_as_default: "Establecer como predeterminada",
    nick_name: "Nombre de usuario",
    update_profile_success: "Su perfil se ha actualizado con éxito ",
    update_profile_failed:
        "Lo sentimos, la actualización de perfil ha fallado. Por favor inténtelo de nuevo más tarde. ",
    last_name_missing: "Campo de Apellido vacío",
    nick_name_missing: "Campo de Nombre de usuario vacío",
    update: "Actualización",
    user_info: "Información del usuario",
    new_phone: "Nuevo teléfono #",
    new_email: "Nuevo Email",
    phone_placeholder: "Número de teléfono",
    alert_phone_updated: "Su número de teléfono se ha actualizado con éxito",
    alert_missing_fields: "Lo sentimos, hay campos vacíos.",
    alert_forbidden_action: "Lo sentimos, esta acción no está permitida.",
    alert_email_updated: "Su email se ha actualizado con éxito ",
    alert_forbidden_email: "Lo sentimos, esta acción no está permitida.",
    alert_email_exists_sign_up:
        "El correo electrónico que ingresó ya está en uso por otra cuenta. Inicie sesión en esa cuenta o use otro correo electrónico.",
    alert_email_exists_update:
        "El correo electrónico que ingresó ya está en uso por otra cuenta. Utilice otro correo electrónico.",
    wallet: "Cartera",
    confirm_delete_credit_card: "Confirme Eliminar tarjeta de crédito",
    confirm_delete_credit_card_message: "¿Está seguro de que desea eliminar esta tarjeta de crédito?",
    add_credit_card_title: "Añadir tarjeta de crédito",
    add_credit_card: "Añadir una tarjeta de crédito",
    save_credit_card: "Guardar tarjeta de crédito",
    card_number: "Número de tarjeta",
    name_on_card: "Nombre en la tarjeta",
    cvv: "CVV",
    set_payment_password: "Establecer contraseña de pago",
    old_password: "Contraseña anterior",
    confirm_new_password: "Confirmar nueva contraseña",
    password_not_detected:
        "La contraseña anterior no coincide, por favor revísela e inténtelo de nuevo o restablezca su contraseña. ",
    server_error: "Lo sentimos, se ha producido un problema. Por favor inténtelo de nuevo más tarde. ",
    unmatched_passwords:
        "Nueva contraseña y Confirmar nueva contraseña no es lo mismo, por favor revísela y vuelva a intentarlo. ",
    social_bind_not_allowed:
        "Inicie sesión o vincule o no vincule a través de una cuenta de red social a menos que el wechat, no se permite en WeChat, utiliza por favor un buscador externo",
    social_bind_success:
        "Su cuenta se ha vinculado con éxito a su cuenta de {socialType}. Ya puede iniciar sesión con su cuenta de {socialType} ",
    social_bind_failed:
        "Se ha producido un error al vincular su cuenta de {socialType}. Por favor inténtelo de nuevo. ",
    connected: "Conectado",
    confirm_unbind_social_account: "¿Está seguro de que desea desvincular su cuenta de {socialType}? ",
    unbind_social_success: "Su cuenta de {socialType} ha sido desvinculada. ",
    unbind_social_error:
        "Lo sentimos, no pudimos desvincular su cuenta de {socialType} en este momento. Por favor inténtelo de nuevo más tarde.",
    create_new_vault_password: "Agregue una contraseña para mantener su tarjeta de crédito segura",
    confirm_vault_password: "Confirme su contraseña",
    input_vault_password: "Introduzca su contraseña de pago",
    vault_password: "Contraseña de pago",
    password_instructions: "Su contraseña necesita tener entre 4 y 6 números",
    password_incorrect: "Contraseña incorrecta",
    add_address_invalid_address: "Dirección no válida. Por favor busque la dirección en el mapa. ",
    country_not_exists: "País no válido. Por favor busque la dirección en el mapa.",
    map_select_address: "Por favor utilice la barra de búsqueda y seleccione una dirección.",
    error_current_location:
        "Se ha producido un error al obtener su localización actual, por favor introduzca su dirección en la barra de búsqueda. ",
    map_address_accept_error:
        "Error en Google maps. Por favor utilice otra dirección o introduzca la dirección manualmente. ",
    invalid_name: "Por favor complete el nombre del titular de la tarjeta. ",
    invalid_cc_info: "La información de la tarjeta de crédito no es válida, por favor revísela e inténtelo de nuevo. ",
    invalid_expiry_date:
        "La fecha de vencimiento de la tarjeta de crédito no es válida, por favor revísela e inténtelo de nuevo. ",
    alert_guest_account:
        "Actualmente ha iniciado sesión como usuario invitado; ingrese su nombre, correo electrónico / número de teléfono para convertirse en un cliente habitual y recibir un servicio personalizado o descuentos en la tienda en el futuro. ¿Desea continuar?",
    alert_missing_account_info: "Please fill in the missing information: {missingInfo}",
    address_amount_exceeded: "Cantidad de direcciones excedida. Sólo puede guardar 10 direcciones máximo. ",
    i_will_do_it_later: "Lo haré luego",
    finish_later: "Terminar más tarde",
    skip: "Omitir",
    change_by_email: "Por email",
    change_by_text_message: "Por mensaje de texto",
    change_by_existing_password: "Por contraseña existente",
    change_password: "Cambiar contraseña",
    update_password: "Actualizar contraseña",
    code: "Código",
    request_pass_reset: "Solicitar Restablecer contraseña",
    confirm_password: "Confirmar nueva contraseña",
    set_password: "Establecer una contraseña",
    set_a_password_caption: "Establezca una contraseña para acelerar su inicio de sesión futuro",
    update_password_success: "Contraseña actualizada con éxito",
    update_password_fail: "Actualización de la contraseña fallida, inténtelo de nuevo más tarde",
    birthday: "Cumpleaños",
    gender: "Género",
    not_provided: "No proporcionado",
    currency: "Divisa",
    male: "Masculino",
    female: "Femenino",
    none: "Ninguno",
    year: "Año",
    month: "Mes",
    day: "Día",
    cannot_update_profile: "No puede actualizar perfil. ",
    updated_profile: "Perfil actualizado.",
    giftcards: "Tarjetas regalo",
    gift_card_details: "Datos de la tarjeta regalo",
    all: "Todos",
    active: "Activo",
    inactive: "Inactivo",
    active_giftcard: "Activo",
    inactive_giftcard: "Inactivo",
    used: "Utilizado",
    balance: "Balance",
    book_value: "Valor contable",
    purchase_date: "Fecha de la compra",
    status: "Estado",
    reassign: "Reasignar",
    reassign_giftcard: "Reasignar tarjeta regalo",
    receivers_email_phone: "Email/teléfono del destinatario",
    giftcard_reassign_success: "Su tarjeta regalo se ha transferido con éxito a {receiver}. ",
    giftcard_reassign_missing_receiver: "Por favor complete la información del destinatario",
    giftcard_reassign_missing_info: "Para enviar la tarjeta regalo debe haber un destinatario válido. ",
    giftcard_reassign_failed:
        "Lo sentimos, se ha producido un error al reasignar su tarjeta regalo. Por favor inténtelo de nuevo más tarde.",
    giftcard_reassign_failed_phone:
        "Este número de teléfono no pertenece a ningún usuario registrado, por favor inténtelo con otro número de teléfono. ",
    giftcard_reassign_failed_email:
        "Este email no pertenece a ningún usuario registrado, por favor inténtelo con otro email. ",
    no_giftcards: "No tiene ninguna tarjeta regalo",
    no_active_giftcards: "No tiene ninguna tarjeta regalo activa",
    no_active_giftcards_head: "No hay tarjetas regalo activas",
    no_used_giftcards_head: "No hay tarjetas regalo utilizadas",
    no_used_giftcards: "No ha utilizado ninguna tarjeta regalo",
    purchase_a_giftcard: "Comprar una tarjeta regalo",
    invalid_phone: "El número de teléfono no es válido, por favor asegúrese de que el número de teléfono es correcto. ",
    giftcard_reassign_tip:
        "La reasignación de una tarjeta regalo le permite adquirir una tarjeta regalo para otro usuario a través de un email o número de teléfono del destinatario",
    gift_card_purchased: "Tarjeta regalo comprada",
    paid_with_gift_card: "Pagado con tarjeta regalo",
    refunded: "Reembolsado",
    invalid_phone_number: "Número de teléfono no válido",
    comment: "Comentar",
    transactions: "Transacciones",
    details: "Datos",
    date: "Fecha",
    balance_change: "Cambio de/en el saldo",
    points_balance: "Balance de puntos",
    no_points: "Actualmente no tiene ningún punto de recompensa. Compre ahora para ganar puntos.",
    no_points_head: "No tiene ningún punto",
    view_stores: "Ver establecimientos",
    last_updated: "Actualizado por última vez: {time} ",
    point_balance: "Saldo: {balance}",
    point_store_name: "Nombre del establecimiento: {time} ",
    points_received: "Puntos recibidos",
    no_orders: "No se han realizados pedidos con anterioridad",
    no_past_orders: "No hay pedidos anteriores",
    no_upcoming_orders: "No hay pedidos en este momento",
    shop_now: "Comprar ahora",
    pickup_method: "Hora de recogida",
    eating_method: "Plazo previsto",
    delivery_method: "Hora de entrega",
    instantCheckout_method: "Instant Checkout",
    items: "Artículo(s)",
    ongoing: "En curso",
    history: "Historial",
    upcoming: "Próximo",
    past_orders: "Pedidos anteriores",
    buzz_code: "Zumbido",
    make_default: "Establecer como predeterminado",
    add_address: "Nueva dirección",
    add_an_address: "Agregue una nueva dirección",
    are_you_sure_delete_address: "¿Está seguro de que desea eliminar esta dirección? ",
    select_address: "Seleccionar dirección",
    search_address: "Buscar dirección",
    current_location: "Ubicación actual",
    use_name_on_account: "Utilice el nombre de la cuenta",
    confirm_address: "Confirmar dirección",
    location_user_denied: "El servicio de ubicación no está activado, por favor active los servicios de localización",
    geolocation_not_supported:
        "Los servicios de localización actuales no son compatibles, por favor busque la dirección en la barra de direcciones",
    location_general_error: "No se puede obtener su ubicación actual, por favor inténtelo de nuevo más tarde",
    self_checkout: "Quick Pay",
    amount: "Cantidad",
    add_comment: "Añadir un comentario",
    contact_merchant: "Contactar comerciante",
    powered_by_goopter: "Desarrollado por Goopter",
    pay: "Pagar",
    self_checkout_disabled: "Quick pay is not available for this store.",
    quick_pay_hint_1: "Quick Pay lets you pay for your order anytime!",
    quick_pay_hint_2: "Just enter the amount due and include any information about this payment in the notes.",
    got_it: "Got It!",
    surcharge_for: "Recargo por pedido: #{oid}",
    phone_number_verification: "Phone Number Verification",
    please_verify_number: "Please verify your number",
    verification_code_sent_message: "A text message with a 4-digit verification code was just sent to {phoneNumber}",
    enter_code: "Enter Code",
    try_different_number: "Try a different number",
    number_verified_message: "Your number has been verified!",
    continue_checkout_message: "Please continue with your checkout",
    get_vc_descprtion_text: "Enviaremos un código de verificación a su teléfono.",
    payment_success_title: "Pago realizado con éxito",
    payment_success_text: "Su pago ha sido recibido. Será redirigido a la página de pedidos. ",
    go_to_order: "Ir a pedidos",
    qr_scanner: "Escanear para realizar un pedido",
    camera_instructor_title: "Acceso a cámara requerido",
    camera_instructor_description_dine_in:
        "A continuación la app de Goopter abrirá su cámara. Por favor enfoque su cámara hacia el código QR para proceder con su pedido. ",
    camera_instructor_button_text_dine_in: "Escanear para realizar un pedido",
    camera_error_title: "Acceso a la cámara denegado",
    camera_error_description_dine_in:
        "Por favor habilite el acceso para que la cámara escanee el código QR para realizar su pedido. ",
    camera_error_button_text: "Activar cámara",
    error_invalid_code_dine_in:
        "Código QR no válido para comer en este establecimiento. Por favor escanee el código QR correcto. ",
    error_invalid_code_in_store:
        "Código QR no válido para comprar en este establecimiento. Por favor escanee el código QR correcto. ",
    parking_lot: "Aparcamiento #",
    kiosk: "Kiosco #",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "Haga clic en el icono de la cámara para cambiar",
    scan_again: "Inténtelo de nuevo",
    scanner_title: "Ajuste el código QR o código de barras dentro del marco para escanear",
    orders_today: "Pedidos Hoy",
    orders_total: "Total de Pedidos",
    points_today: "Puntos Hoy",
    points_total: "Total de Puntos",
    check_how_many_reward_points_from_referrals: "Verifique cuántos puntos de recompensa recibió de las referencias",
    no_referrals_message:
        "Aún no tienes referencias. ¡Comience a recomendar para ganar puntos de recompensa para gastar en sus tiendas favoritas!",
    order_id: "Pedido #",
    no_referral_orders_found: "No se encontraron órdenes de referencia",
    from: "From",
    pts: "pts",
    update_order: "Update Order",
    your_order: "Your Order",
    schedule_for_later: "Schedule for later",
    ready_in_asap: "Ready in {start}-{end} minutes",
    when_would_you_like_to_order: "When would you like to order",
    no_shipping_address_selected: "No shipping address selected.",
    change: "Change",
    start_order: "Start Order",
    order_for: "Order for",
    pick_one_with_name: "{nm} is required, pick 1",
    pick_amount_with_name: "{nm} is required, pick exactly {min}",
    pick_range_up_to_with_name: "{nm} choose up to {max}",
    pick_range_required_with_name: "{nm} is required, pick {min}-{max}",
    current_menu: "Current Menu",
    all_menus: "All Menus",
    are_you_sure_keeping_cart: "There are saved items in the cart. Do you want to keep the items in the cart?",
    clear_the_cart: "Clear The Cart",
    keep_the_cart: "Keep",
    offer_ends_on: "End Time: {time}",
    add_to_cart_success: "Product has been successfully added to the cart",
    gift_cards: "Gift Cards",
    conditions_of_use: "Terms of Use",
    phone_num: "Phone #",
    change_address: "Change Address",
    set_a_password: "Set a password",
    set_a_new_password: "Set a new password",
    set_a_new_password_caption: "Forgot your password? Set a new one to login quicker next time.",
    eatin_method: "Due Time",
    add_a_address: "Add a New Address",
    msg_country_update: "Country successfully updated.",
    msg_currency_update: "Currency successfully updated.",
    msg_birthday_update: "Birthday successfully updated.",
    msg_email_update: "Email successfully updated.",
    msg_gender_update: "Gender successfully updated.",
    msg_name_update: "Name successfuly updated",
    msg_nickname_update: "Nickname successfully updated",
    no_thanks: "No Thanks",
    verify: "Verify",
    number_already_exist:
        "This number has been registered with another account. Please logout and login with the number if you want to use this number to place an order. Alternatively you may use another phone number for your current account.",
    sign_in_with_number: "Sign in with existing number",
    try_another_number: "Try another number",
    add_instructions: "Add Instructions",
    code_not_revieved: "Didn't get code?",
    click_to_resend: "Click to resend.",
    review_recommend_store: "How likely are you to recommend {storeName} to a friend or family?",
    example_review_score: "Tell us a bit more about why you chose {score}",
    change_score: "Change score?",
    not_likely: "Not Likely",
    very_likely: "Very Likely",
    review_thank_you_recieve_coupon: "Thank you! You will recieve a coupon via text in about 1 min.",
    review_share_your_heart: "Would you be kind to share your heart to us?",
    review_copy_comment: "1. Simply copy the comment you wrote.",
    and_share_your_review: "2. And share your review.",
    thank_you: "Thank you!",
    review_thank_you_note: "If you don't receive the coupon text, reply “my-coupon” to view your coupons, Thank you!",
    share_to_google: "Share to Google",
    powered_by: "Powered by",
    quick_pay_self_serve_order_hins: "I have a self-serve order from Kiosk or QR code scan.",
    quick_pay_staff_order_hins: "A staff placed an order for me.",
    quick_pay_order_type_self_serve: "Self-serve order",
    quick_pay_order_type_staff: "Staff-assisted order",
    quick_pay_option_title: "What are you paying for?",
    quick_pay_order_type_no_order: "I do not have an order number",
    add_payment_note: "Add Payment Note",
    select: "Please Select",
    leave_quick_pay_confirm_msg: "Are you sure you want to leave quick pay?",
    self_serve_order_number: "Self-Serve Order Number",
    staff_assisted_order_number: "Staff Assisted Order Number",
    self_serve_order_number_input_placeholder: "Input self-serve order number",
    please_confirm: "Please Confirm",
    staff_assisted_order_number_input_placeholder: "Input staff assisted order number",
    invalid_order_id: "Invalid order #, please try again.",
    quick_pay_order_type_no_order_placeholder: "No order number is associated with this payment",
    quick_pay_order_id: "Payment for {orderType} #: {orderId}",
    quick_pay_confirm_msg: "Are your sure you want to pay for",
    quick_pay_confirm_msg_2: "The following order?",
    amount_due: "Amount Due",
    quick_pay_confirm_hin: "Please choose the ",
    quick_pay_confirm_hin_2: " order options if you have same order number but a different amount due.",
    staff_assisted: "staff assisted",
    quick_pay_order_type_additional_payment: "Partial or additional payment of a self-service order ",
    refunded_details: "Refund details",
    refund_reason: "Refund reason",
    deactivate_account: "Account deactivation & deletion",
    msg_deactivation: "Your account will be permanently deleted. After you delete your Goopter account, you will not be able to log in with your current account and retrieve any past orders or address records.",
    account_deactivated: "Account has been deactivated & deleted"
};

export default exports;
