import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { store } from "../../containers/app/store";
import { TIME_FORMAT, REF_CODE_DURATION } from "./contants";

const refCode = createSlice({
    name: "refCode",
    initialState: {
        ref_code: "",
        expiry_date: "",
    },
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setRefCode: (state, { payload }) => {
            if (state?.ref_code !== payload) {
                state.ref_code = payload;
                state.expiry_date = moment().add(REF_CODE_DURATION, "days").format(TIME_FORMAT);
            }
        },
        handleCurrentRefIsExpired: (state) => {
            if (state?.ref_code) {
                const now = moment();
                const expiry = moment(state?.expiry_date, TIME_FORMAT);

                if (now.isAfter(expiry)) {
                    state.ref_code = "";
                    state.expiry_date = "";
                }
            }
        },
    },
});

export const {
    setState,

    setRefCode,
    handleCurrentRefIsExpired,
} = refCode.actions;

export const getRefCode = () => {
    const state = store.getState();

    return state?.refCode?.ref_code;
};

export default refCode.reducer;
