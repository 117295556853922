const exports = {
    " ": " ",
    go_back: "Revenir en arrière",
    notifications: "Notifications",
    settings: "Paramètres",
    logout: "Déconnection",
    logged_out_error: "Vous avez été déconnecté, connectez-vous de nouveau et réessayer.",
    logged_out_success: "Vous avez été deconnecté avec succès. A bientot!",
    confirm_logout_text: "Etes-vous sure de vouloir vous déconnecter?",
    ok: "D'accord",
    error: "Erreur",
    no: "Non",
    yes: "Oui",
    language: "Langue",
    account: "Compte",
    login: "Connection",
    cancel: "Annuler",
    store: "Magasin",
    products: "Produits",
    restaurant: "Restaurant",
    groupsale: "Offre de Groupe",
    travel: "Voyage",
    shopping: "Shopping",
    service: "Service",
    save: "Sauvegarder",
    confirm: "Confirmer",
    success: "Réussir",
    loading: "Télécharger",
    warning: "Avertissement",
    general_error: "Une erreur inconnue s'est produite, merci de réessayer plus tard",
    orders: "Mes commandes",
    click_to_input_address: "Veuillez cliquer pour saisir l'adresse",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    input: "Saisir",
    edit: "Visualiser",
    years: "Années",
    quarters: "Trimestres",
    months: "Mois",
    weeks: "Semaines",
    days: "Jours",
    days_2: "Jours",
    time: "Heure",
    times: "Heures",
    hours: "Hrs",
    minutes: "Mins",
    seconds: "Secs",
    milliseconds: "Ms",
    sort: "Trier",
    filter: "Filtrer",
    today: "Aujourd'hui",
    tomorrow: "Demain",
    yesterday: "Hier",
    this_week: "Cette semaine",
    last_week: "La semaine dernière",
    this_month: "Ce mois",
    last_month: "Le mois dernier",
    last: "Dernier/e",
    next: "Suivant/e",
    now: "Maintenant",
    mon: "Lun",
    tue: "Mar",
    wed: "Mer",
    thu: "Jeu",
    fri: "Ven",
    sat: "Sam",
    sun: "Dim",
    mo: "Lu",
    tu: "Ma",
    we: "Me",
    th: "Je",
    fr: "Ve",
    sa: "Sa",
    su: "Di",
    share: "Partager",
    dont_show_this_again: "Ne plus montrer ça",
    jan: "Janvier",
    feb: "Février",
    mar: "Mars",
    apr: "Avril",
    may: "Mai",
    jun: "Juin",
    jul: "Juillet",
    aug: "Aout",
    sep: "Septembre",
    oct: "Octobre",
    nov: "Novembre",
    dec: "Decembre",
    view_more: "Voir plus",
    viewing: "Visualiser",
    show_filters: "Montrer les filtres",
    reward_history_filter: "Filtre de l'historique des récompenses",
    date_range: "Plage de dates",
    my_referrals: "Mes Parrainages",
    how_to_do_it: "Comment recevoir des points de récompense et règles relatives aux points de récompense",
    how_to_do_it_referrals:
        "Partagez le lien de parrainage ou le code QR avec vos amis et sur les réseaux sociaux. Ils deviendront votre parrainage s'ils n'ont pas été parrainés auparavant par quelqu'un d'autre et s'ils effectuent un achat en utilisant votre lien. Vous recevrez {v2pCustomerRef} points de récompense pour chaque dollar sur leur achat et les achats futurs. Si vous partagez le lien avec quelqu'un qui a déjà été parrainé, vous recevrez une récompense unique de {v2pOrderRef} points pour chaque dollar dépensé sur son achat actuel.{br}{br}Chaque point de récompense {p2v} équivaut à 1 $. pour ce magasin.",
    copy_ref_url: "Copier l'URL de parrainage",
    total: "Total",
    generate_qr_code: "Générer un Code QR",
    ref_orders: "Commandes",
    income: "Gain",
    check_how_much_money_earned: "Vérifier votre gain",
    check_how_much_rewards_earned: "Verifier le nombre de points de fidélité gagnés",
    redeemable: "Echangeable",
    total_redeemed: "Total échangeable",
    sales: "Achats",
    check_how_many_sales: "Vérifier votre nombre d'achats",
    rewards_history: "Historique des récompenses",
    check_daily_history: "Vérifier les récompenses de vos transactions journalières",
    orders_info: "Vérifier le nombre de commandes de vos amis",
    referrals_info: "Information concernant vos parrainages",
    referrals_info_desc:
        "Ceci est l'information concernant vos parrainages. Veuillez sélectionner pour visualiser les commandes et les informations de vente",
    admin_reward_action: "Admin Points Fidélité",
    points_redeemed: "Points échangés",
    customer_invitation: "Points parrainage client",
    order_invitation: "Points parrainage commande",
    admin_credit: "Admin Crédit",
    sales_rule: "Règles Points Achats",
    admin_revert_points: "Admin Retour Points",
    refunded_order: "Remboursement Points Commande",
    ref_successfully_copied: "Code Parrainage Copiés avec Succès",
    click_tips_with_helper_text: "Tips(click amount to update)",
    click_set_points: "Click to redeem points",
    click_to_search_address: "Click to search address",
    click_please_input_shipping_addresss: "Click to input shipping address",
    click_please_input_pickup_addresss: "Click to choose pick-up location",
    click_please_input_payment_method: "Click to Select Payment Method",
    click_to_show_on_map: "Click here to show on map",
    tap_to_search_address: "Cliquer pour rechercher des adresses",
    sorry_search: "Abscence de résulats pour le mot clé spécifié.",
    try_again_search: "Veuillez essayer un autre mot clé",
    recent_searches: "Recherches récentes",
    recommendations: "Recommandations",
    deals: "Offres",
    view_all: "Tout Afficher",
    products_not_available:
        "Le(s) article(s) suivants sont actuellement indisponibles et seront supprimés de votre panier:",
    shopping_cart_empty: "Votre panier d'achats est vide.",
    not_available_dialog_title: "Actuellement indisponible",
    not_available_dialog_captain: "Cet article est uniquement disponible dans les prochaines heures.",
    sorry_product_not_available:
        "Veuillez nous excuser, cet article est uniquement disponible dans les prochaines heures",
    feedback: "Avis",
    download_qr: "Téléchargez le code QR",
    download_qr_mobile: "Appuyer longuement sur l'image pour télécharger",
    download_poster: "Télécharger l'affiche",
    download_poster_mobile: "Appuyer longuement sur l'image pour télécharger",
    rate_the_product: "Noter l'article",
    are_you_sure_cancel_review: "Etes-vous sur de vouloir supprimer cet avis?",
    submit: "Soumettre",
    review_order_placeholder: "Votre avis aidera les usagers a faire de meilleurs choix",
    review_submitted_message: "Merci de votre avis, il sera publié une fois validé",
    please_input_or_select: "Veuillez mentionner ou selectionner la raison.",
    review_service: "Avis sur le Service",
    rate_hint: "Notez en choisissant le nombre d'étoiles",
    not_available: "Indisponible",
    current_not_available: "Actuellement Indisponible",
    allow_reservation: "Authoriser la Réservation",
    has_tv: "TV disponible",
    has_free_wifi: "Wi-Fi Gratuit Disponible",
    has_outdoor_seating: "Terrace Disponible",
    kid_friendly: "Enfants bienvenus",
    group_friendly: "Groupes bienvenus",
    business_friendly: "Entreprises Bienvenues",
    street_parking: "Stationnement de rue",
    free_parking: "Stationnement Gratuit",
    paid_parking: "Stationnement Payant",
    noise_level: "Niveau Sonore",
    average: "Moyen",
    loud: "Bruyant",
    very_loud: "Très Bruyant",
    casual: "Décontracté",
    business_casual: "Tenue d'Affaires Décontractée",
    smart_casual: "Décontracté Chic",
    business: "Tenue d'Affaires",
    semi_formal: "Tenue Semi Formelle",
    has_alcohol: "Service d'Alcool",
    environment: "Environnement",
    attire: "Tenue",
    upscale: "Haut de Gamme",
    classy: "Chic",
    hipster: "Branché",
    intimate: "Intime",
    no_alcohol: "Sans Alcool",
    beer_and_wine: "Bière & Vin",
    full_bar: "Bar a Licence complète",
    write_your_review_here: "Ecrivez votre avis ici",
    rate_the_store: "Evaluer le commerce",
    more_information: "Plus d'information",
    merchant_information: "Information sur le Commercant",
    additional_pages: "Pages Additionnelles",
    store_info: "Information sur le magasin",
    store_overview: "Vue d'ensemble",
    opens: "Ouvre",
    closes: "Ferme",
    current_store: "Magasin actuel",
    order: "Commande",
    my_order: "Ma Commande",
    deliver_to: "Deliver to",
    delivery: "Livraison",
    check_out: "Valider Commande",
    options: "Options",
    add_to_cart: "Ajouter au panier",
    pick_one: "Obligatoire, sélectioner 1",
    pick_amount: "Obligatoire, sélectioner exactement {min}",
    pick_range_up_to: "Choisissez jusqu'a {max}",
    pick_range_required: "Obligatoire, choisissez {min}-{max}",
    pick_optional: "Optionel",
    pick_one_with_name: "{nm} est obligatoire, sélectioner 1",
    pick_amount_with_name: "{nm} est obligatoire, sélectionner exactement {min}",
    pick_range_up_to_with_name: "{nm} choisir jusqu'a  {max}",
    pick_range_required_with_name: "{nm} est obligatoire, sélectionner {min}-{max}",
    up: "en haut",
    search_products: "Rechercher Produits",
    current_menu: "Menu du jour",
    all_menus: "Tous Menus",
    search: "Recherche",
    discount_by: "{x} off on {y}",
    discount_by_without_price: "{x} réduction",
    store_closed: "Fermé",
    store_closed_not_allow_order: "Magasin fermé, veuillez commander quand le magasin ouvre.",
    order_items: "Commander les Articles",
    are_you_sure_clear_cart: "Etes-vous sure de vouloir vider votre panier?",
    qr_code: "Code QR",
    poster: "Affiche",
    clear: "Effacer",
    results: "Résultats",
    search_results: "{x} résultats recherche",
    pending_acceptance: "Validation en attente",
    delivery_time: "Délais de Livraison",
    pickup: "Retirer",
    takeout: "A emporter",
    eatin: "Sur place",
    order_pickup_time: "Heure retrait: {time}",
    order_delivery_time: "Heure de livraison: {time}",
    transferred_to: "Transféré a: {contact}",
    received_from: "Recu de la part de: {contact}",
    discount_amount: "{currentDiscount} de réduction.",
    remaining: " Ajouter {remaining} de plus pour avoir {amount} de réduction.",
    remaining_delivery: " Ajouter {remaining} pour la livraison.",
    remaining_pickup: " Ajouter {remaining} pour le retrait.",
    menu_available_at: "Menu uniquement valable @{available}…",
    please_input_name: "Saisissez votre adresse",
    no_address_head: "Adresses non sauvegardées",
    no_address_message: "Vous n'avez actuellement aucune adresse sauvegardée.",
    select_branch: "Choisissez une branche, s'il vous plait",
    store_on_hold: "Magasin non disponible, n'acceptant pas de commandes actuellement.",
    out_of_stock: "Stock épuisé",
    load_more: "Télécharger plus",
    no_order_message: "Les commandes apparaitront ici une après avoir crée/validé une commande.",
    no_order: "Abscence de Commandes",
    create_an_order: "Passer une Commande",
    no_more_data: "Pas d'information supplémentaire",
    no_search_results: "Pas de résultats de recherche",
    group_purchase: "Achat groupé",
    show_more: "Montrer Plus",
    show_less: "Montrer Moins",
    purchase_history: "Historique des achats",
    start_time: "Heure d'initialisation",
    end_time: "Heure finale ",
    expired: "Expirée",
    offer_expires_in: "L'Offre Expire dans: {time}",
    offer_starts_in: "L'Offre Débute dans: {time}",
    offer_starts_on: "Start Time: {time}",
    remaining_stock: "{count} restante",
    people_ordered: "{amt} personnes ont commandé",
    select_category: "Selectioner la catégorie",
    selection_error_min: "{min} sélectionné est requis {option}.",
    no_giftcard_message: "Bons cadeaux apparaitront ici.",
    upsell_products: "Vous aimerez peut-etre...",
    switch_to_dine_in: "Confirmer diner sur place",
    switch_to_in_store: "Confirmer en magasin",
    switch_to_dine_in_message:
        "Veuillez entrer votre numéro de table pour continuer la commande de diner sur place, sélectionner annuler pour maintenir la catégorie commande {type}.",
    surcharge: "Supplément",
    store_surcharge: "Supplément en ligne",
    order_surcharge: "Supplément commande",
    item_total: "Article(s) Total",
    surcharge_paid: "Supplément (Réglé)",
    surcharge_unpaid: "Supplément (Du)",
    related_id: "Référence Commande #",
    related: "Référence",
    surcharge_paid_message:
        "Le montant du supplément n'est pas inclus dans la commande. Veuillez faire référence a la commande #{id}",
    surcharge_paid_to_message: "Cette commande a usage de paiement du supplément de la commande#{id}",
    switch_to_dine_in_confirm:
        "Il semble que vous etes sorti du magasin, etes-vous sur de vouloir passer une commande pour diner sur place?",
    switch_to_in_store_confirm:
        "Il semble que vous n'etes pas dans le magasin a présent, etes-vous sur de vouloir passer une commande en magasin?",
    pull_to_refresh: "Sortir pour rafraichir",
    no_products_record: "Produit non identifié.",
    delivery_locations: "Zones de livraison / Calendrier",
    pickup_locations: "Zones de retrait",
    min_amount: "Min. Commande",
    min_order_amount: "Min. Montant Commande",
    free_delivery_amount: "Montant Livraison Gratuite",
    delivery_times: "Horaires de Livraison",
    pickup_times: "Horaires de Retrait",
    time_range: "Plage horaire",
    store_location: "Adresse du magasin",
    pickup_locations_schedule: "Adresse de retrait / Calendrier",
    roll_over_image_to_zoom_in: "Survolez l'image pour l'agrandir",
    about: "Sur",
    product_information: "Description Courte du Produit",
    product_details: "Détails du Produit",
    buy_now: "Acheter Maintenant",
    see_more_product_details: "Voir plus de détails sur le produit",
    video: "Vidéo",
    page_of: "Page {page} sur {totalNumOfPages}",
    warning_max_purchase_limit_reached: "Warning: Maximum purchase limit reached",
    max_purchase_limit_reached_msg: "You may only purchase {max_sale_qty} of this product.",
    warning_max_purchase_qty_exceeded_msg:
        "The following items have exceeded the maximum purchase quantity. Item quantities will be modified to fit within the limit.",
    max_purchase_qty: "Maximum Purchase Quantity: {qty}",
    warning_min_sale_qty_not_met_msg: "The following items do not fulfill the minimum purchase quantity.",
    min_purchase_qty: "Minimum Purchase Quantity: {qty}",
    remove_items: "Remove items",
    increase_item_quantities: "Increase item quantities",
    store_offline_message: "Sorry, we are currently not accepting online orders. Please check back later.",
    order_payment_status_error: "Le magasin a accepté la commande. Veuillez payer sur place.",
    order_comment_status_error:
        "Commande en cours de préparation; veuillez contacter le magasin pour toute modification. Tel: {phone}.",
    oos_items_cannot_be_added:
        "Le(s) article(s) sont indisponibles {oos_items} et ne peuvent pas etre ajoutés au panier.",
    order_cancelled: "Commande annulée",
    pending_store_confirmation: "En attente validation magasin",
    pending_customer_confirmation: "En attente validation client",
    store_accepted: "Commande acceptée",
    order_preparing: "Commande en cours de préparation",
    store_is_preparing: "Préparation en magasin",
    ready_pickup: "Commande prete pour retrait",
    ready_courier_pickup: "Commande prete a la livraison",
    rejected_by_courier: "Rejetée par le coursier",
    courier_assigned: "Coursier assigné",
    courier_accepted: "Coursier a accepté la commande",
    courier_heading_to_store: "Coursier en route vers le magasin",
    courier_arrived_at_store: "Le Coursier est arrivé en magasin",
    courier_collected_package: "Le Coursier a retiré le colis",
    courier_heading_to_customer: "Commande en cours de livraison",
    courier_arrived_at_customer: "Coursier arrivé chez le Client",
    delivered_order: "Commande Livrée",
    order_changes_description:
        "Les changements de votre commande ont été effectué. Veuillez faire référence aux changements suggérés et confirmer rapidement afin d'éviter d'éventuels retard dans votre commande.",
    confirm_item_change: "Confirmer les changements de votre commande",
    are_you_sure_reject:
        "En refusant le changement proposé, l'article sera supprimé de la commande, etes-vous sur de vouloir continuer?",
    accepted: "Accepté(e)",
    rejected: "Rejeté(e)",
    added: "Ajouté(e)",
    removed: "Supprimé(e)",
    updated: "Mis(e) a jour",
    add_back: "Ajouter de nouveau",
    to: "pour",
    updated_an_item: "Mise a jour article",
    added_an_item: "Ajouter un article",
    removed_an_item: "Supprimer un article",
    replaced_an_item: "Remplacer un article",
    are_you_sure_change_order_confirm: "Etes-vous sur de vouloir confirmer les modifications de cette commande?",
    order_change_return_price_delta:
        "Le montant total de la commande mis a jour est de {new_price}, nous procéderons au remboursement de la différence après validation de la commande.",
    order_change_pay_price_delta:
        "Le montant total de la commande mis a jour est de {new_price}, il s'agira du montant final débité de votre carte après validation de la commande.",
    order_change_pay_new_price:
        "Le montant total de la commande mis a jour est de {new_price}, vous devrez payer la différence de prix de {price_delta}. Veuillez effectuer le paiement de ce supplément afin d'éviter le retard votre commande.",
    order_change_reject_all:
        "Vous avez refusé tous les changements suggérés. Etes-vous sur de vouloir annuler la commande?",
    order_change_reject_all_refund:
        "Vous avez refuser tous les changements suggérés. Etes-vous sur de vouloir annuler la commande? Après l'annulation vous serez remboursé de {price_delta}.",
    order_change_refund:
        "Le montant total de la commande mis a jour est de {new_price}, vous recevrez la différence de prix d'un montant de {price_delta}.",
    order_again_adds_items: "[Commander a nouveau] ajouter les meme articles a votre panier automatiquement.",
    hide_menu: "Hide Item",
    show_menu: "Show Item",
    copy: "Copier",
    copied_success: "Commande copiée # dans le presse-papier",
    shipping_time: "Heure de livraison",
    order_time: "Heure de la commande",
    pickup_time: "Heure de retrait,",
    request_due_time: "Heure requise",
    requested_pickup_time: "Heure de retrait requise",
    requested_delivery_time: "Heure de livriaosn requis",
    ready_pickup_time: "Diponible pour retrait",
    estimated_delivery_time: "Estimation heure de livraison",
    store_name: "Nom du Magasin",
    shipping_info: "Information de Livraison",
    order_info: "Information Commande",
    table_info: "Tableau d'information ",
    serve_time: "Estimation disponibilité commande",
    buzz: "Buzz",
    order_detail: "Détails de la commande",
    alipay: "Alipay",
    union_pay: "Union Pay",
    union_pay_express: "Union Pay Express",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "Carte de Crédit",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "E-Transfer/EMT",
    emt_description: "Pay with E-Transfer/EMT after placing order",
    payment_received: "Payment Received",
    payment_pending: "Payment Pending",
    payment_cancelled: "Payment Cancelled",
    payment_failed: "Payment Failed",
    emt_payment_note_long:
        "You selected E-Transfer/EMT payment for this order. Please arrange payment to the store at your earliest convenience and notify the store to avoid delays with your order.",
    emt_payment_password: "Please send {amount} to {emt_account} with the payment password {emt_password}",
    emt_payment_account: "Please send {amount} to {emt_account}",
    free: "Paiement non requis",
    dine_in: "Sur Place",
    in_store: "En Magasin",
    flatrate: "Montant Fixe",
    freeShipping: "Digital Asset",
    instantCheckout: "Instant Checkout",
    pending: "En attente de confirmation",
    processing: "En cours",
    payment_review: "Paiement en attente",
    completed: "Complet(e)",
    canceled: "Annulée",
    closed: "Cloturée",
    pending_payment: "Paiement en attente",
    holded: "Maintenu",
    cancel_order: "Annuler commande",
    order_number: "Commande #",
    order_status: "Etat de la commande",
    order_date: "Heure de la Commande",
    order_type: "Type de Commande",
    shipping_method: "Méthode de livraisaon",
    expected_time: "Heure prévue",
    customer: "Client",
    table_number: "Table #",
    party_size: "Taille du Groupe",
    delivery_address: "Adresse de Livraison",
    pickup_location: "Adresse de Retrait",
    store_discount: "Réduction magasin",
    coupon: "Coupon",
    shipping_and_handling: "Gestion et Livraison",
    shipping_and_handling_with_distance: "Frais de Livraison ({distance})",
    order_total: "Total",
    giftcard_payment: "Paiement avec Bon cadeau",
    points_payment: "Paiement avec Points",
    points_payment_with_points: "Paiement avec Points ({x})",
    comments: "Commentaires",
    reorder: "Commander de nouveau",
    general_order_error: "Une erreur est survenue. Veuillez vous rendre sur la page de commande et ressayer.",
    order_unexist: "Aucune commande n'a été enregistrée.",
    you: "Vous",
    cancel_order_warning: "Etes-vous sur de vouloir effacer la commande?",
    cancel_order_success: "Votre commande #{order_id} avec {store_name} a été annulée avec succès.",
    cancel_order_failed: "Echec de l'annulation de la commande, veuillez ressayer ultérieurement",
    pay_now_unavailable: "Paiement en ligne Non Disponible",
    no_online_payment:
        "Désolé, le magain n'accepte pas les paiements en ligne, en ce moment. Veuillez ressayer ultérieuremnet ou contacter le magasin directement.",
    pay_now: "Payer maintenant",
    reviews: "Avis",
    write_review: "Ecrire un avis",
    write_review_for: "Ecrivez votre avis pour {name}.",
    update_review: "Avis mis a jour",
    overall: "En général",
    add_a_review: "Ajouter un avis",
    rate_our_service: "Evaluer notre service",
    rate_our_delivery: "Evaluer notre livraison",
    post: "Publier",
    requested_time: "Heure prévue",
    scheduled_time: "Heure plannifiée",
    exp_dt: "Heure prévue",
    ord_total: "Montant",
    s_method: "Type de commande",
    p_method: "Mode de paiement",
    start_processing_message: "Etes-vous sur de vouloir classer la(es) commande(s) comme en cours?",
    mark_as_complete_message: "Etes-vous sur de vouloir classer la(es) commande(s) comme completée(s)?",
    cancel_order_message: "Etes-vous sur de vouloir annuler la(es) commande(s)?",
    complete: "Compléter",
    reject: "Rejeter",
    total_refunded: "Totalité du remboursement",
    refund_amount: "Montant",
    refund_all: "Remboursé la totalité",
    remark: "Remarque",
    "edit-shipping-fee-message":
        "La mise a jour des frais d'expédition entraînera l'annulation de la commande précédente et la création d'une nouvelle commande avec les frais d'expédition mis à jour, souhaitez-vous continuer?",
    edit_shipping_info: "Publier l'adresse de livraison",
    additional_info: "Information supplémentaire",
    exp_time: "Horaire de livraison sollicité",
    estimatedDeliTime: "Estimation de l'horaire de livraison ",
    "create-new-order-message":
        "Etes-vous sur? Cette commande sera annulée et une nouvelle commande sera créée a la place.",
    order_and_account_information: "Commande & Info compte",
    address_info: "Information sur l'adresse",
    shipping_address: "Adresse de Livraison",
    payment_and_shipping_method: "Paiement & Méthode de Livraison",
    payment_info: "Information sur le Paiement",
    shipping_handling_info: "Information sur la Gestion de la Livraison",
    item_order: "Articles commandés",
    raw_total: "Total Brut",
    notes_for_this_order: "Notes au sujet de la commande",
    alert_on_notification: "Alerte sur la Notification",
    sub_comment: "Soumettre Commentaires",
    modify_discount: "Modifier la Remise",
    add_discount: "Ajouter une Remise",
    modify_tips: "Modifier le Pourboire",
    add_tips: "Ajouter le Pourboire",
    modify_shipping_fee: "Modifier les Frais de Livraison",
    discount: "Remise",
    edit_payment: "Actualiser le paiement",
    edit_extra: "Actualiser l'Info",
    edit_payment_method: "Actualiser le paiement",
    "select_receipt(s)_to_print": "Selectionner le(s) recu(s) a imprimer",
    change_payment_to: "Etes-vous sur de vouloir modifier le mode de paiement a",
    handling: "Gestion",
    coupon_discount: "Bon de Réduction",
    phone: "Téléhone #",
    email: "Email",
    email_receipt: "Accusé de réception de l'email",
    email_receipt_sent_to: "Accusé de réception de l'email envoyé a",
    unpaid: "Non Réglé",
    admin: "Admin",
    cust: "Client",
    other_payment: "Autre paiement",
    access_denied: "Désolé, vous n'avez pas l'authorisation de visualiser cette commande",
    please_pay_now: "Commande non réglée, vous pouvez payer maintenant.",
    are_you_sure_cancel_order: "Etes-vous sur de vouloir annuler cette commande?",
    please_pay_by_x:
        "Votre commande sera maintenue pour une durée de {x}. Veuillez payer maintenant pour éviter l'annulation",
    reward: "Récompense",
    reward_points: "Points de récompense",
    shipment_tracking_info: "Informations de Suivi des Envois",
    carrier: "Transporteur",
    tracking_number: "Numéro de Suivi",
    register: "Souscription",
    vc_login: "Se connecter via SMS",
    resend: "Renvoyer",
    pwd_login: "Se connecter au compte",
    email_phone_placeholder: "Email/Téléphone",
    password: "Mot de Passe",
    vc: "Code de Vérification",
    get_vc: "Demande de code",
    titleS: "Goopter",
    alert_login_succeed: "Connection réussie",
    alert_phone_not_found: "Ce numéro de téléphone n'a pas encore éte enregistré!",
    alert_too_many_request:
        "Vous avez atteint la limite pour demander le code de vérification. Veuillez réessayer dans 15 minutes.",
    alert_phone_length: "Numéro de téléphone non valide!",
    alert_phone_vc_not_match: "Code de vérification non valide, veuillez ressayer.",
    alert_login_failed_general: "Echec connection, veuillez ressayer ultérieurement.",
    alert_login_failed: "Nom d'usager non valide/ Mot de passe.",
    forget_pwd: "Mot de passe oublié",
    state_reg:
        "Si vous n'etes pas encore inscrit a notre site web, notre systeme va créer un compte pour vous après vous etre connecté via SMS",
    cos_privacy_statement: "En vous connectant, vous accepter les {cos} de Goopter et l' {privacy}",
    cos_privacy_statement_register: "By signing up, you agree to Goopter's {cos} et{privacy}",
    cos: "Conditions d'utilisation",
    privacy: "Avis de confidentialité",
    or_login_with_social: "ou vous connecter avec un compte de reseau social",
    social_redirect_text:
        "Afin de vous connecter avec votre compte de réseau social, vous serez redirigé vers le site web hote du commercant, souhaitez-vous continuer?",
    social_redirect_text_cart:
        "Afin de vous connecter avec votre compte de réseau social, vous serez redirigé vers le site web hote du commercant et votre panier sera effacé, souhaitez-vous continuer?",
    social_login_failed: "Une erreur est survenue lors de la connexion a votre compte {socialType}. Veuillez ressayer.",
    check_out_as_guest: "Se déconnecter en temps que visiteur",
    email_placeholder: "Email",
    c_tn: "Avec Téléphone #",
    nickname: "Surnom/Afficher nom",
    alert_invalid_request: "Demande Non valide",
    alert_phone_exists: "Désolé, ce numéro de téléphone n'existe pas dans notre systeme.",
    alert_bind_phone_conflict_title: "Numéro de téléphone déja existant",
    alert_bind_phone_phone_exists:
        "Ce numéro a été enregistré avec un autre compte, veuillez vous déconnecter et vous reconnecter avec le numéro si vous souhaitez utiliser ce numéro pour passer une commande; sinon vous pouverz utiliser un autre numéro de téléphone pour votre compte actuel.",
    alert_bind_phone_phone_exists_guests:
        "This number has been registered with another account, please sign in with the number if you want to use this number to place an order; alternatively you may use another phone number for your current account",
    alert_bind_phone_conflict:
        "Le numéro utilisé a été enregistré avec un autre compte, si vous souhaiter utiliser ce numéro pour commander, pressez le bouton Continuer pour associer votre numéro a votre compte de réseau sociaux avec ce numéro, si vous souhaitez utiliser un autre numéro, veuillez cliquer annuler et essayer avec un autre numéro.",
    alert_vc_input_time_expired:
        "Si vous n'avez pas reçu de code de vérification SMS dans un délai d'une minute, cela peut signifier que votre numéro est incorrect ou que votre numéro ne peut pas recevoir correctement les messages SMS.{br}{br}Veuillez vous assurer que vous utilisez le bon numéro ou réessayez avec un autre numéro à la place.",
    alert_reg_success_text: "Vous pouvez utliser votre numéro de téléphone pour vous connecter.",
    alert_reg_success_email: "Vous pouvez utliser votre adresse email pour vous vous connecter.",
    alert_reg_failed: "Erreur dans l'enregistrement.",
    alert_email_length: "Adresse email non valide",
    alert_password_min_length: "Le mot de passe doit comporter 6 caractères au minimum",
    open: "Ouvert",
    store_popup_sold: "Vendu",
    distance: "Distance",
    prep_time: "Temps de Préparation",
    min_delivery_amnt: "Montant minimum de Livraison",
    max_delivery_dist: "Distance maximale de livraison",
    public_notice: "Avis Public",
    discounts: "Réduction(s)",
    discount_message: "{discount} de réduction pour {price}",
    discount_message_without_price: "{discount} de réduction",
    pay_later_cash_description: "Paiement en espèce sur place",
    pay_later_card_description: "Paiement par carte de crédit sur place",
    continue: "Continuer",
    please_leave_your_comment: "Veuillez écrire un commentaire",
    order_now: "Commander maintenant",
    order_success: "La commande a été expédiée",
    view_order: "Visualiser la Commande",
    secure_connection: "L'Information est transmise via une connection sécurisée",
    confirm_order: "Confirmer la Commande",
    back_to_store: "Retour au commerce",
    subtotal: "Sous-total",
    delivery_fee: "Frais de livraison",
    total_before_tax: "Total avant TVA",
    tax: "TVA",
    tips: "Pourboire",
    tips_with_helper_text: "Pourboire (introduire le montant)",
    quantity: "Quantité",
    total_paid: "Paiement Total",
    total_due: "Montant du",
    refund: "Rembousement",
    payment_method: "Méthode de Paiement",
    estimate_shipping_fee: "Estimation des Frais de Livraison",
    shipping_option: "Méthode de Livraison",
    pick_shipping_option: "Choisissez une Méthode de Livraison",
    shipping_option_title: "Livraison ",
    pay_later: "Payer ultérieurement par CB",
    cash: "Payer ultérieurment en espèce",
    credit_card: "Carte de Crédit",
    pay_by_credit_card: "Payer par Carte de Crédit",
    giftcard: "Bon Cadeau",
    points: "Points",
    points_and_giftcard: "Bon Cadeau",
    wechat_pay: "Paiement par WeChat",
    address_customer_info: "Info adresse client",
    c2c_regular: "Livraison Standard",
    c2c_express: "Livraison Rapide",
    S01: "Livraison Gratuite",
    S02: "Tarif plat",
    S03: "Liste des Tarifs",
    credit_card_added: "Ajout de la Carte de Credit",
    map_search_placeholder: "Saisir le nom de rue",
    exceed_delivery_distance_select:
        "L'adresse de livraison sélectionnée dépasse la distance de livraison maximale du magasin de {delivery_distance}{distance_unit}. Veuillez choisir une autre adresse de livraison.",
    exceed_delivery_distance_input:
        "L'adresse de livraison fournie dépasse la distance de livraison maximale du magasin de {delivery_distance}{distance_unit}. Veuillez saisir une autre adresse de livraison.",
    address_not_in_delivery_zone:
        "L'adresse de livraison sélectionnée n'est pas à portée des zones de livraison du magasin. Veuillez choisir une autre adresse de livraison.",
    item_oos_all:
        "Tous les article(s) du panier sont en rupture de stock, veuillez ajouter des articles supplémentaires afin d'exécuter la commande.",
    error_occured: "Désolé, l'authentification de votre compte a échoué. Veuillez vous reconnecter et ressayer.",
    transaction_declined:
        "Transaction refusée. Carte de crédit non valide, veuillez sélectionner un autre mode de paiement.",
    invalid_first_name: "Prénom non valide",
    payment_method_missing: "Méthode de paiement manquante",
    billing_address_missing: "Adresse de livraison manquante",
    giftcard_error: "Erreur du bon cadeau, veuiller rafraichir la page et ressayer.",
    customer_info_missing: "Information Client manquante",
    table_number_missing: "Veuillez indiquer votre numéro de table",
    pickup_time_missing: "Horaire de Livraison manquant",
    delivery_time_missing: "Horaire de Livraison manquant",
    delivery_address_missing: "Adresse de Livraison manquante",
    shipping_method_missing: "Méthode de Livraison manquante",
    set: "Définir",
    item_error:
        "Une erreure est survenue. Votre panier achat a été effacé. Veuillez ajouter des articles et ressayer de procéder au paiement.",
    no_items_error:
        "Aucun article n'a été ajouté a votre panier d'achat. Veuillez ajouter des articles et procéder au paiement a nouveau.",
    use_profile_info: "Utiliser l'info de mon profil",
    pick_up: "Retrait",
    delivery_option: "Options de livraison",
    required: "Requis",
    "first_name_placehold,er": "Saisir votre prénom",
    last_name_placeholder: "Saisir votre nom",
    table_number_placeholder: "Saisir votre numéro de table #",
    unit_placeholder: "Saisir votre unité #",
    buzz_placeholder: "Saisir votre buzz #",
    street_placeholder: "Saisir votre adresse",
    city_placeholder: "Saisir votre ville",
    region_placeholder: "Saisir votre province",
    cntry_placeholder: "Saisir votre pays",
    zipcd_placeholder: "Saisir votre code postal ",
    table_number_required: "Le numéro de table est requis",
    are_you_sure_remove_gift_card: "Etes-vous sur de vouloir supprimer ce bon cadeau pour votre paiement?",
    no_shipping_options: "Pas de méthode de livraison pour l'adresse sélectionnée. Veuillez choisir une autre adresse.",
    delivery_time_changed: "Le délai de livraison a été modifié, veuillez sélectioner un autre horaire.",
    gift_card_payment: "Paiement par bon cadeau",
    expires: "Expire",
    allowed_periods_unavailable:
        "Veuillez selectioner une adresse de livraison appropriée pour bénéficier d'horaires de livraison disponibles.",
    delivery_method_unavailable:
        "Livraison non disponible, veuillez retourner a la page magasin et vérifier a nouveau.",
    takeout_method_unavailable: "Retrait non disponible, veuillez retourner a la page magasin et vérifier a nouveau.",
    in_store_method_unavailable:
        "Commande en magasin non disponible, veuillez retourner a la page magasin et vérifier a nouveau.",
    agreement_notice: "En confirmant la commande, j'accepte les {conditions_of_use} et la{privacy_policy} de Goopter",
    terms_of_use: "Conditions d'Utilisation",
    privacy_policy: "Politique de Confidentialité",
    "secure-connection": "Information envoyée via une connection sécurisée",
    set_payment_method: "Définir le Mode de Paiement",
    set_billing_address: "Définir l'Adresse de Facturation",
    set_delivery_address: "Définir l'Adresse de Livraison",
    set_giftcard: "Sélectionner Bon Cadeau",
    set_points: "Taper pour échanger des points",
    redeem_points_dialog_title: "Echanger des Points",
    redeem_x: "Echanger: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "Saisir l'Information Client",
    set_table_number: "Saisir le Numéro de Table",
    set_pickup_time: "Choisir une Heure de Retrait",
    set_delivery_time: "Choisir une Heure de Livraison",
    set_shipping_method: "Choisir la Méthode de Livraison",
    address_book: "Carnet d'adresses",
    "no-giftcard-text": "Vous n'avez aucun bon cadeau pour ce magasin",
    delete_credit_card_failed:
        "Veuillez nous excuser votre carte de crédit n'a pas pu etre supprimée, veuillez réessayer plus tard.",
    checkout_success:
        "Merci pour votre achat, votre commande a été créée: #{order_id}. Vous recevrez une notification quand le status de la commande sera mis a jour.",
    new_order_created: "Nouvelle commande créée",
    distance_exceeded: "Distance Excédée",
    min_delivery_amt_required:
        "Le montant minimum pour livraison est de {required} hors TVA et frais de livraison, veuillez ajouter {gap} supplémentaire pour la livraison.",
    insufficient_fund: "Fonds insuffisants, veuillez essayer avec un autre mode de paiement.",
    invalid_payment_info: "Information paiement non valide, veuillez vérifier votre saisie et ressayer.",
    merchant_not_ready:
        "Le commercant n'est pas disponible pour accepter votre paiement. Veuillez ressayer ultérieurement.",
    shipping_country_text: "Livraison disponible pour les adresses dans les pays suivants: {countries}",
    apply: "Appliquer",
    enter_coupon_code: "Saisir le Code du Coupon",
    remove_coupon_alert: "Etes-vous sur de vouloir supprimer ce coupon?",
    replace_coupon_alert: "Le coupon actuel et actif sera remplacé, souhaitez-vous continuer?",
    invalid_alipay_coupon: "Ce code coupon est seulement valable avec le mode de paiement AliPay",
    remove_current_alipay_coupon:
        "L'actuel panier d'achats contient un coupon uniquement valable avec le mode de paiement AliPay, le changement d'un autre mode de paiement aura pour conséquence la perte de votre réduction, souhaitez-vous encore modifier votre mode de paiement?",
    use_wechat_dialog_message:
        "Afin d'utiliser WeChatPay, veuillez ouvrir le magasin sur Wechat afin de passer la commande. {br}{br} Options pour ouvrir le magasin sur Wechat:{br}1. Si vous disposer du code QR du magasin, utiliser Wechat pour le scanner;{br}2. Saisissez le nom du magasin au-dessus de la page pour aller en arriere sur la page du magasin, ensuite cliquez sur l'icone dans l'angle droit au-dessus afin d'ouvrir la fenetre du code QR, sauvegardez le code QR, et ouvrir le code QR code dans Wechat afin de passer la commande.",
    discount_total: "Total de la Remise",
    first_name_missing: "Please saisir votre prénom",
    phone_number_missing: "Veuillez saisir votre numéro de téléphone",
    payment: "Paiement",
    select_payment: "Sélectioner un mode de paiement",
    select_giftcards: "Sélectioner un bon cadeau ",
    special_request: "Demande Spéciale",
    place_order_with_x: "Passez la commande - {x}",
    min_type_required_message:
        "Le montant minimum pour {type} est {value}, veuillez ajouter d'autres articles pour activer {type}.",
    select_pickup_location: "Selectionner le point de retrait",
    please_choose_pickup_location: "Veuillez selectionner le point de retrait",
    invalid_delivery_zone_title: "Hors de la zone de livraison",
    invalid_delivery_zone_content:
        "L'adresse fournie est hors de la zone de livraison. Veuillez saisir une autre adresse. Zones de livraison et horaires comme suit:",
    please_input_address: "Veuillez saisir une adresse valide",
    please_input_pickup_time: "Veuillez sélectioner l'heure de retrait",
    please_input_delivery_time: "Veuillez sélectioner l'heure de livraison",
    recommend_a_good_restaurant: "Vous a recommandé un bon magasin",
    recommend_a_good_merchat: "Vous a recommandé un bon commercant",
    recommend_a_good_product: "Vous a recommandé un bon produit",
    guest_user: "Usager visiteur",
    require_utensil: "Demande de couverts, etc.",
    already_have_account: "Valider le panier en temps que visiteur",
    order_success_table:
        "Merci de votre commande. Nous allons apporter votre commande a votre table dès qu'elle sera prete.",
    order_success_parking_lot:
        "Merci de votre commande. Nous allons apporter votre commande a votre voiture dès qu'elle sera prete.",
    order_success_kiosk: "Merci de votre commande. Nous vous informerons dès qu'elle sera prete.",
    view_special_deals: "View special deals",
    more_items_to_get_special_deals: "Current order amount does not qualify for special deals. Please add more items.",
    special_deals_for_you: "Special deals for you",
    add_items: "Add items",
    discounted_upsell_items_removed_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers.",
    discounted_upsell_items_removed_empty_cart_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers. Your cart is now empty. Please add items to continue.",
    menu: "Menu",
    category: "Catégorie",
    categories: "Catégories",
    store_details: "Details du magasin",
    store_description: "Description du magasin",
    rank: "Classement",
    customer_reviews: "Avis client",
    single_selection: "Sélection unique",
    optional: "Optionel",
    pick_varied_options: "Sélectionner {count} articles",
    sold: "{count} vendus",
    only_x_left: "Seulement {count} restant",
    sold_out: "Epuisé",
    pick_option: "Selectionner Option",
    contact_vendor: "Contacter le Vendeur",
    call_vendor: "Appeler le Vendeur",
    self_input: "Saisir sois-meme",
    with_option: "Avec Option(s)",
    coupons: "{couponCount} Coupon(s)",
    coupons_title: "Coupons",
    redeemed: "Appliqué",
    redeem: "Appliquer",
    remove: "Supprimer",
    valid_until: "Valable jusqu'au {endDate}",
    checkout: "Valider panier",
    shopping_cart: "Panier",
    empty_cart: "Vide",
    alert_empty_cart: "Etes-vous sur de vouloir vider votre panier?",
    discount_title: "Remise",
    qrcode: "Code QR",
    m: "m",
    km: "km",
    min: "min",
    hour: "h",
    rating: "Notation",
    rating_title: "Notation",
    base_delivery_fee: "Frais de livraison jusqu'a {flatDistance} {distanceUnit}",
    min_delivery_amount: "Montant Min Livraison",
    max_delivery_distance: "Distance de Livraison Maximale",
    distance_title: "Distance",
    takes_reservations: "Accepte des Réservations",
    more_info: "Plus d'Info",
    announcement: "Avis Public",
    no_announcement: "Pas d'Avis Public",
    buy_x_discount_flat: "Acheter {currency}{price}, {currency}{discount} de réduction",
    buy_x_discount_percent: "Acheter{currency}{price}, {discount}% de réduction",
    search_products_title: "Rechercher Produits",
    search_products_input_placeholder: "Saisir un mot-clé pour chercher...",
    search_products_no_match: "Votre recherche '{text}' ne correspond a aucun produit.",
    alert_delete_product_with_options:
        "Un Produit avec options peut uniquement etre supprimé depuis le panier d'achat.",
    store_closed_allowed_preorder: "Le magasin est actuellement fermé, vous pouvez pré-commander.",
    store_closed_allowed_preorder_x_mins_before:
        "Le magasin est actuellement fermé. Vous pouvez pré-commander {mins} minutes avant l'ouverture du magasin.",
    store_closed_soon_not_allowed_order:
        "Le magasin fermera bientôt et n'accepte actuellement pas de nouvelles commandes.",
    results_colon: "Résultats: ",
    price: "Prix",
    popularity: "Popularité",
    service_title: "Service",
    product_title: "Produit",
    all_title: "Tous",
    satisfied_title: "Satisfait",
    unsatisfied_title: "Insatisfait",
    discount_rate: "{rate}% de réduction",
    invalid_option_selection: "Option Selectionnée non valide",
    no_product_options: "Pas d'Options de Produits",
    single_selection_error: "Veuillez choisir une option d'article dans '{productOption}'",
    pick_ranged_options_error:
        "La sélection d'options minimale pour Minimum option sélection pour'{productOption}' est {count} articles",
    pick_fixed_options_error: "Veuillez choisir {count} sélection d'options dans '{productOption}'",
    update_cart: "Metre a jour le panier",
    total_reviews: "Avis",
    store_total_sold_count: "Vendu",
    hot_products: "Produits chauds",
    related_products: "Produits en relation",
    store_hours: "Horaires du magasin",
    delivery_hours: "Horaires de Livraison",
    add_for_delivery: "Ajouter {currency}{amount} pour la livraison",
    open_in_app: "Ouvrir dans l'App",
    qrcode_message: "Scanner ce code QR afin d'ouvrir le magasin",
    review_submitted_validate: "Merci d'avoir soumis votre avis, il sera publie une fois validé.",
    review_submitted: "Votre avis a été soumis.",
    add_update_review: "Veuillez ajouter ou mettre a jour votre avis",
    post_review_failed: "Votre avis n'as pa été soumis. Veuillez rafraichir votre page et ressayer.",
    not_accepting_orders: "Nous n'acceptons pas les commandes en ligne actuellement",
    bookmark_sign_in_alert_store: "Connectez-vous pour ajouter ce magasin a vos favoris!",
    bookmark_sign_in_alert_product: "Connectez-vous pour ajouter ce produit a vos favoris!",
    no_store_bookmarks: "Aucun magasin ajouté a vos favoris",
    no_store_bookmarks_message:
        "Aucun magasin ajouté a vos favoris. Presser l'icone en forme de coeur au-dessus a droite de l'écrant d'accueil du magasin pour l'ajouter a vos favoris.",
    no_store_bookmarks_button: "Ajouter un magasin a vos favoris",
    no_product_bookmarks: "Acun produit ajouté a vos favoris",
    no_product_bookmarks_message:
        "Vous n'avez aucun produit ajouté a vos favoris. Appuyer sur l'icone en forme de coeur associé a un produit afin de l'ajouter a vos favoris.",
    no_product_bookmarks_button: "Ajouter un produit a vos favoris",
    no_group_bookmarks: "Aucune affaire ajoutee a vos favoris",
    no_group_bookmarks_message:
        "Vous n'avez aucune affaire ajoutée a vos favoris. Appuyer sur l'icone en forme de coeur associé avec une affaire afin de l'ajouter a vos favoris.",
    no_group_bookmarks_button: "Ajouter une affaire a vos favoris",
    add_item_another_menu_warning:
        "Votre panier contient des articles d'un autre menu({menu}), Voulez-vous effacer votre panier et ajouter cet article a la place?",
    start_new_cart_question: "Ouvrir nouveau panier?",
    change_menu: "Changer de Menu",
    direction: "Recommendations",
    website: "Site Web",
    we_also_recommend: "Nous recommendons aussi...",
    diff_delivery_price: "Ajouter {x} a la livraison",
    discount_info_format: "Acheter {x}",
    discount_add_x_get_y: "Ajouter {x} de plus pour obtenir {y} de réduction",
    discount_get_percentage_discount: " obtenir {x} de réduction",
    discount_get_value_discount: " obtenir {x} de réduction",
    discount_not_accepting_order: "Désolé, nous n'acceptons pas les commandes en ligne actuellement",
    discount_store_closed_preorder: "Le Magasin est fermé, vous pouver pré-commander maintenant",
    discount_x_off_add_y_get_z: "{x} de réduction, ajouter {y} de plus pour obtenir {z} de réduction",
    discount_x_off: "{x} de Remise",
    switch_menu_warning_message:
        "Le menu sélectionné support un type différent de commandes ({new_order_type}). Les articles dans votre panier seront effacées si vous continuez. Souhaitez-vous aller au nouveau menu?",
    logout_confirm_message: "Etes-vous sur de vouloir vous déconnecter?",
    logout_force_message:
        "Désolé, votre compte a été connecté à partir d'un autre appareil ou a expiré. Veuillez vous reconnecter..",
    cannot_merge_account: "Votre compte existe déjà, veuillez utiliser un autre numéro de téléphone.",
    duplicate_social_error:
        "Le numero de téléphone a été enregistré avec un autre compte, veuillez essayer un numéro de téléphone différent. Afin d'utiliser ce numéro, veuillez vous déconnecter et vous connecter avec ce numéro.",
    store_has_no_products: "Ce magasin ne possède pas d'articles actuellement.",
    error_with_code: "[Error {code}]: {error}",
    unable_to_load_any_data: "Impossible de técharger l'information",
    unstable_network: "Réseau lent ou instable, veuillez vérifier la connection a votre réseau",
    incorrect_password: "Mot de Passe Incorrect",
    country_code_error: "Erreur Code Pays",
    incorrect_username_password: "Nom d'Usager Incorrect/mot de passe",
    verification_code_expired: "Code de Vérification expiré",
    verifcation_code_request_overlimit: "Nombre de demandes de code de vérification dépassé",
    incorrect_verification_code: "Code de vérification incorrect",
    error_fetch_user_profile: "Échec de la récupération du profil utilisateur",
    login_failed: "Echec de la connection",
    profile_update_success: "Mise a jour du profil réussie",
    email_update_success: "Mise a jour de l'email réussie",
    phone_update_success: "Mise a jour de numéro de télephone réussie",
    failed_fetch_gift_card_detail: "Échec de la récupération des détails du bon cadeau",
    gift_card_transfer_success: "Tranfert du bon cadeau avec succès",
    gift_card_transfer_fail: "Echec du transfert du bon cadeau",
    record_not_found: "Enregistrement non trouvé",
    failed_fetch_points_detail: "Échec de la récupération du détail des points",
    profile_update_failed: "Échec de la récupération du profil usager",
    email_update_failed: "Échec de la récupération de l'email",
    phone_update_failed: "Échec de la récupération du numéro de téléphone",
    get_verification_code_success: "Le code de vérification a été envoyé a votre téléphone.",
    verification_too_many_requests:
        "Le code de vérification a été sollicitéé multiples fois, veuillez ressayer ultérieurement",
    invalid_table_number: "Veuillez saisir un numéro",
    invalid_party_size: "Veuillez saisir un numéro",
    email_required: "Email requis",
    password_required: "Mot de Passe requis",
    phone_required: "Téléphone est requis",
    vc_required: "Code de Vérification non requis",
    coupon_invalid: "Coupon non valide",
    coupon_valid: "Coupon valide",
    invalid_coupon: "Code coupon '{couponCode} non valide",
    coupon_applied: "Le Coupon a été pris en compte dans votre panier.",
    invalid_address: "Adresse non valide",
    remaining_stock_header: "Remaining Stock",
    item_oos: "Désolé, le(s) article(s) {oos_items} sont en rupture de stock.",
    invalid_shipping_method:
        "Cette méthode de livraison n'est pas valable. Veuillez essayer une autre méthode de livraison",
    please_input_phone_number: "Veuillez saisir un numéro de téléphone valide",
    please_input_first_name: "Veuillez saisir votre Prénom",
    please_input_last_name: "Veuillez saisir votre Nom",
    please_input_at_least_two_characters: "Veuilez saisir au moins 2 caractères",
    please_input_shipping_method: "Veuillez saisir votre mode de livraison",
    please_input_sub_shipping_method: "Veuillez saisir votre mode de livraison",
    please_input_shipping_addresss: "Veuillez saisir votre adresse de Livraison",
    please_input_pickup_addresss: "Cliquer pour sélectioner la localisation",
    please_input_table_number: "Veuillez saisir le numéro de table",
    please_input_payment_method: "Cliquer pour sélectionner le mode de paiement",
    please_input_valid_credit_card: "Veuillez saisir votre carte de crédit",
    please_input_billing_address: "Veuillez saisir l'adresse de facturation",
    tap_to_show_on_map: "Cliquer ici pour montrer sur le plan",
    please_add_some_items: "Veuillez ajouter des articles",
    bind_phone: "Vérifier votre numéro de téléphone",
    please_bind_phone:
        "La vérification d'un numéro de téléphone est requise avant de passer une commande. Voulez-vous vérifier votre numero de téléphone maintenant?",
    go_to_bind: "Aller a Associer",
    bind_phone_success: "Association du téléphone réussie",
    invalid_credit_card: "Carte de Crédit non valide. Veuillez sélectionner une autre méthode de paiement",
    unsuccessful_checkout: "Echec validation panier. Veuillez réessayer.",
    invalid_request: "Echec validation panier. Demande non valide.",
    price_over_limit: "Echec validation panier. Le prix excède la limite de paiement.",
    invalid_shp_mtd:
        "Echec validation panier. La méthode de livraison n'est pas valide. Veuillez essayer une autre méthode de livraison",
    invalid_pay_mtd:
        "Echec validation panier. La méthode de paiement n'est pas valide. Veuillez essayer un autre mode de paiement.",
    please_input_belows: "Veuillez saisir ci-dessous les champs manquants",
    unable_to_change_here: "Impossible de modifier ici",
    please_select_on_previous_page: "Veuillez sélectionner sur la page précédente",
    invalid_card_number: "Numéro de carte non valide",
    invalid_zipcd: "Code postal non valide",
    click_one_more_time_to_deselect: "Cliquer plus longuement pour désélectionner",
    paypal_payment_success_title: "Paiement réussi",
    paypal_payment_success_text: "Votre paiement a été recu. Vous serez redirigé sur l'autre page.",
    error_payment_failed: "Echec paiement",
    internal_server_error: "Désolé, une erreur est survenue, veuillez réssayer ou contacter le vendeur directement.",
    unexpected_error: "Une erreur inatendue est survenue, veuillez ressayer",
    giftcard_pays_order_total:
        "Le bon cadeau sélectionné paie la totalité de la commande. Pas de mode de paiement supplémentaire requis.",
    points_pays_order_total:
        "Vos points paient pour la totalité de la commande. Pas de mode de paiement supplémentaire requis.",
    giftcard_points_pays_order_total:
        "Votre bon cadeau et vos points paient pour la totalité de la commande. Pas de mode de paiement supplémentaire requis.",
    digit_only: "Veuillez saisir des chiffres uniquement.",
    please_selected_belows: "Veuillez compléter toutes les sections:",
    please_select_location_from_the_list: "Veuillez sélectionner le nom d'une rue dans la liste.",
    alert_password_reset_success:
        "Votre mot de passe a été enregistré avec succès, vous pouvez a présent vous connecter avec votre nouveau mot de passe.",
    alert_email_not_found: "Cette adresse email n'a pas encore éte enregistrée!",
    alert_password_reset_email_send: "Email de réinitialisation du mot de passe a été envoyé",
    x_is_required: " est un champs requis",
    location_permission_is_blocked_service_may_not_perform_correctly:
        "L'authorisation de la permission est bloquée, le service peut etre affecté. ",
    x_missing: "Veuillez saisir {x} ",
    x_invalid: "{x} non valide",
    x_select: "Veuillez sélectioner {x}",
    do_you_want_to_receive_notification: "Voulez-vous recevoir des notifications?",
    gift_cards_points_alert:
        "Vous n'etes pas authorisé a utiliser le bon cadeau lorsque vous échangez des points. Afin d'utiliser un bon cadeau, veuillez supprimer les points de fidélité en premier.",
    points_gift_cards_alert_message:
        "Vous n'etes pas authorisé a utiliser les points de fidélité lorsque vous utilisé le bon cadeau. Afin d'utiliser les points de fidélité veuillez supprimer le bon cadeau en premier.",
    not_found_description: "Oops! La page n'a pas pu etre trouvée",
    not_found_detail_description:
        "Désolé mais la page recherchée n'existe pas, a été supprimée, le nom a changé, ou est temporairement indisponible",
    back_to_home: "Retour a la page d'accueil",
    braintree_credit_card_invalid_message:
        "Un problème est survenu. Veuillez vérifier les détails de votre carte et ressayer.",
    please_input_valid_postal_code: "Veuillez saisir un code postal valide.",
    please_input_valid_x: "Veuillez saisir un {x} valide",
    too_many_card_attempt:
        "The provided card has been declined too many times; please try another card for the payment.",
    too_many_checkout_attempt: "Maximum retry limit exceeded. Please try again later.",
    payment_declined: "Payment Declined",
    credit_card_declined:
        "Please check the credit card information to make sure they were entered correctly and try again. If this does not resolve the problem, please call your card issuing bank to resolve.",
    no_giftcard: "You do not have available gift card",
    giftcard_insuffienct_fund: "Your Gift Card has an insufficient balance for the payment.",
    invalid_request_body: "Invalid request",
    pay_order: "Payer la commande",
    expiry_date: "Date d'Expiration",
    postal_code: "Code Postal",
    postal_code_zipcd: "Code Postal",
    country: "Pays",
    last_four: "Les quatre",
    are_you_sure_delete_card: "Etes-vous sur de vouloir effacer cette carte?",
    visa: "Visa",
    master_card: "Master Card",
    american_express: "American Express",
    discover: "Discover",
    jcb: "JCB",
    maestro: "Maestro",
    credit_card_description: "Visa, Master Card, American Express",
    confirm_pay: "Confirmer le paiement",
    unsuccessful_payment: "Echec paiement. Veuillez essayer a nouveau",
    reset_password_title: "Réactiver le mot de passe",
    new_password: "Nouveau Mot de passe",
    password_reset_continue: "Soumettre",
    reset_by_text: "Réactiver par SMS",
    reset_by_email: "Réinitialiser votre Email",
    alert_vc_expired:
        "Veuillez nous excuser, le code de vérification que vous avez fourni a expiré, veuillez faire une autre demande de code et essayer a nouveau.",
    email_verification: "Email de vérification",
    verification_success: "Votre compte a été vérifié!",
    verify_account: "Merci de vérifier pour votre compte, {email}.",
    verification_fail: "Echec de votre Email de vérification",
    verify_error: "Une erreur s'est produite, veuillez réessayer en renvoyant un e-mail de vérification. {email}",
    view_account: "Voir Compte",
    link_expired: "Couldn't verify email address",
    link_expired_message: "The verification link has expired or was already used. Please try again. {email}",
    email_already_verified: "Your account has already been verified.",
    resend_verification_email: "Resend Verification Email",
    recently_viewed: "Consulté recemment",
    by_phone: "Par téléphone",
    by_email: "Par Email",
    by_password: "Avec le Mot de Passe existant",
    op_incorrect: "Le Mot de Passe Initial soumis est incorrect.",
    password_change_success: "Modification du Mot de Passe avec Succès.",
    password_request_sent: "Demande de Changement du Mot de Passe envoyée ",
    social_binding: "Liaison Sociale",
    linked: "Associé(s)",
    facebook: "Facebook",
    twitter: "Twitter",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    or: "ou",
    connect_with_facebook: "Se Connecter avec Facebook",
    connect_with_twitter: "Se Connecter avec Twitter",
    connect_with_google: "Se Connecter avec Google",
    connect_with_wechat: "Se Connecter avec WeChat",
    connect_with_apple: "Se Connecter avec Apple",
    dont_have_account: "Vous ne disposez pas d'un compte?",
    vouchers: "Bon de réduction",
    gift_card: "Bons Cadeaux",
    bookmarks: "Marque-pages",
    bookmark_added: "Ajouter marque-pages",
    bookmark_deleted: "Supprimer marque-pages",
    account_info: "Information sur le Compte",
    my_points: "Mes Points",
    my_giftcards: "Mes Bons Cadeaux",
    my_bookmarks: "Mes Marque-pages",
    my_messages: "Mes Messages",
    my_orders: "Mes commandes",
    avatar: "Avatar",
    name: "Nom",
    my_address: "Mon Adresse",
    account_binding: "Compte Associé",
    connect: "Se Connecter",
    safety_settings: "Paramètres de Sécurité",
    login_password: "Mot de Passe Connexion",
    modify: "Modifier",
    delete: "Effacer",
    first_name: "Prénom",
    last_name: "Nom",
    phone_number: "Téléphone #",
    address: "Adresse",
    pick_place_on_map: "Rechercher sur le plan",
    done: "effectué",
    enter_address_manually: "Entrer l'adresse manuellement",
    use_name_account: "Utiliser le nom du compte",
    new_address: "Nouvelle adresse",
    unit: "Unité",
    street: "Rue",
    city: "Ville",
    region: "Région",
    cntry: "Pays",
    zipcd: "Code Postal",
    postalcd: "Code Postal",
    customer_info: "Info Client",
    required_text: "* indique qu'un champ est requis",
    map_search: "recherche sur le plan",
    accept: "Accepter",
    invalid_last_name: "Nom de famille Non valide",
    invalid_credit_card_number: "Numéro de carte de crédit Non valide",
    invalid_credit_card_cvv: "CVV Non valide",
    invalid_cntry: "Pays Non valide",
    Invalid_zipcd: "Code postal Non valide",
    street_missing: "Rue manquante",
    city_missing: "Ville manquante",
    region_missing: "Région manquante",
    cntry_missing: "Pays manquant",
    zipcd_missing: "Code Postal manquant",
    billing_address: "Adresse de Facturation",
    confirm_delete_address: "Valider Adresse Effacée",
    delete_address_message: "Etes-vous sur de vouloir effacer cette adresse?",
    address_edit: "Editer l'Adresse",
    default: "par Défaut",
    set_as_default: "Définir par défaut",
    nick_name: "Surnom",
    update_profile_success: "Votre profil a eté mis a jour avec succès!",
    update_profile_failed:
        "Veuillez nous excuser, la mise a jour de votre profile a échouée. Veuillez essayer a nouveau plus tard.",
    last_name_missing: "Nom de famille manquant",
    nick_name_missing: "Surnom manquant",
    update: "Mis a jour",
    user_info: "Information de l'Usager",
    new_phone: "Nouveau numéro de Téléphone #",
    new_email: "Nouvelle Adresse Email",
    phone_placeholder: "Numéro de téléphone",
    alert_phone_updated: "Votre numéro de téléphone a été actualisé avec succès!",
    alert_missing_fields: "Veuillez nous excuser, champs de saisie manquants.",
    alert_forbidden_action: "Nous sommes désolés, cette action est interdite.",
    alert_email_updated: "Votre adresse email a été actualisée avec succès!",
    alert_forbidden_email: "Nous sommes désolés, cette action est interdite.",
    alert_email_exists_sign_up:
        "L'e-mail que vous avez saisi est déjà utilisé par un autre compte. Veuillez vous connecter à ce compte ou utiliser une autre adresse e-mail.",
    alert_email_exists_update:
        "L'e-mail que vous avez saisi est déjà utilisé par un autre compte. Veuillez utiliser une autre adresse e-mail.",
    wallet: "Porte-feuille",
    confirm_delete_credit_card: "Confirmer la suppression de la Carte de Crédit",
    confirm_delete_credit_card_message: "Etes-vous sur de vouloir effacer cette carte de crédit?",
    add_credit_card_title: "Ajouter Nouvelle Carte de Crédit",
    add_credit_card: "Ajouter Carte de Crédit",
    save_credit_card: "Sauvegarder Carte de Crédit",
    card_number: "Numéro de Carte",
    name_on_card: "Nom sur la Carte",
    cvv: "CVV",
    set_payment_password: "Définir le mot de passe pour le paiement",
    old_password: "Ancien Mot de passe",
    confirm_new_password: "Confirmer le nouveau mot de passe",
    password_not_detected:
        "L'ancien mot de passe ne correspond pas, veuillez vérifier et réessayer ou réinitialiser votre mot de passe.",
    server_error: "Désolé, une erreure s'est produite. Veuillez réessayer.",
    unmatched_passwords: "Nouveau mot de passe et mot de passe validé sont différents, veuillez vérifier et réessayer.",
    social_bind_not_allowed:
        "Se connecter, associer ou dissocier via le compte de votre reseau social n'est pas authorisé a l'exception de wechat is not allowed in WeChat, veuillez utiliser le navigateur externe",
    social_bind_success:
        "Votre compte a été associé avec succès avec votre compte {socialType}. Vous pouvez a présent vous connecter avec votre compte {socialType}!",
    social_bind_failed: "Erreur survenue en associant votre compte {socialType}. Veuillez essayer a nouveau.",
    connected: "Connecté",
    confirm_unbind_social_account: "Etes-vous sur de vouloir dissocier votre compte {socialType}?",
    unbind_social_success: "Votre compte {socialType} a été dissocié.",
    unbind_social_error:
        "Désolé, nous n'avons pas pu dissocier votre compte {socialType} actuellement. Veuillez essayer a nouveau.",
    create_new_vault_password: "Ajouter un mot de passe pour sécuriser votre carte de crédit",
    confirm_vault_password: "Confirmer votre mot de passe",
    input_vault_password: "Insérer votre mot de passe pour le paiement",
    vault_password: "Mot de passe paiement",
    password_instructions: "Le mot de passe requiert une longueur de 4 a 6 chiffres",
    password_incorrect: "Mot de passe incorrect",
    add_address_invalid_address: "Adresse non valide. Veuillez essayer de sélectionner une adresse sur la carte.",
    country_not_exists: "Pays non valide. Veuillez essayer de sélectionner une adresse sur la carte.",
    map_select_address: "Veuillez compléter la barre de recherche et sélectionner une adresse suggérée.",
    error_current_location:
        "Erreur rencontrée en cherchant votre localisation actuelle, veuillez introduire votre adresse dans la barre de recherche.",
    map_address_accept_error:
        "Erreur de Google maps. Veuillez essayer une autre adresse ou veuillez essayer manuellement.",
    invalid_name: "Veuillez compléter le nom de l'usager de la carte.",
    invalid_cc_info: "L'information de votre carte de crédit est non valide, veuillez vérifier et essayer de nouveau.",
    invalid_expiry_date:
        "La date d'expiration de votre carte de crédit est non valide, veuillez vérifier et essayer de nouveau.",
    alert_guest_account:
        "Vous êtes actuellement connecté en tant qu'utilisateur invité ; remplissez votre nom, e-mail/numéro de téléphone pour devenir un client régulier et recevoir un service personnalisé ou des remises en magasin à l'avenir. Souhaitez-vous continuer?",
    alert_missing_account_info: "Please fill in the missing information: {missingInfo}",
    address_amount_exceeded:
        "Nombre de contacts en excès dans le carnet d'adresse. Vous pouver sauvegarder 10 adresses au maximum.",
    i_will_do_it_later: "Je le ferai plus tard",
    finish_later: "Finir plus tard",
    skip: "Sauter",
    change_by_email: "Par Email",
    change_by_text_message: "Par SMS",
    change_by_existing_password: "Avec le mot de passe existant",
    set_password: "Créer un mot de passe",
    change_password: "Changer mot de passe",
    update_password: "Mise a jour mot de passe",
    code: "Code",
    request_pass_reset: "Demande de réinitialisation du mot de passe",
    confirm_password: "Confirmer votre mot de passe",
    set_a_password: "Créer un mot de passe",
    set_a_password_caption: "Créer un mot de passe pour accélérer votre future connexion",
    update_password_success: "Mise a jour mot de passe avec succès",
    update_password_fail: "Echec de la mise a jour de votre mot de passe, veuillez essayer plus tard",
    birthday: "Anniversaire",
    gender: "Sexe",
    not_provided: "Non fourni",
    currency: "Monnaie",
    male: "Homme",
    female: "Femme",
    none: "Aucune",
    year: "Année",
    month: "Mois",
    day: "Jour",
    cannot_update_profile: "Ne peut pas mettre a jour le profile.",
    updated_profile: "Profile mis a jour.",
    giftcards: "Bons Cadeaux",
    gift_card_details: "Details du Bon Cadeau",
    all: "Tous",
    active: "Actif",
    inactive: "Inactif",
    active_giftcard: "Actif",
    inactive_giftcard: "Inactif",
    used: "Utilisé",
    balance: "Balance",
    book_value: "Valeur Comptable",
    purchase_date: "Date d'Achat",
    status: "Status",
    reassign: "Réaffecté",
    reassign_giftcard: "Réaffecter Bon Cadeau",
    receivers_email_phone: "Adresse e-mail du destinataire / Numéro de Téléphone",
    giftcard_reassign_success: "Votre Bon cadeau a éte transféré avec succès a {receiver}.",
    giftcard_reassign_missing_receiver: "Veuillez compléter l'information du destinataire",
    giftcard_reassign_missing_info: "Vous devez disposer d'un destinataire valide pour envoyer le bon cadeau.",
    giftcard_reassign_failed:
        "Désolé, il y a eu une erreur en essayant de réassigner votre Bon Cadeau. Veuillez réessayer plus tard.",
    giftcard_reassign_failed_phone:
        "Ce numéro de téléphone n'appartient a aucun usager enregistré, veuillez essayer un autre numéro de téléphone.",
    giftcard_reassign_failed_email:
        "Cette adresse email n'appartient a aucun utlisateur enregistré, veuillez essayer une autre adresse email.",
    no_giftcards: "Vous ne disposez d'aucun Bon de Cadeau",
    no_active_giftcards: "Vous ne disposez d'aucun Bon de Cadeau actif",
    no_active_giftcards_head: "Pas de Bon Cadeau activé",
    no_used_giftcards_head: "Pas de Bon Cadeau utlisé",
    no_used_giftcards: "Vous n'avez utilisé aucun Bon Cadeau",
    purchase_a_giftcard: "Achat Bon Cadeau",
    invalid_phone: "Le numéro de téléphone est invalide, veuillez vous assurer du nombre de caractères.",
    giftcard_reassign_tip:
        "Bon cadeau assigné vous permet d'envoyer un bon cadeau acheté a un autre utilisateur via leur adresse email ou numéro de téléphone enregistrés",
    gift_card_purchased: "Bon cadeau acheté",
    paid_with_gift_card: "Paiement avec bon cadeau",
    refunded: "Remboursé",
    invalid_phone_number: "Numéro de téléphone non valide",
    comment: "Commentaires",
    transactions: "Transactions",
    details: "Details",
    date: "Date",
    balance_change: "Changement du Solde",
    points_balance: "Solde des Points",
    no_points: "Vous n'avez a présent aucuns points de fidélité. Acheter maintenant afin de gagner des points.",
    no_points_head: "Vous n'avez pas de points",
    view_stores: "Voir magasins",
    last_updated: "Dernière mise a jour: {time}",
    point_balance: "Solde: {balance}",
    point_store_name: "Nom du magasin: {name}",
    points_received: "Points Recus",
    no_orders: "Aucune commande passée antérieurement",
    no_past_orders: "Pas de commandes antérieures",
    no_upcoming_orders: "Pas de commandes a venir",
    shop_now: "Acheter Maintenant",
    pickup_method: "Heure de Retrait",
    eatin_method: "Heure Prévu",
    delivery_method: "Delai de Livraison",
    instantCheckout_method: "Instant Checkout",
    items: "Article(s)",
    ongoing: "En cours",
    history: "Historique",
    upcoming: "A venir",
    past_orders: "Commandes Passées",
    buzz_code: "Buzz",
    make_default: "Par Défaut",
    add_address: "Nouvelle Adresse",
    add_a_address: "Ajouter une nouvelle adresse",
    are_you_sure_delete_address: "Etes-vous sur de vouloir effacer cette adresse?",
    select_address: "Selectionner Adresse",
    search_address: "Rechercher Adresse",
    current_location: "Localisation actuelle",
    use_name_on_account: "Utiliser le nom du compte",
    confirm_address: "Confirmer l'Adresse",
    location_user_denied: "L'option de localisation est désactivée, veuillez activer l'option localisation",
    geolocation_not_supported:
        "L'option de localisation actuelle ne fonctionne pas, veuillez rechercher l'adresse dans la barre de recherche d'adresse",
    location_general_error: "Localisation impossible, veuillez essayer plus tard",
    self_checkout: "Quick Pay",
    amount: "Montant",
    add_comment: "Ajouter un commentaire",
    contact_merchant: "Contacter le Commercant",
    powered_by_goopter: "Lancée par Goopter",
    pay: "Payer",
    self_checkout_disabled: "Quick pay is not available for this store.",
    quick_pay_hint_1: "Quick Pay lets you pay for your order anytime!",
    quick_pay_hint_2: "Just enter the amount due and include any information about this payment in the notes.",
    got_it: "Got It!",
    surcharge_for: "Supplément pour la commande: #{oid}",
    phone_number_verification: "Phone Number Verification",
    please_verify_number: "Please verify your number",
    verification_code_sent_message: "A text message with a 4-digit verification code was just sent to {phoneNumber}",
    enter_code: "Enter Code",
    try_different_number: "Try a different number",
    number_verified_message: "Your number has been verified!",
    continue_checkout_message: "Please continue with your checkout",
    get_vc_descprtion_text: "Nous vous enverrons un code de vérification sur votre téléphone",
    payment_success_title: "Paiement validé",
    payment_success_text: "Paiement recu. Vous allez etre rediriger sur une autre page.",
    go_to_order: "Aller a la commande",
    qr_scanner: "Scanner afin de commander",
    camera_instructor_title: "Accès caméra requis",
    camera_instructor_description_dine_in:
        "The Goopter app va ouvrir votre caméra. Veuillez pointer votre caméra vers votre code QR afin de traiter votre commande.",
    camera_instructor_button_text_dine_in: "Scanner afin de commander",
    camera_error_title: "Acces caméra refusé",
    camera_error_description_dine_in: "Veuillez activer la caméra pour scanner le code QR afin de commander.",
    camera_error_button_text: "Activer Caméra",
    error_invalid_code_dine_in:
        "Code QR non valide pour diner en salle dans cet établissement. Veuillez le code QR correct.",
    error_invalid_code_in_store:
        "Code QR non valide pour commander sur place dans cet établissement. Veuillez scanner le code QR correct.",
    parking_lot: "Lot Parking #",
    kiosk: "Kioske #",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "Sélectionner le bouton caméra pour changer",
    scan_again: "Essayer a nouveau",
    scanner_title: "Aligner le code QR/barcode dans le cadre pour scanner",
    orders_today: "Commandes Aujourd'hui",
    orders_total: "Total des Commandes",
    points_today: "Points Aujourd'hui",
    points_total: "Total de Points",
    check_how_many_reward_points_from_referrals:
        "Vérifiez combien de points de récompense vous avez reçus des parrainages",
    no_referrals_message:
        "Vous n'avez pas encore de références. Commencez à parrainer pour gagner des points de fidélité à dépenser dans vos magasins préférés!",
    order_id: "Commande #",
    no_referral_orders_found: "Aucune commande de parrainage trouvée",
    from: "From",
    pts: "pts",
    update_order: "Update Order",
    your_order: "Your Order",
    schedule_for_later: "Schedule for later",
    ready_in_asap: "Ready in {start}-{end} minutes",
    when_would_you_like_to_order: "When would you like to order",
    no_shipping_address_selected: "No shipping address selected.",
    change: "Change",
    start_order: "Start Order",
    order_for: "Order for",
    are_you_sure_keeping_cart: "There are saved items in the cart. Do you want to keep the items in the cart?",
    clear_the_cart: "Clear The Cart",
    keep_the_cart: "Keep",
    offer_ends_on: "End Time: {time}",
    surcharege_paid_message: "The surcharge amount is not included in this order. Please refer to order #{id}",
    add_to_cart_success: "Product has been successfully added to the cart",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    gift_cards: "Gift Cards",
    first_name_placeholder: "Enter your first name",
    conditions_of_use: "Terms of Use",
    please_confirm_tips: "Tap to Set Tip Amount",
    please_set_minimum_tips: "Please Input Minimum Tip Amount",
    please_set_minimum_tips_detail: "The minimum tip amount for delivery is ${tips_amount} in order to secure a driver for your order.",
    phone_num: "Phone #",
    change_address: "Change Address",
    set_a_new_password: "Set a new password",
    set_a_new_password_caption: "Forgot your password? Set a new one to login quicker next time.",
    msg_country_update: "Country successfully updated.",
    msg_currency_update: "Currency successfully updated.",
    msg_birthday_update: "Birthday successfully updated.",
    msg_email_update: "Email successfully updated.",
    msg_gender_update: "Gender successfully updated.",
    msg_name_update: "Name successfuly updated",
    msg_nickname_update: "Nickname successfully updated",
    no_thanks: "No Thanks",
    verify: "Verify",
    number_already_exist:
        "This number has been registered with another account. Please logout and login with the number if you want to use this number to place an order. Alternatively you may use another phone number for your current account.",
    sign_in_with_number: "Sign in with existing number",
    try_another_number: "Try another number",
    add_instructions: "Add Instructions",
    code_not_revieved: "Didn't get code?",
    click_to_resend: "Click to resend.",
    review_recommend_store: "How likely are you to recommend {storeName} to a friend or family?",
    example_review_score: "Tell us a bit more about why you chose {score}",
    change_score: "Change score?",
    not_likely: "Not Likely",
    very_likely: "Very Likely",
    review_thank_you_recieve_coupon: "Thank you! You will recieve a coupon via text in about 1 min.",
    review_share_your_heart: "Would you be kind to share your heart to us?",
    review_copy_comment: "1. Simply copy the comment you wrote.",
    and_share_your_review: "2. And share your review.",
    thank_you: "Thank you!",
    review_thank_you_note: "If you don't receive the coupon text, reply “my-coupon” to view your coupons, Thank you!",
    share_to_google: "Share to Google",
    powered_by: "Powered by",
    quick_pay_self_serve_order_hins: "I have a self-serve order from Kiosk or QR code scan.",
    quick_pay_staff_order_hins: "A staff placed an order for me.",
    quick_pay_order_type_self_serve: "Self-serve order",
    quick_pay_order_type_staff: "Staff-assisted order",
    quick_pay_option_title: "What are you paying for?",
    quick_pay_order_type_no_order: "I do not have an order number",
    add_payment_note: "Add Payment Note",
    select: "Please Select",
    leave_quick_pay_confirm_msg: "Are you sure you want to leave quick pay?",
    self_serve_order_number: "Self-Serve Order Number",
    staff_assisted_order_number: "Staff Assisted Order Number",
    self_serve_order_number_input_placeholder: "Input self-serve order number",
    please_confirm: "Please Confirm",
    staff_assisted_order_number_input_placeholder: "Input staff assisted order number",
    invalid_order_id: "Invalid order #, please try again.",
    quick_pay_order_type_no_order_placeholder: "No order number is associated with this payment",
    quick_pay_order_id: "Payment for {orderType} #: {orderId}",
    quick_pay_confirm_msg: "Are your sure you want to pay for",
    quick_pay_confirm_msg_2: "The following order?",
    amount_due: "Amount Due",
    quick_pay_confirm_hin: "Please choose the ",
    quick_pay_confirm_hin_2: " order options if you have same order number but a different amount due.",
    staff_assisted: "staff assisted",
    quick_pay_order_type_additional_payment: "Partial or additional payment of a self-service order ",
    refunded_details: "Refund details",
    refund_reason: "Refund reason",
};

export default exports;
