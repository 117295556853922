import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialConfig = {
    debug: false,
    appId: "", // 必填，公众号的唯一标识
    timestamp: "", // 必填，生成签名的时间戳
    nonceStr: "", // 必填，生成签名的随机串
    signature: "", // 必填，签名
    jsApiList: [
        "checkJsApi",
        "updateAppMessageShareData",
        "updateTimelineShareData",
        "onMenuShareAppMessage", //旧的接口，即将废弃
        "onMenuShareTimeline", //旧的接口，即将废弃
        "onMenuShareQQ",
        "onMenuShareQZone",
        "onMenuShareWeibo",
    ],
};

const initialState = {
    config: initialConfig,
};

const store = createSlice({
    name: "wechatPlugin",
    initialState,
    reducers: {
        //from component
        setWechatShareImgUrl: (state, { payload }) => {
            state.imageUrl = payload;
        },
        //from service
        getConfigSuccess: (state, { payload }) => {
            state.config = Object.assign(_.cloneDeep(initialConfig), _.pick(payload, Object.keys(initialConfig)));
        },
    },
});

export const {
    //to service
    getConfigSuccess,
    //to component
    setWechatShareImgUrl,
} = store.actions;

export default store.reducer;
