import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    order: {},
};

const paypal = createSlice({
    name: "paypal",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = initialState;
        },
        initDataSuccess: (state, { payload }) => {
            const paypalRC = _.get(payload, "payPalResponse.RC", 400);
            const orderRC = _.get(payload, "orderDetailResponse.RC", 400);
            state.order = _.get(payload, "orderDetailResponse.records", {});
            state.paypalId = _.get(payload, "payPalResponse.records.id", "");
            state.price = _.get(payload, "payPalResponse.records.trans", {});
            if (paypalRC !== 200 || orderRC !== 200) {
                state.displayModal = true;
                state.displayModalContent = "internal_server_error";
            }
        },
        paypalOnAuthorizeSuccess: (state, { payload }) => {
            const RC = _.get(payload, "response.records", 400);
            state.displayModal = true;
            state.displayModalTile = RC === 200 ? "payment_success_title" : "";
            state.displayModalContent = RC === 200 ? "payment_success_text" : "error_payment_failed";
        },
    },
});

export const {
    setState,
    clearData,
    //to service
    initDataSuccess,
    paypalOnAuthorizeSuccess,
} = paypal.actions;

export default paypal.reducer;
