import { createSlice } from "@reduxjs/toolkit";

const orders = createSlice({
    name: "orders",
    initialState: {
        loading: false,
        orders: [],
        paging: {},
    },
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        getOrdersSuccess(state, { payload }) {
            if (payload.paging.page === 1) {
                state.orders = payload.orders;
                state.paging = payload.paging;
            } else {
                state.orders = [...state.orders, ...payload.orders];
                state.paging = payload.paging;
            }
        },
    },
});

export const { getOrdersSuccess, setState } = orders.actions;

export default orders.reducer;
