import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, data, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!rest.isAuthenticated) {
                    return <Redirect to={{ pathname: "/sign-in", state: { from: props.location } }} />;
                } else {
                    return <Component {...props} {...data} />;
                }
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
