import _ from "lodash";
import qs from "query-string";
import helper from "utils/helper";
import { push, replace } from "../app/history";
import { getMenuShippingMethods } from "../store-page/helper";
//step
export const SCAN_STEPS = {
    instruct: 1,
    scan: 2,
    error: 3,
};
//error types
export const ERROR_TYPES = {
    scan: 1,
};
//get url
//back -> use to direct to correct path when user click back or cancel square
//forward -> use to direct to correct path when scan is successful
//type -> to determine what the purpose of the scan
//mid -> use to determine the mid when user click back or cancel square
//fmid -> forward mid. use to determine mid when scan is successful
export const pushScanUrl = (params) => {
    let rParams = Object.assign(
        {},
        {
            back: SCAN_DIRECTS.store,
            forward: SCAN_DIRECTS.store,
            type: SCAN_TYPES.dine_in,
        },
        params
    );
    const query = qs.parse(window.location.search);
    const mid = _.get(query, "mid", "");
    if (mid) rParams.mid = mid;
    push(`/qr-scanner?${qs.stringify(rParams)}`);
};
export const getForwardMid = () => {
    const query = qs.parse(window.location.search);
    const forward = _.get(query, "fmid");
    return forward || "";
};
export const getForwardType = () => {
    const query = qs.parse(window.location.search);
    const forward = _.get(query, "forward");
    return Object.values(SCAN_DIRECTS).includes(forward) ? forward : SCAN_DIRECTS.store;
};
//type
export const SCAN_TYPES = {
    dine_in: "dine_in",
};
export const getType = () => {
    const query = qs.parse(window.location.search);
    const type = _.get(query, "type");
    return Object.values(SCAN_TYPES).includes(type) ? type : SCAN_TYPES.dine_in;
};
//validate
const PARSER_TYPE = {
    store: 1,
    product: 2,
    instant_pay: 3,
    group_sale: 4,
};
export const validateQRUrl = (payload) => {
    const type = getType();
    if (type === SCAN_TYPES.dine_in) {
        return validateDineInQRUrl(payload);
    }
    return false;
};
export const validateDineInQRUrl = (payload) => {
    const response = _.get(payload, "response", {});
    const store = _.get(payload, "payload.store", {});
    const gid = _.get(store, "gid");
    const responseGid = _.get(response, "gid");
    const gidValid = gid === responseGid && responseGid;

    const responseTable = _.get(response, "location_id");
    const tableValid = responseTable;

    const typeValid = Number(_.get(response, "type")) === PARSER_TYPE.store;
    return gidValid && tableValid && typeValid;
};
//back
export const SCAN_DIRECTS = {
    store: "1",
    check_out: "2",
};
export const getBackType = () => {
    const query = qs.parse(window.location.search);
    const back = _.get(query, "back");
    return Object.values(SCAN_DIRECTS).includes(back) ? back : SCAN_DIRECTS.store;
};
export const goBack = (store) => {
    const gid = _.get(store, "gid", "");
    const type = getBackType();
    if (!gid) {
        replace("/");
    }
    if (type === SCAN_DIRECTS.store) {
        const getMid = () => {
            const { mid } = qs.parse(window.location.search);
            const methods = getMenuShippingMethods(mid, store);
            if (methods.includes("delivery", "takeout")) {
                return `?mid=${mid}`;
            }
            return ``;
        };
        helper.getIndependentDomain() ? replace(`/${getMid()}`) : replace(`/store/${gid}${getMid()}`);
    }
    if (type === SCAN_DIRECTS.check_out) {
        replace(`/checkout?query=${gid}`);
    }
};
