import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { SCAN_STEPS, SCAN_TYPES, validateQRUrl, getType, ERROR_TYPES } from "./helper";
import { isRestaurant } from "../store-page/helper";

const initialDialogState = {
    displayModal: false,
    displayModalTitle: "",
    displayModalContent: "",
    displayModalKeywords: {},
    displayModalHandlingType: "",
};

const initialState = {
    ...initialDialogState,
    step: SCAN_STEPS.instruct,
    result: {},
};

const paypal = createSlice({
    name: "QRScanner",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = initialState;
        },
        parseQRSuccess: (state, { payload }) => {
            const isValidate = validateQRUrl(payload);
            const store = _.get(payload, "payload.store", {});
            if (getType() === SCAN_TYPES.dine_in && isValidate) {
                state.result = payload?.response;
            }
            if (getType() === SCAN_TYPES.dine_in && !isValidate) {
                state.displayModal = true;
                state.displayModalContent = isRestaurant(store)
                    ? "error_invalid_code_dine_in"
                    : "error_invalid_code_in_store";
                state.displayModalHandlingType = ERROR_TYPES.scan;
            }
        },
    },
});

export const { setState, clearData, parseQRSuccess } = paypal.actions;

export default paypal.reducer;
