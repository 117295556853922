import _ from "lodash";
import config from "config";
import { isValidPhone } from "utils/helper";

export const isGuest = (account) => {
    return _.get(account, "role") === config.CUSTOMER_ROLES.guest;
};

export const getUserPhone = (customer) => {
    const phone = _.get(customer, "phone", "");
    return isValidPhone(phone) ? phone : "";
};
