import configApi from "./config-api.json";
// import configQa from './config-qa.json';
import configDev from "./config-dev.json";
import config from "./config.json";

const exports = {
    ...config,
    ...(process.env.NODE_ENV === "development" ? configDev : configApi),
};

export default exports;
