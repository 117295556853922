import _ from "lodash";
import TWEEN from "@tweenjs/tween.js";
import moment from "moment";
import config from "config";
import helper from "utils/helper";

export function animateButton(startingX, startingY) {
    let box = document.createElement("div");
    box.style.zIndex = 1525;
    box.style.position = "fixed";
    box.className = "storeProductButton addProduct floatingAnimatedButton";
    box.innerHTML =
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>';
    document.body.appendChild(box);

    // Setup the animation loop.
    function animate(time) {
        requestAnimationFrame(animate);
        TWEEN.update(time);
    }
    requestAnimationFrame(animate);

    var coords = { x: startingX - 10, y: startingY - 5 }; // Start at

    var tweenB = new TWEEN.Tween(coords)
        .to({ x: 0, y: window.innerHeight }, 700)
        .easing(TWEEN.Easing.Exponential.Out)
        .onUpdate(function () {
            // Called after tween.js updates 'coords'.
            // Move 'box' to the position described by 'coords' with a CSS translation.
            // box.style.setProperty('transform', 'translate(' + coords.x + 'px, ' + coords.y + 'px)');
            box.style.setProperty("top", `${coords.y}px`);
            box.style.setProperty("left", `${coords.x}px`);
        })
        .onComplete(function () {
            box.remove();
        });

    var tweenA = new TWEEN.Tween(coords) // Create a new tween that modifies 'coords'.
        .to({ x: (window.innerWidth - (1 - startingX)) / 4, y: startingY }, 300) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Exponential.In) // Use an easing function to make the animation smooth.
        .onUpdate(function () {
            // Called after tween.js updates 'coords'.
            // Move 'box' to the position described by 'coords' with a CSS translation.
            // box.style.setProperty('transform', 'translate(' + coords.x + 'px, ' + coords.y + 'px)');
            box.style.setProperty("top", `${coords.y}px`);
            box.style.setProperty("left", `${coords.x}px`);
        });
    tweenA.chain(tweenB);
    tweenA.start(); // Start the tween immediately.
}

export function calculateDiscount(store, total, method, cart) {
    const discountByMethod = helper.getDiscountDataWithMethod(store, method);
    const giftCardTotal = helper.getGiftCardTotal(cart);
    let newTotal = total - giftCardTotal;
    if (!_.isEmpty(discountByMethod)) {
        let amount = 0;

        Object.keys(discountByMethod.data).forEach((discount) => {
            if (discount <= newTotal) {
                amount = discountByMethod.data[discount];
            }
        });

        if (discountByMethod.type === 1) {
            newTotal = newTotal - (amount / 100) * newTotal;
        } else {
            newTotal = newTotal - amount;
        }
    }

    return newTotal + giftCardTotal;
}

export const getCartProductQty = (cart, product) => {
    const cartArr = Array.isArray(cart) ? cart : [];
    const productPid = product?.pid;
    return cartArr.reduce((acc, p) => {
        if (String(p.pid) === String(productPid)) {
            acc += p.qty;
        }
        return acc;
    }, 0);
};

export const enoughProductToAdd = (cart, product, qty = 0) => {
    const productQty = _.get(product, "sq", 1);
    return productQty >= getCartProductQty(cart, product) + 1 + qty;
};

export const getCartOptionsQty = (cart, product, option, subOpt) => {
    const cartArr = Array.isArray(cart) ? cart : [];
    const productPid = String(product?.pid);
    return (
        cartArr.reduce((acc, p) => {
            if (String(p.pid) !== String(productPid)) {
                return acc;
            }
            const pickedOptions = p?.options ?? {};
            const pickedOptSubOpt = Array.isArray(pickedOptions?.[option?.id]) ? pickedOptions?.[option?.id] : [];
            if (pickedOptSubOpt.includes(subOpt?.id)) {
                acc +=
                    pickedOptSubOpt.filter((pickedSubOptId) => String(pickedSubOptId) === String(subOpt?.id))?.length ??
                    0;
            }
            return acc;
        }, 0) ?? 0
    );
};

export const enoughOptionToAdd = (cart, product, cartOpton, qty = 0) => {
    const opts = Array.isArray(_.get(product, "opt")) ? _.get(product, "opt") : [];
    const insuffienctOpt = opts.find((opt) => {
        const subOpts = Array.isArray(_.get(opt, "opts")) ? _.get(opt, "opts") : [];
        const optId = _.get(opt, "id");
        const pickedOpts = Array.isArray(_.get(cartOpton, `options.${optId}`))
            ? _.get(cartOpton, `options.${optId}`)
            : [];
        return pickedOpts.find((subOptId) => {
            const subOptObj = subOpts.find((subOpt) => _.get(subOpt, "id") === subOptId);
            const subOptQt = _.get(subOptObj, "sq", 1);
            return getCartOptionsQty(cart, product, opt, subOptObj) + 1 + qty > subOptQt;
        });
    });
    return !insuffienctOpt;
};

const TABLE_EXPIRED_TIME = 8 * 60 * 60 * 1000; //in miliseconds
export const getTableNumber = (cartState, gid) => {
    const cartObject = _.get(cartState, `tables.${gid}`, "");
    const time = _.get(cartObject, "time", 0);

    //expired or no value
    if (moment().valueOf() - time >= TABLE_EXPIRED_TIME) {
        return {
            id: "",
            type: config.TABLE_TYPES.table,
        };
    }

    return cartObject;
};

export const MAX_ITEMS_OPTION_VALUE = 50;
