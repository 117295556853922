import React from "react";
import locale from "../../locale";
import _ from "lodash";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "components/private-route";
import { withRouter } from "react-router-dom";
import helper from "utils/helper";
import loadable from "@loadable/component";
import ReOrder from "../reorder";
import ComponentLoader from "components/component-loader";
import { ToastContainer as ReactToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "components/toast";
import AnimatedCartButton from "components/animated-cart-button";

const StorePage = loadable(() => ComponentLoader(() => import("../store-page")));
const CustomContentPage = loadable(() => ComponentLoader(() => import("../custom-content-page")));
const OrderDetailPage = loadable(() => ComponentLoader(() => import("../order-detail-page")));
const SignInPage = loadable(() => ComponentLoader(() => import("../sign-in")));
const SignUpPage = loadable(() => ComponentLoader(() => import("../sign-up")));
const CheckoutPage = loadable(() => ComponentLoader(() => import("../checkout-page")));
const CheckoutSuccess = loadable(() => ComponentLoader(() => import("../checkout-success-page")));
const OrderListPage = loadable(() => ComponentLoader(() => import("../order-list-page")));
const PayNowPage = loadable(() => ComponentLoader(() => import("../pay-now-page")));

const PasswordReset = loadable(() => ComponentLoader(() => import("../password-reset")));
const EmailReset = loadable(() => ComponentLoader(() => import("../email-reset")));
const Notifications = loadable(() => ComponentLoader(() => import("../notifications")));
const Location = loadable(() => ComponentLoader(() => import("../location")));
const StorageAssistant = loadable(() => ComponentLoader(() => import("../storage-assitant")));
const WechatAutoLogin = loadable(() =>
    ComponentLoader(() => import("../../components/social-accounts/wechat-auto-login"))
);
const ErrorBoundary = loadable(() => ComponentLoader(() => import("../../components/error-boundary")));
const WechatShare = loadable(() => ComponentLoader(() => import("../wechat-plugin")));
const RefCode = loadable(() => ComponentLoader(() => import("../../components/ref-code")));

const PrivacyNoticePage = loadable(() => ComponentLoader(() => import("../privacy-notice")));
const ConditionsPage = loadable(() => ComponentLoader(() => import("../conditions-of-use")));
const SiteMapIndexPage = loadable(() => ComponentLoader(() => import("../sitemap-index-page")));
const AccountPage = loadable(() => ComponentLoader(() => import("../account-page")));
const GiftCardPage = loadable(() => ComponentLoader(() => import("../gift-card-page")));
const MyReferralsPage = loadable(() => ComponentLoader(() => import("../referral-page")));
const PublicRoute = loadable(() => ComponentLoader(() => import("../../components/public-route")));
const ProductPage = loadable(() => ComponentLoader(() => import("../product-page")));
const AddressBookPage = loadable(() => ComponentLoader(() => import("../address-book-page")));
const SelfCheckoutPage = loadable(() => ComponentLoader(() => import("../self-checkout-page")));
const GroupProductPage = loadable(() => ComponentLoader(() => import("../group-product-page")));
const RedirectPage = loadable(() => ComponentLoader(() => import("../redirect-page")));
const AccountPointPage = loadable(() => ComponentLoader(() => import("../account-point-page")));
const AccountPageEdit = loadable(() => ComponentLoader(() => import("../account-page-edit")));
const BindPhonePage = loadable(() => ComponentLoader(() => import("../bind-phone-page")));
const SetPasswordPage = loadable(() => ComponentLoader(() => import("../set-password-page")));
const BookmarkPage = loadable(() => ComponentLoader(() => import("../bookmark-page")));
const AccountSettingsPage = loadable(() => ComponentLoader(() => import("../account-settings-page")));
const AccountDeletePage = loadable(() => ComponentLoader(() => import("../account-delete-page")));
const WriteReviewSection = loadable(() => ComponentLoader(() => import("../order-detail-review-page/index")));
const WriteProductReview = loadable(() => ComponentLoader(() => import("../product-review-page")));
const Homepage = loadable(() => ComponentLoader(() => import("../homepage")));
const PayPalPage = loadable(() => ComponentLoader(() => import("../paypal-page")));
const AccountRecentlyViewedPage = loadable(() => ComponentLoader(() => import("../account-recently-viewed-page")));
const NotFoundPage = loadable(() => ComponentLoader(() => import("../404-page")));
const QRScannerPage = loadable(() => ComponentLoader(() => import("../qr-scanner")));
const FingerprintPage = loadable(() => ComponentLoader(() => import("../fingerprint-page")));
const GlobalErrorHandlingDialog = loadable(() =>
    ComponentLoader(() => import("../../components/global-error-handling-dialog"))
);
const ReviewThankYouPage = loadable(() => ComponentLoader(() => import("../review-pages/review-thankyou")));
const ReviewPage = loadable(() => ComponentLoader(() => import("../review-pages/review-page")));
const SMSVerificationPage = loadable(() => ComponentLoader(() => import("../sms-verification-page")));

const routes = [
    {
        path: "/",
        key: "home-page",
        subPaths: ["branch", "tb", "pid"],
        private: false,
        component: helper.getIndependentDomain() ? StorePage : Homepage,
    },
    {
        path: "/account/orders/detail/:orderId",
        key: "order-details",
        private: true,
        component: OrderDetailPage,
    },
    {
        path: "/account/orders/detail/:orderId/review",
        key: "order-details-review",
        private: true,
        component: WriteReviewSection,
    },
    {
        path: "/account/orders",
        key: "orders",
        private: true,
        component: OrderListPage,
    },
    {
        path: "/account",
        key: "account",
        private: true,
        component: AccountPage,
    },
    {
        path: "/account/edit",
        key: "account-edit",
        private: true,
        component: AccountPageEdit,
    },
    {
        path: "/account/edit/:field",
        key: "account-edit-input",
        private: true,
        component: AccountPageEdit,
    },
    {
        path: "/account/onboard/:field",
        key: "account-onboard-input",
        private: true,
        component: AccountPageEdit,
    },
    {
        path: "/account/points",
        key: "account-points",
        private: true,
        component: AccountPointPage,
    },
    {
        path: "/account/settings",
        key: "account-settings",
        private: true,
        component: AccountSettingsPage,
    },
    {
        path: "/account/delete",
        key: "account-delete",
        private: true,
        component: AccountDeletePage,
    },
    {
        path: "/account/settings/:field",
        key: "account-settings",
        private: true,
        component: AccountSettingsPage,
    },
    {
        path: "/account/address-book",
        key: "address-book",
        private: true,
        component: AddressBookPage,
    },
    {
        path: "/account/recent",
        key: "recent",
        private: true,
        component: AccountRecentlyViewedPage,
    },
    {
        path: "/account/bookmarks",
        key: "bookmarks",
        private: true,
        component: BookmarkPage,
    },
    {
        path: "/account/gift-cards",
        key: "gift-cards",
        private: true,
        component: GiftCardPage,
    },
    {
        path: "/account/my-referrals",
        key: "my-referrals",
        private: true,
        component: MyReferralsPage,
    },
    {
        path: "/store/:gid",
        key: "store",
        subPaths: ["branch", "tb", "pid"],
        private: false,
        component: StorePage,
    },
    {
        path: "/product/:pid",
        key: "product",
        private: false,
        component: ProductPage,
    },
    {
        path: "/product/:pid/review",
        key: "product-review",
        private: true,
        component: WriteProductReview,
    },
    {
        path: "/gsale/:id",
        key: "group_purchase",
        subPaths: ["pid"],
        private: false,
        component: GroupProductPage,
    },
    {
        path: "/sign-in",
        key: "sign-in",
        component: SignInPage,
    },
    {
        path: "/sign-up",
        key: "sign-up",
        public: true,
        component: SignUpPage,
    },
    {
        path: "/phone-verification",
        key: "phone-verification",
        component: SMSVerificationPage,
    },
    {
        path: "/reset-password",
        key: "reset-password",
        private: false,
        component: PasswordReset,
    },
    {
        path: "/email-verification",
        key: "email-verification",
        private: false,
        component: EmailReset,
    },
    {
        path: "/checkout",
        key: "checkout",
        private: false,
        component: CheckoutPage,
    },
    {
        path: "/checkout/success",
        key: "checkout-success",
        private: false,
        component: CheckoutSuccess,
    },
    {
        path: "/paynow",
        key: "paynow",
        private: true,
        component: PayNowPage,
    },
    {
        path: "/common/privacy_policy",
        key: "privacy",
        private: false,
        component: PrivacyNoticePage,
    },
    {
        path: "/common/conditions_of_use",
        key: "terms",
        private: false,
        component: ConditionsPage,
    },
    {
        path: "/page/:url",
        key: "content-page",
        private: false,
        component: helper.getIndependentDomain() ? CustomContentPage : NotFoundPage,
    },
    {
        path: "/store/:gid/page/:url",
        key: "content-page",
        private: false,
        component: helper.getIndependentDomain() ? NotFoundPage : CustomContentPage,
    },
    {
        path: "/sitemap.html",
        key: "sitemap-independent",
        component: SiteMapIndexPage,
    },
    {
        path: "/store/:gid/sitemap.html",
        key: "sitemap-independent",
        component: SiteMapIndexPage,
    },
    {
        path: "/pay",
        key: "selfCheckoutDirect",
        private: false,
        component: SelfCheckoutPage,
    },
    {
        path: "/pay/:gid",
        key: "selfCheckout",
        private: false,
        component: SelfCheckoutPage,
    },
    {
        path: "/redirect",
        key: "redirect",
        component: RedirectPage,
    },
    {
        path: "/bind-phone",
        key: "bindPhone",
        private: true,
        component: BindPhonePage,
    },
    {
        path: "/set-password",
        key: "setPassword",
        private: false,
        component: SetPasswordPage,
    },
    {
        path: "/paypal",
        key: "paypal",
        private: true,
        component: PayPalPage,
    },

    {
        path: "/re-order",
        key: "re-order",
        private: true,
        component: ReOrder,
    },
    {
        path: "/qr-scanner",
        key: "qr-scanner",
        component: QRScannerPage,
    },
    {
        path: "/fingerprint",
        key: "fingerprint",
        component: FingerprintPage,
    },
    {
        path: "/r/:hashcode",
        key: "review-page",
        component: ReviewPage,
    },
    {
        path: "/r/:hashcode/thank-you",
        key: "review-thank-you-page",
        component: ReviewThankYouPage,
    },
    {
        path: "*",
        key: "404",
        component: NotFoundPage,
        status: 404,
    },
];

const TOAST_MESSAGE_DISPLAY_DURATION = 5000;

class AppContent extends React.Component {
    getSinglePathComponent = (route, subPath = "") => {
        const { lan = "en" } = this.props;
        const RouteComponent = route.private ? PrivateRoute : route.public ? PublicRoute : Route;
        return (
            <RouteComponent
                key={`${route.key}${subPath ? `-${subPath}` : ""}-${lan}`}
                exact
                path={`${route.path}${subPath}`}
                component={route.component}
                status={route.status ?? 200}
            />
        );
    };

    getMultiPathComponent = (route) => {
        const possibleSubsPaths = helper.generateSubPaths(route.subPaths, {
            withFirstSlash: route.path !== "/",
        });
        const allComponents = possibleSubsPaths.map((path) => {
            return this.getSinglePathComponent(route, path);
        });
        return allComponents;
    };

    getRouteComponents = () => {
        let results = [];
        routes.forEach((route) => {
            if (!_.isEmpty(route.subPaths)) {
                results = results.concat(this.getMultiPathComponent(route));
            }
            results.push(this.getSinglePathComponent(route));
        });
        return results;
    };

    render() {
        const { lan = "en" } = this.props;
        return (
            <IntlProvider locale={lan} messages={locale.getIntlMessages(lan)}>
                <ErrorBoundary key={_.get(this.props, "location.pathname")}>
                    <div className="goopter-app-content">
                        <WechatAutoLogin>
                            <Switch>{this.getRouteComponents()}</Switch>
                            <Notifications />
                            <Location />
                            <WechatShare />
                            <RefCode />
                            <StorageAssistant />
                        </WechatAutoLogin>
                    </div>
                </ErrorBoundary>
                <GlobalErrorHandlingDialog />
                <AnimatedCartButton />
                <ReactToast
                    position="top-center"
                    autoClose={TOAST_MESSAGE_DISPLAY_DURATION}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toast-container"
                />
                <Toast />
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: state?.settings?.lan ?? "en",
    isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContent));
