export const DESKTOP_WIDTH = 1024; // Width to switch to Desktop Version
export const MATERIAL_UI_XS = 585;
export const MATERIAL_UI_SM = 960;
export const MATERIAL_UI_MD = 1280;

export const PHONE_INPUT_STEP = 1;
export const PHONE_VERIFICATION_STEP = 2;

export const PHONE_VERIFICATION_CODE_LEN = 4;

export const DEFAULT_MAX_DELIVERY_DISTANCE_METER = 50000;

export const GOOPTER_APP_CONTENT_MAX_WIDTH = 1440;

export const DEFAULT_CURRENCY_CODE = "CAD";
export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_COUNTRY = "CA";

export const MOBILE_SCREEN_THRESHOLD = 425;
export const SMALL_SCREEN_THRESHOLD = 1024;
export const LARGE_SCREEN_THRESHOLD = 1100;
export const TOTAL_MINUTES_IN_A_DAY = 1440;
export const ASAP = "ASAP";

export const LOCAL_EMAIL_KEY = "loginEmail";
export const LOCAL_PHONE_KEY = "loginPhone";
export const LOCAL_PHONE_COUNTRY_KEY = "loginPhoneCountry";
