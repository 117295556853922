const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    reviewBoost: {
        auth_code: "",
        gid: 0,
        lan: "",
        order_id: "",
        rating: "",
        review_hint: "",
        review_msg: "",
        status: "",
        store_name: "",
        update_dt: "",
        user_id: "",
    },
    reviewValue: -1,
    reviewMsg: "",
    socialUrl: "",
};

const review = createSlice({
    name: "reviewBooster",
    initialState,
    reducers: {
        setReviewBoost: (state, { payload }) => {
            state.reviewBoost = payload;
        },
        setReviewValue: (state, { payload }) => {
            state.reviewValue = payload;
        },
        setReviewMsg: (state, { payload }) => {
            state.reviewMsg = payload;
        },
        setSocialUrl: (state, { payload }) => {
            state.socialUrl = payload;
        },
    },
});

export const { setReviewBoost, setReviewValue, setReviewMsg, setSocialUrl } = review.actions;

export default review.reducer;
