import { createSlice } from "@reduxjs/toolkit";

const reducers = {
    setState: (state, { payload }) => {
        state = Object.assign(state, payload);
    },
    getBookmarksSuccess: (state, { payload = {} }) => {
        state.product = payload.product;
        state.store = payload.store;
        state.groupsale = payload.groupsale;

        if (Array.isArray(payload.products)) {
            payload.products.forEach((item) => {
                state.products[item.pid] = item;
            });
        }

        if (Array.isArray(payload.groupsales)) {
            payload.groupsales.forEach((item) => {
                state.groupsales[item.pid] = item;
            });
        }

        if (Array.isArray(payload.stores)) {
            payload.stores.forEach((item) => {
                state.stores[item.gid] = item;
            });
        }
    },
};

const initialState = {
    product: [],
    store: [],
    groupsale: [],
    stores: {},
    products: {},
    groupsales: {},
    loading: false,
    tab: 0,
};

const bookmark = createSlice({
    name: "bookmark",
    initialState,
    reducers,
});

export const { setState, getBookmarksSuccess } = bookmark.actions;

export default bookmark.reducer;
