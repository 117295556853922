import { createSlice } from "@reduxjs/toolkit";

const errorHandlingDialog = createSlice({
    name: "errorHandlingDialog",
    initialState: {
        displayModal: false,
        displayModalTitle: "error",
        displayModalContent: "",
        displayModalKeywords: {},
        displayModalAllowCancel: false,
        displayCallBack: "",
    },
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        createErrorDialog: (state, { payload }) => {
            state.displayModal = true;
            state.displayModalTitle = payload.title;
            state.displayModalContent = payload.message;
            state.displayModalKeywords = payload.keywords;
            state.displayModalAllowCancel = payload.allowCancel;
            state.displayCallBack = payload.callBack;
        },
    },
});

export const { setState, createErrorDialog } = errorHandlingDialog.actions;

export default errorHandlingDialog.reducer;
