import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userLatLon: null,
    latLngDisplay: { lat: 40.73, lng: -73.93 },
};

const location = createSlice({
    name: "location",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
    },
});

export const {
    //to components
    setState,
    //to service
    clearData,
} = location.actions;

export default location.reducer;
