import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const adapter = createEntityAdapter({
    selectId: (item) => item.gid,
    sortComparer: (a, b) => {
        return new Date(b.date) - new Date(a.date);
    },
});

export const storeRecentlyViewedSelector = adapter.getSelectors();

const storeRecentlyViewedSlice = createSlice({
    name: "storeRecentlyViewed",
    initialState: adapter.getInitialState(),
    reducers: {
        addRecentlyViewedStore: (state, { payload }) => {
            const count = storeRecentlyViewedSelector.selectTotal(state);
            const all = storeRecentlyViewedSelector.selectIds(state);

            if (!all.includes(payload?.records?.store_dtl?.gid)) {
                if (count > 14) {
                    adapter.removeOne(state, all[all.length - 1]);
                }

                adapter.addOne(state, {
                    gid: payload?.records?.store_dtl?.gid,
                    nm: payload?.records?.store_dtl?.nm,
                    mt: payload?.records?.store_dtl?.mt,
                    logo: payload?.records?.store_dtl?.logo,
                    rnk: payload?.records?.store_dtl?.rnk,
                    lat: payload?.records?.store_dtl?.adr?.lat,
                    lon: payload?.records?.store_dtl?.adr?.lon,
                    cid: payload?.records?.store_dtl?.cids?.[0],
                    date: new Date(),
                });
            }
        },
        removeRecentlyViewedStore: (state, { payload }) => {
            adapter.removeOne(state, payload);
        },
    },
});

export const { addRecentlyViewedStore, removeRecentlyViewedStore } = storeRecentlyViewedSlice.actions;

export default storeRecentlyViewedSlice.reducer;
