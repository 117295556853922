import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { removeDuplicatId } from "utils/helper";

const initialState = {
    customer: {
        user_id: null,
        first_name: "",
        last_name: "",
        nick_name: "",
        email: "",
        phone: "",
        phone_country_code: "",
        currency: "",
        country: "",
        points: null,
        type: null,
        is_confirm: null,
        is_password_set: null,
        is_text_msg_login: false,
        is_social_login: false,
        defaultAddress: null,
        has_payment_password: null,
        bookmark: {
            store: [],
            groupsale: [],
            product: [],
        },
        num_gift_cards: null,
    },
    loading: false,
    giftCards: [],
    giftCard: null,
    currentGiftCard: null,
    myReferralStores: [],
    myReferralInfo: {},
    showRefFilterDrawer: false,
    refToDate: undefined,
    refFromDate: undefined,
    refCode: undefined,
    currRefStore: {
        name: undefined,
        gid: undefined,
        customerRefPoints: undefined,
        orderRefPoints: undefined,
        pointsToValue: undefined,
    },
    customerReferralInfo: [],
    addresses: [],
    currentEditAddress: {},
    points: {
        list: [],
        details: [],
        listPaging: {},
        detailsPaging: {},
    },
};

const account = createSlice({
    name: "account",
    initialState,
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        getCustomerInfoSuccess: (state, { payload }) => {
            state.customer = { is_text_msg_login: state?.customer?.is_text_msg_login, ...payload };
            state.refreshCustomer = false;
        },
        getMyReferralStoresSuccess: (state, { payload }) => {
            state.myReferralStores = payload;
            if (Array.isArray(payload) && !_.isEmpty(payload)) {
                state.currRefStore.name = payload[0].name;
                state.currRefStore.gid = payload[0].gid;
            }
        },
        getReferralFlagsSuccess: (state, { payload }) => {
            if (String(payload?.gid) === String(state?.currRefStore?.gid)) {
                state.currRefStore.customerRefPoints = payload?.customerRefPoints;
                state.currRefStore.orderRefPoints = payload?.orderRefPoints;
                state.currRefStore.pointsToValue = payload?.pointsToValue;
            } else {
                state.currRefStore.customerRefPoints = undefined;
                state.currRefStore.pointsToValue = undefined;
            }
        },
        getRefCodeSuccess: (state, { payload }) => {
            state.refCode = payload;
        },
        getCustomerReferralSuccess: (state, { payload }) => {
            state.customerReferralInfo = payload;
        },
        getMyReferralInfoSuccess: (state, { payload }) => {
            state.myReferralInfo = payload;
        },
        getGiftCardsSuccess: (state, { payload }) => {
            state.giftCards = payload;
        },
        getGiftCardHistorySuccess: (state, { payload }) => {
            state.giftCard = payload.history;
            state.currentGiftCard = payload.card;
        },
        closeGiftCardHistory: (state) => {
            state.giftCard = null;
            state.currentGiftCard = null;
        },
        getPointsListSuccess: (state, { payload }) => {
            state.points.list = removeDuplicatId([...state.points.list, ...payload.records], "website_id");
            state.points.listPaging = payload.paging;
        },
        clearPointsDetailsList: (state) => {
            state.points.details = [];
        },
        getPointsDetailsListSuccess: (state, { payload }) => {
            state.points.details = removeDuplicatId([...state.points.details, ...payload.records], "history_id");
            state.points.detailsPaging = payload.paging;
        },
        setLoginViaTextMessage: (state) => {
            state.customer.is_text_msg_login = true;
        },
    },
});

export const {
    setState,
    clearData,
    getCustomerReferralSuccess,
    getMyReferralStoresSuccess,
    getReferralFlagsSuccess,
    getMyReferralInfoSuccess,
    getCustomerInfoSuccess,
    getGiftCardsSuccess,
    getGiftCardHistorySuccess,
    closeGiftCardHistory,
    getPointsListSuccess,
    getPointsDetailsListSuccess,
    clearPointsDetailsList,
    getRefCodeSuccess,
    setLoginViaTextMessage,
} = account.actions;

export default account.reducer;
