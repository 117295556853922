import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const adapter = createEntityAdapter({
    selectId: (item) => item.pid,
    sortComparer: (a, b) => {
        return new Date(b.date) - new Date(a.date);
    },
});

export const productRecentlyViewedSelector = adapter.getSelectors();

const productRecentlyViewedSlice = createSlice({
    name: "productRecentlyViewed",
    initialState: adapter.getInitialState(),
    reducers: {
        addRecentlyViewedProduct: (state, { payload }) => {
            const count = productRecentlyViewedSelector.selectTotal(state);
            const all = productRecentlyViewedSelector.selectIds(state);

            if (!all.includes(payload?.records?.product?.pid)) {
                if (count > 14) {
                    adapter.removeOne(state, all[all.length - 1]);
                }
                adapter.addOne(state, {
                    pid: payload?.records?.product?.pid ?? payload?.records?.id,
                    nm: payload?.records?.product?.nm ?? payload?.records?.nm,
                    logo: payload?.records?.product?.product_detail?.imgs?.[0] ?? payload?.records?.imgs?.[0],
                    rnk: payload?.records?.product?.rat ?? payload?.records?.ratings?.rating,
                    cid: payload?.records?.store?.cids?.[0],
                    date: new Date(),
                });
            }
        },
        removeRecentlyViewedProduct: (state, { payload }) => {
            adapter.removeOne(state, payload);
        },
    },
});

export const { addRecentlyViewedProduct, removeRecentlyViewedProduct } = productRecentlyViewedSlice.actions;

export default productRecentlyViewedSlice.reducer;
