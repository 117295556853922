import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import helper from "utils/helper";
import { getUserPhone } from "../account-page/helper";
import { PHONE_INPUT_STEP, PHONE_VERIFICATION_STEP } from "../app/constants";

const initialState = {
    phone: "",
    isPasswordSet: false,
    shouldShowLogoutButton: false,
    country: "",
    counter: 0,
    currentStep: PHONE_INPUT_STEP,
    vc: "",
    isUserProfileLoaded: false,
    showError: false,
};

const bindPhone = createSlice({
    name: "bindPhone",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        getUserProfileSuccess: (state, { payload }) => {
            state = Object.assign(state, initialState);
            const customerData = _.get(payload, "userProfileResponse.records", {});
            const config = _.get(payload, "config", {});
            state.isPasswordSet = customerData.is_password_set;
            state.phone = getUserPhone(customerData);
            state.country = _.get(config, "country", "CA");
            state.isUserProfileLoaded = true;
        },
        getShowErrorSuccess: (state, { payload }) => {
            state.showError = payload;
        },
        getVerificationCodeSuccess: (state, { payload }) => {
            const RC = _.get(payload, "getVCResponse.RC", {});
            switch (RC) {
                case 200:
                    state.counter = 60;
                    state.currentStep = PHONE_VERIFICATION_STEP;
                    break;
                case 515:
                    state.displayModal = true;
                    state.displayModalTitle = "alert_bind_phone_conflict_title";
                    state.displayModalContent = "alert_bind_phone_conflict";
                    state.displayModalHandlingType = "mergeAccount";
                    state.displayModalAllowCancel = true;
                    break;
                case 486:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "invalid_phone";
                    break;
                case 400:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_invalid_request";
                    break;
                case 429:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_too_many_request";
                    break;
                case 452:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_bind_phone_phone_exists";
                    state.shouldShowLogoutButton = true;
                    state.phone = "";
                    break;
                case 516:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "cannot_merge_account";
                    break;
                case 517:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "duplicate_social_error";
                    break;
                default:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "general_error";
                    break;
            }
        },
        updatePhoneSuccess: (state, { payload }) => {
            const RC = _.get(payload, "updatePhoneResponse.RC", {});

            switch (RC) {
                case 200:
                    state.bindSuccess = true;
                    state.counter = 0;
                    break;
                case 204:
                    state.bindSuccess = true;
                    state.counter = 0;
                    const accessToken = _.get(payload, "updatePhoneResponse.token", "");
                    const accessSecret = _.get(payload, "updatePhoneResponse.secret", "");
                    if (accessToken && accessSecret) {
                        helper.setLocalStorage("accessToken", accessToken);
                        helper.setLocalStorage("accessSecret", accessSecret);
                    }
                    break;
                case 400:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_invalid_request";
                    break;
                case 408:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_vc_expired";
                    break;
                case 429:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_too_many_request";
                    break;
                case 452:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_phone_exists";
                    break;
                case 483:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "alert_phone_vc_not_match";
                    break;
                case 516:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "cannot_merge_account";
                    break;
                default:
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "general_error";
                    break;
            }
        },
    },
});

export const {
    //to components
    setState,
    clearData,
    //to service
    getUserProfileSuccess,
    getVerificationCodeSuccess,
    updatePhoneSuccess,
    getShowErrorSuccess,
} = bindPhone.actions;

export default bindPhone.reducer;
