import config from "config";
import _ from "lodash";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-hk";
import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import {
    ASAP,
    LARGE_SCREEN_THRESHOLD,
    MOBILE_SCREEN_THRESHOLD,
    SMALL_SCREEN_THRESHOLD,
    TOTAL_MINUTES_IN_A_DAY,
} from "../../containers/app/constants";
import { goBack, push } from "../../containers/app/history";
import { AVAILABLE_LANS } from "../../containers/settings/helper";
import { MAX_ITEMS_OPTION_VALUE } from "../../containers/shopping-cart-page/helpers";
import {
    allowMulitpleDelivery,
    allowMultiplePickup,
    getMenuAvaibilityInfo,
    getMuplipleMinDeliveryAmount,
    getMuplipleMinPickupAmount,
    isMenuAvailableNow,
} from "../../containers/store-page/helper";
import locale, { getIntl } from "../../locale";
import countryData from "../data/countryList.json";
import Store from "utils/model/store";

const APP_LAN_MOMENT_LAN_MAP = {
    zh: "zh-cn",
    en: "en",
    "zh-Hant": "zh-hk",
    jp: "jp",
    es: "es",
    "fr-lang": "fr",
};

function getLocalStorage(key) {
    let result;
    try {
        result = localStorage.getItem(key);
        return JSON.parse(result);
    } catch (e) {
        removeLocalStorage(key);
        return false;
    }
}

function setLocalStorage(key, value, triggerEvent = true) {
    try {
        value = JSON.stringify(value);
        localStorage.setItem(key, value);

        //fire event to notified
        if (triggerEvent) triggerCloudStorageEvent(key, value);
        return true;
    } catch (e) {
        return false;
    }
}

function removeLocalStorage(key) {
    try {
        localStorage.removeItem(key);
        triggerCloudStorageEvent(key, "");
        return true;
    } catch (e) {
        return false;
    }
}

function triggerCloudStorageEvent(key, value) {
    const obj = document.getElementById("storage-assitant");
    obj.dispatchEvent(
        new CustomEvent("storage-assitant", {
            detail: {
                key,
                value,
            },
        })
    );
}

function isNumeric(val) {
    return Number(parseFloat(val)) === val;
}

export function getImageUrl(image, extraUrl = "") {
    return config.IMG_PREFIX + `f_auto,fl_lossy,q_auto,w_350,h_350,c_limit,b_auto,c_fill_pad,g_auto${extraUrl}/` + image;
}

export function getImageUrlCustom(image, width, height) {
    return config.IMG_PREFIX + `f_auto,fl_lossy,q_auto,w_${width},h_${height},c_limit,c_fit/` + image;
}

function getImageUrl1024(image) {
    return config.IMG_PREFIX + "f_auto,fl_lossy,q_auto,w_1024,h_256,c_limit,c_fit/" + image;
}

export function getImageUrlCarousel(image) {
    return config.IMG_PREFIX + "f_auto,fl_lossy,b_white,c_pad,q_auto,w_1024,h_824/" + image;
}

export function getImageUrlOriginal(image) {
    return config.IMG_PREFIX + image;
}

function getImageUrlInternal(image) {
    return config.WEBSITE_ROOT + "/images?path=" + image;
}

function getTransString(stringMap, lan = "en") {
    if (!stringMap) return;
    if (typeof stringMap === "string") return stringMap;
    let returnStr = "";

    if (stringMap[lan]) {
        returnStr = stringMap[lan];
    } else if (lan === "zh" && stringMap["zh-Hant"]) {
        returnStr = stringMap["zh-Hant"];
    } else if (lan === "zh-Hant" && stringMap["zh"]) {
        returnStr = stringMap["zh"];
    } else if (stringMap["en"]) {
        returnStr = stringMap["en"];
    } else if (Object.keys(stringMap) && Object.keys(stringMap).length) {
        returnStr = stringMap[Object.keys(stringMap)[0]];
    } else {
        return "";
    }

    return returnStr.trim();
}

function formatAddress(
    addressObj,
    options = {
        without: [],
        unitOnStreet: false,
    }
) {
    let address = "";
    if ((addressObj = _.omit(addressObj, options.without))) {
        const { buzz, unit, street, city, region, cntry, country, zipcd, postal_code } = addressObj;
        const unitCode = unit ? "#" + unit : "";
        const buzzCode = buzz ? "Buzz# " + buzz : "";
        const unitDisplay = options.unitOnStreet ? "" : unitCode;
        const streetDisplay = options.unitOnStreet && unitCode && street ? `${unitCode}-${street}` : street;
        const adrArray = _.compact([
            streetDisplay,
            unitDisplay,
            city,
            region,
            cntry,
            country,
            zipcd,
            postal_code,
            buzzCode,
        ]);
        address = adrArray.join(", ");
    }
    return address;
}

function formatPhoneNumber(phone) {
    let phoneStr = "";
    if (phone) {
        phone = String(phone);
        phoneStr = phone;
        phone = phone?.replace?.(/[^\d]/g, "");
        let country = countryData.records.find((country) => {
            let reg = new RegExp(country.phone_format);
            return phone.match(reg);
        });
        if (country) {
            if (country.country_code === "CA" || country.country_code === "US") {
                phoneStr = phone?.replace?.(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            } else if (country.country_code === "CN") {
                phoneStr = phone?.replace?.(/(\d{3})(\d{4})(\d{4})/, "($1) $2-$3");
            } else {
                phoneStr = phone;
            }
        }
    }
    if (isString(phoneStr) && _.cloneDeep(phoneStr).replace(/0/g, "")?.length === 0) {
        return "";
    }
    return phoneStr ? phoneStr : phone;
}

export function formatCurrency(amount, currency = "CAD", prefix = "", withSymbol = true) {
    let symbol = currency ? config.CURRENCY_SYMBOL[currency] : config.CURRENCY_SYMBOL["CAD"];
    const isNAN = _.isNaN(parseFloat(amount));
    if (!isNAN) {
        // regex adds comma every third number
        const number = parseFloat(Math.round(Math.abs(amount) * 100) / 100)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        return `${prefix}${withSymbol ? symbol : ""}${number}`;
    } else {
        return false;
    }
}

// function adds comma every third number using regular expression
function formatNumber(number) {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getIndependentDomain() {
    const currentDomain = document.location.host;
    for (const domain of ["goopter.com", "goopter.cn", "localhost", "192.168"]) {
        if (currentDomain.includes(domain)) return null;
    }
    return currentDomain;
}

function getUrlParameters() {
    var a = window.location.search.substr(1).split("&");
    if (String(a) === "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split("=", 2);
        if (p.length !== 2) continue;
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
}

function cleanPhoneNumber(phone, countryCode = "CA") {
    if (phone && _.isString(phone)) {
        countryData.records.forEach((country) => {
            if (country.country_code === countryCode) {
                let areaCode = country.area_code;
                //removes (), - and white space
                phone = phone?.replace?.(/[^\d]/g, "");
                //checks if the leading number is the same with the area_code
                let lengthAreaCode = areaCode.length;
                if (phone.substring(0, lengthAreaCode) === areaCode) {
                    phone = phone.substring(lengthAreaCode);
                }
            }
        });
    }
    return phone;
}

function checkPhonePattern(phone, code = "CA") {
    let result = "";
    countryData.records.forEach((country) => {
        if (country.country_code === code) {
            result = country.phone_format;
        }
    });
    let reg = new RegExp(result);
    return reg.test(phone);
}

function getExpectedTimeFullStr(startDate, endDate) {
    const SERVER_FORMAT = "YYYY-MM-DD HH:mm:ss";
    const TIME_FORMAT = "hh:mm A";
    const DATE_FORMAT = "ddd, MMM DD";
    const getCalenderFormat = () => {
        const string = (id) => getIntl().formatMessage({ id: id || " " });
        const formats = {
            nextDay: `[${string("tomorrow")}], ${DATE_FORMAT}`,
            sameDay: `[${string("today")}], ${DATE_FORMAT}`,
            lastDay: `[${string("yesterday")}], ${DATE_FORMAT}`,
            sameWeek: DATE_FORMAT,
            lastWeek: DATE_FORMAT,
            nextWeek: DATE_FORMAT,
            sameYear: DATE_FORMAT,
            sameElse: DATE_FORMAT,
        };
        return formats;
    };

    if (startDate && startDate.includes("2000-01-01 00")) {
        return ["ASAP"];
    }

    const startMoment = moment(startDate, SERVER_FORMAT);
    const endMomment = moment(endDate, SERVER_FORMAT);

    if (startMoment.isValid() && endMomment.isValid()) {
        const startDate = startMoment.calendar(null, getCalenderFormat());
        const endDate = endMomment.calendar(null, getCalenderFormat());
        const startTime = startMoment.format(TIME_FORMAT);
        const endTime = endMomment.format(TIME_FORMAT);
        if (startDate === endDate) {
            return [`${startDate}`, `${startTime}~${endTime}`];
        } else {
            return [`${startDate}, ${startTime}`, `~${endDate}, ${endTime}`];
        }
    }
    return ["ASAP"];
}

function utcToLocal(time = "", params = {}) {
    let defaultParams = {
        type: "short",
        time: true,
        date: true,
        asap: false,
        local: true,
        dateTimeSeperator: ",",
        timeFormat: "HH:mm",
    };
    const rParams = Object.assign({}, defaultParams, params);

    if (time && (typeof time === "string" || time instanceof String)) {
        // "zh": 'zh_cn',
        // "en": "en",
        // "zh-Hant": "zh_hk",
        // "kr": "kr",
        const momentLan = moment.locale();
        const isChinese = momentLan.includes("zh");
        const appLan = Object.keys(APP_LAN_MOMENT_LAN_MAP).find((key) => APP_LAN_MOMENT_LAN_MAP[key] === momentLan);

        if (rParams.asap) {
            if (time.includes("2000-01-01") || time.toUpperCase().includes("ASAP")) {
                return "ASAP";
            }
        }

        let revisedTime = time;
        if (rParams.local) {
            revisedTime = time.includes("UTC") ? time : `${time} UTC`;
            revisedTime = revisedTime.replace(/-/g, "/");
        }

        let todayDate = moment().date();
        let todayMonth = moment().month();
        let todayYear = moment().year();

        let parsedDate = moment(revisedTime).date();
        let parsedMonth = moment(revisedTime).month();
        let parsedYear = moment(revisedTime).year();

        let sameDate = todayDate === parsedDate && todayMonth === parsedMonth && todayYear === parsedYear;
        //let sameMonth = todayMonth === parsedMonth && todayYear === parsedYear;
        let sameYear = todayYear === parsedYear;
        // let sameHour = false;
        // if (sameDate) {
        //     let duration = moment.duration(moment().diff(moment(revisedTime)));
        //     sameHour = duration.asHours() < 1;
        // }

        let dateFormat;
        if (rParams.date) {
            switch (rParams.type) {
                case "short":
                    dateFormat = sameYear ? "MMM D" : "MMM D, YYYY";
                    break;
                case "medium":
                    dateFormat = "MMMM D, YYYY";
                    break;
                case "long":
                    dateFormat = "MMMM Do YYYY";
                    break;
                case "shortest":
                    dateFormat = sameDate ? "" : sameYear ? "MMM DD" : "MMM DD YYYY";
                    break;
                case "YYYY-MM-DD":
                    dateFormat = "YYYY-MM-DD";
                    break;
                case "YYYY-MMM-DD":
                    dateFormat = "YYYY-MMM-DD";
                    break;
                case "date-only":
                    dateFormat = "DD";
                    break;
                case "calendar":
                    const format = isChinese ? "MMMDo" : "MMM Do";
                    const time = rParams.local ? moment(revisedTime).local() : moment(revisedTime);
                    const string = (id) => locale.getIntlMessages(appLan)[id];
                    const timeFormat = rParams.timeFormat ? rParams.timeFormat : "hh:mm A";
                    const formats = {
                        nextDay: `[${string("tomorrow")} @] ${timeFormat}`,
                        sameDay: `[${string("today")} @] ${timeFormat}`,
                        lastDay: `[${string("yesterday")} @] ${timeFormat}`,
                        sameWeek: `${format} [@] ${timeFormat}`,
                        lastWeek: `${format} [@] ${timeFormat}`,
                        sameYear: `${format} [@] ${timeFormat}`,
                        sameElse: `${format} [@] ${timeFormat}`,
                    };
                    return time.calendar(null, formats);
                default:
                    dateFormat = "MMM D YYYY";
            }
        }

        if (rParams.time) {
            const timeFormat = rParams.timeFormat;
            if (dateFormat) {
                dateFormat += `${rParams.dateTimeSeperator}${timeFormat}`;
            } else {
                dateFormat = timeFormat;
            }
        }

        if (rParams.format) {
            dateFormat = rParams.format;
        }

        return rParams.local ? moment(revisedTime).local().format(dateFormat) : moment(revisedTime).format(dateFormat);
    }
    return time;
}

function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .filter((key) => obj[key] !== "" && obj[key] !== null)
        .map((key, index) => {
            var startWith = index === 0 ? "?" : "&";
            return startWith + key + "=" + obj[key];
        })
        .join("");
}

export function getUserAgent() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i)) {
        return "weixin";
    } else if (ua.match(/QQ/i)) {
        return "qq";
    } else if (ua.match(/AlipayClient/i)) {
        return "alipay";
    } else if (ua.match(/iPhone|iPad/i)) {
        return "ios";
    } else if (ua.match(/Android|Blackberry|Opera Mini|IEMobile/i)) {
        return "mobile_non_ios";
    } else {
        return "";
    }
}

function isUsingAliPay() {
    return getUserAgent() === "alipay";
}

function isUsingWechat() {
    return getUserAgent() === "weixin";
}

function isUsingIos() {
    return getUserAgent() === "ios";
}

export const isUsingAppleDevice = () => {
    return (
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod", "MacIntel"].includes(
            navigator?.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator?.userAgent.includes("Mac") && "ontouchend" in document)
    );
};

function getAIOAgentName(pay_mtd = 1) {
    // device is desktop_web by default
    let agent = getUserAgent();
    switch (agent) {
        case "wechat":
        case "weixin":
            agent = "wechat";
            break;
        case "alipay":
            agent = "alipay";
            break;
        case "ios":
        case "mobile_non_ios":
            agent = "h5";
            break;
        default:
            agent = "desktop_web";
            break;
    }
    return agent;
}

function stringToCamelCase(str) {
    return str && str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

function upperCaseFirstLetter(str) {
    return str ? str[0].toUpperCase() + str.slice(1) : "";
}

function isValidPostal(postal, code = "CA") {
    let result = "";
    countryData.records.forEach((country) => {
        if (country.country_code === code) {
            result = country.zipcode_format;
        }
    });
    let reg = new RegExp(result);
    return reg.test(postal);
}

//check phone number with countries to confirm phone is valid
export function isValidPhone(phone) {
    const valid = countryData.records.find((country) => {
        const reg = new RegExp(country.phone_format);
        return reg.test(phone);
    });
    return !_.isEmpty(valid);
}

export const getPrice = (product) => {
    const productData = product?.data || product;
    if (!productData?.spc) {
        return productData?.pc;
    }

    const noSpecialStartEndDateSet = _.isNil(productData.edt) && _.isNil(productData.sdt);
    const now = moment();
    // arbitrarily descreasing/increasing the start/end date by 1 respectively here if the dates do not exist
    // because empty start date is treated as the any time in the past and empty end date is treated as any time in the future
    const start = productData?.sdt ? moment.utc(productData?.sdt).local() : moment().subtract(1, "days");
    const end = productData?.edt ? moment.utc(productData?.edt).local() : moment().add(1, "days");

    if (
        noSpecialStartEndDateSet ||
        now.isBetween(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"))
    ) {
        return productData?.spc;
    } else {
        return productData?.pc;
    }
};

const clipboard = (function (window, document, navigator) {
    var textArea, copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement("textArea");
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range, selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }

    copy = function (text, callback = () => {}) {
        createTextArea(text);
        selectText();
        copyToClipboard();
        callback();
    };

    return {
        copy,
    };
})(window, document, navigator);

export const getYoutubeVideoID = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);

    if (match && match[7].length === 11) {
        return match[7];
    }
};

export const isMobile = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const getDiffProperties = (a, b) => {
    return _.reduce(
        a,
        function (result, value, key) {
            return _.isEqual(value, b[key]) ? result : result.concat(key);
        },
        []
    );
};

export const getNavigatorLan = () => {
    return window.navigator.language.slice(0, 2);
};

export const getDefaultLan = () => {
    const availbleValues = AVAILABLE_LANS;
    const localLan = getLocalStorage("lan");
    const paramLan = _.get(getUrlParameters(), "lan", null);
    const agentRequiredLan = isUsingAliPay() ? "zh" : null;
    const naviLan = getNavigatorLan();

    let result = "en";

    if (availbleValues.includes(paramLan)) {
        result = paramLan;
        setLocalStorage("lan", paramLan);
    } else if (availbleValues.includes(localLan)) {
        result = localLan;
    } else if (availbleValues.includes(agentRequiredLan)) {
        result = agentRequiredLan;
    } else if (availbleValues.includes(naviLan)) {
        result = naviLan;
    }

    updateMoment(result);

    return result;
};

export const updateMoment = (result) => {
    const locale = APP_LAN_MOMENT_LAN_MAP[result] || "en";
    moment.locale(locale);
};

export const removeDuplicatId = (arr, key = "id") => {
    return arr.reduce((acc, current) => {
        const x = acc.find((item) => item[key] === current[key]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
};

//https://stackoverflow.com/questions/5752002/find-all-possible-subset-combos-in-an-array
export const generateSubPaths = (combos, params = { withFirstSlash: true }) => {
    function permutate(src, minLen, maxLen) {
        minLen = minLen - 1 || 0;
        maxLen = maxLen || src.length + 1;
        var Asource = src.slice(); // copy the original so we don't apply results to the original.

        var Aout = [];

        var minMax = function (arr) {
            var len = arr.length;
            if (len > minLen && len <= maxLen) {
                Aout.push(arr);
            }
        };

        var picker = function (arr, holder, collect) {
            if (holder.length) {
                collect.push(holder);
            }
            var len = arr.length;
            for (var i = 0; i < len; i++) {
                var arrcopy = arr.slice();
                var elem = arrcopy.splice(i, 1);
                var result = holder.concat(elem);
                minMax(result);
                if (len) {
                    picker(arrcopy, result, collect);
                } else {
                    collect.push(result);
                }
            }
        };

        picker(Asource, [], []);

        return Aout;
    }

    let result = [];
    permutate(combos, 0, combos.length).forEach((combination) => {
        let path = "";
        combination.map((com) => (path += `${path || params.withFirstSlash ? "/" : ""}${com}/:${com}`));
        result.push(path);
    });
    return result;
};

export const isString = (str) => {
    return (str && typeof str === "string") || str instanceof String;
};

export const isBoolean = (val) => {
    return typeof val === "boolean";
};

const getCategoryString = (c_id) => {
    return config["CATEGORIES_MAPPING"]["" + c_id] ?? config["CATEGORIES_MAPPING"]["1"];
};

/**
 * depending on the current content the user is viewing, return a corresponding url
 * The share feature should be available while the user is visiting the follow routes:
 * product page: domain/product/product_id
 * the group purchase product page: domain/gsale/product_id
 * the store page: domain/store/store_id
 *
 * after the route is determined, always add language referral code
 *
 * the domain can be an independent domain (ie. https://m.sushihut.ca/) or h5.goopter.com
 * In the case of an independent domain, the https://m.sushihut.ca/ points the same destination as https://h5.goopter.com/store/907
 * and the store/store_ID can be omit in routing
 *
 * @param props - the props passed in from a class component
 * @returns result - a string url linked to the content
 *
 */
export const getShareURL = (props) => {
    if (props?.QRURL) {
        return props.QRURL;
    }
    const { lan, type, groupPurchaseId, refCode } = props;
    const gid = props?.store?.gid || props?.store?.data?.group_id;
    const pid = props?.product?.data.pid;
    const gp_id = groupPurchaseId || this?.state?.gpId;

    let result = window.location.origin;

    if (type === "product") {
        result += `/product/${pid}`;
    } else if (type === "group-purchase") {
        result += `/gsale/${pid}`;
    } else if (getIndependentDomain()) {
        //do nothing
    } else if (gid) {
        result += `/store/${gid}`;
    }

    // always add the following 3 parameter if any of them exist
    if (lan) {
        result += `?lan=${lan}`;
    }
    if (gp_id) {
        result += `&gp_id=${gp_id}`;
    }
    if (refCode) {
        result += `&ref=${refCode}`;
    }

    return result;
};

//https://stackoverflow.com/questions/18515254/recursively-remove-null-values-from-javascript-object
//however I wanted to remove not only null values
// but also undefined, NaN, empty String, empty array and empty object values,
//recursively, by inspecting nested objects and also nested arrays.
export const cleanObj = (obj) => {
    return (function prune(current) {
        _.forOwn(current, function (value, key) {
            if (
                _.isUndefined(value) ||
                _.isNull(value) ||
                _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isObject(value) && _.isEmpty(prune(value)))
            ) {
                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) _.pull(current, undefined);

        return current;
    })(_.cloneDeep(obj));
};

export const to24Hours = (str) => {
    str = String(str).toLowerCase().replace(/\s/g, "");
    var has_am = str.indexOf("am") >= 0;
    var has_pm = str.indexOf("pm") >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace("am", "").replace("pm", "");
    // if hour, convert to hour:00
    if (str.indexOf(":") < 0) str = str + ":00";
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += " am";
    if (has_pm) str += " pm";
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
        return parseInt(n) < 10 ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ":" + doubleDigits(d.getMinutes());
};

export const allowApplePay = () => {
    try {
        const supportsVersion = window.ApplePaySession && window.ApplePaySession.supportsVersion(3);
        const canMakePayments = window.ApplePaySession && window.ApplePaySession.canMakePayments();
        const braintreeClient = window.braintree && window.braintree.client;
        const braintreeApplePay = window.braintree && window.braintree.applePay;
        return supportsVersion && canMakePayments && braintreeClient && braintreeApplePay;
    } catch (e) {
        return false;
    }
};

export const directToAppLink = (gid) => {
    if (isUsingAppleDevice()) {
        // due to deep link, no need to consider dev/qa sever
        window.location = `https://www.goopter.com/download/goopter?id=${gid}&type=1`;
        // https://www.goopter.com/download/goopter?id=492&type=1
        // http://h5.goopter.com/goopter?id=${gid}&type=1
        // https://itunes.apple.com/ca/app/goopter/id1082587203?mt=8
        // http://h5.goopter.com/download/goopter?id=${gid}&type=1
    } else {
        window.location = `https://www.goopter.com/download/goopter?id=${gid}&type=1`;
        // "https://play.google.com/store/apps/details?id=com.goopter.diyorder";
    }
};

//to format all address to the same key
export const formatAddressObj = (adr = {}) => {
    //left possilbe values, -> reformat key value
    const VALUE_KEY_MAP = {
        street: "street",
        city: "city",
        region: "region",
        "cntry,country": "country",
        "zipcd,postal_code": "postal_code",
        lat: "lat",
        lon: "lon",
    };
    let result = {};
    const possibleValues = Object.keys(VALUE_KEY_MAP);
    Object.keys(adr).forEach((key) => {
        possibleValues.forEach((possible) => {
            if (possible.includes(key)) {
                const actualKey = VALUE_KEY_MAP[possible];
                result[actualKey] = adr[key];
            }
        });
    });
    return result;
};

export const metersToMiles = (meter) => {
    const meterInNumber = Number(meter) ?? 0;
    return meterInNumber * 0.000621371;
};

export const milesToMeters = (mile) => {
    const mileInNumber = Number(mile) ?? 0;
    return mileInNumber * 1609.34;
};

export const metersToKm = (meter) => {
    const meterInNumber = Number(meter) ?? 0;
    return meterInNumber * 0.001;
};

export const digitToArray = (n) => {
    return n.toString(10).replace(/\D/g, "0").split("").map(Number);
};

export const decToBinary = (dec, length) => {
    const string = (dec >>> 0).toString(2);
    return string.length >= length ? string : string.padStart(length, "0");
};

export const strToCharArray = (str) => {
    if (isString(str)) return str.split("");
    return [];
};

// to get the info from a url
// var reURLInformation = new RegExp([
//     '^(https?:)//', // protocol
//     '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
//     '(/{0,1}[^?#]*)', // pathname
//     '(\\?[^#]*|)', // search
//     '(#.*|)$' // hash
// ].join(''));
//var match = href.match(reURLInformation);
export const getLocation = (href) => {
    var match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return (
        match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7],
        }
    );
};

/**
 * Used for validating the proper fomatting an email input
 *
 * @param email - the email inputted
 *
 * @returns true if email inputted is a valid email format
 *
 * @example
 * return "true" for `isanemail@goopter.com` but "false"
 * for `notanemail.123`:
 */
export function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * Used for validating the proper fomatting an email input
 *
 * @param phone - the phone inputted + "@c.goopter.com"
 *
 * @returns true if phone inputted is a valid phone format
 *
 * @example
 * return "true" for `isanemail@goopter.com` but "false"
 * for `notanemail.123`:
 */
export function validatePhone(phone) {
    const num = phone.slice(0, phone.length - 14);
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return re.test(num);
}

/**
 * Used for validating that the password is valid
 *
 * @param pwd - the password inputted
 *
 * @returns true if the password inputted is at least 6
 * characters long
 *
 * @example
 * Returns "true" for `123456` but "false"
 * for `123s`:
 */
export const validatePassword = (pwd) => {
    const re = /[0-9a-zA-Z]{6,}/;
    return re.test(pwd);
};

/**
 * Checks whether the store only supports 3rd party delivery
 *
 * @param store - the store object
 *
 * @returns whether or not the store only supports 3rd party delivery
 */
export function isThirdPartyDeliveryOnly(store) {
    const storeData = store?.data || store || {};
    if (typeof storeData === "object") {
        const kflg = Array.isArray(storeData.kflg) ? storeData.kflg : [];
        if (kflg.length >= config.STORE_KFLG_INDEX_MAPPING.preferred_delivery_method) {
            return (
                kflg[config.STORE_KFLG_INDEX_MAPPING.preferred_delivery_method] ===
                config.PREFERRED_DELIVERY_METHOD_MAPPING_TO_NUMERIC.third_party_courier
            );
        }
    }

    return false;
}

/**
 * Checks whether the store only supports 3rd party local delivery
 *
 * @param store - the store object
 *
 * @returns whether or not the store only supports 3rd party local delivery
 */
export function isThirdPartyLocalDeliveryService(store) {
    const storeData = store?.data || store || {};
    if (typeof storeData === "object") {
        const kflg = Array.isArray(storeData.kflg) ? storeData.kflg : [];
        let deliveryProviderMatched = false;
        let thridPartyDelieryServiceMatched = false;
        if (kflg.length >= config.STORE_KFLG_INDEX_MAPPING.preferred_delivery_method) {
            deliveryProviderMatched =
            kflg[config.STORE_KFLG_INDEX_MAPPING.preferred_delivery_method] ===
            config.PREFERRED_DELIVERY_METHOD_MAPPING_TO_NUMERIC.self_local_delivery;
        }
        if (kflg.length >= config.STORE_KFLG_INDEX_MAPPING.enable_third_party_delivery_service) {
            thridPartyDelieryServiceMatched = kflg[config.STORE_KFLG_INDEX_MAPPING.enable_third_party_delivery_service] 
        }
        return deliveryProviderMatched && thridPartyDelieryServiceMatched;
    }

    return false;
}


/**
 * Checks whether the store has enabled delivery
 *
 * @param store - the store object
 *
 * @returns whether or not the store has enabled delivery
 */
export function isDeliveryEnabled(store) {
    const storeData = store?.data || store || {};
    if (typeof storeData === "object") {
        const storeObject = new Store(store)
        return storeObject.data.delivery;
    }

    return false;
}


/** Checks whether the menus have the same order types
 *
 * @params menus - a list of menus
 * @returns whether the menus have the same order types, and true if there are no menus
 */
export function areOrderTypesForAllMenusTheSame(menus) {
    if (!Array.isArray(menus) || menus.length === 0) {
        return true;
    }

    const initialAcceptedOrderTypes = menus[0].sflg;
    for (let i = 1; i < menus.length; i++) {
        for (let j = 0; j < initialAcceptedOrderTypes.length; j++) {
            if (menus[i].sflg[j] !== initialAcceptedOrderTypes[j]) {
                return false;
            }
        }
    }

    return true;
}

export const getGiftCardTotal = (cart) => {
    return cart.reduce((acc, item) => {
        if (item.productType === 1) {
            acc += item.price * item.qty;
        }
        return acc;
    }, 0);
};

export const getDiscountDataWithMethod = (store = {}, method) => {
    const METHOD_DISCOUNT_MESSAGE_MAP = {
        [config.SHIPPING_MAPPING_TO_NUMERIC.delivery]: "dlvry",
        [config.SHIPPING_MAPPING_TO_NUMERIC.eatin]: "instore",
        [config.SHIPPING_MAPPING_TO_NUMERIC.takeout]: "pickup",
    };

    const storeData = store?.data || store;
    const discountObject = _.get(storeData, "discount", {});
    const methodValue = Number(method) ? String(method) : config.SHIPPING_MAPPING_TO_NUMERIC[method];
    const discountMethodValue = METHOD_DISCOUNT_MESSAGE_MAP[methodValue];
    return _.get(discountObject, discountMethodValue, {});
};

export const getMinDeliveryAmountLeft = (store, total) => {
    const currentTotal = _.get(total, "discounted", 0);
    const storeMinDeliveryAmt = allowMulitpleDelivery(store?.data)
        ? getMuplipleMinDeliveryAmount(store?.data)
        : store?.data?.min_delivery_amount ?? 0;
    const minDeliveryAmountLeft = storeMinDeliveryAmt - currentTotal > 0 ? storeMinDeliveryAmt - currentTotal : 0;
    return minDeliveryAmountLeft;
};

export const getMinPickupAmountLeft = (store, total) => {
    const currentTotal = _.get(total, "discounted", 0);
    const storeMinPickupAmt = allowMultiplePickup(store?.data)
        ? getMuplipleMinPickupAmount(store?.data)
        : store?.data?.min_pickup_amount ?? 0;
    const minPickupAmountLeft = storeMinPickupAmt - currentTotal > 0 ? storeMinPickupAmt - currentTotal : 0;
    return minPickupAmountLeft;
};

export const getMenuAvailabilityInfo = (selectedMenu, store) => {
    let result = "";
    let info = {};

    if (!isMenuAvailableNow(selectedMenu, store?.data)) {
        info = getMenuAvaibilityInfo(selectedMenu, store?.data);
    }
    if (!_.isEmpty(info)) {
        const keys = Object.keys(info);
        const values = Object.values(info);
        result = `${_.get(values, 0.0)} ${_.get(keys, 0)}`;
    }
    return result;
};

export const getIsCheckOutEnabled = (
    store,
    total,
    cart,
    invalidGroupSale,
    shippingMethod,
    selectedMenu,
    checkoutState
) => {
    const CHECKOUT_BUTTON_DISABLED_REASONS = {
        NO_ITEM: 0,
        ALLOW_ORDER_API: 1,
        ONLINE_ORDER_API: 2,
        MIN_DELIVERY_AMOUNT: 3,
        MIN_PICKUP_AMOUNT: 4,
        MENU_AVAILABLITY: 5,
        INVALID_GROUP_SALE_PRODUCT: 6,
        CLOSED_STORE_ORDER_NOW: 7,
    };
    let disabledReasons = [];

    // if the cart is empty, disable checkout button
    if (!(cart.length > 0)) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.NO_ITEM);
    }

    // if the store does not allow order, add CHECKOUT_BUTTON_DISABLED_REASONS.ALLOW_ORDER_API to disabledReasons array
    // and disable checkout button
    if (store.data.allow_order === 0) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.ALLOW_ORDER_API);
    }

    // if the store does not allow online order, add CHECKOUT_BUTTON_DISABLED_REASONS.ONLINE_ORDER_API to disabledReasons array
    // and disable checkout button
    if (store.data.online_order === 0) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.ONLINE_ORDER_API);
    }

    // if the store's minimum amount for delivery is not met, disable checkout button
    if (shippingMethod === "delivery" && getMinDeliveryAmountLeft(store, total) > 0) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.MIN_DELIVERY_AMOUNT);
    }

    // if the store's minimum amount for pick-up(take out) is not met, disable checkout button
    if (shippingMethod === "takeout" && getMinPickupAmountLeft(store, total) > 0) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.MIN_PICKUP_AMOUNT);
    }

    // if the store menu is not avaiable, disable checkout button
    if (!getMenuAvailabilityInfo(selectedMenu, store) && store.data.allow_order === 0) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.MENU_AVAILABLITY);
    }

    //
    if (invalidGroupSale) {
        disabledReasons.push(CHECKOUT_BUTTON_DISABLED_REASONS.INVALID_GROUP_SALE_PRODUCT);
    }

    return disabledReasons.length === 0;
};

export const getSelectionMessage = (option) => {
    const max = _.get(option, "max", 1);
    const min = _.get(option, "min", 0);

    if (max === 1 && min === 1) {
        return "pick_one";
    } else if (min > 0 && max === min) {
        return "pick_amount";
    } else if (min > 0 && max > min) {
        return "pick_range_required";
    } else if (min === 0 && max === 1) {
        return "pick_optional";
    } else if (min === 0) {
        return "pick_range_up_to";
    }

    return "pick_one";
};

const getValueOfStoreFlag = (store, flagIndex) => {
    const storeData = store?.data ?? store;
    const kflg = Array.isArray(storeData?.kflg) ? storeData.kflg : [];

    if (kflg.length >= flagIndex) {
        return kflg[flagIndex];
    }
};

export const shouldDisplaySoldQuantity = (store, soldQuantity) => {
    if (!soldQuantity) {
        return false;
    }

    const soldQtyThreshold = getValueOfStoreFlag(store, config.STORE_KFLG_INDEX_MAPPING.sold_qty_display_threshold);
    if (soldQtyThreshold === config.SOLD_QTY_DISPLAY_OPTIONS.hide) {
        return false;
    } else if (soldQtyThreshold === config.SOLD_QTY_DISPLAY_OPTIONS.show) {
        return true;
    } else {
        return soldQuantity >= soldQtyThreshold;
    }
};

export const shouldDisplayStockQuantity = (store, stockRemaining) => {
    const stockDisplayThreshold = getValueOfStoreFlag(
        store,
        config.STORE_KFLG_INDEX_MAPPING.stock_qty_display_threshold
    );
    if (stockDisplayThreshold === config.STOCK_QTY_DISPLAY_OPTIONS.hide) {
        return false;
    } else if (stockDisplayThreshold === config.STOCK_QTY_DISPLAY_OPTIONS.show) {
        return true;
    } else {
        return stockRemaining <= stockDisplayThreshold;
    }
};

export const getPaymentMethodNameByCode = (paymentCode) => {
    return config.PAYMENT_METHOD_MAPPING[paymentCode];
};

/**
 * When a customer clicks the store home button,
 * case1: if the site has an independent domain, open the independent domain front page.
 * case2: if the site has a shared domain and storeHomeURL prop, open the store home url.
 * storeHomeURL can be the store front page(ex: /store/835) or the group sale page(ex: /gsale/1234567).
 * case3: if it isn't case 1 or case 2 but there is a store id, open the store front page.
 * else case: just goback to the previous page.
 *
 * @param gid - store gid
 * @param storeHomeURL - page url of store home
 */
export const goToStoreHome = (gid, storeHomeURL) => {
    if (getIndependentDomain()) {
        push("/");
    } else if (storeHomeURL) {
        push(storeHomeURL);
    } else if (gid) {
        push(`/store/${gid}`);
    } else {
        goBack();
    }
};

export const getGroupPurchaseId = () => {
    return _.get(getUrlParameters(), "gp_id");
};

export const isDiscountedUpsellOn = (store) => {
    const storeData = store.data || store || {};
    return Boolean(_.get(storeData, `kflg.${config.STORE_KFLG_INDEX_MAPPING.enable_discounted_upsell_logic}`, false));
};

export const getOOSInfo = (products, oosRecords) => {
    let oosItems = [];
    let oosPids = [];

    Object.keys(oosRecords).forEach((responsePid) => {
        products.forEach((cartItem) => {
            if (Number(cartItem.pid) === Number(responsePid)) {
                oosPids.push(cartItem.pid);
            }
        });
    });

    Object.entries(oosRecords).forEach((responseOOSItem) => {
        products.forEach((cartItem) => {
            const [responseOOSItemPid, responseOOSItemOptions] = responseOOSItem;
            if (Number(cartItem.pid) === Number(responseOOSItemPid)) {
                let oosItem = {};
                oosItem["pid"] = responseOOSItemPid;
                oosItem["nm"] = cartItem.nm;
                if (_.isObject(responseOOSItemOptions)) {
                    let oosSubOptions = [];
                    Object.values(responseOOSItemOptions).forEach((responseOOSItemOptions) => {
                        Object.entries(responseOOSItemOptions).forEach((responseOOSItemOption) => {
                            const [responseOOSItemOptionIndex, responseOOSItemSubOptions] = responseOOSItemOption;
                            const opt = cartItem.opt.find((opt) => opt.id === Number(responseOOSItemOptionIndex));

                            Object.values(responseOOSItemSubOptions).forEach((responseOOSItemSubOption) => {
                                Object.entries(responseOOSItemSubOption).forEach((responseOOSItemSubOption) => {
                                    const [responseOOSItemSubOptionIndex, responseOOSItemSubOptionStock] =
                                        responseOOSItemSubOption;
                                    oosSubOptions.push({
                                        nm: opt.opts.find(
                                            (subopt) => subopt.id === Number(responseOOSItemSubOptionIndex)
                                        ).nm,
                                        optId: responseOOSItemOptionIndex,
                                        subOptId: responseOOSItemSubOptionIndex,
                                        sq: responseOOSItemSubOptionStock,
                                    });
                                });
                            });
                        });
                    });
                    oosItem["subOptions"] = oosSubOptions;
                } else {
                    oosItem["sq"] = responseOOSItemOptions || 0;
                }
                oosItems.push(oosItem);
            }
        });
    });
    return { oosItems, oosPids };
};

export const renderItemWithOutSubOption = (oosItem) => {
    return (
        <div className="oos-product">
            <div className="oos-product-name">
                <b>{`${oosItem?.nm}:`}</b>
            </div>
            <div className="oos-product-stock">
                <FormattedMessage id="remaining_stock_header" />
                {`: ${oosItem?.sq}`}
            </div>
        </div>
    );
};

export const renderItemWithSubOption = (oosItem) => {
    const itemName = _.get(oosItem, "nm", "");
    const subOptions = _.get(oosItem, "subOptions", []);
    return (
        <div className="oos-product">
            <div className="oos-product-name">
                <b>{`${itemName}:`}</b>
            </div>
            <div className="oos-product-header">
                <div className="oos-product-header-opt">
                    <FormattedMessage id="options" />
                </div>
                <div className="oos-product-header-stock">
                    <FormattedMessage id="remaining_stock_header" />
                </div>
            </div>
            {subOptions.map((subOption) => {
                const subOptionName = _.get(subOption, "nm", "");
                const subOptionStock = _.get(subOption, "sq", "");
                return (
                    <div className="oos-product-subopt">
                        <div className="oos-product-subopt-name">{` • ${subOptionName}`}</div>
                        <div className="oos-product-subopt-stock">{subOptionStock}</div>
                    </div>
                );
            })}
        </div>
    );
};

export const getOOSOptionKeywords = (oosItems) => {
    return (
        <div className="oos">
            <br />
            <div className="oos-contents">
                {oosItems.map((oosItem) => {
                    const subOptions = _.get(oosItem, "subOptions", []);
                    return _.isEmpty(subOptions) ? (
                        <div>{renderItemWithOutSubOption(oosItem)}</div>
                    ) : (
                        <div>{renderItemWithSubOption(oosItem)}</div>
                    );
                })}
            </div>
        </div>
    );
};

export const getProductQuantityInCart = (cart, product) => {
    const cProduct = _.get(product, "data", product);

    return cart.reduce((acc, p) => {
        if (String(p.pid) === String(cProduct.pid)) {
            acc += p.qty;
        }
        return acc;
    }, 0);
};

export const isPurchasingLimitReached = (cart, product) => {
    const cProduct = _.get(product, "data", product);
    const maxSaleQty = cProduct?.max_sq ?? 0;
    if (maxSaleQty === 0) {
        return false;
    }

    const quantity = getProductQuantityInCart(cart, product);
    return quantity >= maxSaleQty;
};

export const getAddItemButtonBackgroundColor = ({ cart, product, disabled, activeColor }) => {
    let bgColor = `#${activeColor}`;
    if (disabled) {
        bgColor = "grey";
    } else if (isPurchasingLimitReached(cart, product)) {
        bgColor = `#${activeColor}77`;
    }

    return bgColor;
};

export const getQtyOptions = (product) => {
    const qty = [];
    let maxValue = Math.min(product?.sq, MAX_ITEMS_OPTION_VALUE);
    const maxSaleQty = product?.max_sq ?? 0;
    if (maxSaleQty !== 0) {
        maxValue = Math.min(maxValue, maxSaleQty);
    }
    for (let i = product?.min_sq ?? 1; i <= maxValue; i++) {
        qty.push(i);
    }

    return qty;
};

export const isLargeSizedScreen = (width) => {
    return width >= LARGE_SCREEN_THRESHOLD;
};

export const isMidSizedScreen = (width) => {
    return width < LARGE_SCREEN_THRESHOLD && width >= SMALL_SCREEN_THRESHOLD;
};

export const isSmallSizedScreen = (width) => {
    return width < SMALL_SCREEN_THRESHOLD;
};

export const isMobileSizedScreen = (width) => {
    return width <= MOBILE_SCREEN_THRESHOLD;
};

export const getStartTimeOfTimeRange = (timeRangeString) => {
    return moment(timeRangeString.split("-", 1), "hh:mma");
};

/**
 * Returns next available date in the allowed periods
 *
 * @param inputDate      - Previous Date stored in the state
 * @param rangeObject    - Updated allowed periods from the API
 * @returns The next available date
 * @example "YYYY-MM-DD" or Empty string if no next date exists in allowed periods
 *
 */
export const getNextAvailableDate = (inputDate, rangeObject) => {
    let currentDate = moment(inputDate);
    let isFirstPositiveNumber = true;
    let result = "";
    //atleast one date exists
    if (typeof rangeObject === "object" && !_.isEmpty(rangeObject)) {
        //if date doesn't exists, check for next first available date
        Object.keys(rangeObject).forEach((key) => {
            //if date exists return input date
            let availableDate = moment(key);
            if (availableDate.diff(currentDate, "days") > 0 && isFirstPositiveNumber) {
                result = key;
                //sets flag to false after first positive integer
                isFirstPositiveNumber = false;
            }
        });
    }
    return result;
};

/**
 * Returns the date and time for best time slot
 *
 * @param inputDate      - Previous Date stored in the state
 * @param timeToCompare  - Previous time stored in the state
 * @param allowedPeriods - Updated allowed periods from the API
 * @returns The best date and time slot range as an object based on smallest delta
 * @example { Date: "YYYY-MM-DD", Time: "hh:mm-hh:mm" }
 *
 */
export const getBestTimeSlot = (inputDate, timeToCompare, allowedPeriods) => {
    let smallest = TOTAL_MINUTES_IN_A_DAY;
    let smallestIndex = 0;
    let openTime;
    let closeTime;
    let firstTimeSlot;
    let lastTimeSlot;
    let arrayLength;
    let startTimeForComparison;
    let nextDate = getNextAvailableDate(inputDate, allowedPeriods);
    let timesArray = allowedPeriods[inputDate];
    let sizeOfSlots;
    let startTimeForComparison2;
    let lastTimeSlot2;
    let arrayLength2;
    let closeTime2;
    let lastDate = Object.keys(allowedPeriods)[Object.keys(allowedPeriods).length - 1];
    let result = {
        date: "",
        time: ASAP,
    };

    //Case #1 dates inside allowed_periods doesn't exists OR time and next date doesn't exists
    if (_.isEmpty(allowedPeriods) || (_.isNil(inputDate) && _.isNil(timeToCompare))) {
        return result;
    }

    //Case #2 if date doesn't exists but a next available date exists
    if ((!timesArray && !_.isEmpty(nextDate)) || (inputDate in allowedPeriods && _.isEmpty(timesArray))) {
        if (!_.isEmpty(allowedPeriods[nextDate])) {
            result = {
                date: nextDate,
                time: allowedPeriods[nextDate][0],
            };
        } else {
            result = {
                date: nextDate,
                time: ASAP,
            };
        }
    }

    //get opening time, closing time when date and time range exists, start time of input time string, range length
    if (inputDate in allowedPeriods && !_.isEmpty(timesArray)) {
        if (timesArray[0] === ASAP && allowedPeriods[inputDate].length > 1) {
            openTime = timesArray[1];
        } else {
            openTime = timesArray[0];
        }
    }
    if (_.isString(openTime)) {
        firstTimeSlot = getStartTimeOfTimeRange(openTime);
    }
    if (_.isString(timeToCompare)) {
        startTimeForComparison = getStartTimeOfTimeRange(timeToCompare);
    }
    if (!_.isEmpty(timesArray)) {
        arrayLength = timesArray.length;
        closeTime = timesArray[arrayLength - 1];
    }
    if (_.isString(closeTime)) {
        let lastTimeSlotArray = closeTime.split("-", 2);
        // when store is open 24 hour, API response for closing time is 12:00AM
        lastTimeSlot = moment(lastTimeSlotArray[0], "hh:mma");
    }
    if (inputDate in allowedPeriods) {
        sizeOfSlots = allowedPeriods[inputDate].length;
    }

    //getting the very last time range of new allowed_periods and comparison time
    //this case usually occur when shipping method is switched from delivery to pickup
    if (_.isString(timeToCompare)) {
        startTimeForComparison2 = moment(inputDate + " " + timeToCompare.split("-", 1), "YYYY-MM-DD hh:mma");
    }
    if (!_.isEmpty(allowedPeriods)) {
        let timeRange = allowedPeriods[lastDate];
        arrayLength2 = timeRange.length;
        closeTime2 = timeRange[arrayLength2 - 1];
    }
    if (_.isString(closeTime2)) {
        let lastTimeSlotArray = closeTime2.split("-", 2);
        // when store is open 24 hour, API response for closing time is 12:00AM
        if (lastTimeSlotArray[1] === "12:00am") {
            lastTimeSlot2 = moment(lastDate + " 11:59pm", "YYYY-MM-DD hh:mma");
        } else {
            lastTimeSlot2 = moment(lastDate + " " + lastTimeSlotArray[1], "YYYY-MM-DD hh:mma");
        }
    }
    //Case #3a when allowed exists and nextDate is empty and time for comparison is after store is closed
    //this usually happens when shipping method is changed from delivery to pickup
    if (!_.isEmpty(allowedPeriods) && _.isEmpty(nextDate) && lastTimeSlot2 < startTimeForComparison2) {
        result = {
            date: Object.keys(allowedPeriods)?.[0],
            time: allowedPeriods?.[Object.keys(allowedPeriods)?.[0]]?.[0],
        };
    }

    //Case #3 time to compare is after store is closed, then get first slot of next day
    if (lastTimeSlot < startTimeForComparison && !_.isEmpty(nextDate) && nextDate in allowedPeriods) {
        if (!_.isEmpty(allowedPeriods[nextDate])) {
            result = {
                date: nextDate,
                time: allowedPeriods[nextDate][0],
            };
        } else {
            result = {
                date: nextDate,
                time: ASAP,
            };
        }
    }

    //Case #4 when time to compare is ASAP and date exists OR range has only one element ASAP
    if (timeToCompare === ASAP || (sizeOfSlots === 1 && timesArray[0] === ASAP)) {
        let firstIndex = allowedPeriods[Object.keys(allowedPeriods)[0]];
        result = {
            date: Object.keys(allowedPeriods)[0],
            time: firstIndex[0],
        };

        //Case #5 time to compare is before store opens
    } else if (startTimeForComparison < firstTimeSlot) {
        result = {
            date: inputDate,
            time: timesArray[0],
        };
        //Case #6 time to compare is before store store closes
    } else if (startTimeForComparison <= lastTimeSlot) {
        timesArray.forEach((element, index) => {
            //Split the start of array time range “7:30pm-7:45pm” into 07:30pm
            let startTimeOfAllowedPeriods = getStartTimeOfTimeRange(element);
            //Calculate the difference between start time of input and start time of allowed slots
            let delta = Math.abs(moment.duration(startTimeOfAllowedPeriods.diff(startTimeForComparison)).asMinutes());
            //store the index of smallest delta
            if (delta < smallest) {
                smallestIndex = index;
                smallest = delta;
            }
        });
        result = {
            date: inputDate,
            time: timesArray[smallestIndex],
        };
    }
    return result;
};

export function isDelivery(shippingMethod) {
    return (
        String(shippingMethod) === config.SHIPPING_MAPPING_TO_NUMERIC.delivery ||
        shippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.delivery ||
        shippingMethod ===
            getKeyByValue(config.SHIPPING_MAPPING_TO_NUMERIC, config.SHIPPING_MAPPING_TO_NUMERIC.delivery)
    );
}

/**
 * Returns true only if the store only has third party delivery, and shipping method is delivery.
 *
 * @param store - store object
 * @param shippingMethod - the current shipping method
 * @returns true only if the store only has third party delivery, and shipping method is delivery.
 */
export function isDeliveryAndThirdPartyCourier(store, shippingMethod) {
    const storeData = store?.data || store || {};

    return isThirdPartyDeliveryOnly(storeData) && isDelivery(shippingMethod);
}

export function createKey(prefix) {
    return `${prefix}-${new Date().getTime()}`;
}

/**
 * Returns the formatted phone number with only the first, and last 4 digits showing.
 * The rest is replaced with "*".
 *
 * @param phoneNumber - phone number
 * @returns formatted hidden phone number
 */
export function formatHiddenPhoneNumber(phoneNumber) {
    const DIGITS_SHOWN = 4;
    let hiddenNumber = "";
    for (let i = 0; i < phoneNumber.length; i++) {
        if (i === 0) {
            hiddenNumber += "(";
        }
        if (i === 6) {
            hiddenNumber += "-";
        }
        if ((i >= phoneNumber.length - DIGITS_SHOWN && phoneNumber.length - DIGITS_SHOWN >= 0) || i === 0) {
            hiddenNumber += phoneNumber.charAt(i);
        } else {
            hiddenNumber += "*";
        }
        if (i === 2) {
            hiddenNumber += ") ";
        }
    }

    return hiddenNumber;
}

export function isFromAccountPage(origin) {
    return origin === config.ACCOUNT_INFO_EDIT_ORIGIN.account_page;
}

export function isFromOrderSuccessPage(origin) {
    return origin === config.ACCOUNT_INFO_EDIT_ORIGIN.order_success_page;
}

export function isFromCheckoutPage(origin) {
    return origin === config.ACCOUNT_INFO_EDIT_ORIGIN.checkout_page;
}

export function isFromStorePage(origin) {
    return origin === config.ACCOUNT_INFO_EDIT_ORIGIN.store_page;
}

/**
 * Returns a unique key for based on product id and selected options
 *
 * @param item - product in shopping cart
 * @returns a unique key for selected product
 */
export function getProductKey(item) {
    let key = `${item?.pid}-`;
    Object.keys(item?.options ?? {}).forEach((optionId) => {
        item?.options[optionId].forEach((selectionId) => {
            key += `${optionId}-${selectionId}`;
        });
    });

    return key;
}

export function isGuestUser(role) {
    return role === config.CUSTOMER_ROLES.guest;
}

/**
 * Returns whether phone number is required for checkout.
 * Dine In: not required
 * Pick Up: required if guest checkout is not allowed
 * Delivery: required
 *
 * @param shippingMethod - the numeric shipping method value
 * @param isGuestCheckoutAllowed - whether guest checkout is allowed for the shipping method passed
 *
 * @returns whether phone number is required for checkout
 */
export function isPhoneNumberRequiredForCheckout(shippingMethod, isGuestCheckoutAllowed) {
    switch (shippingMethod) {
        case Number(config.SHIPPING_MAPPING_TO_NUMERIC.eatin):
            return false;
        case Number(config.SHIPPING_MAPPING_TO_NUMERIC.pickup):
            return !isGuestCheckoutAllowed;
        case Number(config.SHIPPING_MAPPING_TO_NUMERIC.delivery):
            return true;
        default:
            return false;
    }
}

/**
 * Returns the x, y coordinates of the plus button relative to the screen
 *
 * @param id - id of plus button
 * @returns the x, y coordinates of the plus button relative to the screen
 */
export function getPlusButtonPosition(id) {
    const plusButton = document.getElementById(id);
    const coords = plusButton?.getBoundingClientRect();

    return { x: coords?.x, y: coords?.y };
}

export const getMinPointsRedemptionValueAmount = (store) => {
    return getValueOfStoreFlag(store, config.STORE_KFLG_INDEX_MAPPING.tips_min_points_redemption_value_amount);
};

export const getMaxPointsRedemptionType = (store) => {
    return getValueOfStoreFlag(store, config.STORE_KFLG_INDEX_MAPPING.tips_max_points_redemption_type);
};

export const getMaxPointsRedemptionValuePerOrder = (store) => {
    return getValueOfStoreFlag(store, config.STORE_KFLG_INDEX_MAPPING.tips_max_points_redemption_value_per_order);
};

const exports = {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    isNumeric,
    getTransString,
    getImageUrl,
    getImageUrl1024,
    getImageUrlOriginal,
    getImageUrlInternal,
    getImageUrlCarousel,
    getShareURL,
    formatAddress,
    formatPhoneNumber,
    formatCurrency,
    formatNumber,
    getIndependentDomain,
    getUrlParameters,
    cleanPhoneNumber,
    checkPhonePattern,
    getExpectedTimeFullStr,
    utcToLocal,
    getKeyByValue,
    objectToQueryString,
    getUserAgent,
    isUsingAliPay,
    isUsingWechat,
    getAIOAgentName,
    stringToCamelCase,
    upperCaseFirstLetter,
    isValidPostal,
    getPrice,
    clipboard,
    getDiffProperties,
    getDefaultLan,
    generateSubPaths,
    isUsingIos,
    isString,
    isBoolean,
    getCategoryString,
    cleanObj,
    getGiftCardTotal,
    getDiscountDataWithMethod,
    getMinDeliveryAmountLeft,
    getMinPickupAmountLeft,
    getMenuAvailabilityInfo,
    getIsCheckOutEnabled,
    milesToMeters,
    isMobile,
    goToStoreHome,
    isDiscountedUpsellOn,
    getOOSInfo,
    getOOSOptionKeywords,
    getStartTimeOfTimeRange,
    getNextAvailableDate,
    getBestTimeSlot,
};

export default exports;
