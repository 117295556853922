import helper from "utils/helper";
import { callWithErrorHandling, executeOauth } from "utils/api";
import { getOrdersSuccess, setState } from "./slice";
import { push } from "../app/history";
import { getStoreProducts } from "../store-page/service";
import _ from "lodash";
import qs from "query-string";

export const getOrders = (payload) => async (dispatch) => {
    dispatch(setState({ loading: true }));
    try {
        const { page = 1, lan, state } = payload;

        let query = { page, lan, state };

        if (helper.getIndependentDomain()) query.domain = helper.getIndependentDomain();

        const response = await callWithErrorHandling(
            {
                fn: executeOauth,
                url: "orderList",
                query,
            },
            "GET"
        );

        if (response.RC === 200) {
            dispatch(
                getOrdersSuccess({
                    orders: response.records,
                    paging: response.paging,
                })
            );
        }
    } catch (e) {
        
    }
    dispatch(setState({ loading: false }));
};

export const orderAgain = (payload) => async (dispatch) => {
    try {
        const orderId = payload.orderId;
        const lan = payload.lan;
        const append = `/${orderId}/${lan}`;
        const response = await callWithErrorHandling({ fn: executeOauth, url: "orderDetail", append }, "GET");

        if (response.RC === 200) {
            dispatch(
                getStoreProducts({
                    gid: response.records.g_id,
                    lan,
                    pids: response.records.order_item.map((i) => i.pid),
                    orderAgain: {
                        items: response.records.order_item,
                        gid: response.records.g_id,
                    },
                })
            );

            //handle mid params
            const mids = Array.isArray(_.get(response, "records.menu_ids")) ? _.get(response, "records.menu_ids") : [];
            const mid = _.get(mids, "0.id");
            const midParam = mid ? { mid } : {};
            //handle lid params(table)
            const lid = _.get(response, "records.table");
            const lidParam = lid ? { lid } : {};
            //handle tid params (table type)
            const tid = _.get(response, "records.location_type");
            const tidParam = tid ? { tid } : {};

            const paramsStr = qs.stringify({ ...midParam, ...lidParam, ...tidParam });
            //push to correct url
            const pushUrl = helper.getIndependentDomain()
                ? `/?${paramsStr}`
                : `/store/${response.records.g_id}?${paramsStr}`;
            push(pushUrl);
        }
    } catch (e) {
        
    }
};
