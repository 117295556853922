import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customer: {
        user_id: null,
        first_name: "",
        last_name: "",
        nick_name: "",
        phone: "",
        phone_country_code: "",
        currency: "",
        country: "",
        points: null,
        type: null,
        is_confirm: null,
        is_password_set: null,
        defaultAddress: null,
        has_payment_password: null,
        bookmark: {
            store: [],
            groupsale: [],
            product: [],
        },
        num_gift_cards: null,
    },
};

const posterShare = createSlice({
    name: "sharePoster",
    initialState,
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        getCustomerInfoSuccess: (state, { payload }) => {
            state.customer = payload;
            state.refreshCustomer = false;
        },
    },
});

export const { setState, clearData, getCustomerInfoSuccess } = posterShare.actions;

export default posterShare.reducer;
