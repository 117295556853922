import LatLon from "geodesy/latlon-ellipsoidal-vincenty.js";
import _ from "lodash";

//distance in m
const calculateDistance = (latlng1, latlng2, precision = 0) => {
    const lat1 = _.get(latlng1, "lat", null);
    const lng1 = _.get(latlng1, "lng", null);

    const lat2 = _.get(latlng2, "lat", null);
    const lng2 = _.get(latlng2, "lng", null);

    if (lat1 && lng1 && lat2 && lng2) {
        return calculateDistanceWithLib(lat1, lng1, lat2, lng2, precision);
    }

    return false;
};
//distance in m
const calculateDistanceWithLib = (lat1, lng1, lat2, lng2, precision) => {
    const p1 = new LatLon(lat1, lng1);
    const p2 = new LatLon(lat2, lng2);
    const d = p1.distanceTo(p2);
    return d.toFixed(precision);
};

const exports = {
    calculateDistance,
}

export default exports;
