import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const emailReset = createSlice({
    name: "emailReset",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = initialState;
        },
        setEmailVerificationSuccess: (state, { payload }) => {
            const RC = Number(_.get(payload, "response.RC", 400));
            state.validPage = RC === 200;
            state.statusCode = RC;
            state.email = _.get(payload, "response.email");
        },
    },
});

export const { setState, clearData, setEmailVerificationSuccess } = emailReset.actions;

export default emailReset.reducer;
