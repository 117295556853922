import config from "config";
import _ from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import qs from "query-string";
import helper, { formatAddressObj, getUserAgent, metersToKm, metersToMiles, updateMoment } from "utils/helper";
import locale from "../../locale";
import { replace } from "../app/history";
import { store as reduxStore } from "../app/store";
import locationHelper from "../location/helper";
import { AVAILABLE_LANS } from "../settings/helper";

export const TAB_MENU = 0;
export const TAB_OVERVIEW = 1;
export const TAB_REVIEWS = 2;
export const STORE_COLLAPSED_HEADER_HEIGHT = 64; //when it's stringed
export const STORE_EXPANDED_HEADER_HEIGHT = 252;
export const STORE_TAB_HEIGHT = 48;
export const STORE_CAT_HEIGHT = 56;
export const STORE_PRODUCTS_CATEGORY_TITLE_PREFIX = "store-product-category-title-";

export const getDefaultGetStoreParams = (props, lan) => {
    const gid = Number(_.get(props, "match.params.gid"));
    const { mid } = qs.parse(_.get(window, "location.search", ""));
    const { cid } = qs.parse(_.get(window, "location.search", ""));
    return {
        gid,
        lan: lan,
        menu_lan: lan,
        cid: cid,
        mid: mid,
    };
};

const format = "H:mm";
//distance in m
const getStoreUserDistance = (store, user) => {
    const storeLatLng = {
        lat: _.get(store, "adr.lat", null),
        lng: _.get(store, "adr.lon", null),
    };
    const userLatLng = user;
    return locationHelper.calculateDistance(storeLatLng, userLatLng); //in m
};

export const getMaxDeliveryDistance = (store) => {
    const value = _.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.max_delivery_distance}`, 0);
    return Number(value) ? Number(value) : 0;
};

//distance in m
export const DINE_IN_DISTANCE_CUT = 30;
export const PICK_UP_DISTANCE_CUT = 3000;
const getDefaultShippingMethod = (params) => {
    const { availableMethods, distance, tableNumber, store, selectedMenu } = params;
    const methods = Array.isArray(availableMethods) ? availableMethods : [];
    //eat in
    const validDistace = distance === 0 || Number(distance) ? true : false;
    const useDistance = !requireScanForDineIn(store);
    const byDistance = useDistance && validDistace && distance < DINE_IN_DISTANCE_CUT;
    const byTable = tableNumber || tableNumber === 0;
    const defaultEatIn = byDistance || byTable;
    const { mid, tid, lid } = qs.parse(_.get(window, "location.search", ""));
    const useAvailableMethods = String(mid) === String(selectedMenu) && (mid || mid === 0);
    const eatInAvailable = useAvailableMethods
        ? methods.includes("eatin")
        : getAvailableMenuWithMethod(store, "eatin") || methods.includes("eatin");
    //Issue-14541 - add checking for eatin with table number and table type
    if (defaultEatIn && eatInAvailable && (tid != null && lid !=null)) {
        return "eatin";
    }

    //take out
    const distanctFit =
        (distance >= DINE_IN_DISTANCE_CUT && distance < PICK_UP_DISTANCE_CUT) ||
        distance > getMaxDeliveryDistance(store) * 1000;
    const noDelivery = !methods.includes("delivery");
    const defaultPickup = distanctFit || noDelivery;
    if (defaultPickup && methods.includes("takeout")) {
        return "takeout";
    }

    //delivery
    const defaultDelivery = distance > PICK_UP_DISTANCE_CUT && distance < getMaxDeliveryDistance(store) * 1000;
    if (defaultDelivery && methods.includes("delivery")) {
        return "delivery";
    }

    return methods.includes("takeout") ? "takeout" : _.get(methods, "0");
};

export const isRestaurant = (store) => {
    const storeData = store?.data || store || {};
    const cids = Array.isArray(_.get(storeData, "cids")) ? _.get(storeData, "cids") : [];
    return cids.includes(config.CATEGORIES_MAPPING_TO_NUMERIC.restaurant) || cids.includes("1");
};

export const getStoreTimeZone = (store) => {
    const timeZone = _.get(store, "tz");
    const zones = momentTz.tz.names();
    const zonesArr = Array.isArray(zones) ? zones : [];
    if (zonesArr.includes(timeZone)) {
        return timeZone;
    }
    return moment.tz.guess();
};

//get a moment object now with store time zone
export const getNowInStoreTimeZone = (store) => {
    const LOGIC_FORMAT = "YYYY-MM-DD HH:mm:ss";
    const tz = getStoreTimeZone(store);
    const nowStr = moment.tz(moment(), tz).format(LOGIC_FORMAT);
    const now = moment(nowStr, LOGIC_FORMAT);
    return now;
};

export const isOpenNow = (hours = [], lan) => {
    //update locale
    updateMoment("en");

    //open now logic
    const now = moment().locale("en");
    const start = moment(hours[0], "hh:mma").locale("en");
    const end = moment(hours[1], "hh:mma").locale("en");

    if (is24Hours(hours)) {
        return true;
    }

    if (
        hours[1]?.includes("am") &&
        hours[0]?.includes("am") &&
        (Number(hours[0]?.split(":")[0]) >= Number(hours[1]?.split(":")[0]) || Number(hours[1]?.split(":")[0] === "12"))
    ) {
        end.add(1, "d");
    }

    const result = now.isBetween(start, end);

    //update locale
    updateMoment(lan);
    return result;
};

export const isOpenCurrentHoursRange = (hoursRange, lan) => {
    const hours = hoursRange.split("-");
    //update locale
    updateMoment("en");

    //open now logic
    const now = moment().locale("en");
    const start = moment(hours[0], "hh:mma").locale("en");
    const end = moment(hours[1], "hh:mma").locale("en");

    if (is24Hours(hours)) {
        return true;
    }

    if (
        hours[1]?.includes("am") &&
        hours[0]?.includes("am") &&
        (Number(hours[0]?.split(":")[0]) >= Number(hours[1]?.split(":")[0]) || Number(hours[1]?.split(":")[0] === "12"))
    ) {
        end.add(1, "d");
    }

    const result = now.isBetween(start, end);

    //update locale
    updateMoment(lan);
    return result;
};

export const is24Hours = (hours) => {
    return Number(hours[0]) === 24;
};

export const formatHour = (hour, lan) => {
    let result = "N/A";

    const currentTime = moment(hour, "hh:mma");
    if (currentTime.isValid()) {
        //update locale to format time to get correct 24 hours format
        updateMoment("en");
        const twoFourHours = moment(hour, "hh:mma").locale("en").format("HH:mm");

        //update back to current locale
        updateMoment(lan);
        result = moment(twoFourHours, "HH:mm").format("hh:mm A");
    }

    return result;
};

//method to find out the next open hour
//return
// true if it's currently open
// 'N/A' if no openning it's found
// 'xx:xx' if next open hours is today at xx:Xx
//'MON' if next open hours is the coming MON and hours is 24
// 'MON xx:xx' if next open hours is  the coming Mon and hours is provided
export const getNextAvailableHour = (hours = [], lan) => {
    let result = getTodayOpenHour(hours, lan);
    if (!result) {
        for (var i = 1; i < 7; i++) {
            updateMoment("en");
            const weekday = moment().add(i, "days").format("ddd");
            updateMoment(lan);
            const openHour = getWeekdayOpenHour(weekday, hours, lan);

            if (openHour) {
                result = openHour;
                break;
            }
        }
    }

    return result ? result : "N/A";
};

//method to find out open hours for today
//return
//true if it is opening
//false if no opening found
// xx:xx if anytime today
export const getTodayOpenHour = (hours, lan) => {
    updateMoment("en");

    const today = moment().format("ddd").toUpperCase();
    const todayHoursStr = helper.isString(_.get(hours, today)) ? _.get(hours, today) : "";
    const todayHoursArray = todayHoursStr.split("-");
    var result = false;

    if (todayHoursStr === "24") {
        result = true;
    } else if (todayHoursArray.length === 2) {
        const now = moment().locale("en");
        const start = moment(todayHoursArray[0], "hh:mma").locale("en");
        const end = moment(todayHoursArray[1], "hh:mma").locale("en");
        if (
            todayHoursArray[1]?.includes("am") &&
            todayHoursArray[0]?.includes("am") &&
            Number(todayHoursArray[0]?.split(":")[0]) >= Number(todayHoursArray[1]?.split(":")[0])
        ) {
            end.add(1, "d");
        }
        if (now.isBefore(start)) {
            updateMoment(lan);
            result = formatHour(todayHoursArray[0], lan);
        }
        if (now.isBetween(start, end)) {
            result = true;
        }
    }
    updateMoment(lan);
    return result;
};

export const getWeekdayOpenHour = (day, hours, lan) => {
    //update locale
    const weekDayUpperCase = helper.isString(day) ? day.toUpperCase() : "";
    const todayHoursStr = helper.isString(_.get(hours, weekDayUpperCase)) ? _.get(hours, weekDayUpperCase) : "";
    const todayHoursArray = todayHoursStr.split("-");
    const weekDayLowerCase = day.toLowerCase();
    const weekDayTrans = locale.getIntlMessages(lan)[weekDayLowerCase];

    let result = false;
    if (todayHoursStr === "24") {
        result = `${weekDayTrans}`;
    } else if (todayHoursArray.length === 2) {
        result = `${weekDayTrans} ${formatHour(todayHoursArray[0], lan)}`;
    }

    return result;
};

export const getReviewInputs = () => [
    {
        key: "rating",
        control: "rating",
        name: "rating",
        label: "rate_the_store",
        hint: "rate_hint",
    },
    {
        key: "comment",
        control: "text",
        name: "comment",
        placeholder: "write_your_review_here",
        label: "write_your_review_here",
        multiline: true,
        rows: 5,
    },
];

export const getServiceRatingAverage = (revs) => {
    var average = 0;
    var count = 0;
    for (var i = 0; i < revs.length; i++) {
        average += _.get(revs[i], "svc_rat", 0);
        count++;
    }
    return average / count;
};

export const getProductRatingAverage = (revs) => {
    var average = 0;
    var count = 0;
    try {
        for (var i = 0; i < revs.length; i++) {
            for (var j = 0; j < _.get(revs[i], "p_review", []).length; j++) {
                average += revs[i].p_review[j].rat;
                count++;
            }
        }
    } catch (e) {
        return 0;
    }
    return average / count;
};

// check if the item is available now
export const isAvailableNow = (store, code) => {
    // if there is no time code, return TRUE
    if (!code) {
        return true;
    }
    const state = reduxStore?.getState();
    const lan = state?.settings?.lan;

    const storeData = store?.data || store || {};
    // the time_setting is (if it exists) an array of time codes with their respective names and available times
    const timeSetting = storeData.time_setting ?? [];

    // when the timeSetting is an array, time = the time selected
    const time = Array.isArray(timeSetting) && timeSetting?.find((t) => t.code === code);

    if (_.isEmpty(time)) {
        return true;
    } else {
        updateMoment("en");
        const now = getNowInStoreTimeZone(storeData);
        const nowDay = now.format("dd").toLowerCase();
        const availableTimes = time?.available_time ?? [];
        // Return TRUE if the item is available based on current time, FALSE otherwise
        const availableTime = availableTimes.find((t) => {
            if (t?.days.includes(nowDay)) {
                const hours = t?.hours ?? [];
                return hours.find((h) => {
                    const open = moment(h?.open, format);
                    const close = moment(h?.close, format);
                    return now.isBetween(open, close);
                });
            }
            return false;
        });
        updateMoment(lan);
        // return TRUE if the availableTime isn't empty, FALSE otherwise
        return !_.isEmpty(availableTime);
    }
};

export const getAvailabilityInfos = (store, code) => {
    if (!code) {
        return false;
    }
    const state = reduxStore?.getState();
    const lan = state?.settings?.lan;

    const timeSetting = store?.data?.time_setting ?? store?.time_setting ?? [];

    const time = Array.isArray(timeSetting) && timeSetting?.find((t) => t.code === code);
    const timeInfo = {};
    if (_.isEmpty(time)) {
        return true;
    } else {
        const availableTimes = time?.available_time ?? [];

        availableTimes.forEach((t) => {
            const days = t?.days;
            const hours = t?.hours;
            const hoursString = (hours ?? []).reduce((acc, val) => {
                acc.push(
                    `${moment(val.open, "H:mm").format("h:mm A")} - ${moment(val.close, "H:mm").format("h:mm A")}`
                );
                return acc;
            }, []);
            days.forEach((day) => {
                const d = day.charAt(0).toUpperCase() + day.slice(1);
                updateMoment("en");
                const enDate = moment(`${d}, 2020`, "dd, YYYY").format("YYYY-MM-DD");
                updateMoment(lan);
                const lanDate = moment(enDate);
                timeInfo[lanDate.format("ddd")] = hoursString;
            });
        });

        return timeInfo;
    }
};

export const getCombinedAvailabilityInfo = (availableTimes) => {
    const availabilities = Array.isArray(availableTimes) ? availableTimes : [];
    const state = reduxStore?.getState();
    const lan = state?.settings?.lan;
    const timeInfo = {};

    availabilities.forEach((t) => {
        const days = Array.isArray(t?.days) ? t?.days : [];
        const hours = Array.isArray(t?.hours) ? t?.hours : t?.hours === "ALL" ? ["ALL"] : [];
        const hoursString = hours.reduce((acc, val) => {
            if (val === "ALL") {
                acc.push(`${moment("00:00", "H:mm").format("h:mm A")} - ${moment("23:59", "H:mm").format("h:mm A")}`);
            } else {
                acc.push(
                    `${moment(val.open, "H:mm").format("h:mm A")} - ${moment(val.close, "H:mm").format("h:mm A")}`
                );
            }
            return acc;
        }, []);

        days.forEach((day) => {
            const d = day.charAt(0).toUpperCase() + day.slice(1);
            updateMoment("en");
            const enDate = moment(`${d}, 2020`, "dd, YYYY").format("YYYY-MM-DD");
            updateMoment(lan);
            const lanDate = moment(enDate);
            const prevTimeInfo = timeInfo[lanDate.format("ddd")];
            timeInfo[lanDate.format("ddd")] = prevTimeInfo ? `${prevTimeInfo}, ${hoursString}` : hoursString;
        });
    });
    let combinedTimeInfo = {};
    Object.keys(timeInfo).forEach((key) => {
        const times = Array.isArray(timeInfo[key]) ? timeInfo[key] : [];
        times.forEach((time) => {
            let previouDays = _.get(combinedTimeInfo, time, []);
            previouDays.push(key);
            combinedTimeInfo[time] = previouDays;
        });
    });
    let result = {};
    Object.keys(combinedTimeInfo).forEach((key) => {
        //get all weekdays and make mon the first day
        let weekdays = moment?.weekdaysShort();
        let sun = weekdays.shift();
        weekdays.push(sun);
        //sort days according to weekdays values
        let daysArray = Array.isArray(combinedTimeInfo[key]) ? combinedTimeInfo[key] : [];
        daysArray = _.sortBy(daysArray, (day) => _.indexOf(weekdays, day));
        //handle combined
        let sequeceDaysGroup = []; //key -> first weekday index, values: sequesce weekdays index array
        daysArray.forEach((day, index, arr) => {
            const weekdayIndex = _.indexOf(weekdays, day);
            if (_.isEmpty(sequeceDaysGroup)) {
                sequeceDaysGroup.push([weekdayIndex]);
            } else {
                //try to find out to add in any day group
                //if the last weekday in the group + 1 === current day, they are in sequece
                let nextDayGroupIndex = _.findIndex(sequeceDaysGroup, (weekdays) => {
                    const lastWeekDayIndex = weekdays[weekdays.length - 1];
                    return lastWeekDayIndex + 1 === weekdayIndex;
                });
                // if found, should be the same group. add to the group
                //if not found, create a new group
                if (nextDayGroupIndex !== -1) {
                    let nextDayGroup = sequeceDaysGroup[nextDayGroupIndex];
                    nextDayGroup.push(weekdayIndex);
                    sequeceDaysGroup[nextDayGroupIndex] = nextDayGroup;
                } else {
                    sequeceDaysGroup.push([weekdayIndex]);
                }
            }
        });
        //generate combined day str
        let days = "";
        sequeceDaysGroup.forEach((combinedDays) => {
            let addedStr = "";
            if (combinedDays.length === 1) {
                const firstItem = combinedDays[0];
                addedStr = weekdays[firstItem];
            } else if (combinedDays.length > 1) {
                const firstItem = combinedDays[0];
                const lastItem = combinedDays[combinedDays.length - 1];
                addedStr = `${weekdays[firstItem]}~${weekdays[lastItem]}`;
            }
            if (addedStr) days += days ? `, ${addedStr}` : addedStr;
        });
        const time = key;
        result[days] = time;
    });
    return result;
};

export const getInfoMapping = (store, time) => {
    const infos = getAvailabilityInfos(store, time);
    const keys = Object.keys(infos);
    let lastKey;
    return keys.reduce((acc, key, i) => {
        const setToLastKey = () => {
            lastKey = key;
            acc[key] = infos[key];
        };

        if (i !== 0) {
            if (acc[lastKey] === infos[key]) {
                delete acc[lastKey];
                lastKey = `${lastKey.split("-")[0]}-${key}`;
                acc[lastKey] = infos[key];
            } else {
                setToLastKey();
            }
        } else {
            setToLastKey();
        }
        return acc;
    }, {});
};

export const getUnavailableCartProducts = (store = {}, cart = [], products = []) => {
    return cart.reduce((acc, item) => {
        const product = Array.isArray(products) ? products.find((p) => p?.data?.pid === item.pid) : {};
        const isAvailable = isAvailableNow(store, product?.data?.time);

        if (!isAvailable) {
            acc.push(product);
        }

        return acc;
    }, []);
};

export const getDeliveryRatingAverage = (revs) => {
    var average = 0;
    var count = 0;
    for (var i = 0; i < revs.length; i++) {
        average += _.get(revs[i], "deliv_rat", 0);
        count++;
    }
    return average / count;
};

export const shouldHideCategory = (cat = "") => {
    if (helper.isUsingAliPay()) {
        let hide = false;

        ["giftcard", "membershipcard", "礼品卡", "会员卡", "充值卡", "储值卡", "會員卡"].forEach((check) => {
            if (cat.toLowerCase().replace(/\s/g, "").includes(check)) {
                hide = true;
            }
        });

        return hide;
    }
};

const getSubVal = (store) => {
    const d = _.get(store, "data.display_format");
    const t = _.get(store, "data.tab_display_style");
    let val = 64;
    if (d === 4) {
        val = val + 52;
    }
    if (t > 0) {
        val = val + 60;
    }
    return val;
};

export const getCategoryTitleScrollPoint = (catId, store) => {
    const el = document.getElementById(`${STORE_PRODUCTS_CATEGORY_TITLE_PREFIX}${catId}`);
    if (!el) {
        return;
    }

    //handle category title scrolling
    const subVal = getSubVal(store);
    const pageYOffset = window.pageYOffset;
    const documentScrollTop = (document?.documentElement || document?.body?.parentNode || document?.body)?.scrollTop;
    const distanceFromTop = pageYOffset || documentScrollTop;
    const y = el.getBoundingClientRect().top + distanceFromTop;
    return y - subVal;
};

//to use store as a pickup locaion
//return array
export const getStoreAsPickupLocation = (store) => {
    const storeName = _.get(store, "nm", "Store Name");
    const storeAdress = formatAddressObj(_.get(store, "adr", {}));
    const storePhone = _.get(store, "phone");
    return {
        _id: 1,
        name: storeName,
        pickup_location: {
            address: storeAdress,
            phone: storePhone,
        },
    };
};

//to get all the available pick up locations
//@return array
export const getPickupLocations = (store) => {
    const zoneRules =
        typeof _.get(store, "delivery_zone_rules") === "string"
            ? JSON.parse(_.get(store, "delivery_zone_rules"))
            : _.get(store, "delivery_zone_rules");
    const deliveryZones = Array.isArray(zoneRules) ? zoneRules : [];
    const withPickup = deliveryZones.filter((zone) => !_.isEmpty(_.get(zone, "pickup_location")));
    let result = allowMultiplePickup(store) && Array.isArray(withPickup) ? withPickup : [];
    if (_.isEmpty(result)) {
        result = [getStoreAsPickupLocation(store)];
    }
    return result;
};

export const getDeliveryLocations = (store) => {
    const zoneRules =
        typeof _.get(store, "delivery_zone_rules") === "string"
            ? JSON.parse(_.get(store, "delivery_zone_rules"))
            : _.get(store, "delivery_zone_rules");
    const deliveryZones = Array.isArray(zoneRules) ? zoneRules : [];
    const withPickup = deliveryZones.filter((zone) => _.isEmpty(_.get(zone, "pickup_location")));
    return allowMulitpleDelivery(store) && Array.isArray(withPickup) ? withPickup : [];
};

export const allowMulitpleDelivery = (store) => {
    const enableFlag = _.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.allow_multiple_delivery}`, 0);
    return String(enableFlag) === "1";
};

export const allowMultiplePickup = (store) => {
    const enableFlag = _.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.allow_multiple_pickup}`, 1);
    return String(enableFlag) === "1";
};

export const getMuplipleMinDeliveryAmount = (store) => {
    const locations = getDeliveryLocations(store);
    var min = 0;
    locations.forEach((location) => {
        const minDeli = _.get(location, "min_delivery_amt", 0);
        if (min === 0 || minDeli < min) min = minDeli;
    });
    return min;
};

export const getMuplipleMinPickupAmount = (store) => {
    const locations = getPickupLocations(store);
    var min = 0;
    locations.forEach((location) => {
        const minPickup = _.get(location, "min_pickup_amt", 0);
        if (min === 0 || minPickup < min) min = minPickup;
    });
    return min;
};

export const getShipMethodTransStr = (method, store) => {
    const methodTransMap = {
        delivery: "delivery",
        takeout: "pickup",
        eatin: isRestaurant(store) ? "eatin" : "in_store",
    };
    const trans = methodTransMap[method] ? methodTransMap[method] : " ";
    return trans;
};

export const getShipMethodsFromFlags = (sflg) => {
    const shipMethodValueIndexMap = {
        0: "delivery",
        1: "eatin",
        2: "takeout",
    };
    const flags = Array.isArray(sflg) ? sflg : [];
    let result = [];
    flags.forEach((value, index) => {
        const shippingValue = shipMethodValueIndexMap[index];
        if (value && shippingValue) result.push(shippingValue);
    });
    return result;
};

export const getMenuWithId = (menuId, store) => {
    const storeMenus = Array.isArray(_.get(store, "menus")) ? _.get(store, "menus") : [];
    return storeMenus.find((menu) => String(_.get(menu, "id")) === String(menuId));
};

export const getMenuShippingMethods = (menuId, store) => {
    const currentMenu = getMenuWithId(menuId, store);
    const menuSflg = _.get(currentMenu, "sflg");
    return getShipMethodsFromFlags(menuSflg);
};

export const isMenuAvailableNow = (menuId, store) => {
    const menu = getMenuWithId(menuId, store);
    const menuTimeCode = _.get(menu, "time");
    return isAvailableNow(store, menuTimeCode);
};

export const getMenuAvaibilityInfo = (menuId, store) => {
    const menu = getMenuWithId(menuId, store);
    const menuTimeCode = _.get(menu, "time");
    return getInfoMapping(store, menuTimeCode);
};

export const getAvailableMenuWithMethod = (store, method) => {
    const storeMenus = Array.isArray(_.get(store, "menus")) ? _.get(store, "menus") : [];
    const menu = storeMenus.find((menu) => {
        const menuId = _.get(menu, "id");
        const isEnabled = String(_.get(menu, "is_available")) === "1" || _.get(menu, "is_available");
        return isMenuAvailableNow(menuId, store) && getMenuShippingMethods(menuId, store).includes(method) && isEnabled;
    });
    const menuId = _.get(menu, "id");
    return menuId === 0 || menuId ? String(menuId) : null;
};

export const getAvailableMenuWithMethods = (store, methods) => {
    const storeMenus = Array.isArray(_.get(store, "menus")) ? _.get(store, "menus") : [];
    const menu = storeMenus.find((menu) => {
        const menuId = _.get(menu, "id");
        const isEnabled = String(_.get(menu, "is_available")) === "1" || _.get(menu, "is_available");
        const availableMethods = getMenuShippingMethods(menuId, store);
        const methodAvailable = methods.find((method) => availableMethods.includes(method));
        return isMenuAvailableNow(menuId, store) && methodAvailable && isEnabled;
    });
    const menuId = _.get(menu, "id");
    return menuId === 0 || menuId ? String(menuId) : null;
};

export const getStoreShippingMethods = (store, currentMenu) => {
    const storeShippingFlag = _.get(store, "sflg");
    const storeMethods = getShipMethodsFromFlags(storeShippingFlag);

    const menuMethods = currentMenu ? getMenuShippingMethods(currentMenu, store) : [];
    return menuMethods.length ? menuMethods : storeMethods;
};

export const shareStore = async ({ data, refCode, lan, type = "store" }) => {
    try {
        const baseUrl = window.location.origin;
        const otherUrl =
            type === "group-purchase"
                ? `/gsale/${_.get(data, "data.pid")}`
                : type === "product"
                ? `/product/${_.get(data, "data.pid")}`
                : !helper.getIndependentDomain()
                ? `/store/${_.get(data, "data.gid")}`
                : "";
        let url = `${baseUrl}${otherUrl}?`;
        if (lan) {
            url = url + "&lan=" + lan;
        }
        if (refCode) {
            url = url + "&ref=" + refCode;
        }
        const shareName = _.get(data, "data.nm");
        await navigator?.share({
            title: shareName,
            text: shareName,
            url,
        });
    } catch (e) {}
};

export const getDefaultLan = (lan) => {
    const availbleValues = AVAILABLE_LANS;
    const stateLan = lan;
    const localLan = helper.getLocalStorage("lan");
    const paramLan = _.get(helper.getUrlParameters(), "lan", null);
    const agentRequiredLan = helper.isUsingAliPay() ? "zh" : null;

    let result = "";
    if (availbleValues.includes(paramLan)) {
        result = paramLan;
    } else if (availbleValues.includes(localLan)) {
        result = localLan;
    } else if (availbleValues.includes(agentRequiredLan)) {
        result = agentRequiredLan;
    } else if (!helper.getIndependentDomain() && availbleValues.includes(stateLan)) {
        result = stateLan;
    }

    return result;
};

export const requireTableNumber = (store) => {
    const storeData = store?.data || store || {};
    const enableFlag = String(_.get(storeData, `kflg.${config.STORE_KFLG_INDEX_MAPPING.require_table_no}`, 0));
    return enableFlag === "1";
};

export const REQUIRE_UTENSIL_MAP = {
    do_not_show: "0",
    show_default_not_selected: "1",
    show_default_selected: "2",
};

export const getRequireUtensilValue = (store) => {
    return String(_.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.require_utensil}`, 0));
};

export const showRequireUtensil = (store) => {
    const value = getRequireUtensilValue(store);
    const resturant = isRestaurant(store);
    const showOnValues = [REQUIRE_UTENSIL_MAP.show_default_not_selected, REQUIRE_UTENSIL_MAP.show_default_selected];
    const defaultOn = showOnValues.includes(value);
    return resturant && defaultOn;
};

export const isMultipleSubOption = (option) => {
    return String(_.get(option, "qty_input")) === "1" || _.get(option, "qty_input");
};

export const validateOptions = (currentSelected = {}, product) => {
    let invalids = {};
    const options = Array.isArray(_.get(product, "data.opt", [])) ? _.get(product, "data.opt", []) : [];

    const validateSubOption = (option, selectedSubOpts) => {
        let subOptionInvalid = [];
        if (isMultipleSubOption(option)) {
            const subOptions = Array.isArray(option?.opts) ? option?.opts : [];
            subOptions.forEach((subOpt) => {
                const subOptMin = _.get(subOpt, "min", 0);
                const subOptMax = _.get(subOpt, "max", 1);
                const subOptId = _.get(subOpt, "id", 0);
                const selectedSubOpt =
                    selectedSubOpts.filter((selectedSubOpt) => String(selectedSubOpt) === String(subOptId)).length ?? 0;
                if (selectedSubOpt < subOptMin || selectedSubOpt > subOptMax) subOptionInvalid.push(subOptId);
            });
        }
        return subOptionInvalid;
    };

    Object.keys(currentSelected).forEach((id) => {
        const option = options.find((opt) => String(opt?.id) === String(id));
        const min = _.get(option, "min", 0);
        const max = _.get(option, "max", 0);
        const selectedSubOpts = currentSelected?.[id] ?? [];
        if (selectedSubOpts.length < min || selectedSubOpts.length > max) {
            invalids[option?.id] = validateSubOption(option, selectedSubOpts);
        }
    });

    return invalids;
};

export const STORE_DISTANCE_UNIT_MAP = {
    km: "1",
    mi: "2",
};
export const getStoreDistanceUnitNumberic = (store) => {
    const value = String(_.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.distance_unit}`));
    return Object.values(STORE_DISTANCE_UNIT_MAP).includes(value) ? value : STORE_DISTANCE_UNIT_MAP.km;
};
const KM_LOCALIZED_MAP = {
    en: "km",
    es: "km",
    "fr-lang": "km",
    jp: "km",
    kr: "km",
    "zh-Hant": "公里",
    zh: "公里",
};
const MI_LOCALIZED_MAP = {
    en: "mi",
    es: "mi",
    "fr-lang": "mi",
    jp: "mi",
    kr: "mi",
    "zh-Hant": "英里",
    zh: "英里",
};
// localized distance unit
export const getStoreDistanceUnit = (store) => {
    const distanceUnitNum = getStoreDistanceUnitNumberic(store);
    const lan = store?.lan ?? "en";
    const map = distanceUnitNum === STORE_DISTANCE_UNIT_MAP.km ? KM_LOCALIZED_MAP : MI_LOCALIZED_MAP;
    return map[lan];
};
//unit is in meters
export const getDisplayDistance = (distanceInM, store) => {
    const unit = getStoreDistanceUnitNumberic(store);
    const unitStr = getStoreDistanceUnit(store);
    let distance = 0;
    if (unit === STORE_DISTANCE_UNIT_MAP.km) {
        distance = metersToKm(distanceInM).toFixed(2);
    } else if (unit === STORE_DISTANCE_UNIT_MAP.mi) {
        distance = metersToMiles(distanceInM).toFixed(2);
    }
    if (distance < 1) {
        return `<1 ${unitStr}`;
    } else if (distance > 1000) {
        return `+1000 ${unitStr}`;
    } else {
        return `${distance} ${unitStr}`;
    }
};

//support_guest_order // Default: 0.
//Decimal conversions of the binary values of the possible combinations indicating which shipping methods
// allow guest orders. this one flag represents the guest checkout permission for 4 different
// type of orders, convert the decimal to binary to check/store the setting,
//the 5 digit binary number represents [dine in, pick up, and delivery, instant pay],
//e.g. 10010 binary value means the dine-in and instant pay allows guest checkout, and the decimal number is 9
const shippingMap = config.SHIPPING_MAPPING_TO_NUMERIC;
export const GUEST_CHECKOUT_BINARY_INDEX = {
    [shippingMap.eatin]: 0b10000,
    [shippingMap.pickup]: 0b01000,
    [shippingMap.delivery]: 0b00100,
    [shippingMap.freeShipping]: 0b00010,
    [shippingMap.instantCheckout]: 0b00001,
};

export const allowGuestCheckout = (store, method) => {
    const storeData = store?.data || store || {};

    //get enable arrays
    const value = _.get(storeData, `kflg.${config.STORE_KFLG_INDEX_MAPPING.guest_checkout_support}`);
    const digitValue = Number(value) ? Number(value) : 0;

    //find check index
    const methodValue = Number(method) ? String(method) : _.get(shippingMap, method);
    const checkIndex = _.get(GUEST_CHECKOUT_BINARY_INDEX, methodValue, 0b1111);
    const allow = (digitValue & checkIndex) > 0;

    return allow;
};

const paymentMap = config.PAYMENT_METHOD_NUMERIC_MAPPING;
export const OFFLINE_PAYMENT_BINARY_INDEX = {
    [shippingMap.eatin]: 0b10000,
    [shippingMap.pickup]: 0b01000,
    [shippingMap.delivery]: 0b00100,
    [shippingMap.freeShipping]: 0b00010,
    [shippingMap.instantCheckout]: 0b00001,
};
export const OFFLINE_PAYMENTS = [paymentMap.pay_later, paymentMap.cash];

export const allowPayment = (storeParams, payment, method) => {
    //normal payment flag logic
    const store = storeParams?.data || storeParams;
    const paymentFlags = Array.isArray(_.get(store, "pflg")) ? _.get(store, "pflg") : [];
    const paymentValue = Number(payment)
        ? helper.getKeyByValue(config.PAYMENT_METHOD_NUMERIC_MAPPING, payment)
        : String(payment);
    const checkPaymentIndex = _.get(config.STORE_PFLG_INDEX_MAPPING, paymentValue, -1);
    const paymentAllowSingleCheck = (index) => String(_.get(paymentFlags, index, 1)) === "1";
    let paymentAllow = true;
    if (Array.isArray(checkPaymentIndex)) {
        paymentAllow = !checkPaymentIndex.find((index) => !paymentAllowSingleCheck(index));
    } else {
        paymentAllow = paymentAllowSingleCheck(checkPaymentIndex);
    }

    //off line payment with method logic
    const value = _.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.allow_offline_pay}`);
    const digitValue = Number(value) ? Number(value) : 0;
    const methodValue = Number(method) ? String(method) : _.get(shippingMap, method);
    const checkIndex = _.get(OFFLINE_PAYMENT_BINARY_INDEX, methodValue, -1);
    const methodAllow = (digitValue & checkIndex) > 0;

    //if it's off line payment, also check with method
    const paymentNumericValue = Number(payment)
        ? Number(payment)
        : _.get(config.PAYMENT_METHOD_NUMERIC_MAPPING, payment);
    if (OFFLINE_PAYMENTS.includes(paymentNumericValue)) {
        return paymentAllow && methodAllow;
    } else {
        const onlinePayBlockedForMethod =
            ((digitValue >> Object.keys(OFFLINE_PAYMENT_BINARY_INDEX).length) & checkIndex) > 0;
        return paymentAllow && !onlinePayBlockedForMethod;
    }
};

export const getPrepTime = (store, method) => {
    //delivery value
    const deliveryValue = _.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.time_interval}`);

    //pick value
    const pickupValue = _.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.pickup_prep_time}`);

    const methodValue = Number(method) ? String(method) : _.get(shippingMap, method);
    if (methodValue === shippingMap.pickup) {
        return pickupValue;
    }
    return deliveryValue;
};

export const requireScanForDineIn = (store) => {
    return String(_.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.scan_qr_dine_in}`)) === "1";
};

export const getAnnouncementMessage = ({ store: passedStore, shippingMethod: deliveryMethod }) => {
    const store = passedStore?.data ?? passedStore;

    // Public notice format:
    //  if delivery is missing, choose pick-up, then dine-in;
    //  if pick-up is missing, choose dine-in, then delivery;
    //  if dine-in is missing, choose pick-up, then delivery
    const pn = store?.pn;
    const shippingMethod = Number(config.SHIPPING_MAPPING_TO_NUMERIC[deliveryMethod]);
    const delivery = Number(config.SHIPPING_MAPPING_TO_NUMERIC.delivery);
    const pickUp = Number(config.SHIPPING_MAPPING_TO_NUMERIC.pickup);
    const dineIn = Number(config.SHIPPING_MAPPING_TO_NUMERIC.eatin);

    const matchNotice = pn?.find((p) => p?.type?.includes(shippingMethod));
    const deliveryNotice = pn?.find((p) => p?.type?.includes(delivery));
    const pickUpNotice = pn?.find((p) => p?.type?.includes(pickUp));
    const dineInNotice = pn?.find((p) => p?.type?.includes(dineIn));
    const backupNoticeDelivery = pickUpNotice ?? dineInNotice;
    const backupNoticePickup = dineInNotice ?? deliveryNotice;
    const backupNoticeDineIn = pickUpNotice ?? deliveryNotice;

    const backupNotice =
        shippingMethod === delivery
            ? backupNoticeDelivery
            : shippingMethod === pickUp
            ? backupNoticePickup
            : backupNoticeDineIn;

    const oldNotice = store?.pa;
    const publicNotice = matchNotice ?? backupNotice;

    return publicNotice?.msg ?? oldNotice;
};

export const storeUseCloudStorage = (store) => {
    const value = Number(_.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.sync_cart_with_server}`));
    return value > 0;
};
export const storeCloudUploadGap = (store) => {
    const value = Number(_.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.sync_cart_with_server}`));
    return value === 0 || value === 1 ? 0 : value * 1000;
};

export const getAgentPayment = (store, shippingMethod) => {
    const agent = getUserAgent();
    const agentPayment = config.AGENT_PAYMENT_MAPPING[agent];
    if (agentPayment && allowPayment(store, agentPayment, shippingMethod)) {
        return agentPayment;
    }
    return "";
};

export const CC_GATEWAY_VALUES = {
    offline: "0",
    paypal: "1",
    stripe: "2",
    braintee: "3",
};

export const getStoreCCGateway = (store) => {
    const value = Number(_.get(store, `kflg.${config.STORE_KFLG_INDEX_MAPPING.default_cc_gateway}`));
    return String(value) === CC_GATEWAY_VALUES.braintee ? "braintree" : "normal";
};

export const QUERY_KEY_TYPE = {
    menuId: "mid",
    categoryId: "cid",
};

/**
 * Replace the selected query(ex: menu id(mid) or category id(cid)) on the url.
 * Reload the current page if it is needed after the replacement.
 *
 * @param selectedQueryKey - param key string of query(ex: mid, cid)
 * @param selectedQueryValue - param value of query
 * @param shouldReload - after selected query replacement, should reload or not
 */
export const replaceSelectedQuery = (selectedQueryKey, selectedQueryValue, shouldReload = false) => {
    if (_.isNil(selectedQueryValue)) {
        return;
    }

    let query = qs.parse(window?.location.search);
    if (selectedQueryKey === QUERY_KEY_TYPE.menuId) {
        query = _.omit(query, QUERY_KEY_TYPE.categoryId);
    }

    const path = window?.location?.pathname;
    const queryString = qs.stringify(Object.assign(query, { [selectedQueryKey]: selectedQueryValue }));
    replace(`${path}?${queryString}`);

    if (shouldReload) {
        window.location.reload();
    }
};

const exports = {
    STORE_DISTANCE_UNIT_MAP,
    getStoreUserDistance,
    getDefaultShippingMethod,
    allowMulitpleDelivery,
    getMaxDeliveryDistance,
    getStoreDistanceUnit,
    getStoreDistanceUnitNumberic,
    replaceSelectedQuery,
    QUERY_KEY_TYPE,
};

export default exports;
