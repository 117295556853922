import { createSlice } from "@reduxjs/toolkit";
import helper from "utils/helper";
import { AVAILABLE_LANS } from "./helper";

const settings = createSlice({
    name: "settings",
    initialState: {
        lan: helper.getDefaultLan(),
        languagePickerShow: false,
        languageList: [
            { label: "en", value: "en" },
            { label: "zh", value: "zh" },
            { label: "zh-Hant", value: "zh-Hant" },
            { label: "kr", value: "kr" },
            { label: "jp", value: "jp" },
            { label: "es", value: "es" },
            { label: "fr-lang", value: "fr" },
            { label: "de", value: "de" },
        ],
    },
    reducers: {
        changeLanguage: (state, { payload }) => {
            const changedLan = AVAILABLE_LANS.includes(payload?.language) ? payload?.language : AVAILABLE_LANS[0];
            state.lan = changedLan;
            helper.setLocalStorage("lan", changedLan);
        },
        showLanguagePicker: (state, { payload }) => {
            state.languagePickerShow = !state.languagePickerShow;
        },
    },
});

export const { changeLanguage, showLanguagePicker } = settings.actions;

export default settings.reducer;
