import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    device_token: "",
    provider: "",
};

const notifications = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
    },
});

export const {
    //to components
    setState,
    //to service
    clearData,
} = notifications.actions;

export default notifications.reducer;
