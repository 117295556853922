import { createSlice } from "@reduxjs/toolkit";
import { createAnimatedButton } from "components/animated-cart-button/slice";
import config from "config";
import _ from "lodash";
import moment from "moment";
import helper from "utils/helper";
import { store } from "../app/store";
import { requireScanForDineIn } from "../store-page/helper";
import { calculateDiscount, enoughOptionToAdd, enoughProductToAdd, getTableNumber } from "./helpers";

const LOCAL_KEY_STATE_KEY_MAP = {
    shoppingCartMethod: "shippingMethod",
    shoppingCartProducts: "products",
    shoppingCartTotal: "total",
    shoppingCartMenu: "menu",
    shoppingCartTable: "tables",
    shoppingCart: "cart",
    selectedMenu: "selectedMenu",
};

const removeItemHelper = (state, payload) => {
    const gid = _.get(payload, "store.group_id", _.get(payload, "store.gid"));
    const cart = _.get(state, `cart.${gid}`, []);
    let products = _.cloneDeep(_.get(state, `products.${gid}`, []));
    const existingIndex =
        payload.options && !payload.removeAll
            ? cart.findIndex((product) => {
                  const isSameProduct = _.get(product, "pid", "") === _.get(payload, "product.pid", "");
                  const isSameOptions = _.isEqual(_.get(product, "options", []), _.get(payload, "options", []));
                  return isSameProduct && isSameOptions;
              })
            : cart.findIndex((product) => product.pid === payload.product.pid);

    if (existingIndex !== -1) {
        const minSaleQty = cart[existingIndex].min_sq ?? 1;
        cart[existingIndex].qty -= 1;
        if (cart[existingIndex].qty < minSaleQty || payload.removeAll) {
            cart.splice(existingIndex, 1);

            if (!cart.find((item) => item.pid === payload.product.pid)) {
                products = products.filter((product) => product.pid !== payload.product.pid);
            }
        }
    }

    state.cart[gid] = cart;
    //close cart is last item was cleared
    if (_.isEmpty(cart)) state.isOpen = false;

    state.products[gid] = products;

    const total = cart.reduce((acc, item) => acc + item.originalPrice * item.qty, 0);
    const discounted = cart.reduce((acc, item) => acc + item.price * item.qty, 0);
    state.total[gid] = {
        original: total,
        discounted: calculateDiscount(payload.store, discounted, state.shippingMethod[gid], cart),
    };

    //update table exprity time
    let update = getTableNumber(_.cloneDeep(state), gid);
    update.time = moment().valueOf();
    state.tables[gid] = update;

    updateLocalStorage(state, gid);
};

const hasExceededPurchasingLimit = (cart, product) => {
    const purchaseLimit = getMaxSaleQty(product);
    // product has no max sale limit
    if (purchaseLimit === 0) {
        return false;
    }

    let productQtyInCart = 0;
    cart.forEach((cartProduct) => {
        if (cartProduct.pid === product.pid) {
            productQtyInCart += cartProduct.qty;
        }
    });
    return productQtyInCart > purchaseLimit;
};

const displayPurchasingLimitExceededErrorDialog = (state, purchaseLimit) => {
    state.gDisplayModal = true;
    state.gDisplayModalTitle = "warning_max_purchase_limit_reached";
    state.gDisplayModalContent = "max_purchase_limit_reached_msg";
    state.gDisplayModalKeywords = { max_sale_qty: parseFloat(purchaseLimit) };
    state.gDisplayModalHandlingType = "continue";
};

const getMinSaleQty = (product) => {
    if (!isNaN(parseInt(product?.min_sq))) {
        return parseInt(product?.min_sq);
    }
    return 1;
};

const getMaxSaleQty = (product) => {
    if (!isNaN(parseInt(product?.max_sq))) {
        return parseInt(product?.max_sq);
    }
    return 0;
};

const shoppingCart = createSlice({
    name: "shoppingCart",
    initialState: {
        urlQuery: {},
        loading: false,
        isOpen: false,
        oosItems: [],
        currentGroupId: !_.isEmpty(helper.getLocalStorage("currentGroupId"))
            ? helper.getLocalStorage("currentGroupId")
            : {},
        cart: !_.isEmpty(helper.getLocalStorage("shoppingCart")) ? helper.getLocalStorage("shoppingCart") : {},
        shippingMethod: !_.isEmpty(helper.getLocalStorage("shoppingCartMethod"))
            ? helper.getLocalStorage("shoppingCartMethod")
            : {},
        products: !_.isEmpty(helper.getLocalStorage("shoppingCartProducts"))
            ? helper.getLocalStorage("shoppingCartProducts")
            : {},
        total: !_.isEmpty(helper.getLocalStorage("shoppingCartTotal"))
            ? helper.getLocalStorage("shoppingCartTotal")
            : {},
        menu: !_.isEmpty(helper.getLocalStorage("shoppingCartMenu")) ? helper.getLocalStorage("shoppingCartMenu") : {},
        tables: !_.isEmpty(helper.getLocalStorage("shoppingCartTable"))
            ? helper.getLocalStorage("shoppingCartTable")
            : {},
        selectedMenu: !_.isEmpty(helper.getLocalStorage("selectedMenu")) ? helper.getLocalStorage("selectedMenu") : {},
    },
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        addItem(state, { payload }) {
            const gid = payload.store?.group_id;
            const cart = _.cloneDeep(_.get(state, `cart.${gid}`, []));
            const products = _.cloneDeep(_.get(state, `products.${gid}`, []));
            const productType = payload?.product?.pt;
            const isSelfInput = productType === 2;
            let price = isSelfInput ? payload.selfInput : helper.getPrice({ data: payload.product });
            let originalPrice = isSelfInput ? payload.selfInput : payload?.product?.pc;
            const minSaleQty = getMinSaleQty(payload?.product);
            const maxSaleQty = getMaxSaleQty(payload?.product);
            const special_request = payload.special_request;

            const existingIndex = cart.findIndex((product) => {
                const isSameProduct = product.pid === payload?.product?.pid;
                const isSameOptions = _.isEqual(_.get(product, "options", {}), _.get(payload, "options", {}));
                const isSameSpecialRequest = _.isEqual(
                    _.get(product, "special_request", ""),
                    _.get(payload, "special_request", "")
                );
                return isSameProduct && isSameOptions && isSameSpecialRequest;
            });
            if (existingIndex !== -1) {
                if (productType === 2) {
                    cart[existingIndex].price = price;
                    cart[existingIndex].originalPrice = originalPrice;
                    cart[existingIndex].qty = _.get(payload, "qty", 1);
                } else {
                    cart[existingIndex].qty += _.get(payload, "qty", 1);
                }
            } else {
                let options = _.get(payload, "options", {});

                Object.keys(options).forEach((id) => {
                    const option = _.get(payload, "product.opt", []).find((opt) => String(opt?.id) === String(id));
                    const selected = Array.isArray(options?.[id]) ? options?.[id] : [];
                    const subOptons = Array.isArray(option?.opts) ? option?.opts : [];
                    if (!_.isEmpty(option)) {
                        selected.forEach((selectedId) => {
                            const opt = subOptons.find((subOpt) => Number(subOpt.id) === Number(selectedId));
                            price += Number(_.get(opt, "pc")) ?? 0;
                            originalPrice += Number(_.get(opt, "pc")) ?? 0;
                        });
                    }
                });
                //Issue 14502 - Prevent adding empty product name to cart due to unknown gid corruption or invalid product
                let product_name = _.get(payload,"product.nm") 
                if( _.isString(product_name) && !_.isEmpty(product_name.trim()))
                {
                    cart.push({
                        pid: payload?.product?.pid,
                        qty: _.get(payload, "qty", minSaleQty),
                        price,
                        originalPrice,
                        productType: payload?.product?.pt,
                        nm: payload?.product?.nm,
                        options: _.get(payload, "options", {}),
                        menu: payload?.selectedMenu,
                        min_sq: minSaleQty,
                        max_sq: maxSaleQty,
                        special_request,
                    });
                    
                    if (!products.find((product) => product.pid === payload?.product?.pid)) {
                        products.push(payload.product);
                    }
                }
            }

            if (hasExceededPurchasingLimit(cart, payload?.product)) {
                displayPurchasingLimitExceededErrorDialog(state, maxSaleQty);
                return;
            }

            state.cart[gid] = cart;
            state.products[gid] = products;

            const total = cart.reduce((acc, item) => acc + item.originalPrice * item.qty, 0);
            const discounted = cart.reduce((acc, item) => acc + item.price * item.qty, 0);
            state.total[gid] = {
                original: total,
                discounted: calculateDiscount(
                    payload.store,
                    discounted,
                    state.shippingMethod[payload.store.group_id],
                    cart
                ),
            };

            //selected menu save logic
            const selectedMenu = payload?.selectedMenu;
            if (selectedMenu) {
                state.menu[gid] = selectedMenu;
                state.selectedMenu[gid] = selectedMenu;
            }

            //update table exprity time
            let update = getTableNumber(_.cloneDeep(state), gid);
            update.time = moment().valueOf();
            state.tables[gid] = update;

            updateLocalStorage(state, gid);
        },
        setItemQty(state, { payload }) {
            const gid = payload.store?.group_id;
            const cart = _.cloneDeep(_.get(state, `cart.${gid}`, []));
            const products = _.cloneDeep(_.get(state, `products.${gid}`, []));
            const productType = payload?.product?.pt;
            const isSelfInput = productType === 2;
            const index = payload?.index;
            let price = isSelfInput ? payload.selfInput : helper.getPrice({ data: payload.product });
            let originalPrice = isSelfInput ? payload.selfInput : payload?.product?.pc;
            const special_request = payload.special_request;

            const existingIndex =
                index ??
                cart.findIndex((product) => {
                    const isSameProduct = product.pid === payload?.product?.pid;
                    const isSameOptions = _.isEqual(_.get(product, "options", {}), _.get(payload, "options", {}));
                    return isSameProduct && isSameOptions;
                });
            if (existingIndex !== -1) {
                if (productType === 2) {
                    cart[existingIndex].price = price;
                    cart[existingIndex].originalPrice = originalPrice;
                    cart[existingIndex].qty = _.get(payload, "qty", 1);
                } else {
                    cart[existingIndex].qty = _.get(payload, "qty", 1);
                    if (payload.options) {
                        cart[existingIndex].options = payload?.options ?? {};
                    }
                }
            } else {
                let options = _.get(payload, "options", {});

                Object.keys(options).forEach((id) => {
                    const option = _.get(payload, "product.opt", []).find((opt) => String(opt?.id) === String(id));
                    const selected = Array.isArray(options?.[id]) ? options?.[id] : [];
                    const subOptons = Array.isArray(option?.opts) ? option?.opts : [];
                    if (!_.isEmpty(option)) {
                        selected.forEach((selectedId) => {
                            const opt = subOptons.find((subOpt) => subOpt.id === selectedId);
                            price += Number(_.get(opt, "pc")) ?? 0;
                            originalPrice += Number(_.get(opt, "pc")) ?? 0;
                        });
                    }
                });

                cart.push({
                    pid: payload?.product?.pid,
                    qty: _.get(payload, "qty", 1),
                    price,
                    originalPrice,
                    productType: payload?.product?.pt,
                    nm: payload?.product?.nm,
                    options: _.get(payload, "options", {}),
                    menu: payload?.selectedMenu,
                    min_sq: getMinSaleQty(payload?.product),
                    max_sq: getMaxSaleQty(payload?.product),
                    special_request,
                });

                if (!products.find((product) => product.pid === payload?.product?.pid)) {
                    products.push(payload.product);
                }
            }

            if (hasExceededPurchasingLimit(cart, payload?.product)) {
                displayPurchasingLimitExceededErrorDialog(state, getMaxSaleQty(payload?.product));
                return;
            }

            state.cart[gid] = cart;
            state.products[gid] = products;

            const totalOriginalPrice = cart.reduce((acc, item) => acc + item.originalPrice * item.qty, 0);
            const totalPrice = cart.reduce((acc, item) => acc + item.price * item.qty, 0);

            state.total[gid] = {
                original: totalOriginalPrice,
                discounted: calculateDiscount(
                    payload.store,
                    totalPrice,
                    state.shippingMethod[payload.store.group_id],
                    cart
                ),
            };

            //selected menu save logic
            const selectedMenu = payload?.selectedMenu;
            if (selectedMenu) {
                state.menu[gid] = selectedMenu;
                state.selectedMenu[gid] = selectedMenu;
            }

            //update table exprity time
            let update = getTableNumber(_.cloneDeep(state), gid);
            update.time = moment().valueOf();
            state.tables[gid] = update;

            updateLocalStorage(state, gid);
        },
        removeItem(state, { payload }) {
            removeItemHelper(state, payload);
        },
        removeAllItemsByProductId(state, { payload }) {
            const gid = _.get(payload, "store.group_id", _.get(payload, "store.gid"));
            const cart = _.get(state, `cart.${gid}`, []);
            const removalId = _.get(payload, "removed_id");

            let index;
            while (index !== -1) {
                index = cart.findIndex((product) => product.pid === removalId);
                if (index !== -1) {
                    removeItemHelper(state, payload);
                }
            }
        },
        setShippingMethod(state, { payload }) {
            const gid = _.get(payload, "store.group_id", "");
            if (gid) {
                const cart = state.cart[gid] || [];
                const total = cart.reduce((acc, item) => acc + item.originalPrice * item.qty, 0);
                const discounted = cart.reduce((acc, item) => acc + item.price * item.qty, 0);

                //discount
                state.total[payload.store.group_id] = {
                    original: total,
                    discounted: calculateDiscount(payload.store, discounted, payload.method, cart),
                };

                //method
                state.shippingMethod[gid] = payload.method;
                state.shippingMethodEventValue = payload.method;
                updateLocalStorage(state, gid);
            }
        },
        toggleDrawer(state) {
            state.isOpen = !state.isOpen;
        },
        openDrawer(state) {
            state.isOpen = true;
        },
        closeDrawer(state) {
            state.isOpen = false;
        },
        clearCart(state, { payload }) {
            state.cart[payload.group_id] = [];
            state.products[payload.group_id] = [];
            state.total[payload.group_id] = {};
            state.currentGroupId[payload.group_id] = "";
            state.menu[payload.group_id] = "";
            updateLocalStorage(state, payload.group_id, false);
            if (payload?.callBack) payload.callBack();
        },
        clearCartAll(state, { payload }) {
            //also clear method and table,
            state.currentGroupId[payload.group_id] = "";
            state.cart[payload.group_id] = [];
            state.shippingMethod[payload.group_id] = "";
            state.products[payload.group_id] = [];
            state.total[payload.group_id] = {};
            state.menu[payload.group_id] = "";
            state.tables[payload.group_id] = "";
            updateLocalStorage(state, payload.group_id, false);
        },
        updateTable(state, { payload }) {
            //original
            const gid = payload?.store?.gid;
            if (!gid) return;
            const originalCopy = _.cloneDeep(getTableNumber(_.cloneDeep(state), gid));
            let update = getTableNumber(_.cloneDeep(state), gid);
            //update table id
            const table = payload.table || payload.table === 0 ? String(payload.table) : "";
            //set updated table number to id, which is the table number
            if (table?.trim()?.length > 0) {
                update.id = table;
            }
            else if (payload.table == null){
                update.id = null;
            }
            //update type
            const tableTypes = config.TABLE_TYPES;
            const inputType = String(payload.tableType);
            if (Object.values(tableTypes).includes(inputType)) {
                update.type = inputType;
            }
            if (!_.get(update, "type")) {
                update.type = tableTypes.table;
            }
            
            //show switch table clear dialog
            const requireScan = requireScanForDineIn(payload?.store);
            const cartNotEmpty = !_.isEmpty(payload?.cart);
            const tableUpdated = !_.isEqual(update, originalCopy);
            const previousTable = _.get(originalCopy, "id");
            if (requireScan && cartNotEmpty && tableUpdated && previousTable) {
                state.confirmDineInClearCart = true;
            }
            //update time
            update.time = moment().valueOf();
            //update to state
            state.tables[gid] = update;

            updateLocalStorage(state, gid);
        },
        clearTable(state, { payload }) {
            state.tables[payload.group_id] = {};
            updateLocalStorage(state, payload.group_id);
        },
        updateCartItems(state, { payload }) {
            const oos_items = [];
            const gid = payload?.gid;
            const products = Array.isArray(payload?.products) ? payload?.products : [];
            const cart = Array.isArray(state?.cart?.[gid]) ? state?.cart?.[gid] : [];

            state.products[gid] = _.cloneDeep(products);

            //remove oos items
            var newCart = [];
            cart.forEach((item, i) => {
                const product = products.find((p) => p.pid === item.pid);
                const enoughProduct = enoughProductToAdd(newCart, product);
                const enoughOption = enoughOptionToAdd(newCart, product, item);
                if (enoughProduct && enoughOption && product) {
                    newCart.push(item);
                } else {
                    oos_items.push(item.nm);
                }
            });
            state.cart[gid] = newCart;

            const total = cart.reduce((acc, item) => acc + item.originalPrice * item.qty, 0);
            const discounted = cart.reduce((acc, item) => acc + item.price * item.qty, 0);

            state.total[gid] = {
                original: total,
                discounted: calculateDiscount(
                    payload?.store,
                    discounted,
                    state?.shippingMethod?.[payload?.store?.group_id],
                    cart
                ),
            };

            updateLocalStorage(state, gid);

            if (oos_items?.length > 0) {
                state.oosItems = oos_items;
            }
        },
        orderAgainSuccess(state, { payload }) {
            const products = payload.products;
            const oos_items = [];

            state.products[payload.gid] = payload.products;

            state.cart[payload.gid] = payload.items
                .map((item) => {
                    const options = {};
                    if (Array.isArray(item?.opts)) {
                        item.opts.forEach((opt) => {
                            const pickedOpts = opt?.opts?.reduce((acc, o) => {
                                const qty = Number(o?.qty) ? o?.qty : 1;
                                const subOpts = Array(qty).fill(o?.id);
                                acc.push(...subOpts);
                                return acc;
                            }, []);
                            options[opt?.id] = pickedOpts;
                        });
                    }
                    return {
                        pid: item.pid,
                        qty: item.cnt,
                        price: item.pc,
                        originalPrice: item.pc,
                        nm: item.nm,
                        options,
                    };
                })
                .filter((item, i) => {
                    const product = products.find((p) => p.pid === item.pid);
                    if ((product?.sq ?? 0) > 0 && !_.isEmpty(product)) {
                        return true;
                    } else {
                        oos_items.push(item.nm);
                        return false;
                    }
                });

            state.oosItems = oos_items;
            const cart = state.cart[payload.gid];
            const total = cart.reduce((acc, item) => acc + item.originalPrice * item.qty, 0);
            const discounted = cart.reduce((acc, item) => acc + item.price * item.qty, 0);
            state.total[payload.gid] = {
                original: total,
                discounted: calculateDiscount(
                    payload.store,
                    discounted,
                    state.shippingMethod[payload.store.group_id],
                    cart
                ),
            };

            updateLocalStorage(state, payload.gid);
        },
        clearOosItems: (state) => {
            state.oosItems = [];
        },
        setCurrentGroupId: (state, { payload }) => {
            state.currentGroupId[payload?.gid] = payload?.gp_id;
            helper.setLocalStorage("currentGroupId", state.currentGroupId);
        },
        updateShoppingCartFromLocal: (state, { payload }) => {
            let currenStorage = _.cloneDeep({ ...localStorage });
            Object.keys(LOCAL_KEY_STATE_KEY_MAP).forEach((localKey) => {
                const stateKey = LOCAL_KEY_STATE_KEY_MAP[localKey];
                const localItem = currenStorage[localKey];
                if (!_.isEmpty(localItem)) {
                    state[stateKey] = _.isObject(localItem) ? localItem : JSON.parse(localItem);
                }
            });
            state.updateFromLocalDone = true;
        },
        clearUrlQuery: (state, { payload }) => {
            state.urlQuery = {};
        },
    },
});

//input @recent gid and state
//return @null
//to update local storage
//step 1 get and update recent 5 visted store list
//step 2 filtered state items with current list
//step 4 save to local storage
const KEEP_LENGTH = 5;
const updateLocalStorage = (state, gid, add = true) => {
    gid = String(gid);
    //step 1
    let list = Array.isArray(helper.getLocalStorage("shoppingCartRecentList"))
        ? helper.getLocalStorage("shoppingCartRecentList")
        : [];
    //to fill the list with previous records
    if (_.isEmpty(list)) list = Object.keys(state.cart);
    //to filtered out recent gid (will be pushed to most recent) and
    //more than KEEP_LENGTH - 2 (1 related to index, 1 for space for gid being pushed)
    list = list.filter((lgid) => lgid !== gid).filter((lgid, i) => i < KEEP_LENGTH - 2);
    //to unshift first to list
    if (add) list.unshift(gid);
    //step 2
    //step 3
    Object.keys(LOCAL_KEY_STATE_KEY_MAP).forEach((localKey) => {
        const stateKey = LOCAL_KEY_STATE_KEY_MAP[localKey];
        let savedObject = _.cloneDeep(state[stateKey]);
        //delete gid not in the recent list
        Object.keys(savedObject).forEach((gid) => {
            if (localKey !== "selectedMenu" && !list.includes(gid)) delete savedObject[gid];
        });
        helper.setLocalStorage(localKey, savedObject);
    });
    helper.setLocalStorage("shoppingCartRecentList", list);
};

export const {
    setState,
    removeItem,
    removeAllItemsByProductId,
    setShippingMethod,
    openDrawer,
    toggleDrawer,
    clearCart,
    clearCartAll,
    updateTable,
    clearTable,
    updateCartItems,
    orderAgainSuccess,
    clearOosItems,
    setCurrentGroupId,
    setItemQty,
    closeDrawer,
    updateShoppingCartFromLocal,
    clearUrlQuery,
} = shoppingCart.actions;

export const addItem = (payload) => async (dispatch) => {
    dispatch(shoppingCart.actions.addItem(payload));

    const state = _.get(store.getState(), "shoppingCart", {});
    // Do not show add to cart animation if an error occurred
    if (!_.isEmpty(payload.position) && !state.gDisplayModal) {
        dispatch(createAnimatedButton(payload.position.startingX, payload.position.startingY));
    }
};

export default shoppingCart.reducer;
