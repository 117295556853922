import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import helper from "utils/helper";

const initialState = {
    customer: null,
    addresses: [],
    currentEditAddress: null,
    focusField: "",
};

const addressBook = createSlice({
    name: "addressBook",
    initialState: initialState,
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        getCustomerInfoSuccess: (state, { payload }) => {
            state.customer = payload;
            state.refreshCustomer = false;
        },
        getCustomerAddressListSuccess: (state, { payload }) => {
            state.addresses = payload;
        },
        selectEditAddress: (state, { payload }) => {
            if (!state.currentEditAddress) {
                state.currentEditAddress = {};
            }

            state.currentEditAddress.street = "";
            state.currentEditAddress.city = "";
            state.currentEditAddress.region = "";
            state.currentEditAddress.cnty = "";
            state.currentEditAddress.zipcd = "";

            if (Array.isArray(payload?.component)) {
                payload.component.forEach((component) => {
                    const { types } = component;
                    if (types.includes("street_number")) {
                        state.currentEditAddress.street = component["short_name"];
                    } else if (types.includes("route")) {
                        state.currentEditAddress.street += `${state.currentEditAddress.street.length ? " " : ""}${
                            component["long_name"]
                        }`;
                    } else if (
                        (types.includes("locality") ||
                            types.includes("sublocality") ||
                            types.includes("administrative_area_level_2") ||
                            types.includes("administrative_area_level_3")) &&
                        !state.currentEditAddress.city
                    ) {
                        state.currentEditAddress.city = component["long_name"];
                    } else if (types.includes("administrative_area_level_1")) {
                        state.currentEditAddress.region = component["short_name"];
                    } else if (types.includes("country")) {
                        state.currentEditAddress.cntry = component["short_name"];
                    } else if (types.includes("postal_code")) {
                        state.currentEditAddress.zipcd = component["short_name"];
                    }
                });
            }
            state.currentEditAddress.lat = _.get(payload, "latLon.lat", null);
            state.currentEditAddress.lon = _.get(payload, "latLon.lng", null);

            //verify zipcd
            const cntry = state.currentEditAddress.cntry;
            const postCode = state.currentEditAddress.zipcd;
            var validPostCode = false;
            if (cntry && postCode) validPostCode = helper.isValidPostal(postCode, cntry);
            if (!validPostCode) {
                state.focusField = "postal_code";
            }

            state.mapSearch = false;
        },
    },
});

export const { setState, getCustomerInfoSuccess, getCustomerAddressListSuccess, selectEditAddress } =
    addressBook.actions;

export default addressBook.reducer;
