import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentTab: 0,
    phoneNumber: "",
    vc: "",
    email: "",
    countryCodeEdit: "",
    country_code: "CA",
    counter: -1,
    password: "",
    showDialog: false,
    dialogContent: "login",
    requestTextDisabled: true,
    resetPassDisabled: true,
    resetEmailDisabled: true,
    countryCodeList: {},
    isCountingDown: false,
    phoneDisplayPw: "password",
    newPw: "password",
    cNewPw: "password",
    loading: false,
};

const passwordReset = createSlice({
    name: "passwordReset",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = initialState;
        },
        setCurrentTab: (state, { payload }) => {
            state.currentTab = payload;
        },
        setPhoneNumber: (state, { payload }) => {
            state.phoneNumber = payload;
        },
        setVC: (state, { payload }) => {
            state.vc = payload.trim();
        },
        setEmail: (state, { payload }) => {
            state.email = payload.trim();
        },
        setCountryCode: (state, { payload }) => {
            state.country_code = payload.trim();
        },
        setCounter: (state, { payload }) => {
            state.counter = payload;
        },
        setPassword: (state, { payload }) => {
            state.password = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        toggleIsCountingDown: (state, { payload }) => {
            state.isCountingDown = payload;
        },
        toggleDialog: (state, { payload }) => {
            state.showDialog = payload;
        },
        togglePhoneDisplayPw: (state, { payload }) => {
            state.phoneDisplayPw = payload === "password" ? "text" : "password";
        },
        submitVCTextRequestSuccess: (state, { payload }) => {
            state.isCountingDown = true;
        },
        submitEmailSuccess: (state, { payload }) => {
            state.dialogContent = "alert_password_reset_email_send";
            state.showDialog = true;
        },
        submitTextResetSuccess: (state, { payload }) => {
            state.dialogContent = "alert_password_reset_success";
            state.showDialog = true;
        },
    },
});

export const {
    setState,
    clearData,
    submitEmailSuccess,
    submitTextResetSuccess,
    submitVCTextRequestSuccess,
    setCurrentTab,
    setCountryCode,
    setPhoneNumber,
    setVC,
    setEmail,
    setCounter,
    setPassword,
    setCountryCodeList,
    setLoading,
    toggleIsCountingDown,
    toggleDialog,
    togglePhoneDisplayPw,
} = passwordReset.actions;

export default passwordReset.reducer;
