import { getIntl } from "../../locale";
import { toasted } from "./slice";

export const createErrorToast = (code, error) => async (dispatch) => {
    const intl = getIntl();

    dispatch(
        toasted({
            message: intl.formatMessage(
                {
                    id: "error_with_code",
                },
                {
                    code,
                    error: intl.formatMessage({ id: error }),
                }
            ),
        })
    );
};

export const createToast =
    (message, values = {}, className) =>
    async (dispatch) => {
        dispatch(
            toasted({
                message: message,
                keywords: values,
                className: className,
            })
        );
    };
