import React from "react";
import { Provider } from "react-redux";
import AppContent from "./app-content";
import { Router } from "react-router-dom";
import history from "./history";
import "./app.scss";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={<div>loading persistor</div>} persistor={persistor}>
                    <Router history={history}>
                        <AppContent />
                    </Router>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
