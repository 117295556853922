import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    numberPadValue: "0",
    quickPayOrderType: null,
    payingOrderId: "",
    paymentRemark: "",
    orderIdValidated: true,
    validatedOrder: {},
    confirmDialogStatus: 0,
    quickPayConfirmDialogOpen: false
};

export const QUICK_PAY_CONFIRM_DIALOG_STATUS = {
    close: 0,
    success: 1,
    fail: 2,
};

export const QUICK_PAY_ORDER_TYPE_EMUN = {
    SELF_SERVE_ORDER: 1,
    ADDITIONAL_PAYMENT_ORDER: 2,
    STAFF_ASSISED_ORDER: 3,
    NO_ORDER_NUMBER: 4,
};

export const QUICK_PAY_ORDER_TYPE_STR_ID = {
    1: "quick_pay_order_type_self_serve",
    2: "quick_pay_order_type_additional_payment",
    3: "quick_pay_order_type_staff",
    4: "quick_pay_order_type_no_order",
};

const orderDetail = createSlice({
    name: "selfCheckout",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        setZero: (state, { payload }) => {
            state = Object.assign(state, { numberPadValue: "0" })
        },
        //from service
        initDataSuccess: (state, { payload }) => {
            state.gid = _.get(payload, "gid", "");
            state.product = _.get(payload, "instantPayResponse.records.product", {});
            state.store = _.get(payload, "instantPayResponse.records.store", {});
            state.displayModal = _.get(payload, "instantPayResponse.RC", 400) !== 200;
        },
        setQuickPayOrderType: (state, { payload }) => {
            state.quickPayOrderType = payload;
        },
        setPayingOrderId: (state, { payload }) => {
            state.payingOrderId = payload;
        },
        setPaymentRemark: (state, { payload }) => {
            state.paymentRemark = payload;
        },
        setOrderIdValidated: (state, { payload }) => {
            state.orderIdValidated = payload;
        },
        setQuickPayConfirmDialogOpen: (state, { payload }) => {
            state.quickPayConfirmDialogOpen = payload;
        },
    },
});

export const {
    setState,
    clearData,
    setZero,
    setQuickPayOrderType,
    setPayingOrderId,
    setPaymentRemark,
    //to service
    initDataSuccess,
    setOrderIdValidated,
    setQuickPayConfirmDialogOpen
} = orderDetail.actions;

export default orderDetail.reducer;
