import _ from "lodash";
import qs from "query-string";
import routes from "./routes";
import config from "config";
import helper from "utils/helper";
import { clearAuth } from "../../containers/auth/slice";
import { clearSesitiveData } from "../../containers/checkout-page/slice";
import { clearData as clearAccountData } from "../../containers/account-page/slice";
import { store } from "../../containers/app/store";
import { oauth } from "./oauth";
import { createErrorToast } from "components/toast/service";

const rootApi = config.API_ROOT;

export const callWithErrorHandling = async ({ fn, url, query, append }, ...rest) => {
    url = _.isEmpty(query) ? routes[url] : `${routes[url]}?${qs.stringify(query)}`;
    url = _.isEmpty(append) ? url : `${url}${append}`;
    try {
        const response = await fn(url, ...rest);
        if (response && response.RC === 401) {
            store.dispatch(clearAuth());
            store.dispatch(clearSesitiveData());
            store.dispatch(clearAccountData());
        }

        if (response.RC === 10) {
            store.dispatch(createErrorToast(response.RC, "unable_to_load_any_data"));
        }

        return response;
    } catch (e) {}
};

export const executeOauth = (url, type, params, oauthToken, oauthTokenSecret) => {
    return oauthLib(url, type, params, oauthToken, oauthTokenSecret);
};

export const oauthLib = (url, type, params, oauthToken, oauthTokenSecret) => {
    const accessToken = helper.getLocalStorage("accessToken");
    const accessSecret = helper.getLocalStorage("accessSecret");
    const executeUrl = `${rootApi}${url}`;
    const token = {
        key: oauthToken ? oauthToken : accessToken,
        secret: oauthTokenSecret ? oauthTokenSecret : accessSecret,
    };

    const request_data = {
        url,
        method: type,
        data: params,
    };

    return new Promise((resolve, reject) => {
        let response;
        fetch(executeUrl, {
            method: type,
            body: JSON.stringify(params),
            headers: new Headers({
                "Content-Type": "application/json",
                ...oauth.toHeader(oauth.authorize(request_data, token)),
            }),
        })
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    resolve(body);
                } else {
                    reject(body);
                }
            })
            .catch((error) => reject(error));
    });
};

//being depreciated
export const oauthGetWay = (url, type, params, oauthToken, oauthTokenSecret) => {
    const accessToken = helper.getLocalStorage("accessToken");
    const accessSecret = helper.getLocalStorage("accessSecret");

    const executeUrl = `${rootApi}/api/v8/gateway`;

    const finalPostBody = {
        consumer: {
            oauthToken: oauthToken ? oauthToken : accessToken,
            oauthTokenSecret: oauthTokenSecret ? oauthTokenSecret : accessSecret,
        },
        apis: [
            {
                auth: "consumer",
                apiURL: url,
                requestType: type,
                data: params,
            },
        ],
    };

    return new Promise((resolve, reject) => {
        let response;
        fetch(executeUrl, {
            method: "POST",
            body: JSON.stringify(finalPostBody),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    resolve(body[0]);
                } else {
                    reject(body[0]);
                }
            })
            .catch((error) => reject(error));
    });
};

export const get = (url) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(`${rootApi}/${url}`)
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    resolve(body);
                } else {
                    reject(body);
                }
            })
            .catch((error) => reject(error));
    });
};

export const post = (url, postbody) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(`${rootApi}/${url}`, {
            method: "POST",
            body: JSON.stringify(postbody),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    resolve(body);
                } else {
                    reject(body);
                }
            })
            .catch((error) => reject(error));
    });
};

export const outsideGet = (url) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(url)
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    resolve(body);
                } else {
                    reject(body);
                }
            })
            .catch((error) => reject(error));
    });
};

export const outsidePost = (url, postbody) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(url, {
            method: "POST",
            body: JSON.stringify(postbody),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    resolve(body);
                } else {
                    reject(body);
                }
            })
            .catch((error) => reject(error));
    });
};
