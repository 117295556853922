import helper from "utils/helper";
import { getBestTimeSlot } from "../../utils/helper";
import config from "config";
import _ from "lodash";
import { push } from "../app/history";
import locationHelper from "../location/helper";
import { getTableNumber } from "../shopping-cart-page/helpers";
import { getRequireUtensilValue, REQUIRE_UTENSIL_MAP, isRestaurant, allowGuestCheckout } from "../store-page/helper";
import qs from "query-string";
import { ASAP } from "../app/constants";

const giftCardPageSize = 5;
export const MIN_POINTS_AMOUNT = 5;

export const getGid = () => {
    const pageParams = helper.getUrlParameters();
    return pageParams.query;
};

export const INSTANT_CHECKOUT_CONFIRM = {
    require: 1,
    not_require: 0,
};
export const getInstantCheckoutParams = () => {
    const query = qs.parse(window.location.search);
    const pid = _.get(query, "pid");
    const payment_method = _.get(query, "p_mtd", config.PAYMENT_METHOD_NUMERIC_MAPPING.giftcard);
    const confirm = _.get(query, "confirm", INSTANT_CHECKOUT_CONFIRM.not_require);
    return pid ? { pid, payment_method, confirm } : null;
};

const getCurrentCart = (props = {}) => {
    const gid = getGid() || _.get(props, "checkoutState.gid");
    const carts = _.get(props, "checkoutState.carts", {});

    return carts[gid] ? carts[gid] : [];
};

const isSelfCheckout = (props = {}) => {
    const gid = getGid() || _.get(props, "checkoutState.gid");
    const products = _.get(props, "checkoutState.products", {});
    const storeProducts = products[gid] ? products[gid] : [];
    return (
        storeProducts.findIndex((product) => {
            return product?.selfCheckout;
        }) !== -1
    );
};

export const isCartOnlyHasGiftCards = (props = {}, cart = null) => {
    const actualCart = cart || getCurrentCart(props);

    const onlyHasGiftCards =
        Array.isArray(actualCart) &&
        _.isEmpty(
            actualCart.find((item) => {
                return item.productType !== config.PRODUCT_TYPE_NUMERIC_MAPPING.giftcard;
            })
        );

    const cartNotEmpty = !_.isEmpty(actualCart);

    return onlyHasGiftCards && cartNotEmpty;
};

const isCartHasGiftCards = (props = {}, cart = null) => {
    const actualCart = cart || getCurrentCart(props);

    const hasGiftCard =
        Array.isArray(actualCart) &&
        !_.isEmpty(
            actualCart.find((item) => {
                return item.productType === config.PRODUCT_TYPE_NUMERIC_MAPPING.giftcard;
            })
        );

    return hasGiftCard;
};

const getSelectedThirdPartyMethod = (code, types = []) => {
    let result = {};
    types.find((type) => {
        const currentMethods = _.get(type, "methods", []);
        return currentMethods.find((method) => {
            const currentCode = method.code === code;
            if (currentCode) {
                result = method;
            }
            return currentCode;
        });
    });
    return result;
};

export const getRequireUtentsilDefaultValue = (store) => {
    const value = getRequireUtensilValue(store);
    const resturant = isRestaurant(store);
    const defaultOn = [REQUIRE_UTENSIL_MAP.show_default_selected].includes(value);
    return resturant && defaultOn;
};

const getGroupPurchaseId = (props) => {
    const gid = getGid();
    const groupPurchaseIds = _.get(props, "checkoutState.groupPurchaseIds", {});
    return groupPurchaseIds[gid] ? groupPurchaseIds[gid] : null;
};

const goBack = (props) => {
    //back url
    const backUrl = _.get(props, "checkoutState.backUrl", "");
    //store url
    const storeUrl = helper.getIndependentDomain() ? "/" : `/store/${getGid()}`;
    //group puchase id
    const groupPurchaseUrl = getGroupPurchaseId(props) ? `/gsale/${getGroupPurchaseId(props)}` : null;
    //pay url
    const payUrl = isSelfCheckout(props) ? `/pay/${getGid()}` : null;

    push(backUrl ? backUrl : payUrl ? payUrl : groupPurchaseUrl ? groupPurchaseUrl : storeUrl);
};

const getTipsSetting = (store) => {
    return {
        percents: _.get(store, "tips.pct", [-1, 0, 10, 12, 15, 20]), //Array of percentage tips
        amts: _.get(store, "tips.amt", [-1, 0, 2, 3, 4, 5]), // Array of $ tips
        cut: _.get(store, "tips.limit", 20), //if the order total is over the limit then show percentage, otherwise show dollar amount,
        default_percent: _.get(store, "tips.dflt_pct", [12, 12, 0]), //1 delivery, 2 dine in, 3 pickup
        default_amt: _.get(store, "tips.dflt_amt", [2, 2, 0]),
        is_enable: tipsIsEnable(store),
    };
};

const tipsIsEnable = (store) => {
    return String(_.get(store, "tips.is_enabled", 1)) === "1";
};

const getDefaultTips = (store, method, isPercent = true) => {
    if (!tipsIsEnable(store)) return 0;
    const key = isPercent ? "default_percent" : "default_amt";
    const defaultPertentage = getTipsSetting(store)[key];
    if (Array.isArray(defaultPertentage)) {
        if (!!Number(method)){
            const tipsIndex = config.SHIPPING_METHOD_TIPS_INDEX_MAP[method];
            return defaultPertentage[tipsIndex ? tipsIndex : 0];
        }else {
            const tipsIndex = config.SHIPPING_METHOD_STR_TIPS_INDEX_MAP[method];
            return defaultPertentage[tipsIndex ? tipsIndex : 0];
        }
    }
    return defaultPertentage;
};

const isInStore = (props) => {
    const method = _.get(props, "checkoutState.shippingMethod", 1);
    return Number(method) === 3 && !isRestaurant(_.get(props, "checkoutState.storeInstance", {}));
};

const getShippingMethodStr = (props = {}) => {
    const method = _.get(props, "checkoutState.shippingMethod", 1);
    const methodStr = isInStore(props) ? "in_store" : config.SHIPPING_MAPPING_TO_TEXT[method];
    return methodStr ? methodStr : " ";
};

export const getStoreUserDistance = (store, user) => {
    const storeLatLng = {
        lat: _.get(store, "adr.lat", null),
        lng: _.get(store, "adr.lon", null),
    };
    const userLatLng = user;
    return locationHelper.calculateDistance(storeLatLng, userLatLng); //in m
};

export const GUEST_HIDDEN_FIELDS = ["first_name", "last_name", "phone", "table_number", "party_size"];

export const useGuestCheckout = (store, method) => {
    const roles = config.CUSTOMER_ROLES;

    const accessToken = helper.getLocalStorage("accessToken");
    const accessSecret = helper.getLocalStorage("accessSecret");
    const accessRole = helper.getLocalStorage("accessRole");

    if ([roles.customer, roles.staff].includes(accessRole)) {
        return false;
    }
    if (accessToken && accessSecret) {
        return false;
    }
    return allowGuestCheckout(store, method);
};

export const isGuestRole = (state) => {
    return String(_.get(state, "customerRole")) === config.CUSTOMER_ROLES.guest;
};

export const isGuest = (state) => {
    const isGuest = isGuestRole(state);

    const store = _.get(state, "storeInstance", {});
    const storeGid = _.get(state, "storeInstance.gid", {});
    const method = _.get(state, `shippingMethods.${storeGid}`, {});
    const allowGuest = allowGuestCheckout(store, method);

    return isGuest && allowGuest;
};

export const getAIOShareLogic = () => {
    const SHARE_VALUES = { independent: 0, shared: 1 };
    return {
        is_shared_domain: helper.getIndependentDomain() ? SHARE_VALUES.independent : SHARE_VALUES.shared,
    };
};

export const getTableInfo = (cartState) => {
    const query = qs.parse(window.location.search);
    const urlTable = _.get(query, "lid");
    const urlType = _.get(query, "tid");
    const localTable = getTableNumber(cartState, getGid());
    return {
        id: urlTable || _.get(localTable, "id"),
        type: urlType || _.get(localTable, "type"),
    };
};

/**
 * Sets state when Pickup or Delivery allowed_periods changes
 *
 * @param state      - store state
 * @param periods    - Updated allowed_periods from the API eta_slots or ctotal
 *
 */
export const setBestTimeSlot = (state, periods) => {
    if (!state.allowedPeriods && periods.length > 0) {
        state.allowedPeriods = periods;
    }

    if (
        (!state.deliveryDate || !state.deliveryTime) &&
        Object.keys(periods).length > 0 &&
        periods[Object.keys(periods)[0]].length > 0
    ) {
        state.deliveryDisplayDate = Object.keys(periods)[0];
        state.deliveryDate = Object.keys(periods)[0];
        state.deliveryTime = periods[Object.keys(periods)[0]][0];
    }

    if (!state.deliveryTime && Object.keys(periods).length === 0) {
        state.deliveryTime = ASAP;
    }

    if (!_.isEqual(state.allowedPeriods, periods)) {
        state.allowedPeriods = periods;
        // previous date and time in state are passed as first two arguments.
        // New allowed_periods is passed as third argument
        if (state.deliveryDate) {
            let bestTimeSlot = getBestTimeSlot(state.deliveryDate, state.deliveryTime, periods);
            state.deliveryDisplayDate = bestTimeSlot.date;
            state.deliveryDate = bestTimeSlot.date;
            state.deliveryTime = bestTimeSlot.time;
        }
    }
};

/**
 * Sets state for allowedPeriods, deliveryDisplayDate, deliveryDate, deliveryTime for multiple pickupLocations
 *
 * @param state              - store state
 * @param pickupLocations    - pickup locations array
 *
 */
export const setBestTimeSlotMultipleLocations = (state, pickupLocations) => {
    let indexOfPickupLocationId = 0;
    // get the index of pickup location id from records
    if (state.pickupLocationId && pickupLocations.length > 0) {
        indexOfPickupLocationId = pickupLocations.findIndex((element) => element.id === state.pickupLocationId);
    }
    // when id is not found inside the API, set the state to id at index 0
    if (indexOfPickupLocationId === -1 && "id" in pickupLocations[0]) {
        state.pickupLocationId = pickupLocations[0].id;
    }
    // assigns index to 0 when no match is found in records
    if (indexOfPickupLocationId === -1) {
        indexOfPickupLocationId = 0;
    }

    // when allowed periods are not equal then best time slot function is called
    setBestTimeSlot(state, pickupLocations[indexOfPickupLocationId].timeslots);
};

const exports = {
    giftCardPageSize,
    MIN_POINTS_AMOUNT,
    getGid,
    getSelectedThirdPartyMethod,
    getCurrentCart,
    isSelfCheckout,
    isCartOnlyHasGiftCards,
    isCartHasGiftCards,
    getGroupPurchaseId,
    goBack,
    getTipsSetting,
    getDefaultTips,
    getShippingMethodStr,
    getStoreUserDistance,
    setBestTimeSlot,
    setBestTimeSlotMultipleLocations,
};

export default exports;
