import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import { setState, clearToast } from "./slice";
import Toasted from "./Toasted";

class Toast extends React.Component {
    componentDidUpdate = (prevProps) => {
        this.clearToast(prevProps);
    };

    clearToast = (prevProps) => {
        const showToast = !_.isEqual(this.shouldShowToast(prevProps), this.shouldShowToast()) && this.shouldShowToast();
        if (showToast) {
            setTimeout(() => {
                this.props.clearToast();
            }, 5000);
        }
    };

    shouldShowToast = (props = this.props) => {
        return _.get(props, "state.showToast", false);
    };

    getToastMessage = () => {
        const message = _.get(this.props, "state.toastMessage", "");
        return message ? message : " ";
    };

    getToastKeywords = () => {
        return _.get(this.props, "state.toastKeywords", {});
    };

    getToastClassName = () => {
        return _.get(this.props, "state.toastClassName", "");
    };

    getToastContent = () => {
        return <FormattedMessage id={this.getToastMessage()} values={this.getToastKeywords()} />;
    };

    render() {
        return (
            <Toasted
                className={this.getToastClassName()}
                icon="toast"
                show={this.shouldShowToast()}
                onClick={() => this.props.clearToast()}
            >
                {this.getToastContent()}
            </Toasted>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "toast", {}),
});

const mapDispatchToProps = {
    setState,
    clearToast,
};

Toast = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Toast));
export default Toast;
