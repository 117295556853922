import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const storesSearchAdapter = createEntityAdapter({
    selectId: (e) => (e.gid + "-" + e.nm).replace(/\s/g, "-").toLowerCase(),
});

const storesSearch = createSlice({
    name: "storesList",
    initialState: storesSearchAdapter.getInitialState({ total: 0, page: 1, noResults: false }),
    reducers: {
        getStoresSearchResultsSuccess: (state, { payload }) => {
            storesSearchAdapter.addMany(state, payload.records);
            state.total = payload?.paging?.total ?? 0;
            state.page = payload?.paging?.page ?? 0;
            state.noResults = false;
        },
        emptyStoreSearchResults: storesSearchAdapter.removeAll,
        resetResults: (state) => {
            state.noResults = false;
        },
        getStoresSearchResultsFailed: (state, { payload }) => {
            state.noResults = true;
        },
        getStoreSubCategorySuccess: (state, { payload }) => {
            state.subCategories = payload?.response?.records;
            state.categoryFilters = payload?.response2?.records;
            state.sortFilters = payload?.response3?.records;
            state.filterFilters = payload?.response4?.records;
        },
    },
});

export const {
    getStoresSearchResultsSuccess,
    emptyStoreSearchResults,
    getStoresSearchResultsFailed,
    resetResults,
    getStoreSubCategorySuccess,
} = storesSearch.actions;

export const storesSearchSelectors = storesSearchAdapter.getSelectors();

export default storesSearch.reducer;
