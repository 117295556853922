import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { store as reduxStore } from "../app/store";

const store = createSlice({
    name: "store",
    initialState: {
        loading: false,
        storeInstance: {},
        productInstance: {},
        products: [],
        reviews: [],
        g_products: [],
        orders: [],
        categories: [],
        allCategories: [],
        ordersTotal: 0,
        keywords: "",
        selectedBranch: "",
        upsellProducts: [],
        tab: 0,
        dialogs: {
            storeDetailsPopUp: false,
            productInfoPopUp: false,
            productSearchPopUp: false,
            productOverlay: false,
            branchSelection: false,
            productGroupOverlay: false,
            qrDialog: false,
            oosItemsDialog: false,
            orderDetailDialog: false,
            addressBookDialog: false,
        },
        selfInputState: {
            price: 10,
            qty: 1,
        },
        ordersLoading: false,
        response: null, //to keep a copy to avoid repeat call
        productReviews: [],
        refCode: "",
        storeHomeURL: undefined, //the redirect url when you click the store icon on the header
        pageTotal: 0,
        urlRefCode: "",
    },
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getStoreSuccess: (state, { payload }) => {
            state.storeInstance = _.omit(payload, ["products", "categories"]);
            state.categories = payload.categories;
            state.keywords = "";
            state.response = payload.response;
            state.lan = payload.lan;
            state.refreshStore = false;
            state.tab = 0;
            state.selectedMenu = payload.selectedMenu;
            state.products = payload.products;
            state.activeCat = payload.selectedCid;
            state.pageTotal = payload.productTotal;
        },
        getStoreReviewsSuccess: (state, { payload }) => {
            state.reviews = payload;
        },
        getStoreCatalogSuccess: (state, { payload }) => {},
        getStoreProductsSuccess: (state, { payload }) => {
            const newProducts = payload?.records;
            const shouldAdd = payload?.shouldAdd;
            const stateProducts = _.cloneDeep(_.get(state, "products"));
            const currentProducts = Array.isArray(stateProducts) ? stateProducts : [];
            let newStateProducts = [];

            //if shouldAdd = true, add products are triggered by scrolling. add all the new products to list
            if (shouldAdd) {
                //only adding differences to avoid repeat products
                const isRepeat = (array, item) => {
                    return _.findIndex(array, (accItem) => accItem?.pid === item?.pid) !== -1;
                };
                const results = currentProducts
                    .concat(newProducts)
                    .reduce(
                        (accumulator, item) => (isRepeat(accumulator, item) ? accumulator : [...accumulator, item]),
                        []
                    );
                newStateProducts = results;
            } else {
                //else just update products
                newStateProducts = newProducts;
            }
            state.pageTotal = payload?.paging?.total;
            state.products = newStateProducts;
            state.keywords = "";
        },
        openProductContainerSuccess: (state, { payload }) => {
            if (payload.overlay) {
                state.dialogs = Object.assign({}, state.dialogs, { productOverlay: true });
            } else {
                state.dialogs = Object.assign({}, state.dialogs, { productInfoPopUp: true });
            }

            if (!_.isEmpty(payload.selfInputItem)) {
                state.selfInputState = {
                    price: payload.selfInputItem.price,
                    qty: payload.selfInputItem.qty,
                };
            }

            state.productInstance = payload.product;
        },
        closeProductOverlay: (state, { payload }) => {
            state.dialogs = Object.assign({}, state.dialogs, { productOverlay: false });
            state.productInstance = {};
        },
        closeGroupProductOverlay: (state, { payload }) => {
            state.dialogs = Object.assign({}, state.dialogs, { productGroupOverlay: false });
            state.productInstance = {};
        },
        closeProductContainer: (state, { payload }) => {
            state.dialogs.productInfoPopUp = false;
        },
        getSearchProductsSuccess: (state, { payload }) => {
            state.products = payload.records;
            state.keywords = payload.keywords;
            state.tab = 0;
            try {
                window.scrollTo(0, 0);
            } catch (e) {}
        },
        clearKeywords: (state) => {
            state.keywords = "";
        },
        setDialogs: (state, { payload }) => {
            state.dialogs = Object.assign({}, state.dialogs, payload);
        },
        getStoreAndProductSuccess: (state, { payload }) => {
            state.storeInstance.store = _.omit(payload.store, ["products", "categories"]);
            state.g_products = payload?.rplst ?? [];
            state.orders = payload?.order_lst?.orders ?? [];
            state.ordersTotal = payload?.order_lst?.total ?? 0;
            state.productGroupInstance = payload?.product ?? [];
            if (!payload?.order_lst) {
                state.productInstance = payload?.product ?? [];
            }
        },
        getProductGroupOrderHistorySuccess: (state, { payload }) => {
            state.orders = [...state.orders, ...payload.records];
            state.ordersTotal = _.get(payload, "paging.total");
        },
        getUpsellProductsSuccess: (state, { payload }) => {
            state.upsellProducts = payload;
        },
        getProductReviewSuccess: (state, { payload }) => {
            state.productReviews = payload.records;
        },
        getRefCodeSuccess: (state, { payload }) => {
            state.refCode = payload;
        },
    },
});

export const {
    setState,
    getStoreSuccess,
    getStoreReviewsSuccess,
    getStoreCatalogSuccess,
    openProductContainerSuccess,
    getSearchProductsSuccess,
    setDialogs,
    closeProductContainer,
    getStoreProductsSuccess,
    getStoreAndProductSuccess,
    closeProductOverlay,
    getProductGroupOrderHistorySuccess,
    getUpsellProductsSuccess,
    closeGroupProductOverlay,
    getProductReviewSuccess,
    getRefCodeSuccess,
} = store.actions;

export const getCurrentGid = () => {
    const state = reduxStore.getState();

    return state?.stores?.storeInstance?.store?.gid;
};

export default store.reducer;
