import { createSlice } from "@reduxjs/toolkit";
import helper from "utils/helper";
import _ from "lodash";
import config from "config";

const auth = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
    },
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setAuth: (state, { payload }) => {
            const accessToken = _.get(payload, "records.token", "");
            const accessSecret = _.get(payload, "records.secret", "");
            const accessRole = _.get(payload, "records.customer_role", config.CUSTOMER_ROLES.customer);
            if (accessToken && accessSecret) {
                helper.setLocalStorage("accessRole", accessRole);
                helper.setLocalStorage("accessToken", accessToken);
                helper.setLocalStorage("accessSecret", accessSecret);
                state.isAuthenticated = true;
            }
        },
        setAuthState: (state, { payload }) => {
            const accessToken = _.get(payload, "token", "");
            const accessSecret = _.get(payload, "secret", "");
            if (accessToken && accessSecret) {
                state.isAuthenticated = true;
            }
        },
        clearAuth: (state, { payload }) => {
            state.isAuthenticated = false;
            state.logoutDialogDisplay = false;

            const role = _.get(payload, "customer.role");
            if (role) {
                helper.setLocalStorage("accessRole", role);
            }

            helper.removeLocalStorage("accessToken");
            helper.removeLocalStorage("accessSecret");
        },
        //to service
        triggerLogout: (state, { payload }) => {
            state.logoutDialogType = payload === "normal" ? "normal" : "force";
            state.logoutDialogDisplay = true;
        },
    },
});

export const {
    //to components
    setState,
    setAuth,
    setAuthState,
    clearAuth,
    //to service
    triggerLogout,
} = auth.actions;

export default auth.reducer;
