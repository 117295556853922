import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import qs from "query-string";
import { PHONE_INPUT_STEP } from "../app/constants";
import { push, goBack } from "../app/history";

const initialState = {
    phone: "",
    isPasswordSet: false,
    country: "",
    counter: 0,
    currentStep: PHONE_INPUT_STEP,
    vc: "",
};

const setPassword = createSlice({
    name: "setPassword",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        updatePasswordSuccess: (state, { payload }) => {
            const RC = _.get(payload, "updatePasswordResponse.RC", 400);
            switch (RC) {
                case 200:
                    //push to sign in page after 3 sec
                    const query = qs.parse(window.location.search);
                    const goBackEnable = _.get(query, "goBack", "");
                    setTimeout(() => {
                        goBackEnable ? goBack() : push("/sign-in");
                    }, 2000);
                    break;
                default:
                    break;
            }
        },
    },
});

export const {
    //to components
    setState,
    clearData,
    //to service
    updatePasswordSuccess,
} = setPassword.actions;

export default setPassword.reducer;
