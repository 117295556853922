import { createErrorDialog } from "components/error-handling-dialog/slice";
import { createErrorToast } from "components/toast/service";
import config from "config";
import { changeLanguage } from "containers/settings/slice";
import _ from "lodash";
import { callWithErrorHandling, executeOauth, get } from "utils/api";
import helper from "utils/helper";
import Store from "utils/model/store";
import { getReferralFlagsSuccess } from "../account-page/slice";
import { addRecentlyViewedProduct } from "../account-recently-viewed-page/productRecentlyViewedSlice";
import { addRecentlyViewedStore } from "../account-recently-viewed-page/storeRecentlyViewedSlice";
import { store } from "../app/store";
import { orderAgainSuccess, setState as setShoppingCartState, updateCartItems } from "../shopping-cart-page/slice";
import { setWechatShareImgUrl } from "../wechat-plugin/slice";
import {
    getProductGroupOrderHistorySuccess,
    getProductReviewSuccess,
    getRefCodeSuccess,
    getSearchProductsSuccess,
    getStoreAndProductSuccess,
    getStoreCatalogSuccess,
    getStoreProductsSuccess,
    getStoreReviewsSuccess,
    getStoreSuccess,
    getUpsellProductsSuccess,
    openProductContainerSuccess,
    setState,
} from "./slice";

export const getStoreDetailsWithCategoriesAndProducts = (payload) => async (dispatch) => {
    dispatch(setState({ loaded: false }));
    try {
        const fn = get;
        const url = "store";
        const { gid, isScan, lan, mid, menu_lan, cid, fromReferrals } = payload;
        const domain = helper.getIndependentDomain();
        const query = { lan };

        if (domain) {
            query.domain = domain;
        } else {
            if (!helper.isNumeric(gid)) {
                query.url_key = gid;
            } else {
                query.gid = gid;
            }
        }

        if (isScan === "1") {
            query.sc = isScan;
        }

        if (menu_lan) {
            query.menu_lan = menu_lan;
        }

        if (cid) {
            query.cid = cid;
        }

        // Check menu id on the url first and then check selectedMenu which was selected before
        const loadedMenu = mid || _.get(helper.getLocalStorage("selectedMenu"), gid, null);
        if (loadedMenu) {
            query.mid = loadedMenu;
        }

        const response = await callWithErrorHandling({ fn, url, query });

        const data = {
            store: response.records["store_dtl"],
            categories: response.records["cat_lst"],
            products: response.records["p_lst"],
            productTotal: response.records["p_total"],
            selectedCid: response.records["curr_cid"],
            selectedMenu: response.records["curr_menu_id"],
            response,
            lan,
        };

        const store = new Store(data.store);

        const handleStoreData = () => {
            dispatch(getStoreSuccess(data));
            dispatch(addRecentlyViewedStore(response));
            const storeLogo = _.get(response, "records.store_dtl.logo");
            const storeLogoImg = helper.getImageUrl(storeLogo, ",c_fill");
            dispatch(setWechatShareImgUrl(storeLogoImg));
            const currentSelectedMenu = helper.getLocalStorage("selectedMenu") || {};
            if (data.selectedMenu) {
                dispatch(
                    setShoppingCartState({ "selectedMenu": { ...currentSelectedMenu, [gid]: data.selectedMenu } })
                );
            }
            if (domain && !helper.getLocalStorage("lan")) {
                dispatch(changeLanguage({ language: _.get(data, "store.lan") }));
            }
        };

        if (response?.RC === 200) {
            if (fromReferrals) {
                // called from referrals page store selection
                const v2pCr = _.get(
                    response,
                    `records.store_dtl.kflg.${config.STORE_KFLG_INDEX_MAPPING.v2p_customer_referral}`,
                    0
                );
                const v2pOr = _.get(
                    response,
                    `records.store_dtl.kflg.${config.STORE_KFLG_INDEX_MAPPING.v2p_order_referral}`,
                    0
                );
                const p2v = _.get(response, `records.store_dtl.kflg.${config.STORE_KFLG_INDEX_MAPPING.p2v}`, 0);
                dispatch(
                    getReferralFlagsSuccess({
                        gid: gid,
                        customerRefPoints: Number(v2pCr) ? Number(v2pCr) : 0,
                        orderRefPoints: Number(v2pOr) ? Number(v2pOr) : 0,
                        pointsToValue: Number(p2v) ? Number(p2v) : 0,
                    })
                );
            }

            if (store?.data?.allow_referrals) {
                dispatch(getRefCode({ gid: response?.records?.store_dtl?.gid }));
            } else {
                // remove existing ref code
                dispatch(getRefCodeSuccess(""));
            }
            handleStoreData();
            if (payload?.callBack) payload.callBack();
        }

        if (response?.RC === 204) {
            handleStoreData();
            dispatch(createErrorToast(response.RC, "store_has_no_products"));
        }
    } catch (e) {}
    dispatch(setState({ loaded: true }));
};

export const getRefCode = (payload) => async (dispatch) => {
    try {
        const fn = executeOauth;
        const url = "refCode";
        const response = await callWithErrorHandling({ fn, url, query: payload }, "GET");
        if (response.RC === 200 || response.RC === 204) {
            dispatch(getRefCodeSuccess(response?.code ?? ""));
        } else {
            dispatch(getRefCodeSuccess(""));
        }
    } catch (e) {}
};

export const getStoreReviews = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const url = "storeReviews";
        const response = await callWithErrorHandling({ fn, url, query: payload });

        if (response.RC === 200) {
            dispatch(getStoreReviewsSuccess(response.records));
        }
    } catch (e) {}
};

export const writeStoreReview = (payload) => async (dispatch) => {
    try {
        const fn = executeOauth;
        const url = "reviews";

        const response = await callWithErrorHandling({ fn, url }, "POST", {
            data: {
                gid: payload.gid,
                s_review: {
                    general: {
                        cmt: payload.comment,
                        rat: payload.rating,
                    },
                },
            },
        });
        if (response.RC === 200) {
            dispatch(getStoreReviews({ gid: payload.gid, lan: payload.lan }));
        } else if (response.RC === 401) {
            //If token is invalid then go to sign-in page
            payload?.callback();
        }
    } catch (e) {}
};

export const getStoreProducts = (payload) => async (dispatch) => {
    dispatch(setState({ gettingStoreProducts: true }));
    try {
        const fn = get;
        const url = "storeProducts";
        const storeInstance = new Store(store.getState()?.stores?.storeInstance?.store);
        const {
            gid,
            lan,
            isPaginated,
            activeCat,
            pids,
            mid,
            page,
            callBack,
            cart = false,
            orderAgain,
            shouldAdd,
        } = payload;
        const query = {};
        const domain = helper.getIndependentDomain();

        if (domain) {
            query.domain = domain;
        } else {
            query.gid = gid;
        }
        query.lan = lan;

        if (isPaginated && activeCat && activeCat?.id !== "all") {
            query.page = 0;
            query.cids = activeCat?.id;
        } else {
            query.page = page ? page : 0;
        }

        if (mid) {
            query.mid = mid;
        }

        if (pids) {
            // query.ids = pids.join(',')
        }

        const response = await callWithErrorHandling({ fn, url, query });

        if (response.RC === 200) {
            if (!cart && _.isEmpty(orderAgain)) {
                dispatch(
                    getStoreProductsSuccess({
                        shouldAdd,
                        records: response.records,
                        paging: response.paging,
                    })
                );
            } else if (cart) {
                dispatch(
                    updateCartItems({
                        products: response.records,
                        gid,
                        store: storeInstance.data,
                    })
                );
            }
            if (!_.isEmpty(orderAgain)) {
                dispatch(
                    orderAgainSuccess({
                        ...orderAgain,
                        products: response.records,
                        store: storeInstance.data,
                    })
                );
            }
        }

        if (typeof callBack === "function") {
            callBack();
        }
    } catch (e) {}
    dispatch(setState({ gettingStoreProducts: false }));
};

export const getUpsellProducts = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const url = "storeProducts";
        const query = {};
        const { cart } = payload;
        query.gid = payload.gid;
        query.lan = payload.lan;
        query.ids = Array.isArray(payload.pids) && payload.pids.flat().join(",");
        const response = await callWithErrorHandling({ fn, url, query });

        if (response.RC === 200) {
            const records = _.get(response, "records", []);
            // filter out upsell product already in the cart
            const upsellProducts = records.reduce((curr, r) => {
                //Fix issue 14494 for potentially products becoming undefined and get called as an array
                //original error: I.find is not a function. (In 'I.find ((function(e)(return e.pid===t.pid}))', 'I.find' is undefined 
                //observation: right after scan to order redirect to store page, the error throws
                if (Array.isArray(cart) && _.isEmpty(cart.find((c) => c?.pid === r.pid))) {
                    curr.push(r);
                }
                return curr;
            }, []);

            dispatch(getUpsellProductsSuccess(upsellProducts));
        } else {
            dispatch(getUpsellProductsSuccess([]));
        }
    } catch (e) {}
};

export const getStoreAndProduct =
    (payload, isGroup = false, callBack = () => {}) =>
    async (dispatch) => {
        dispatch(setState({ gettingStoreAndProduct: true }));
        try {
            const fn = get;
            const url = isGroup ? "productAndStoreGroup" : "productAndStore";
            const query = { ...payload };

            const response = await callWithErrorHandling({ fn, url, query });

            const store = new Store(response?.records?.store);
            if (store?.data?.allow_referrals) {
                dispatch(getRefCode({ gid: response?.records?.store?.gid }));
            } else {
                // remove existing ref code
                dispatch(getRefCodeSuccess(""));
            }
            dispatch(getProductReviews({ pid: payload.pid }));
            dispatch(getStoreAndProductSuccess(response?.records ?? {}));
            dispatch(addRecentlyViewedProduct(response));
            callBack(response?.records ?? {});
        } catch (e) {}
        dispatch(setState({ gettingStoreAndProduct: false }));
    };

export const getStoreCatalog = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const url = "storeCatalog";
        const { gid, lan, nextMenu } = payload;
        const query = {};

        query.gid = gid;
        query.lan = lan;

        if (nextMenu) {
            query.mid = nextMenu;
        }

        const response = await callWithErrorHandling({ fn, url, query });

        if (response.RC === 200) {
            dispatch(getStoreCatalogSuccess(response.records));
        }
    } catch (e) {}
};

export const getProductReviews = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const url = "productReviews";
        const response = await callWithErrorHandling({ fn, url, query: payload });

        if (response.RC) {
            dispatch(getProductReviewSuccess(response));
        }
    } catch (e) {}
};

export const writeProductReview = (payload) => async (dispatch) => {
    try {
        const fn = executeOauth;
        const url = "reviews";

        const response = await callWithErrorHandling({ fn, url }, "POST", {
            data: {
                gid: payload.gid,
                p_review: [
                    {
                        pid: payload.pid,
                        cmt: payload.comment,
                        rat: payload.rating,
                    },
                ],
            },
        });

        if (response.RC) {
            dispatch(getStoreReviews({ gid: payload.gid, lan: payload.lan }));
            switch (response.RC) {
                case 200:
                    dispatch(createErrorDialog({ message: "review_submitted_message", title: "write_review" }));
                    break;
                default:
                    dispatch(createErrorDialog({ message: "general_error" }));
                    break;
            }
        }
    } catch (e) {}
};

export const openProductContainer = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const { product, lan, item, index } = payload;
        const pid = product.data.pid;

        if (payload.overlay) {
            const response = await callWithErrorHandling({ fn, url: "product", query: { lan, pid } });

            if (response.RC === 200) {
                dispatch(getProductReviews({ pid }));
                dispatch(addRecentlyViewedProduct(response));
                dispatch(
                    openProductContainerSuccess({
                        product: Object.assign({}, product.data, { product_detail: response.records, item, index }),
                        overlay: true,
                    })
                );
                // get product image
                const productImgs = _.get(response.records, "imgs", []);
                if (!_.isEmpty(productImgs)) {
                    const productImg = helper.getImageUrlInternal(productImgs[0]);
                    dispatch(setWechatShareImgUrl(productImg));
                }
            }
        } else {
            const response = await callWithErrorHandling({ fn, url: "product", query: { lan, pid } });
            if (response.RC === 200) {
                dispatch(
                    openProductContainerSuccess({
                        product: Object.assign({ index: payload.index, item: payload.item }, product.data,{product_detail:response.records}),
                        selfInputItem: payload.selfInputItem,
                    })
                );
            }
        }
    } catch (e) {}
};

export const getSearchProduct = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const url = "storeProducts";
        const { keywords, gid, pagination, lan, mid } = payload;

        const query = {
            gid,
            keywords,
            lan,
            mid,
        };

        if (pagination) {
            const response = await callWithErrorHandling({ fn, url, query });

            if (response.RC === 200) {
                dispatch(
                    getSearchProductsSuccess({
                        records: response.records,
                        keywords,
                    })
                );
            }
        } else {
            dispatch(getSearchProductsSuccess(payload));
        }
    } catch (e) {}
};

export const getProductGroupOrderHistory = (query) => async (dispatch) => {
    dispatch(setState({ ordersLoading: true }));
    try {
        const fn = get;
        const url = "productGroupOrders";

        const response = await callWithErrorHandling({ fn, url, query });

        if (response.RC === 200) {
            dispatch(getProductGroupOrderHistorySuccess(response));
        }
    } catch (e) {}
    dispatch(setState({ ordersLoading: false }));
};

export const getStoreCategories = (payload) => async (dispatch) => {
    try {
        const fn = get;
        const url = "storeCategories";
        const { gid } = payload;
        const query = {};
        const domain = helper.getIndependentDomain();

        if (domain) {
            query.domain = domain;
        } else {
            query.gid = gid;
        }
        const response = await callWithErrorHandling({ fn, url, query });
        if (response.RC === 200) {
            dispatch(
                setState({
                    allCategories: response?.records,
                })
            );
        }
    } catch (e) {}
};

export const getContentPageList = (params) => async (dispatch) => {
    try {
        const fn = get;
        const url = "contentPages";
        const { gid, lan } = params;
        const query = {};
        query.gid = gid;
        query.lan = lan;

        const response = await callWithErrorHandling({ fn, url, query });
        switch (response.RC) {
            case 200:
                dispatch(setState({ contentPageList: response.res }));
                break;
            default:
                break;
        }
    } catch (e) {}
};
