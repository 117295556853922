import React from "react";
import { orderAgain } from "../order-list-page/service";
import qs from "query-string";
import { connect } from "react-redux";

class ReOrder extends React.Component {
    componentDidMount = () => {
        const { lan, location, orderAgain } = this.props;
        const search = qs.parse(location?.search);

        orderAgain({
            lan,
            orderId: search["id"],
        });
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    lan: state?.settings?.lan ?? "en",
});

const mapDispatchToProps = {
    orderAgain,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReOrder);
