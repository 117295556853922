import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./_toasted.scss";

/**
 *  pop out indicator to inform users
 *
 */
class Toasted extends Component {
    static propTypes = {
        /**
         * Icon Value
         *
         */
        icon: PropTypes.string,
        /**
         * Icon Size
         *
         */
        iconSize: PropTypes.string,
        /**
         * display toast
         *
         */
        show: PropTypes.bool,
    };

    static defaultProps = {
        icon: "toast",
        show: false,
    };

    render() {
        const { className, icon, show, children, iconSize, ...others } = this.props;
        const cls = classNames("toast", {
            [className]: className,
        });
        return (
            <div className="toast-wrapper" style={{ display: show ? "flex" : "none" }} onClick={this.props.onClick}>
                <div className={cls} {...others}>
                    <p className="toast-content">{children}</p>
                </div>
            </div>
        );
    }
}

export default Toasted;
