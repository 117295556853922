const exports = {
    " ": " ",
    go_back: "돌아가기",
    notifications: "알리미",
    settings: "설정",
    logout: "로그아웃",
    logged_out_error: "로그아웃 되었으므로, 로그인 후 다시 시도해 주세요.",
    logged_out_success: "성공적으로 로그아웃 되었습니다. 다음에 다시 봐요!",
    confirm_logout_text: "정말 로그아웃 하시겠습니까?",
    ok: "Ok",
    error: "에러",
    no: "아니오",
    yes: "예",
    language: "언어",
    account: "계정",
    login: "로그인",
    cancel: "취소",
    store: "스토어",
    products: "상품리스트",
    restaurant: "레스토랑",
    groupsale: "공동구매",
    travel: "여행",
    shopping: "쇼핑",
    service: "서비스",
    save: "저장",
    confirm: "확인",
    success: "성공",
    loading: "로딩 중..",
    warning: "경고",
    general_error: "알 수 없는 에러가 발생하였습니다. 추후 다시 시도해 주세요",
    orders: "주문내역",
    click_to_input_address: "주소를 입력을 위해 클릭해 주세요",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    input: "입력",
    edit: "수정",
    years: "년도",
    quarters: "분기",
    months: "월",
    weeks: "주",
    days: "일",
    days_2: "Days",
    time: "Time",
    times: "Times",
    hours: "시간",
    minutes: "분",
    seconds: "초",
    milliseconds: "1/1000초",
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
    this_week: "This week",
    last_week: "Last week",
    this_month: "This Month",
    last_month: "Last Month",
    last: "Last",
    next: "Next",
    now: "Now",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    mo: "Mo",
    tu: "Tu",
    we: "We",
    th: "Th",
    fr: "Fr",
    sa: "Sa",
    su: "Su",
    share: "공유",
    dont_show_this_again: "다시 보기 않기",
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    view_more: "View More",
    viewing: "Viewing",
    show_filters: "Show Filters",
    reward_history_filter: "Reward History Filter",
    date_range: "Date Range",
    my_referrals: "My Referrals",
    how_to_do_it: "리워드 포인트 얻는 방법과 리워드 포인트 관련 규정",
    how_to_do_it_referrals:
        "친구나 SNS에 초대링크나 QR 코드를 공유하세요. 만약 그들이 신규유저일 경우 주문을 한다면. 당신은 그들이 링크를 통한 주문과 미래의 어느 주문에 대해서도 그들이 사용하는 매 달러마다 {v2pCustomerRef} 포인트를 얻게 될것입니다. 만약 당신이 기존유저를 초대한다고 해도, 이번 주문에 관련해서 단 한번 그들이 링크를 통해 주문하는 매 달러마다 {v2pOrderRef} 포인트를 얻게 될 것입니다.{br}{br}{p2v} 리워드 포인트는 스토어에서 1달러와 같습니다.",
    copy_ref_url: "Copy Referral URL",
    total: "Total",
    generate_qr_code: "Generate QR Code",
    ref_orders: "Orders",
    income: "Income",
    check_how_much_money_earned: "Check how much money you've earned",
    check_how_much_rewards_earned: "Check how many reward points you've earned",
    redeemable: "Redeemable",
    total_redeemed: "Total Redeemed",
    sales: "Sales",
    check_how_many_sales: "Check how many sales you've made",
    rewards_history: "Rewards History",
    check_daily_history: "Check your daily reward transactions",
    orders_info: "Check how many orders your friends have placed",
    referrals_info: "Referrals Information",
    referrals_info_desc: "This is your referrals information. Tap on each one to get orders and sales information",
    admin_reward_action: "Admin Reward Points",
    points_redeemed: "Redeemed Points",
    customer_invitation: "Customer Referral Points",
    order_invitation: "Order Referral Points",
    admin_credit: "Admin Credit",
    sales_rule: "Sales Rule Points",
    admin_revert_points: "Admin Revert Points",
    refunded_order: "Refunded Order Points",
    ref_successfully_copied: "Referral Code Copied Successfully",
    click_tips_with_helper_text: "Tips(click amount to update)",
    click_set_points: "Click to redeem points",
    click_to_search_address: "Click to search address",
    click_please_input_shipping_addresss: "Click to input shipping address",
    click_please_input_pickup_addresss: "Click to choose pick-up location",
    click_please_input_payment_method: "Click to Select Payment Method",
    click_to_show_on_map: "Click here to show on map",
    tap_to_search_address: "Tap to search address",
    sorry_search: "No search result for the specified keyword.",
    try_again_search: "Please try another search keyword",
    recent_searches: "최근 검색",
    recommendations: "추천 업소",
    deals: "세일",
    view_all: "모두 보기",
    products_not_available: "The following item(s) are not available now, and will be removed from your cart:",
    shopping_cart_empty: "현재 고객님의 쇼핑카트는 비어 있습니다",
    not_available_dialog_title: "Not Available Now",
    not_available_dialog_captain: "This item is only available in the following hours.",
    sorry_product_not_available: "죄송합니다, 이 품목은 다음 영업시간에만 가능합니다",
    feedback: "피드백",
    download_qr: "Download the QR Code",
    download_qr_mobile: "Long press the image to download",
    download_poster: "Download the poster",
    download_poster_mobile: "Long press the image to download",
    rate_the_product: "제품 평가",
    are_you_sure_cancel_review: "이 리뷰를 삭제 하시겠습니까?",
    submit: "전송",
    review_order_placeholder: "내 리뷰는 다른 사람들이 더 나은 선택을 할 수 있도록 도와 줄 것입니다.",
    review_submitted_message: "참여해 주셔서 감사합니다. 검토 후 검토가 게시됩니다",
    please_input_or_select: "충분한 이유를 입력하거나 선택하십시오",
    review_service: "비즈니스 서비스 검토",
    rate_hint: "별을 탭하여 평가",
    not_available: "사용 불가",
    current_not_available: "Currently not available",
    allow_reservation: "예약 허용",
    has_tv: "TV 있음",
    has_free_wifi: "무료 Wi-Fi 있음",
    has_outdoor_seating: "야외좌석 있음",
    kid_friendly: "아동 동반 적합",
    group_friendly: "단체모임 적합",
    business_friendly: "비즈니스 모임 적합",
    street_parking: "거리 주차 가능",
    free_parking: "무료 주차",
    paid_parking: "유료 주차",
    noise_level: "소음 레벨",
    average: "보통",
    loud: "시끄러움",
    very_loud: "매우 시끄러움",
    casual: "캐주얼복장",
    business_casual: "비즈니스 캐주얼",
    smart_casual: "단정한 캐주얼 복장",
    business: "정장",
    semi_formal: "세미 정장",
    has_alcohol: "주류 있음",
    environment: "환경",
    attire: "옷차림새",
    upscale: "고급",
    classy: "고품격",
    hipster: "힙스터",
    intimate: "친밀함",
    no_alcohol: "주류 없음",
    beer_and_wine: "맥주 & 와인",
    full_bar: "바 있음",
    write_your_review_here: "이 비즈니스에 대한 리뷰를 작성하십시오",
    rate_the_store: "비즈니스 검토",
    more_information: "추가 정보",
    merchant_information: "비즈니스 정보",
    additional_pages: "추가 페이지",
    store_info: "사업 세부 사항",
    store_overview: "Overview",
    "열림": "사업",
    "닫기": "닫기",
    current_store: "현재 상인",
    order: "주문",
    my_order: "주문 내역",
    deliver_to: "Deliver to",
    delivery: "배달",
    check_out: "결제하기",
    options: "옵션",
    add_to_cart: "카트에 담기",
    pick_one: "필수, 1 선택",
    pick_amount: "Required, pick exactly {min}",
    pick_range_up_to: "{max} 까지 선택",
    pick_range_required: "필수, {min}-{max} 선택",
    pick_optional: "선택사항",
    pick_one_with_name: "{nm} is required, pick 1",
    pick_amount_with_name: "{nm} is required, pick exactly {min}",
    pick_range_up_to_with_name: "{nm} choose up to {max}",
    pick_range_required_with_name: "{nm} is required, pick {min}-{max}",
    up: "up",
    search_products: "상품 검색",
    current_menu: "Current Menu",
    all_menus: "All the menus",
    search: "검색",
    discount_by: "{y}구매시 {x} 할인 ",
    discount_by_without_price: "{x} off",
    store_closed: "비영업중",
    store_closed_not_allow_order: "스토어가 비영업중입니다. 스토어가 오픈하면 주문해 주세요.",
    order_items: "주문 항목",
    are_you_sure_clear_cart: "카트를 비우시겠습니까?",
    are_you_sure_keeping_cart: "There are saved items in the cart. Do you want to keep the items in the cart?",
    clear_the_cart: "Clear The Cart",
    keep_the_cart: "Keep The Cart",
    qr_code: "QR 코드",
    poster: "Poster",
    clear: "지우기",
    results: "결과",
    search_results: "{x} search results",
    pending_acceptance: "승인 요청중",
    delivery_time: "배달 시간",
    pickup: "픽업 시간",
    takeout: "픽업 시간",
    eatin: "방문식사 시간",
    order_pickup_time: "픽업시간: {time}",
    order_delivery_time: "배달 시간: {time}",
    transferred_to: "{contact} 에게 전송",
    received_from: "{contact} 로부터 수신",
    discount_amount: "{currentDiscount} 할인.",
    remaining: " {amount} 할인을 받으시려면 {remaining}를 더 추가하세요 .",
    remaining_delivery: " 배달 주문을 하시려면 {remaining} 만큼 더 추가하세요.",
    remaining_pickup: "보류중인 {remaining} 추가",
    menu_available_at: "This menu is only available @{available}…",
    please_input_name: "이름을 입력해 주세요",
    no_address_message: "현재 주소 기록이 없습니다. 나중에 주문을 쉽게하기 위해 주소지를 추가 할 수 있습니다.",
    select_branch: "지점을 선택하세요",
    store_on_hold: "스토어가 보류 중이며 현재 주문을 받지 않습니다.",
    out_of_stock: "재고 없음",
    load_more: "더 보기",
    no_order_message: "주문 내역이 없습니다. 주문을 시작/완료하면 주문 내역이 여기에 나타납니다.",
    no_more_data: "데이터가 더 이상 없습니다",
    no_search_results: "검색 결과가 없습니다",
    group_purchase: "공동 구매",
    show_more: "더 보기",
    show_less: "줄여서 보기",
    purchase_history: "구매 내역",
    start_time: "시작 시간",
    end_time: "완료 시간",
    expired: "만료됨",
    offer_expires_in: "주문만료 시간: {time}",
    offer_starts_in: "주문시작 시간: {time}",
    offer_starts_on: "시작 시간: {time}",
    offer_ends_on: "완료 시간: {time}",
    remaining_stock: "{count} 개가 남아 있습니다",
    people_ordered: "{amt} 명이 주문하였습니다",
    selection_error_min: "{option}은 최소한 {min} 개의 항목을 선택해야합니다",
    no_giftcard_message: "내가 소유 한 기프트 카드가 여기에 나타납니다.",
    upsell_products: "당신은 또한 좋아할지도 모릅니다 ...",
    switch_to_dine_in: "방문식사 확인",
    switch_to_in_store: "매장 주문 확인",
    switch_to_dine_in_message:
        "방문식사 주문을 진행하시려면 테이블 번호를 입력해 주세요. 주문 타입 {type}를 유지하시려면 [취소]를 탭하세요.",
    item_total: "추가 요금",
    surcharge_paid: "추가 요금 (유료)",
    surcharge_unpaid: "추가 요금 (유료)",
    related_id: "관련 주문 번호",
    related: "관련",
    surcharege_paid_message: "The surcharge amount is not included in this order. Please refer to order #{id}",
    surcharge_paid_to_message: "This order is to pay for the surcharge of order #{id}",
    switch_to_dine_in_confirm: "현재 매장에없는 것 같습니다. 식사 주문을 하시겠습니까?",
    switch_to_in_store_confirm: "현재 판매자가 아닌 것 같습니다. 매장에서 주문을 하시겠습니까?",
    pull_to_refresh: "Pull to Refresh",
    no_products_record: "No products found.",
    delivery_locations: "Delivery Zones / Schedule",
    pickup_locations: "Pick-up Locations",
    min_amount: "Minimum Amount",
    min_order_amount: "Min. Order Amount",
    free_delivery_amount: "Free Delivery Amount",
    delivery_times: "Delivery Times",
    pickup_times: "Pick-up Times",
    time_range: "Time Range",
    store_location: "Store Location",
    pickup_locations_schedule: "Pick-up Locations / Schedule",
    roll_over_image_to_zoom_in: "이미지 위에 마우스 커서로 확대보기",
    about: "정보",
    product_information: "상품 간략 설명",
    product_details: "상세 상품 설명",
    buy_now: "바로 구매",
    see_more_product_details: "상세 상품 설명 더 보기",
    video: "동영상",
    page_of: "페이지 {page} / {totalNumOfPages}",
    warning_max_purchase_limit_reached: "Warning: Maximum purchase limit reached",
    max_purchase_limit_reached_msg: "You may only purchase {max_sale_qty} of this product.",
    warning_max_purchase_qty_exceeded_msg:
        "The following items have exceeded the maximum purchase quantity. Item quantities will be modified to fit within the limit.",
    max_purchase_qty: "Maximum Purchase Quantity: {qty}",
    warning_min_sale_qty_not_met_msg: "The following items do not fulfill the minimum purchase quantity.",
    min_purchase_qty: "Minimum Purchase Quantity: {qty}",
    remove_items: "Remove items",
    increase_item_quantities: "Increase item quantities",
    store_offline_message: "Sorry, we are currently not accepting online orders. Please check back later.",
    order_payment_status_error: "The store has accepted this order. Please pay in person.",
    order_comment_status_error:
        "The store has started preparing this order; please contact the store for any change request. Tel: {phone}.",
    oos_items_cannot_be_added: "The item(s) {oos_items} are not available and could not be added to the cart.",
    order_cancelled: "Order Cancelled",
    pending_store_confirmation: "스토어 확인 대기중",
    pending_customer_confirmation: "손님 확인 대기중",
    store_accepted: "Order is accepted",
    order_preparing: "Order is being prepared",
    store_is_preparing: "Store preparing",
    ready_pickup: "Order is ready for pick-up",
    ready_courier_pickup: "Order is ready for delivery",
    rejected_by_courier: "Rejected by courier",
    courier_assigned: "Courier assigned",
    courier_accepted: "Courier accept the task",
    courier_heading_to_store: "Courier heading to the store",
    courier_arrived_at_store: "Courier arrived at the store",
    courier_collected_package: "Courier collected the package",
    courier_heading_to_customer: "Order is being delivered",
    courier_arrived_at_customer: "Courier arrived at customer",
    delivered_order: "Delivered order",
    order_changes_description:
        "Changes have been made to your order. Please refer to the suggested changes and confirm ASAP to avoid any potential delay of your order.",
    confirm_item_change: "Confirm Order Change",
    are_you_sure_reject:
        "Reject the suggested change will result in removing this item from your order, are you sure you want to continue?",
    accepted: "Accepted",
    rejected: "Rejected",
    added: "Added",
    removed: "Removed",
    updated: "Updated",
    add_back: "Add Back",
    to: "to",
    updated_an_item: "Updated an item",
    added_an_item: "Added an item",
    removed_an_item: "Removed an item",
    replaced_an_item: "Replaced an item",
    are_you_sure_change_order_confirm: "Are you sure you want to confirm the changes to this order?",
    order_change_return_price_delta:
        "The total amount for the updated order is {new_price}, you will be refunded the difference upon completing the order.",
    order_change_pay_price_delta:
        "The total amount for the updated order is {new_price}, the final charge to your card will be this amount upon completing the order.",
    order_change_pay_new_price:
        "The total amount for the updated order is {new_price}, you will need to pay the price difference of {price_delta}. Please make the payment for the surcharge to avoid delaying your order.",
    order_change_reject_all: "You have rejected all the suggested changes. Are you sure you want to cancel the order?",
    order_change_reject_all_refund:
        "You have rejected all the suggested changes. Are you sure you want to cancel the order? Upon cancelling you will be refunded {price_delta}.",
    order_change_refund:
        "The total amount for the updated order is {new_price}, you will be refunded the price difference of {price_delta}.",
    write_review: "리뷰 작성",
    order_again_adds_items: "[재주문하기]는 동일  상품을 자동으로 카트에 담습니다.",
    hide_menu: "Hide Item",
    show_menu: "Show Item",
    copy: "복사하기",
    copied_success: "주문번호 # 를 클립보드에 복사하였습니다",
    shipping_time: "선적 시간",
    order_time: "주문 시간",
    pickup_time: "픽업 시간",
    request_due_time: "Due Time",
    requested_pickup_time: "Req. Pickup Time",
    requested_delivery_time: "Req. Delivery Time",
    ready_pickup_time: "Ready For Pickup time",
    estimated_delivery_time: "Estimated Delivery Time",
    store_name: "스토어명",
    shipping_info: "배송 정보",
    order_info: "주문 정보",
    buzz: "부저",
    order_detail: "주문 상세",
    union_pay: "Union Pay",
    union_pay_express: "Union Pay Express",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "Credit Card",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "E-transfer/이메일 송금",
    emt_description: "주문 후 E-Transfer/이메일 송금으로 결제",
    payment_received: "결제 완료",
    payment_pending: "결제 대기중",
    payment_cancelled: "결제 취소됨",
    payment_failed: "결제 실패",
    emt_payment_note_long:
        "E-Transfer/이메일 송금 결제를 선택하셨습니다. 결제를 신속히 처리하시어 스토어에서 바로 주문을 받을 수 있게 해주세요.",
    emt_payment_password: "결제 비밀번호 {emt_password} 를 이용하여 {emt_account} 로 {amount} 을 송금해주세요",
    emt_payment_account: "{emt_account} 로 {amount} 을 송금해주세요",
    free: "No payment required",
    dine_in: "방문식사",
    in_store: "매장안에서",
    flatrate: "기본 요율",
    freeShipping: "Digital Asset",
    instantCheckout: "Instant Checkout",
    pending: "보류 중",
    processing: "진행 중",
    payment_review: "결제 검토",
    completed: "완료됨",
    canceled: "취소 되었습니다",
    closed: "닫힘",
    pending_payment: "결제 보류",
    holded: "계류됨",
    cancel_order: "주문 취소",
    order_number: "주문 #",
    order_status: "주문 상황",
    order_date: "주문 시간",
    order_type: "주문 타입",
    shipping_method: "배달비",
    expected_time: "마감 시간",
    customer: "고객",
    table_number: "테이블 #",
    party_size: "일행수",
    delivery_address: "배달 주소",
    pickup_location: "Pickup Location",
    store_discount: "스토어 할인",
    coupon: "쿠폰",
    shipping_and_handling: "배송 & 취급",
    shipping_and_handling_with_distance: "배달비 ({distance})",
    order_total: "합계",
    giftcard_payment: "기프트카드로 지불",
    points_payment: "포인트로 지불",
    points_payment_with_points: "포인트로 지불 ({x})",
    comments: "코멘트",
    reorder: "재주문하기",
    general_order_error: "에러가 발생했다. 주문 목록 페이지로 돌아가서 다시 시도하십시오.",
    order_unexist: "주문 기록이 없습니다",
    you: "고객님",
    cancel_order_warning: "이 주문을 취소하시겠습니까?",
    cancel_order_success: " {store_name}에서 주문하신 주문번호 #{order_id} 가 취소 되었습니다.",
    cancel_order_failed: "주문 취소가 실패 했습니다 추후 다시 시도해 주세요.",
    pay_now_unavailable: "온라인 결제 사용 불가",
    no_online_payment:
        "죄송합니다, 현재 스토어에서 온라인 결제를 받지 않습니다, 나중에 다시 시도하거나 스토어에 직접 문의 하십시오.",
    pay_now: "즉시 지불하기",
    update_review: "리뷰 수정",
    write_review_for: "{name}에 대한 리뷰를 작성하십시오.",
    requested_time: "요청 시각",
    scheduled_time: "예정 시각",
    exp_dt: "만료 시간",
    ord_total: "주문 합계",
    s_method: "주문 타입",
    p_method: "결제수단",
    start_processing_message: "주문을 처리 중으로 표시하시겠습니까?",
    mark_as_complete_message: "주문을 완료로 표시하시겠습니까?",
    cancel_order_message: "주문을 취소하시겠습니까?",
    complete: "완료",
    reject: "거절",
    total_refunded: "환불 합계",
    refund_amount: "환불 금액",
    refund_all: "모두 환불하기",
    remark: "코멘트",
    "edit-shipping-fee-message":
        "배송비를 업데이트하면 이전 주문이 취소되고 배송비가 업데이트되어 새 주문이 생성되는데, 계속하시겠습니까?",
    edit_shipping_info: "배송지 주소 수정",
    additional_info: "추가 정보",
    exp_time: "요청된 배달시간",
    estimatedDeliTime: "예상 배달시간",
    "create-new-order-message": "이 주문이 취소되고, 대신 신규 주문이 생성될 것입니다. 진행하시겠습니까?",
    order_and_account_information: "주문 & 계정 정보",
    address_info: "주소 정보",
    shipping_address: "배송지 정보",
    payment_and_shipping_method: "결제 및 배송 방법",
    payment_info: "결제 정보",
    shipping_handling_info: "배송 처리 정보",
    item_order: "주문된 항목",
    raw_total: "원시 총계",
    notes_for_this_order: "이 주문에 대한 참고 사항",
    alert_on_notification: "알리미 도착 알람",
    sub_comment: "코멘트 제출하기",
    modify_discount: "할인정보 수정",
    add_discount: "할인정보 추가",
    modify_tips: "팁 수정하기",
    add_tips: "팁 추가",
    modify_shipping_fee: "배송비 수정",
    discount: "할인",
    edit_payment: "결제정보 수정",
    edit_extra: "정보 업데이트",
    edit_payment_method: "결제정보 업데이트",
    "select_receipt(s)_to_print": "인쇄할 영수증 선택",
    change_payment_to: "지급 방법 변경 확인 - 지불 방법 : ",
    handling: "처리",
    coupon_discount: "할인 쿠폰",
    phone: "Phone #",
    email: "Email",
    unpaid: "미결제",
    admin: "관리자",
    cust: "고객",
    other_payment: "기타 결제",
    access_denied: "죄송합니다, 이 주문을 볼 수 있는 권한이 없습니다",
    please_pay_now: "미결제 중이니, 지금 지불하셔도 됩니다.",
    are_you_sure_cancel_order: "주문을 취소하시겠습니까?",
    please_pay_by_x: "고객님의 주문은 {x}를 위해 보류될 것입니다다. 취소 방지를 위해 지금 지불하세요.",
    reward: "Reward",
    reward_points: "Reward Points",
    shipment_tracking_info: "운송 배달 정보",
    carrier: "배송업체",
    tracking_number: "운송장 번호",
    register: "회원 가입",
    vc_login: "SMS 수신 후 가입하기",
    resend: "재전송하기",
    pwd_login: "계정 로그인하기",
    email_phone_placeholder: "이메일/전화번호",
    password: "비밀번호",
    vc: "인증 코드",
    titleS: "Goopter",
    alert_login_succeed: "성공적으로 로그인 하셨습니다",
    alert_phone_not_found: "이 전화 번호는 아직 등록되지 않았습니다!",
    alert_too_many_request: "인증코드 요청 횟수가 최대에 도달했습니다. 15분 후에 다시 시도해 주세요.",
    alert_phone_length: "유효하지 않은 전화 번호!",
    alert_phone_vc_not_match: "잘못된 인증 코드입니다. 다시 시도해 주세요.",
    alert_login_failed_general: "로그인 실패. 나중에 다시 시도하십시오.",
    alert_login_failed: "잘못된 사용자 이름/비밀번호입니다.",
    forget_pwd: "비밀번호 찾기",
    state_reg:
        "만약 저희 웹사이트에 아직 등록하지 않으셨다면, 문자메시지를 통해 로그인 하시면 시스템에서 계정을 자동 생성할 것입니다.",
    cos_privacy_statement: "로그인함은, 고객님이 Goopter의 {cos} 와 {privacy} 에 동의함을 의미합니다.",
    cos: "이용 약관",
    privacy: "개인정보 보호정책",
    or_login_with_social: "또는 소셜로그인 이용 ",
    social_redirect_text: "소셜 계정으로 로그인하려면, 해당 스토어의 호스트 웹 사이트로 이동합니다. 계속하시겠습니까?",
    social_redirect_text_cart:
        "소셜 계정으로 로그인하면, 스토어의 호스트 웹 사이트로 이동하고, 카트가 비워집니다. 계속하시겠습니까?",
    social_login_failed: "{socialType} 계정으로 로그인 중에 에러가 발생했습니다. 다시 시도해 주세요.",
    check_out_as_guest: "Check out as Guest",
    email_placeholder: "Email",
    c_tn: "전화번호 #",
    nickname: "닉네임",
    alert_invalid_request: "잘못된 요청 입니다",
    alert_phone_exists: "죄송합니다. 이 전화번호는 이미 가입되어 있습니다.",
    alert_bind_phone_conflict_title: "번호가 이미 등록되었습니다",
    alert_bind_phone_phone_exists:
        "This number has been registered with another account, please logout and login with the number if you want to use this number to place order;  alternatively you may use another phone number for your current account.",
    alert_bind_phone_phone_exists_guests:
        "This number has been registered with another account, please sign in with the number if you want to use this number to place an order; alternatively you may use another phone number for your current account",
    alert_bind_phone_conflict:
        "The number you try to use has been registered on another account, if want to use this number for ordering,  tap Continue button to bind your current social account with this number, if you want to use another number,  click cancel and try with another number.",
    alert_vc_input_time_expired:
        "1분내에 SMS 인증코드를 받지 못하셨다면, 해당 전화번호가 올바르지 않거나 문자를 받을 수 없는 전화번호일 수 있습니다.{br}{br}전화번호가 올바른지 확인하시고 다시 시도해 주세요.",
    alert_reg_success_text: "이제 전화번호를 사용하여 로그인 가능합니다.",
    alert_reg_success_email: "이제 이메일을 사용하여 로그인 가능합니다.",
    alert_reg_failed: "가입 실패 하였습니다.",
    alert_email_length: "유효하지 않은 이메일",
    alert_password_min_length: "비밀번호는 6 자 이상이어야합니다",
    open: "영업중",
    store_popup_sold: "팔림",
    distance: "거리",
    prep_time: "Prep. Time",
    min_delivery_amnt: "최소 배달 금액",
    max_delivery_dist: "최대 배달 거리",
    public_notice: "공지 사항",
    discounts: "할인",
    discount_message: " {price} 구매시 {discount} 할인",
    discount_message_without_price: "{discount} off",
    credit_card_added: "신용카드가 추가 되었습니다.",
    map_search_placeholder: "주소를 입력하세요",
    exceed_delivery_distance_select:
        "선택하신 주소는 스토어에서 정한 최대 배달거리 {delivery_distance}{distance_unit}를 초과합니다. 다른 주소를 선택해 주세요.",
    exceed_delivery_distance_input:
        "입력하신 주소는 스토어에서 정한 최대 배달거리 {delivery_distance}{distance_unit}를 초과합니다. 다른 주소를 입력해 주세요.",
    address_not_in_delivery_zone: "선택하신 주소는 스토어에 배달 가능 지역이 아닙니다. 다른 주소를 선택해 주세요.",
    invalid_first_name: "유효하지 않은 이름(명)입니다.",
    unsuccessful_checkout: "체크 아웃에 실패했습니다. 다시 시도하십시오.",
    invalid_shp_mtd: "체크 아웃에 실패했습니다. 배송 방법이 유효하지 않습니다. 다른 배송 방법을 시도하십시오",
    pay_later_cash_description: "직접 현금으로 결제",
    pay_later_card_description: "직접 카드로 결제",
    continue: "계속",
    customer_info: "고객 정보",
    please_leave_your_comment: "코멘트를 남겨주십시오.",
    order_now: "지금 주문하기",
    order_success: "주문서가 발송 되었습니다.",
    view_order: "주문서 보기",
    secure_connection: "보안 연결을 통해 정보가 전송 되었습니다.",
    confirm_order: "주문 완료하기",
    back_to_store: "스토어로 돌아가기",
    subtotal: "중간 합계",
    delivery_fee: "배달비",
    total_before_tax: "세전 합계",
    tax: "세금",
    tips: "팁",
    tips_with_helper_text: "팁(수정 하시려면 탭하세요)",
    quantity: "수량",
    total_paid: "지불한 총 금액",
    total_due: "지불할 금액",
    refund: "환불 요청",
    payment_method: "결제 수단",
    estimate_shipping_fee: "배달비 견적",
    shipping_option: "배송 방법",
    pick_shipping_option: "배송 방법 선택",
    shipping_option_title: "배송: ",
    pay_later: "추후 카드 결제",
    cash: "추후 현금 결제",
    credit_card: "신용카드",
    pay_by_credit_card: "신용카드로 결제",
    giftcard: "기프트카드",
    points: "포인트",
    points_and_giftcard: "포인트와 기프트카드",
    wechat_pay: "WeChat Pay",
    alipay: "Alipay",
    address_customer_info: "고객 정보 주소 지정",
    accept: "승인하기",
    c2c_regular: "정규 배송",
    c2c_express: "퀵 배송",
    S01: "무료 배송",
    S02: "기본 비용",
    S03: "요율 표",
    error_occured: "죄송합니다, 계정 인증이 실패 하셨습니다. 다시 로그인한 후 다시 시도하십시오.",
    transaction_declined: "거래가 거절 되었습니다. 유효하지 않은 신용 카드. 다른 결제 방법을 선택하십시오.",
    payment_method_missing: "결제수단 누락",
    billing_address_missing: "청구 주소 누락",
    giftcard_error: "기프트카드 오류. 페이지를 새로 고침 후 다시 시도하십시오.",
    customer_info_missing: "고객 정보 누락",
    table_number_missing: "현재 테이블 번호를 입력하십시오.",
    pickup_time_missing: "픽업 시간 누락",
    delivery_time_missing: "배달 시간 누락",
    delivery_address_missing: "배달 주소 누락",
    shipping_method_missing: "배달 방법 누락",
    set: "설정",
    price_over_limit: "체크 아웃에 실패했습니다. 가격이 결제 한도를 초과했습니다.",
    item_error: "에러 발생. 쇼핑 카트가 비어 있습니다. 일부 항목을 추가하고 다시 체크아웃해 보십시오.",
    no_items_error: "장바구니에 추가된 항목이 없습니다. 일부 항목을 추가하고 다시 체크아웃해 보십시오.",
    use_profile_info: "프로필 정보 사용",
    pick_up: "픽업",
    delivery_option: "배달 옵션",
    required: "필수",
    first_name_placeholder: "이름(명)을 입력하십시오.",
    last_name_placeholder: "이름(성)을 입력하십시오.",
    table_number_placeholder: "테이블 번호를 입력하세요. 테이블 #",
    unit_placeholder: "호수 #",
    buzz_placeholder: "부저번호 #",
    street_placeholder: "거리명/호수 입력",
    city_placeholder: "도시를 입력하세요",
    region_placeholder: "주명을 입력하세요",
    cntry_placeholder: "국가를 입력하세요",
    zipcd_placeholder: "우편 번호를 입력하십시오.",
    table_number_required: "테이블 번호는 필수 사항입니다",
    are_you_sure_remove_gift_card: "이 기프트카드를 결제에서 삭제하시겠습니까?",
    no_shipping_options: "선택한 주소에 대한 발송 방법이 없습니다. 다른 배송 주소를 선택하십시오.",
    delivery_time_changed: "배송 시간이 수정되었으므로 다른 적절한 시간을 선택하십시오.",
    gift_card_payment: "기프트카드 결제",
    balance: "잔액",
    expires: "만료",
    allowed_periods_unavailable: "선택 가능한 배송 시간을 얻으려면 적절한 배송 주소를 선택하십시오.",
    delivery_method_unavailable: "더 이상 배달이 불가능하니 스토어로 돌아가 다시 체크아웃하십시오.",
    takeout_method_unavailable: "더 이상 픽업이 불가능하니 스토어로 돌아가 다시 체크아웃하십시오.",
    in_store_method_unavailable: "더 이상 '매장안에서' 옵션이 불가능하니 스토어로 돌아가 다시 체크아웃하십시오.",
    agreement_notice: "구매 확인함으로써, 나는 Goopter의 {conditions_of_use} 과 {privacy_policy} 를 동의합니다",
    conditions_of_use: "이용 약관",
    privacy_policy: "개인정보 보호정책",
    "secure-connection": "보안 연결을 통해 정보 전송",
    set_payment_method: "결제 방법 설정",
    set_billing_address: "청구 주소 설정",
    set_delivery_address: "배달 주소 설정",
    set_giftcard: "기프트카드 선택",
    set_points: "Tap to redeem points",
    redeem_points_dialog_title: "Redeem Points",
    redeem_x: "Redeem: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "고객 정보 입력",
    set_table_number: "테이블 번호 입력",
    set_pickup_time: "픽업 시간 선택",
    set_delivery_time: "배달 시간 선택",
    set_shipping_method: "배송 방법 설정",
    address_book: "주소록",
    "no-giftcard-text": "이 스토어에 대한 기프트 카드를 소유하고 있지 않습니다",
    delete_credit_card_failed: "죄송합니다. 카드를 삭제할 수 없습니다. 나중에 다시 시도하십시오.",
    checkout_success:
        "귀하의 비즈니스에 감사드립니다. 주문  : # {order_id}이 생성되었습니다. 주문 상태가 업데이트되면 알림을 전송해 드립니다.",
    new_order_created: "새로운 주문이 생성 되었습니다.",
    distance_exceeded: "한계 거리를 초과하였습니다",
    min_delivery_amt_required:
        "The minimum delivery amount is {required} before tax and delivery fee, please add {gap} more for delivery.",
    insufficient_fund: "Insufficient fund, please try with another payment method.",
    invalid_payment_info: "Invalid payment information, please check your input and try again.",
    merchant_not_ready: "The merchant is not ready to accept your payment. Please try again later.",
    shipping_country_text: "다음 국가 내 주소로 배송 가능: {countries}",
    apply: "적용하기",
    enter_coupon_code: "쿠폰 코드 입력",
    remove_coupon_alert: "이 쿠폰을 제거하시겠습니까?",
    replace_coupon_alert: "현재 사용 중인 쿠폰이 교체되는데 계속 하시겠습니까?",
    invalid_alipay_coupon: "This coupon code is only available for AliPay payment method",
    remove_current_alipay_coupon:
        "The current shopping cart contains a coupon that is only available for AliPay payment method, changing to another payment will result in losing the discount, do you still want to change the payment method?",
    use_wechat_dialog_message:
        "To use WeChatPay, please open the store on Wechat to proceed to order. {br}{br}Options to open the store on Wechat:{br}1. If you have the store QR code, use Wechat to scan it;{br}2. Tap the store name on top of the page to go back to the store page, then tap the top right corner icon to open the QR code pop up window, save the QR code, then open the QR code in Wechat to proceed to order.",
    discount_total: "할인 총액",
    first_name_missing: "이름(명)을 입력하십시오.",
    phone_number_missing: "전화번호를 입력하십시오.",
    payment: "결제",
    select_payment: "결제수단을 선택하세요",
    select_giftcards: "Select a Gift Card",
    special_request: "특수 요청 사항",
    place_order_with_x: "주문 - {x}",
    min_type_required_message: "The minimum amount for {type} is {value}, please add more item to enable {type}.",
    select_pickup_location: "Select Pick-up Location",
    please_choose_pickup_location: "Please choose pick-up location",
    invalid_delivery_zone_title: "배달 가능 구역이 아닙니다.",
    invalid_delivery_zone_content:
        "The provided address is out of delivery boundary. Please input another address. Delivery zones and schedules as following:",
    please_input_address: "Please input a valid address",
    please_input_pickup_time: "Please choose pick-up time",
    please_input_delivery_time: "Please choose delivery time",
    recommend_a_good_restaurant: "Recommended you a good store",
    recommend_a_good_merchat: "Recommended you a good merchant",
    recommend_a_good_product: "Recommended you a good product",
    guest_user: "Guest User",
    require_utensil: "Request utensils, etc.",
    already_have_account: "Checking out as Guest",
    order_success_table: "Thanks for your order. We'll bring your order to your table as soon as it's ready.",
    order_success_parking_lot: "Thanks for your order. We'll bring your order to your car as soon as it's ready.",
    order_success_kiosk: "Thanks for your order. We'll notify you as soon as it's ready.",
    view_special_deals: "스페셜 상품 보기",
    more_items_to_get_special_deals: "현재 주문금액이 스페셜 상품을 구매하기에는 부족합니다. 상품을 더 구매하세요.",
    special_deals_for_you: "당신을 위한 스페셜 딜",
    add_items: "아이템 추가",
    discounted_upsell_items_removed_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers.",
    discounted_upsell_items_removed_empty_cart_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers. Your cart is now empty. Please add items to continue.",
    menu: "메뉴",
    category: "카테고리",
    categories: "카테고리",
    store_details: "스토어 상세정보",
    store_description: "스토어 설명",
    reviews: "리뷰",
    rank: "순위",
    customer_reviews: "고객 리뷰",
    single_selection: "단일 선택",
    optional: "선택 사항",
    pick_varied_options: "{count} 개의 항목을 고르세요",
    sold: "{count} 개 팔림",
    only_x_left: "단 {count} 개 재고 남음",
    sold_out: "매진",
    pick_option: "옵션을 선택하세요",
    contact_vendor: "Vendor 에게 연락하세요",
    call_vendor: "Vendor 에게 전화하세요",
    self_input: "직접 입력",
    with_option: "옵션 (s)",
    coupons: "{couponCount} 쿠폰",
    coupons_title: "쿠폰",
    redeemed: "할인적용 되었습니다.",
    redeem: "적용",
    remove: "삭제",
    valid_until: "{endDate} 까지 유효",
    checkout: "체크아웃",
    shopping_cart: "쇼핑 카트",
    empty_cart: "비우기",
    alert_empty_cart: "쇼핑 카트를 비우시겠습니까?",
    discount_title: "할인",
    qrcode: "QR코드",
    m: "미터",
    km: "킬로미터",
    rating: "평점",
    rating_title: "평점",
    base_delivery_fee: "{flatDistance} {distanceUnit} 내에서 배달비",
    min_delivery_amount: "최소 배달가능 금액",
    max_delivery_distance: "최대 배달가능 거리",
    distance_title: "거리",
    takes_reservations: "예약하기",
    more_info: "상세 정보",
    announcement: "공지 사항",
    no_announcement: "공지사항 없음",
    buy_x_discount_flat: "{currency}{price}, {currency}{discount} 할인가로 구매하세요",
    buy_x_discount_percent: "{currency}{price}, {discount}% 할인가로 구매하세요",
    search_products_title: "아이템 검색",
    search_products_input_placeholder: "검색 키워드를 입력하세요...",
    search_products_no_match: "입력하신 검색 키워드 '{text}' 로 검색된 결과가 없습니다.",
    alert_delete_product_with_options: "옵션이 있는 상품은 쇼핑 카트에서만 제거 할 수 있습니다.",
    store_closed_allowed_preorder: "스토어가 현재 비영업중입니다. 미리 선주문 하세요.",
    store_closed_allowed_preorder_x_mins_before:
        "스토어가 현재 비영업중입니다. 스토어가 오픈하기 {mins} 전부터 선주문이 가능합니다.",
    store_closed_soon_not_allowed_order: "스토어가 곧 영업을 종료하여 새로운 주문을 받지 않습니다.",
    results_colon: "결과: ",
    price: "가격",
    popularity: "인기",
    service_title: "서비스",
    product_title: "상품",
    all_title: "전부",
    satisfied_title: "만족",
    unsatisfied_title: "불만족",
    discount_rate: "{rate}% 할인",
    invalid_option_selection: "잘못된 옵션 선택",
    no_product_options: "상품 옵션 없음",
    single_selection_error: "다음 옵션 항목 중 하나를 선택하십시오. '{productOption}'",
    pick_ranged_options_error: "'{productOption}의 최소 선택 옵션수는 {count} 개 입니다",
    pick_fixed_options_error: "'{productOption}' 으로부터 {count} 개 이상의 옵션을 선택해 주세요.",
    update_cart: "카트 업데이트하기",
    total_reviews: "리뷰",
    store_total_sold_count: "팔림",
    hot_products: "Hot 아이템",
    related_products: "관련 상품",
    store_hours: "영업 시간",
    delivery_hours: "배달가능 시간",
    add_for_delivery: "최소 배달가능 금액은 {currency}{amount} 입니다.",
    open_in_app: "앱에서 열기",
    qrcode_message: "스토어를 열려면 QR code를 스캔하세요.",
    review_submitted_validate: "리뷰를 써 주셔서 감사드리며, 검증 후 게시될 것입니다.",
    review_submitted: "리뷰가 제출 되었습니다.",
    add_update_review: "리뷰를 추가하시거나 업데이트해 주세요",
    post_review_failed: "리뷰가 제출되지 않았습니다다. 페이지를 새로 고침 하신 후 다시 시도하십시오.",
    not_accepting_orders: "저희는 현재 온라인 주문을 받지 않고 있습니다.",
    bookmark_sign_in_alert_store: "로그인하신 후 이 스토어를 즐겨찾기에 추가하십시오!",
    bookmark_sign_in_alert_product: "로그인하신 후 이 아이템을 즐겨찾기에 추가하십시오!",
    add_item_another_menu_warning:
        "카트에 이미 다른 메뉴 ({menu})가 포함되어 있습니다.  카트를 비우고 이 항목을 추가하시겠습니까?",
    start_new_cart_question: "새 카트를 시작하시겠습니까?",
    change_menu: "메뉴 변경하기",
    direction: "지침",
    website: "웹사이트",
    we_also_recommend: "추천해 드립니다...",
    diff_delivery_price: "배달이 가능하려면 {x} 만큼 추가하세요",
    discount_add_x_get_y: "{y} 할인을 받으시려면 {x} 만큼 더 추가 구매하세요 ",
    discount_get_percentage_discount: " {x} 할인 받기",
    discount_get_value_discount: " {x} 할인 받기",
    discount_info_format: "{x} 구매",
    discount_not_accepting_order: "죄송합니다, 현재 온라인 주문 접수가 불가합니다",
    discount_store_closed_preorder: "스토어가 비영업중입니다. 현재는 선주문만 가능합니다",
    discount_x_off_add_y_get_z: "{x} 할인, {y} 만큼 추가하시면 {z} 할인됩니다",
    discount_x_off: "{x} 할인",
    switch_menu_warning_message:
        "The selected menu supports a different order type ({new_order_type}). The items in the cart will be cleared if you proceed. Do you want to switch to the new menu?",
    logout_confirm_message: "로그아웃 하시겠습니까?",
    logout_force_message: "죄송합니다, 계정이 다른 장치에서 로그인되었거나 시간 초과하셨습니다. 다시 로그인하십시오.",
    cannot_merge_account: "귀하의 계정이 이미 존재합니다. 다른 전화번호를 사용하십시오.",
    duplicate_social_error:
        "전화 번호가 이미 다른 계정에 등록되어 있습니다. 다른 번호를 사용하십시오. 이 번호를 사용하려면 로그 아웃 한 후이 번호로 로그인하십시오.",
    store_has_no_products: "현재이 상점에 상품이 없습니다.",
    error_with_code: "[Error {code}]: {error}",
    unable_to_load_any_data: "데이터를 로드할 수 없습니다",
    unstable_network: "네트워크 속도가 느리거나 불안정한 경우 네트워크 연결을 확인하십시오.",
    incorrect_password: "잘못된 비밀번호입니다",
    country_code_error: "국가 코드 에러",
    incorrect_username_password: "잘못된 유저네임 혹은 비밀번호",
    verification_code_expired: "인증코드가 만료되었습니다.",
    verifcation_code_request_overlimit: "인증 코드 요청 초과 제한",
    incorrect_verification_code: "잘못된 인증 코드입니다",
    error_fetch_user_profile: "사용자 프로필을 가져오지 못했습니다",
    login_failed: "로그인 하는데 실패 하였습니다",
    profile_update_success: "프로필 업데이트 완료",
    email_update_success: "이메일 업데이트 완료",
    phone_update_success: "전화번호 업데이트 완료",
    failed_fetch_gift_card_detail: "기프트 카드 세부 정보를 가져오지 못했습니다",
    gift_card_transfer_success: "기프트카드 전송 완료",
    gift_card_transfer_fail: "기프트카드 전송 실패",
    record_not_found: "데이터를 찾을 수 없습니다",
    failed_fetch_points_detail: "포인트 세부 정보를 가져 오지 못했습니다.",
    profile_update_failed: "사용자 프로필을 업데이트하지 못했습니다",
    email_update_failed: "이메일을 업데이트하지 못했습니다",
    phone_update_failed: "전화번호를 업데이트하지 못했습니다",
    get_verification_code_success: "인증코드가 휴대 전화로 전송되었습니다.",
    verification_too_many_requests: "인증 코드가 너무 많이 요청되었습니다. 나중에 다시 시도하십시오",
    invalid_table_number: "숫자를 입력하십시오",
    invalid_party_size: "숫자를 입력하십시오",
    email_required: "이메일 주소가 필요합니다",
    password_required: "비밀번호가 필요합니다",
    phone_required: "전화번호가 필요합니다",
    vc_required: "인증코드가 필요합니다",
    coupon_invalid: "유효하지 않은 쿠폰입니다",
    coupon_valid: "유효한 쿠폰입니다",
    invalid_coupon: "쿠폰코드 {couponCode} 는 유효합니다",
    coupon_applied: "쿠폰이 카트에 적용 되었습니다.",
    invalid_address: "유효하지 않은 주소입니다",
    remaining_stock_header: "Remaining Stock",
    item_oos: "죄송합니다, 현재 다음 품목들은 재고가 부족합니다.",
    invalid_shipping_method: "배송 방법이 유효하지 않습니다. 다른 배송 방법을 시도하십시오",
    please_input_phone_number: "Please input a valid phone number",
    please_input_first_name: "이름(명)을 입력하십시오",
    please_input_at_least_two_characters: "적어도 2가지 이상의 문자를 입력해 주세요",
    please_input_last_name: "성(이름)을 입력하십시오",
    please_input_shipping_method: "배달 방법을 입력하십시오",
    please_input_sub_shipping_method: "배달 방법을 입력하십시오",
    please_input_shipping_addresss: "배송지 주소를 입력하십시오",
    please_input_pickup_addresss: "Tap to choose pick-up location",
    please_input_table_number: "테이블 번호를 입력하십시오",
    please_input_payment_method: "Tap to Select Payment Method",
    please_input_valid_credit_card: "유효한 카드를 입력해 주세요",
    please_input_billing_address: "청구서 수신 주소를 입력하십시오",
    tap_to_show_on_map: "Tap here to show on map",
    please_add_some_items: "Please add some items",
    please_confirm_tips: "Tap to Set Tip Amount",
    please_set_minimum_tips: "Please Input Minimum Tip Amount",
    please_set_minimum_tips_detail: "The minimum tip amount for delivery is ${tips_amount} in order to secure a driver for your order.",
    bind_phone: "Bind Phone Number",
    please_bind_phone: "Phone number is needed to make an order. Do you want to bind now?",
    go_to_bind: "Go to Bind",
    bind_phone_success: "Bind Phone Number Success",
    invalid_credit_card: "신용카드가 유효하지 않습니다. 다른 결제 수단을 선택하십시오",
    invalid_request: "체크 아웃에 실패했습니다. 잘못된 요청입니다.",
    invalid_pay_mtd: "체크 아웃에 실패했습니다. 결제 수단이 유효하지 않습니다. 다른 결제 수단을 시도하십시오",
    please_input_belows: "누락 된 필드 아래에 입력 하십시오",
    unable_to_change_here: "이 곳에서는 변경할 수 없습니다",
    please_select_on_previous_page: "이전 페이지에서 선택하십시오",
    invalid_card_number: "유효하지 않은 카드 번호입니다",
    invalid_zipcd: "잘못된 우편 번호입니다",
    click_one_more_time_to_deselect: "선택을 해제하려면 한 번 더 클릭하십시오",
    paypal_payment_success_title: "결제가 완료 되었습니다",
    paypal_payment_success_text: "결제가 접수되었습니다. 주문 페이지로 이동됩니다.",
    error_payment_failed: "결제가 실패 했습니다",
    internal_server_error: "죄송합니다. 오류가 발생했습니다. 나중에 다시 시도하거나 공급 업체에 직접 문의하십시오.",
    unexpected_error: "예기치 않은 오류가 발생했습니다. 나중에 다시 시도하십시오",
    giftcard_pays_order_total: "선택한 기프트카드는 이미 주문 총액을 지불합니다. 추가 결제 수단이 필요하지 않습니다.",
    points_pays_order_total: "포인트는 이미 주문 총액을 지불하는데 충분합니다. 추가 결제 수단이 필요하지 않습니다.",
    giftcard_points_pays_order_total:
        "기프트카드와 포인트는 이미 주문 총액을 지불데 충분합니다. 추가 결제 수단이 필요하지 않습니다.",
    digit_only: "숫자만 입력하십시오",
    please_selected_belows: "모든 선택 항목을 완료하십시오:",
    please_select_location_from_the_list: "목록에서 주소를 선택하십시오.",
    alert_password_reset_success: "비밀번호가 성공적으로 업데이트되었습니다. 이제 새 비밀번호로 로그인 할 수 있습니다.",
    alert_email_not_found: "이 이메일은 아직 등록되지 않았습니다!",
    alert_password_reset_email_send: "비밀번호 재설정 이메일이 발송되었습니다",
    x_is_required: " 는 필수 항목입니다.",
    location_permission_is_blocked_service_may_not_perform_correctly:
        "위치 권한이 차단되어 이 서비스에 영향을 줄 수 있습니다.",
    x_missing: "{x} 를 입력해 주세요 ",
    x_invalid: "유효하지 않은 {x} 입니다",
    x_select: "{x} 를 선택해 주세요",
    do_you_want_to_receive_notification: "알리미를 받으시겠습니까?",
    gift_cards_points_alert:
        "You are not allowed to use the gift card when you redeem points. To use a gift card, please remove the reward points first.",
    points_gift_cards_alert_message:
        "You are not allowed to use the reward points when you use gift card. To use reward points please remove the gift card first.",
    not_found_description: "Oops! Page Not Be Found",
    not_found_detail_description:
        "Sorry but the page you are looking for does not exist, has been removed/renamed, or is temporarily unavailable",
    back_to_home: "Back to homepage",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    please_input_valid_postal_code: "Please input a valid postal code/zip code.",
    please_input_valid_x: "Please input a valid {x}",
    too_many_card_attempt:
        "The provided card has been declined too many times; please try another card for the payment.",
    too_many_checkout_attempt: "Maximum retry limit exceeded. Please try again later.",
    payment_declined: "Payment Declined",
    credit_card_declined:
        "Please check the credit card information to make sure they were entered correctly and try again. If this does not resolve the problem, please call your card issuing bank to resolve.",
    no_giftcard: "You do not have available gift card",
    giftcard_insuffienct_fund: "Your Gift Card has an insufficient balance for the payment.",
    invalid_request_body: "Invalid request",
    pay_order: "주문 결제",
    expiry_date: "만료일자",
    postal_code: "우편번호",
    postal_code_zipcd: "Postal Code/Zip Code",
    country: "국가",
    last_four: "마지막 4숫자",
    are_you_sure_delete_card: "이 신용카드를 정말 삭제하시겠습니까?",
    visa: "Visa",
    master_card: "Master Card",
    american_express: "American Express",
    discover: "Discover",
    jcb: "JCB",
    maestro: "Maestro",
    credit_card_description: "Visa, Master Card, American Express",
    confirm_pay: "결제 확인",
    unsuccessful_payment: "결제 실패. 다시 시도해 주세요.",
    reset_password_title: "비밀번호 초기화하기",
    new_password: "새 비밀번호",
    password_reset_continue: "전송",
    reset_by_text: "SMS로 초기화하기",
    reset_by_email: "이메일 초기화하기",
    alert_vc_expired: "제공 한 인증 코드가 만료되었습니다. 다른 인증 코드를 요청한 후 다시 시도하십시오.",
    email_verification: "Email Verification",
    verification_success: "Your account has been verified!",
    verify_account: "Thanks for verifying for your account, {email}.",
    verification_fail: "Email Verification Failed",
    verify_error: "An error has occurred, please try again by resending a verification email. {email}",
    view_account: "View Account",
    link_expired: "Couldn't verify email address",
    link_expired_message: "The verification link has expired or was already used. Please try again. {email}",
    email_already_verified: "Your account has already been verified.",
    resend_verification_email: "Resend Verification Email",
    recently_viewed: "“최근에 본 항목”",
    by_phone: "SMS로",
    by_email: "이메일을 통해",
    password_change_success: "암호가 성공적으로 변경되었습니다.",
    password_request_sent: "비밀번호 변경 요청이 전송되었습니다",
    social_binding: "Social Binding",
    linked: "Linked",
    facebook: "Facebook",
    twitter: "Twitter",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    or: "or",
    connect_with_facebook: "Sign in with Facebook",
    connect_with_twitter: "Sign in with Twitter",
    connect_with_google: "Sign in with Google",
    connect_with_wechat: "Sign in with WeChat",
    connect_with_apple: "Sign in with Apple",
    dont_have_account: "Don't have an account?",
    vouchers: "상품권",
    gift_card: "기프트카드",
    bookmarks: "북마크",
    bookmark_added: "북마크 추가",
    bookmark_deleted: "북마크가 삭제되었습니다.",
    account_info: "계정 정보",
    my_points: "내 포인트",
    my_giftcards: "내 키프트카드",
    my_bookmarks: "내 북마크",
    my_messages: "내 메세지",
    my_orders: "내 주문서",
    avatar: "아바타",
    name: "이름",
    my_address: "나의 주소",
    account_binding: "계정 바인딩",
    connect: "연결하기",
    safety_settings: "보안 설정",
    login_password: "로그인 비밀번호",
    modify: "수정",
    delete: "삭제",
    first_name: "명(이름)",
    last_name: "성(이름)",
    phone_number: "전화 #",
    address: "주소",
    pick_place_on_map: "지도상에서 찾기",
    done: "완료",
    enter_address_manually: "주소를 수동으로 입력하기",
    use_name_account: "계정에 있는 이름 사용하기",
    new_address: "새 주소",
    unit: "호",
    street: "거리",
    city: "시/타운",
    region: "지역",
    cntry: "국가",
    zipcd: "우편번호",
    postalcd: "우편번호",
    required_text: "* 는 필수 항목입니다",
    map_search: "지도 검색",
    invalid_last_name: "유효하지 않은 이름(성)입니다.",
    invalid_credit_card_number: "유효하지 않은 신용카드입니다.",
    invalid_credit_card_cvv: "잘못된 CVV",
    invalid_cntry: "유효하지 않은 국가",
    Invalid_zipcd: "유효하지 않은 Zip Code",
    street_missing: "거리명을 입력해 주세요",
    city_missing: "시/타운명을 입력해 주세요",
    region_missing: "지역을 입력해 주세요",
    cntry_missing: "국가를 선택해 주세요",
    zipcd_missing: "우편번호를 입력해 주세요",
    billing_address: "청구지 주소",
    confirm_delete_address: "주소 삭제 확인",
    delete_address_message: "이 주소를 삭제 하시겠습니까?",
    address_edit: "주소 편집",
    default: "기본값",
    set_as_default: "기본값으로 지정",
    nick_name: "별명",
    update_profile_success: "프로필이 성공적으로 업데이트되었습니다!",
    update_profile_failed: "죄송합니다. 프로필 업데이트에 실패했습니다. 나중에 다시 시도하십시오.",
    last_name_missing: "이름(성)을 입력해 주세요",
    nick_name_missing: "별명을 입력해 주세요",
    update: "업데이트",
    user_info: "고객 정보",
    new_phone: "새 전화 #",
    new_email: "새 이메일",
    get_vc: "인증코드 요청",
    phone_placeholder: "전화번호",
    alert_phone_updated: "전화 번호가 성공적으로 업데이트되었습니다!",
    alert_missing_fields: "필수 입력란을 채워주세요.",
    alert_forbidden_action: "죄송합니다.이 작업은 금지되어 있습니다.",
    alert_email_updated: "귀하의 이메일이 성공적으로 업데이트되었습니다!",
    alert_forbidden_email: "죄송합니다.이 작업은 금지되어 있습니다.",
    alert_email_exists: "죄송합니다.이 이메일은 이미 시스템에 존재합니다.",
    wallet: "지갑",
    confirm_delete_credit_card: "신용 카드 삭제 확인",
    confirm_delete_credit_card_message: "이 신용 카드를 삭제 하시겠습니까?",
    add_credit_card_title: "신용 카드 추가",
    add_credit_card: "신용 카드 추가",
    save_credit_card: "신용 카드 저장",
    card_number: "카드번호",
    name_on_card: "카드 소유자명",
    cvv: "CVV",
    set_payment_password: "결제 비밀번호 설정하기",
    old_password: "이전 비밀번호",
    confirm_new_password: "새 비밀번호 확인",
    password_not_detected: "기존 비밀번호가 일치하지 않습니다. 수정 후 다시 시도하거나 비밀번호를 재설정하십시오..",
    server_error: "문제가 발생했습니다. 나중에 다시 시도하십시오.",
    unmatched_passwords: "새 비밀번호와 새 비밀번호가 동일하지 않은지 확인하십시오. 수정 후 다시 시도하십시오.",
    social_bind_not_allowed:
        "WeChat에서는 wechat이 허용되지 않는 한 소셜 계정을 통해 로그인 또는 바인딩 또는 바인딩 해제합니다. 외부 브라우저를 사용하십시오",
    social_bind_success:
        "귀하의 계정이 {socialType} 계정과 성공적으로 연결되었습니다. 이제 {socialType} 계정으로 로그인 할 수 있습니다!",
    social_bind_failed: "{socialType} 계정을 연결하는 중에 오류가 발생했습니다. 다시 시도하십시오.",
    connected: "연결 되었습니다",
    confirm_unbind_social_account: "{socialType} 계정의 바인딩을 해제 하시겠습니까?",
    unbind_social_success: "귀하의 {socialType} 계정 바운딩이 해제 되었습니다.",
    unbind_social_error: "현재 귀하의 {socialType} 계정을 바인딩 해제 할 수 없습니다. 나중에 다시 시도하십시오.",
    create_new_vault_password: "신용 카드 보안을 유지하기 위해 비밀번호 추가",
    confirm_vault_password: "비밀번호 재확인",
    input_vault_password: "결제 비밀번호를 입력하세요",
    vault_password: "결제 비밀번호",
    password_instructions: "비밀번호는 4-6 자리 숫자 여야합니다.",
    password_incorrect: "비밀번호 오류",
    add_address_invalid_address: "잘못된 주소. 지도에서 주소를 선택하십시오.",
    country_not_exists: "잘못된 국가입니다. 지도에서 주소를 선택하십시오.",
    map_select_address: "검색 창을 채우고 제안 된 주소를 선택하십시오.",
    error_current_location: "현재 위치를 가져 오는 중에 오류가 발생했습니다. 검색 창에 주소를 입력하십시오.",
    map_address_accept_error: "Google지도 오류 다른 주소를 시도하거나 수동으로 주소를 입력하십시오.",
    invalid_name: "카드 소지자의 이름을 입력하십시오.",
    invalid_cc_info: "신용 카드 정보가 유효하지 않습니다. 수정 한 후 다시 시도하십시오.",
    invalid_expiry_date: "신용 카드 유효 기간이 유효하지 않습니다. 수정 후 다시 시도하십시오.",
    alert_guest_account:
        "현재 게스트 계정으로 로그인 하셨습니다; 이름, 이메일/전화번호를 입력하시고 계정을 등록 하시면 개인설정 저장 및 스토어 할인을 받으실 수 있습니다. 계속 하시겠습니까?",
    alert_missing_account_info: "Please fill in the missing information: {missingInfo}",
    address_amount_exceeded: "주소 목록이 초과되었습니다. 최대 10 개의 주소 만 저장할 수 있습니다.",
    i_will_do_it_later: "다음에 하겠습니다",
    finish_later: "나중에",
    skip: "건너뛰기",
    change_by_email: "이메일로",
    change_by_text_message: "SMS로",
    change_by_existing_password: "기존 비밀번호로",
    set_password: "비밀번호 설정",
    change_password: "비밀번호 변경하기",
    update_password: "비밀번호 업데이트",
    code: "코드",
    request_pass_reset: "비밀번호 재설정 요청",
    confirm_password: "비밀번호 확인",
    set_a_password: "비밀번호 설정",
    set_a_password_caption: "이후에 로그인을 빠르게 하기 위해 비밀번호를 설정하세요.",
    set_a_new_password: "새 비밀번호 설정",
    set_a_new_password_caption: "비밀번호를 잊으셧나요? 빠른 로그인을 위해 새 비밀번호를 설정하세요.",
    update_password_success: "비밀번호 업데이트 성공",
    update_password_fail: "비밀번호 업데이트에 실패했습니다. 나중에 다시 시도하십시오",
    birthday: "Birthday",
    gender: "Gender",
    not_provided: "Not provided",
    currency: "Currency",
    male: "Male",
    female: "Female",
    none: "None",
    year: "Year",
    month: "Month",
    day: "Day",
    cannot_update_profile: "프로필을 업데이트 할 수 없습니다.",
    updated_profile: "업데이트 된 프로필.",
    giftcards: "기프트카드",
    gift_card_details: "기프트카드 상세정보",
    all: "모두",
    active: "활성",
    inactive: "비활성",
    active_giftcard: "활성",
    inactive_giftcard: "비활성",
    used: "사용완료",
    book_value: "장부 가격",
    purchase_date: "구입일자",
    status: "상태",
    reassign: "재할당하기",
    reassign_giftcard: "기프트카드 재할당하기",
    receivers_email_phone: "수신자 이메일/전화번호",
    giftcard_reassign_success: "기프트 카드가 {receiver} (으)로 이전되었습니다.",
    giftcard_reassign_missing_receiver: "수신자 정보를 입력하십시오",
    giftcard_reassign_missing_info: "기프트 카드를 보내려면 유효한 수신자가 있어야합니다.",
    giftcard_reassign_failed: "기프트 카드를 재 할당하는 중에 오류가 발생했습니다. 나중에 다시 시도하십시오",
    giftcard_reassign_failed_phone: "이 전화 번호는 등록 된 사용자의 것이 아닙니다. 다른 전화 번호를 사용해보십시오.",
    giftcard_reassign_failed_email: "이 이메일은 등록 된 사용자가 아닙니다. 다른 이메일을 사용해보십시오.",
    no_giftcards: "보유중인 기프트카드가 없습니다",
    no_active_giftcards: "유효한 기프트 카드가 없습니다.",
    no_used_giftcards: "사용한 기프트 카드가 없습니다",
    invalid_phone: "전화 번호가 잘못되었습니다. 전화 번호의 길이가 올바른지 확인하십시오.",
    giftcard_reassign_tip:
        "기프트 카드 재 할당을 통해 구매 한 기프트 카드를 등록 된 이메일 또는 전화 번호를 통해 다른 사용자에게 보낼 수 있습니다",
    gift_card_purchased: "기프트카드 구매",
    paid_with_gift_card: "기프트카드로 결제",
    refunded: "환불 되었습니다",
    invalid_phone_number: "유효하지 않은 전화 번호",
    comment: "코멘트",
    transactions: "거래내역",
    details: "상세정보",
    date: "일자",
    balance_change: "잔액 변경",
    points_balance: "포인트 잔액",
    no_points: "현재 보상 포인트가 없습니다. 포인트를 적립하려면 지금 쇼핑하십시오!",
    view_stores: "스토어 보기",
    last_updated: "최종 업데이트: {time}",
    point_balance: "포인트잔액: {balance}",
    point_store_name: "스토어명: {name}",
    points_received: "포인트를 받았습니다",
    no_orders: "이전에 주문한 적이 없습니다",
    shop_now: "지금 쇼핑",
    pickup_method: "픽업 시간",
    delivery_method: "배달 시간",
    instantCheckout_method: "Instant Checkout",
    items: "아이템",
    ongoing: "가는 중",
    history: "기록",
    upcoming: "진행중",
    past_orders: "주문기록",
    buzz_code: "Buzz",
    make_default: "기본값으로 저장",
    add_address: "새 주소",
    are_you_sure_delete_address: "이 주소를 삭제 하시겠습니까?",
    select_address: "주소 선택",
    search_address: "주소 검색",
    current_location: "현재 위치",
    use_name_on_account: "계정에 저장된 이름 사용",
    confirm_address: "주소 확인",
    location_user_denied: "위치 서비스가 비활성화되었습니다. 현재 위치 서비스를 활성화하십시오",
    geolocation_not_supported: "현재 위치 서비스는 지원되지 않습니다. 주소 표시 줄에서 주소를 검색하십시오",
    location_general_error: "현재 위치를 찾을 수 없습니다. 나중에 다시 시도하십시오",
    self_checkout: "Quick Pay",
    amount: "금액",
    add_comment: "코멘트를 남겨주세요",
    contact_merchant: "생산자에게 연락하세요",
    powered_by_goopter: "Powered by Goopter",
    pay: "결제",
    self_checkout_disabled: "Quick pay is not available for this store.",
    quick_pay_hint_1: "Quick Pay lets you pay for your order anytime!",
    quick_pay_hint_2: "Just enter the amount due and include any information about this payment in the notes.",
    got_it: "Got It!",
    surcharge_for: "Surcharge for order: #{oid}",
    phone_number_verification: "Phone Number Verification",
    please_verify_number: "Please verify your number",
    verification_code_sent_message: "A text message with a 4-digit verification code was just sent to {phoneNumber}",
    enter_code: "Enter Code",
    try_different_number: "Try a different number",
    number_verified_message: "Your number has been verified!",
    continue_checkout_message: "Please continue with your checkout",
    get_vc_descprtion_text: "문자로 인증코드 전송",
    payment_success_title: "Payment Successful",
    payment_success_text: "Your payment has been received. You will be redirected to the order page.",
    go_to_order: "Go to order",
    qr_scanner: "Scan to Order",
    camera_instructor_title: "Camera access required",
    camera_instructor_description_dine_in:
        "The Goopter app will open your camera next. Please point the camera towards the QR code to proceed with your order.",
    camera_instructor_button_text_dine_in: "Scan to order",
    camera_error_title_order: "Camera access denied",
    camera_error_description_order: "Please allow camera access to scan the QR code for ordering.",
    camera_error_button_text_order: "Enable Camera",
    error_invalid_code_dine_in: "Invalid QR code for dine-in order for this store. Please scan the correct QR code.",
    error_invalid_code_in_store: "Invalid QR code for in-store order for this store. Please scan the correct QR code.",
    parking_lot: "Parking Lot #",
    kiosk: "Kiosk #",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "Tap camera icon to switch",
    scan_again: "Try Again",
    scanner_title: "Align QR Code/barcode within frame to scan",
    orders_today: "오늘의 주문",
    orders_total: "전체 주문",
    points_today: "오늘의 포인트",
    points_total: "전체 포인트",
    check_how_many_reward_points_from_referrals: "초대한 친구를 통해 얼마나 많은 적립 포인트를 받았는지 확인해 보세요",
    no_referrals_message:
        "아직 당신의 추천을 통해 가입한 사람이 없습니다. 친구를 초대하여 당신이 가장 좋아하는 스토어에서 사용가능한 포인트를 얻으세요!",
    order_id: "주문번호",
    no_referral_orders_found: "현재 추천인 주문이 없습니다",
    from: "From",
    pts: "pts",
    sort: "Sort",
    filter: "Filter",
    update_order: "Update Order",
    your_order: "Your Order",
    schedule_for_later: "Schedule for later",
    ready_in_asap: "Ready in {start}-{end} minutes",
    when_would_you_like_to_order: "When would you like to order",
    no_shipping_address_selected: "No shipping address selected.",
    change: "Change",
    start_order: "Start Order",
    order_for: "Order for",
    opens: "Opens",
    closes: "Closes",
    no_address_head: "No Saved Addresses",
    no_order: "No Orders",
    create_an_order: "Place an Order",
    select_category: "Select Category",
    surcharge: "Surcharge",
    store_surcharge: "Online Surcharge Fee",
    order_surcharge: "Order Surcharge",
    add_to_cart_success: "Product has been successfully added to the cart",
    table_info: "Table Info",
    serve_time: "Estimated ready time",
    overall: "Overall",
    add_a_review: "Add a review",
    rate_our_service: "Rate our service",
    rate_our_delivery: "Rate our delivery",
    post: "Post",
    email_receipt: "Email Receipt",
    email_receipt_sent_to: "Email Receipt Sent To",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    cos_privacy_statement_register: "By signing up, you agree to Goopter's {cos} and {privacy}",
    gift_cards: "Gift Cards",
    item_oos_all: "All the item(s) in the cart are out of stock, please add additional items to proceed.",
    min: "min",
    hour: "h",
    no_store_bookmarks: "No Store Bookmarks",
    no_store_bookmarks_message:
        "You have no bookmark for any store. Press the heart icon at the top right of a store home screen to bookmark a store.",
    no_store_bookmarks_button: "Add a Store Bookmark",
    no_product_bookmarks: "No Product Bookmarks",
    no_product_bookmarks_message:
        "You have no bookmarks for products. Press the heart icon associated with a product to bookmark a product.",
    no_product_bookmarks_button: "Add a Product Bookmark",
    no_group_bookmarks: "No Deal Bookmarks",
    no_group_bookmarks_message:
        "You have no bookmarks for deals. Press the heart icon associated with a deal to bookmark.",
    no_group_bookmarks_button: "Add a Deal Bookmark",
    by_password: "By Existing Password",
    op_incorrect: "Original password submitted is incorrect.",
    phone_num: "Phone #",
    change_address: "Change Address",
    alert_email_exists_sign_up:
        "The email you inputted is already in use by another account. Please sign into that account or use another email.",
    alert_email_exists_update: "The email you inputted is already in use by another account. Please use another email.",
    no_active_giftcards_head: "No Active Gift Cards",
    no_used_giftcards_head: "No Used Gift Cards",
    purchase_a_giftcard: "Purchase a Gift Card",
    no_points_head: "You Have No Points",
    no_past_orders: "No Past Orders",
    no_upcoming_orders: "No Upcoming Orders",
    eatin_method: "Due Time",
    add_a_address: "Add a New Address",
    camera_error_title: "Camera access denied",
    camera_error_description_dine_in: "Please allow camera access to scan the QR code for ordering.",
    camera_error_button_text: "Enable Camera",
    msg_country_update: "Country successfully updated.",
    msg_currency_update: "Currency successfully updated.",
    msg_birthday_update: "Birthday successfully updated.",
    msg_email_update: "Email successfully updated.",
    msg_gender_update: "Gender successfully updated.",
    msg_name_update: "Name successfuly updated",
    msg_nickname_update: "Nickname successfully updated",
    no_thanks: "No Thanks",
    verify: "Verify",
    number_already_exist:
        "This number has been registered with another account. Please logout and login with the number if you want to use this number to place an order. Alternatively you may use another phone number for your current account.",
    sign_in_with_number: "Sign in with existing number",
    try_another_number: "Try another number",
    add_instructions: "Add Instructions",
    code_not_revieved: "Didn't get code?",
    click_to_resend: "Click to resend.",
    review_recommend_store: "How likely are you to recommend {storeName} to a friend or family?",
    example_review_score: "Tell us a bit more about why you chose {score}",
    change_score: "Change score?",
    not_likely: "Not Likely",
    very_likely: "Very Likely",
    review_thank_you_recieve_coupon: "Thank you! You will recieve a coupon via text in about 1 min.",
    review_share_your_heart: "Would you be kind to share your heart to us?",
    review_copy_comment: "1. Simply copy the comment you wrote.",
    and_share_your_review: "2. And share your review.",
    thank_you: "Thank you!",
    review_thank_you_note: "If you don't receive the coupon text, reply “my-coupon” to view your coupons, Thank you!",
    share_to_google: "Share to Google",
    powered_by: "Powered by",
    quick_pay_self_serve_order_hins: "I have a self-serve order from Kiosk or QR code scan.",
    quick_pay_staff_order_hins: "A staff placed an order for me.",
    quick_pay_order_type_self_serve: "Self-serve order",
    quick_pay_order_type_staff: "Staff-assisted order",
    quick_pay_option_title: "What are you paying for?",
    quick_pay_order_type_no_order: "I do not have an order number",
    add_payment_note: "Add Payment Note",
    select: "Please Select",
    leave_quick_pay_confirm_msg: "Are you sure you want to leave quick pay?",
    self_serve_order_number: "Self-Serve Order Number",
    staff_assisted_order_number: "Staff Assisted Order Number",
    self_serve_order_number_input_placeholder: "Input self-serve order number",
    please_confirm: "Please Confirm",
    staff_assisted_order_number_input_placeholder: "Input staff assisted order number",
    invalid_order_id: "Invalid order #, please try again.",
    quick_pay_order_type_no_order_placeholder: "No order number is associated with this payment",
    quick_pay_order_id: "Payment for {orderType} #: {orderId}",
    quick_pay_confirm_msg: "Are your sure you want to pay for",
    quick_pay_confirm_msg_2: "The following order?",
    amount_due: "Amount Due",
    quick_pay_confirm_hin: "Please choose the ",
    quick_pay_confirm_hin_2: " order options if you have same order number but a different amount due.",
    staff_assisted: "staff assisted",
    quick_pay_order_type_additional_payment: "Partial or additional payment of a self-service order ",
    refunded_details: "Refund details",
    refund_reason: "Refund reason",
    deactivate_account: "Account deactivation & deletion",
    msg_deactivation: "Your account will be permanently deleted. After you delete your Goopter account, you will not be able to log in with your current account and retrieve any past orders or address records.",
    account_deactivated: "Account has been deactivated & deleted"
};

export default exports;
