import { configureStore } from "@reduxjs/toolkit";
import { reducer } from "./reducer";

//persist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createFilter from "redux-persist-transform-filter";

const persistConfig = {
    key: "cart",
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        "shoppingCart",
        "auth",
        "checkout",
        "signIn",
        "notifications",
        "stores",
        "homepage",
        "recentStores",
        "recentProducts",
        "refCode",
    ],
    transforms: [createFilter("signIn", ["accessToken", "accessSecret"])],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV === "development" ? true : false,
});
export const persistor = persistStore(store);
