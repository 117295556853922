import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    domain: "",
};

const redirect = createSlice({
    name: "redirect",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        getMobileDomainSuccess: (state, { payload }) => {
            state.domain = _.get(payload, "records.domain", "");
        },
    },
});

export const {
    //to components
    setState,
    clearData,
    //to service
    getMobileDomainSuccess,
} = redirect.actions;

export default redirect.reducer;
