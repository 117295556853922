import { createSlice } from "@reduxjs/toolkit";

const accountSettings = createSlice({
    name: "accountSettings",
    initialState: {
        tab: 0,
        counter: -1,
        isCountingDown: false,
    },
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        passwordVCTextRequestSuccess: (state) => {
            state.isCountingDown = true;
        },
    },
});

export const { setState, passwordVCTextRequestSuccess } = accountSettings.actions;

export default accountSettings.reducer;
