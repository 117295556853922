import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import config from "config";
import { getFormarttedOrderId, getOrderShippngMethod } from "./helper";

const initialState = {
    showMenuDetail: false,
    showPriceDetail: false,
    orderInstance: {},
    shortComments: {},
    comments: [],
    allowAddComment: false,
    orderUpdatePriceChanges: {},
    confirmItemChanges: null,
    confirmItemChangesChange: null,
};

const orderDetail = createSlice({
    name: "orderDetail",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        //from service
        getOrderDetailSuccess: (state, { payload }) => {
            const records = _.get(payload, "response.records", {});
            const RC = Number(_.get(payload, "response.RC", 200));
            if (RC === 200) {
                state.orderInstance = records;
                state.allowCancel = Number(records["status"]) === 1;
                state.refreshOrder = false;
                state.showMenuDetail =
                    getOrderShippngMethod(records) === config.SHIPPING_MAPPING_TO_NUMERIC.instantCheckout ||
                    records.order_total === 0;
                state.lan = payload.lan;
            } else if (RC === 204) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "order_unexist";
                state.displayModalHandlingType = "go_back";
            } else if (RC !== 401) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "general_order_error";
                state.displayModalHandlingType = "go_back";
            }
        },
        cancelOrderSuccess: (state, { payload }) => {
            const records = _.get(payload, "orderDetailReponse.records", {});
            const RC = Number(_.get(payload, "cancelOrderResponse.RC", 400));
            if (RC === 200 && records) {
                state.orderInstance = records;
                state.displayModalContent = "cancel_order_success";
                state.displayModalTitle = "success";
                state.displayModalKeywords = {
                    order_id: getFormarttedOrderId(records["id"]),
                    store_name: records["s_nm"],
                };
            } else {
                state.displayModalContent = "cancel_order_failed";
            }
            state.displayModal = true;
            state.allowCancel = false;
        },
        getShortCommentsSuccess: (state, { payload }) => {
            state.shortComments = payload.records;
        },
        getCommentsSuccess: (state, { payload }) => {
            state.comments = payload?.records ?? [];
        },
    },
});

export const {
    setState,
    clearData,
    //to service
    getOrderDetailSuccess,
    cancelOrderSuccess,
    getShortCommentsSuccess,
    getCommentsSuccess,
} = orderDetail.actions;

export default orderDetail.reducer;
