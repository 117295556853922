import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { store } from "../../containers/app/store";

const adapter = createEntityAdapter({
    selectId: (item) => item?.id,
    sortComparer: (a, b) => a.sorter - b.sorter,
});

const animatedButtons = createSlice({
    name: "animatedButtons",
    initialState: adapter.getInitialState(),
    reducers: {
        addAnimatedButton: adapter.addOne,
        editAnimatedButton: adapter.updateOne,
        removeAnimatedButton: adapter.removeOne,
        removeAllAnimatedButtons: adapter.removeAll,
    },
});

export const animatedButtonsSelector = adapter.getSelectors((state) => state.animatedButtons);

export const { addAnimatedButton, removeAnimatedButton, editAnimatedButton, removeAllAnimatedButtons } =
    animatedButtons.actions;

export const createAnimatedButton = (x, y) => async (dispatch) => {
    const state = store.getState();
    const buttons = animatedButtonsSelector.selectAll(state);

    if (buttons.length > 10) {
        for (let i = 0; i < 8; i++) {
            dispatch(removeAnimatedButton(buttons[i]?.id));
        }
    }

    const id = (new Date().valueOf() + Math.random()).toString().replace(".");
    dispatch(addAnimatedButton({ id, x, y, sorter: Number(id) }));
    let translateX;
    //If screen is larger than 1000, cart animation will go to the right cart
    if (window.innerWidth > 1000) {
        translateX = window.innerWidth - (x + 50);
    } else {
        //If screen is smaller than 1000, cart will be on the left and animation will go to left
        translateX = 0 - x;
    }
    dispatch(
        editAnimatedButton({ id, changes: { translateY: window.innerHeight - (y - 10), translateX: translateX } })
    );
};

export default animatedButtons.reducer;
