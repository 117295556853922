import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_PHONE_KEY, LOCAL_PHONE_COUNTRY_KEY, LOCAL_EMAIL_KEY } from "containers/app/constants";
import _ from "lodash";
import helper from "utils/helper";

const otherInitialState = {
    currentTab: 0,
    vc: "",
    counter: -1,
    password: "",
    showDialog: false,
    dialogTitle: "titleS",
    dialogContent: "login",
    dialogOnClose: "",
    requestTextDisabled: true,
    signInTextDisabled: true,
    isCountingDown: false,
    signInEmailDisabled: true,
    accessToken: "",
    accessSecret: "",
    consumerKey: "",
    consumerSecret: "",
    cid: "",
    gid: "",
    pid: "",
    checkout: "",
    isLoggedIn: false,
    isLoading: true,
    emailDisplayPw: "password",
    pageLoading: false,
    pingLoading: true,
};

const doNotRemoveState = {
    phoneNumber: helper.getLocalStorage(LOCAL_PHONE_KEY) || "",
    email: helper.getLocalStorage(LOCAL_EMAIL_KEY) || "",
    country_code: helper.getLocalStorage(LOCAL_PHONE_COUNTRY_KEY) || "CA",
};

const initialState = {
    ...doNotRemoveState,
    ...otherInitialState,
};

const signIn = createSlice({
    name: "signIn",
    initialState,
    reducers: {
        //from components
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        clearSensitiveData: (state, { payload }) => {
            state = Object.assign(state, otherInitialState);
        },
        setCurrentTab: (state, { payload }) => {
            state.currentTab = payload;
        },
        setPhoneNumber: (state, { payload }) => {
            state.phoneNumber = payload;
        },
        setVC: (state, { payload }) => {
            state.vc = payload;
        },
        setEmail: (state, { payload }) => {
            state.email = payload;
        },
        setPassword: (state, { payload }) => {
            state.password = payload;
        },
        setCountryCode: (state, { payload }) => {
            state.country_code = payload;
        },
        setCounter: (state, { payload }) => {
            state.counter = payload;
        },
        toggleShowDialog: (state, { payload }) => {
            state.showDialog = payload;
        },
        setDialogTitle: (state, { payload }) => {
            state.dialogTitle = payload;
        },
        setDialogContent: (state, { payload }) => {
            state.dialogContent = payload;
        },
        setDialogOnClose: (state, { payload }) => {
            state.dialogOnClose = payload;
        },
        toggleRequestTextDisabled: (state, { payload }) => {
            state.requestTextDisabled = payload;
        },
        toggleIsCountingDown: (state, { payload }) => {
            state.isCountingDown = payload;
        },
        toggleSignInTextDisabled: (state, { payload }) => {
            state.signInTextDisabled = payload;
        },
        toggleSignInEmailDisabled: (state, { payload }) => {
            state.signInEmailDisabled = payload;
        },
        setPidGidCidCheckout: (state, { payload }) => {
            state.pid = payload.pid;
            state.gid = payload.gid;
            state.cid = payload.cid;
            state.checkout = payload.checkout;
        },
        toggleEmailDisplayPw: (state, { payload }) => {
            state.emailDisplayPw = payload === "password" ? "text" : "password";
        },
        togglePageLoader: (state, { payload }) => {
            state.pageLoading = payload;
        },
        toggleAlertDialog: (state, { payload }) => {
            state.alertDialogDisplay = payload;
        },
        toggleSocialRedirectDialog: (state, { payload }) => {
            state.socialRedirectDialogDisplay = payload;
        },
        failedLoginWithSocial: (state, { payload }) => {
            state.alertDialogDisplay = true;
            state.alertDialogType = "continue";
            state.alertDialogTitle = "error";
            state.alertDialogText = "social_login_failed";
            state.alertDialogKeywords = { socialType: payload ? payload.replace(/^\w/, (c) => c.toUpperCase()) : " " };
            state.pageLoading = false;
        },

        //from service
        loginVCTextRequestSuccess: (state, { payload }) => {
            state.isCountingDown = true;
        },

        pingSessionSuccess: (state, { payload }) => {
            const RC = _.get(payload, "RC", 400);
            state.isLoggedIn = RC === 200;
            state.isLoading = false;
        },
        loginEmailSuccess: (state, { payload }) => {
            const email = _.get(payload, "payload.originalEmail","");
            helper.setLocalStorage(LOCAL_EMAIL_KEY, email);
        },
        loginTextSuccess: (state, { payload }) => {
            const phone = _.get(payload, "payload.phone", 403);
            const country = _.get(payload, "payload.country_code", 403);
            helper.setLocalStorage(LOCAL_PHONE_KEY, phone);
            helper.setLocalStorage(LOCAL_PHONE_COUNTRY_KEY, country);
        },
        getGidSuccess: (state, { payload }) => {
            const response = payload.response && payload.response;
            if (Number(response.RC) === 200) {
                state.gid = response?.records?.id;
            }
        },
    },
});

export const {
    //to components
    setState,
    clearData,
    clearSensitiveData,
    setCurrentTab,
    setPhoneNumber,
    setVC,
    setEmail,
    setPassword,
    setCountryCode,
    setCounter,
    toggleShowDialog,
    setDialogTitle,
    setDialogContent,
    setDialogOnClose,
    toggleRequestTextDisabled,
    toggleIsCountingDown,
    toggleSignInTextDisabled,
    toggleSignInEmailDisabled,
    setPidGidCidCheckout,
    toggleEmailDisplayPw,
    togglePageLoader,
    toggleAlertDialog,
    toggleSocialRedirectDialog,
    failedLoginWithSocial,
    //to service
    loginVCTextRequestSuccess,
    pingSessionSuccess,
    loginEmailSuccess,
    loginTextSuccess,
    getGidSuccess,
} = signIn.actions;

export default signIn.reducer;
