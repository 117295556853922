import _ from "lodash";
import helper, { metersToKm, metersToMiles } from "utils/helper";
import config from "config";
import { STORE_DISTANCE_UNIT_MAP } from "../store-page/helper";

const MAX_STATUS_FOR_PAY_NOW = 115; // Store Accepted
const allowPayNow = (order) => {
    return (
        _.get(order, "total_due", 0) > 0 &&
        _.get(order, "allow_paynow", 0) === 1 &&
        _.get(order, "delivery_status") < MAX_STATUS_FOR_PAY_NOW
    );
};

const isAccepted = (order) => {
    return _.get(order, "delivery_status", 280) >= 115;
};

const allowItemChange = (order) => {
    return order?.item_changes?.length > 0 && [1, 2].includes(order?.status);
};

const isRestuarant = (order) => {
    return _.get(order, "c_id", 0) === 1;
};

export const isOrderActive = (order) => {
    const orderSt = String(_.get(order, "status", 1));
    // "1": "pending",
    // "2": "processing",
    // "3": "payment_review",
    // "4": "completed",
    // "5": "canceled",
    // "6": "closed",
    // "7": "pending_payment",
    // "8": "holded"
    return orderSt === "1" || orderSt === "2";
};

const getEstimatedReadyTime = (props = {}) => {
    const ETA = props.etimatedReadyTime;
    const convertedValue = helper.utcToLocal(ETA, { asap: true, local: false, timeFormat: "hh:mm A" });
    return convertedValue === "ASAP" ? "" : convertedValue;
};

export const getOrderStatus = (order) => {
    return String(order?.st ?? order?.status);
};

export const getOrderShippngMethod = (order) => {
    return String(order?.shp_mtd || order?.shipping_method || config.SHIPPING_MAPPING_TO_NUMERIC.delivery);
};

export const getOrderStatusText = (order) => {
    const orderStatus = getOrderStatus(order);
    const orderDeliveryStatus = order?.delivery_status;
    const orderShippingMethod = getOrderShippngMethod(order);
    const orderActualStatus = orderStatus;
    const showDeliveryStatus =
        orderShippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.delivery ||
        orderShippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.pickup ||
        orderShippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.eatin;
    const deliveryStatusText = config.ORDER_DELIVERY_STATUS_MAPPING[orderDeliveryStatus];
    const orderStatusText = config.ORDER_STATUS_MAPPING[orderActualStatus];
    const orderStatusStr = showDeliveryStatus ? deliveryStatusText ?? orderStatusText : orderStatusText;
    return orderStatusStr ? orderStatusStr : " ";
};

export const getOrderShippngMethodText = (order) => {
    const method = getOrderShippngMethod(order);
    const isDineIn = method === config.SHIPPING_MAPPING_TO_NUMERIC.eatin;
    const isInStore = isDineIn && !isRestuarant(order);
    const methodStr = isInStore ? "in_store" : config.SHIPPING_MAPPING_TO_TEXT[method];
    return methodStr ?? " ";
};

export const getFormarttedOrderId = (orderNumberOriginal) => {
    const orderNum = "" + orderNumberOriginal;
    const orderNumber = orderNum.split("-")[0];
    const ORDER_NUMBER_LENGTH = 4;
    const id = orderNumber || " ";
    const dashLength = helper.isString(orderNumber) ? orderNumber.replace(/[^-]/g, "").length : 0;
    const subStringLengt = ORDER_NUMBER_LENGTH + dashLength;
    const end = orderNum.split("-")[1] ? "-" + orderNum.split("-")[1] : "";
    return id.length <= subStringLengt ? id : id.substring(id.length - subStringLengt) + end;
};

export const requestUtensil = (order) => {
    const requestUtensil = String(_.get(order, "require_utensil", 1));
    return requestUtensil === "1";
};

export const showRequestUtensil = (order) => {
    const requestUtensil = String(_.get(order, "require_utensil", 1));
    const isRestuarantLocal = isRestuarant(order);
    return isRestuarantLocal && requestUtensil !== "-1";
};

const getShortCommentOptions = (options) => {
    const selected = [];
    Object.keys(options).forEach((key) => {
        selected.push({
            label: options[key],
            value: key,
        });
    });
    return selected;
};

export const getItemOptionsArray = (options) => {
    let fullOptions = [];
    Array.isArray(options) &&
        options.forEach((optionCat) => {
            const subOptions = Array.isArray(optionCat.opts) ? optionCat.opts : [];
            //combined all with same id
            let optItems = {};
            subOptions.forEach((optItem) => {
                const qty = optItems?.[optItem?.id]?.qty ?? 0;
                const newQty = optItem?.qty ?? 1;
                optItems[optItem?.id] = {
                    ...optItem,
                    qty: qty + newQty,
                };
            });
            //put it to string
            let optItemStr = "";
            Object.values(optItems).forEach((item) => {
                const itemStr = item?.qty > 1 ? `${item?.nm} x ${item?.qty}` : item?.nm;
                optItemStr += optItemStr ? `, ${itemStr}` : itemStr;
            });
            if (!_.isEmpty(optItems)) {
                fullOptions.push({
                    nm: _.get(optionCat, "nm", ""),
                    optStr: optItemStr,
                });
            }
        });
    return fullOptions;
};

export const getItemOptionsString = (options) => {
    let fullOptionsStrs = "";
    const optionsArray = getItemOptionsArray(options);
    optionsArray.forEach((opt) => {
        const optStr = `${opt.nm} - ${opt.optStr}`;
        fullOptionsStrs += fullOptionsStrs ? `, ${optStr}` : optStr;
    });
    return fullOptionsStrs;
};

export const getOrderDisplayDistance = (order) => {
    const distanceInM = _.get(order, "distance", 0);
    const orderUnit = String(_.get(order, "distance_unit"));
    const unit = Object.values(STORE_DISTANCE_UNIT_MAP).includes(orderUnit) ? orderUnit : STORE_DISTANCE_UNIT_MAP.km;
    const unitStr = helper.getKeyByValue(STORE_DISTANCE_UNIT_MAP, unit);
    let distance = 0;
    if (unit === STORE_DISTANCE_UNIT_MAP.km) {
        distance = metersToKm(distanceInM).toFixed(2);
    } else if (unit === STORE_DISTANCE_UNIT_MAP.mi) {
        distance = metersToMiles(distanceInM).toFixed(2);
    }
    if (distance < 1) {
        return `<1 ${unitStr}`;
    } else {
        return `${distance} ${unitStr}`;
    }
};

export const getOrderMenus = (order) => {
    const orderMenus = _.get(order, "menu_ids");
    const orderMenusArray = Array.isArray(orderMenus) ? orderMenus : [];
    return _.uniqBy(orderMenusArray, "id");
};

export const getOrderMenuString = (order) => {
    const menus = getOrderMenus(order);
    let result = "";
    menus.forEach((menu) => {
        const name = (menu = _.get(menu, "name"));
        result += name ? (result ? `, ${name}` : name) : "";
    });
    return result;
};

export const getTableTitle = (order) => {
    const restaurant = isRestuarant(order);
    const locationType = String(order.location_type);
    const types = config.TABLE_TYPES;
    if (locationType === types.parking_lot) {
        return "parking_lot";
    } else if (locationType === types.kiosk) {
        return "kiosk";
    } else {
        return restaurant ? "table_number" : "table_number";
    }
};

export const getOrderSuccessExtraText = (order) => {
    const isDineIn = String(order?.shipping_method) === config.SHIPPING_MAPPING_TO_NUMERIC.eatin;
    const restaurant = isRestuarant(order);
    if (!isDineIn) {
        return "";
    }
    const types = config.TABLE_TYPES;
    const locationType = String(order.location_type);
    if (locationType === types.parking_lot) {
        return "order_success_parking_lot";
    } else if (locationType === types.kiosk) {
        return "order_success_kiosk";
    } else {
        return restaurant ? "order_success_table" : "order_success_table";
    }
};

const exports = {
    getFormarttedOrderId,
    allowPayNow,
    allowItemChange,
    getEstimatedReadyTime,
    isOrderActive,
    isRestuarant,
    getShortCommentOptions,
    isAccepted,
}

export default exports;
