import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const productsSearchAdapter = createEntityAdapter({
    selectId: (e) => (e.pid + "-" + e.nm).replace(/\s/g, "-").toLowerCase(),
    sortComparer: (a, b) => a.pid - b.pid,
});

const productsSearch = createSlice({
    name: "storesList",
    initialState: productsSearchAdapter.getInitialState({ total: 0, page: 1, noResults: false }),
    reducers: {
        getProductsSearchResultsSuccess: (state, { payload }) => {
            productsSearchAdapter.addMany(state, payload.records);
            state.total = payload?.paging?.total ?? 0;
            state.page = payload?.paging?.page ?? 0;
            state.noResults = false;
        },
        emptyProductsSearchResults: productsSearchAdapter.removeAll,
        resetProductResults: (state) => {
            state.noResults = false;
        },
        getProductsSearchResultsFailed: (state, { payload }) => {
            state.noResults = true;
        },
    },
});

export const {
    getProductsSearchResultsSuccess,
    emptyProductsSearchResults,
    getProductsSearchResultsFailed,
    resetProductResults,
} = productsSearch.actions;

export const productsSearchSelectors = productsSearchAdapter.getSelectors();

export default productsSearch.reducer;
