import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    redirectUrl: "",
    viewOrderEnable: true,
};

const checkoutSuccess = createSlice({
    name: "checkoutSucess",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setRedirectUrl: (state, { payload }) => {
            const { url, callBack } = payload;
            state.redirectUrl = url;
            state.viewOrderEnable = false;
            callBack();
        },
        clearRedirectUrl: (state, { payload }) => {
            state.redirectUrl = "";
        },
    },
});

export const {
    //to components
    setState,
    setRedirectUrl,
    clearRedirectUrl,
    //to service
} = checkoutSuccess.actions;

export default checkoutSuccess.reducer;
